import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import * as AiIcons from "react-icons/ai";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DateSelector = (props) => {
  const [startDate, setStartDate] = useState(null);
  return (
    <div>
      {props.label ? (
        <label htmlFor="Input" className="form-label">
          {props.label}
        </label>
      ) : null}
      <div style={{ display: "flex", position: "relative" }}>
        <DatePicker
          className="form-control"
          selected={props.date}
          onChange={(date) => props.setDate(date)}
          placeholderText={props.placeholder}
        />
        <AiIcons.AiOutlineCalendar
          fontSize={"1.8rem"}
          style={{ position: "absolute", right: "5px", margin: "5px" }}
        />
      </div>
    </div>
  );
};

export default DateSelector;
