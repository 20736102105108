//TAG:AUTHENTICATION

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import WarningMessage from "../../components/Messages/WarningMessage";
import SuccessMessage from "../../components/Messages/SuccessMessage";
import { forgotPassword, loginUser } from "../../features/authentication/api";
import BoldHoverable from "../../components/Buttons/BoldHoverable";
import Input from "../../components/Forms/Input";
import { useLocation, useNavigate } from "react-router-dom";

export default function Login(props) {
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const token = await loginUser({
                email,
                password,
            });
            console.log(token);

            if (token.error) {
                console.log("error");
                setError(token.error);
                return true;
            } else {
                console.log("sucess");
                setError(null);
                props.setUser(token);
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleForgotPassword = async (e) => {
        try {
            const res = await forgotPassword({
                email: email,
            });
            if (res.error) {
                setError(res.error);
                setSuccess(null);
            }
            if (res.success) {
                setSuccess(res.success);
                setError(null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const reset = () => {
        setError(null);
        setSuccess(null);
        setEmail(null);
        setPassword(null);
    };

    let loc = useLocation();
    let nav = useNavigate();
    return (
        <>
            <Modal
                show={props.popup.show}
                onHide={() => {
                    props.handleClose();
                    reset();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <WarningMessage message={error} />}
                    {success && <SuccessMessage message={success} />}

                    <Input
                        id="email-input"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Input
                        id="password-input"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                        }}
                    >
                        <BoldHoverable onClick={handleForgotPassword}>
                            Forgot password?
                        </BoldHoverable>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.handleClose();
                            reset();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        onClick={(e) => {
                            handleLogin(e).then((error) => {
                                if (!error) {
                                    props.handleClose();
                                    if (loc.pathname === "/") {
                                        nav("/launchpad");
                                    }
                                }
                            });
                        }}
                    >
                        {props.submit}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
