import currency from "currency.js";
/*
money.js
functions to calculate money related totals
all functions expect unformatted parameters (usually float strings)

*/
export function sumMoney(amountList) {
  var sum = currency(0);
  for (let i in amountList) {
    sum = sum.add(amountList[i]);
  }
  return sum.value;
}

export function addMoney(amount1, amount2) {
  const currency1 = currency(amount1);
  const currency2 = currency(amount2);
  return currency1.add(currency2).value;
}
export function subtractMoney(amount1, amount2) {
  const currency1 = currency(amount1);
  const currency2 = currency(amount2);
  return currency1.subtract(currency2).value;
}

//quantity*price
export function computeLineSubtotal(quantity, price) {
  const quantityCurrency = currency(quantity);
  const priceCurrency = currency(price);
  return quantityCurrency.multiply(priceCurrency).value;
}

//quantity*price*discount
export function computeDiscount(subtotal, discount) {
  const lineSubtotalCurrency = currency(subtotal);
  return lineSubtotalCurrency.multiply(discount).value;
}

//quantity*price*discount
export function multiply(mulitplicand, multiplier) {
  const money = currency(mulitplicand);
  return money.multiply(multiplier).value;
}

//quantity * price * (1-discount)
export function computeLineDiscountSubtotal(lineSubtotal, discount) {
  const lineSubtotalCurrency = currency(lineSubtotal);
  return lineSubtotalCurrency.multiply(1 - discount).value;
}

//quantity * price * (1 - discount) * tax
export function computeLineTaxes(discountSubtotal, tax) {
  const discountSubtotalCurrency = currency(discountSubtotal);
  return discountSubtotalCurrency.multiply(tax).value;
}

//quantity * price * (1-discount) + Summation(quantity * price * (1 - discount) * tax)
export function computeLineTotal(discountSubtotal, taxList) {
  console.log(discountSubtotal);
  console.log(taxList);
  var sumCurrency = currency(discountSubtotal);
  for (var i in taxList) {
    console.log(sumCurrency);
    console.log(computeLineTaxes(discountSubtotal, taxList[i].percentage));
    sumCurrency = sumCurrency.add(
      computeLineTaxes(discountSubtotal, taxList[i].percentage)
    );
  }
  console.log(sumCurrency.value);
  return sumCurrency.value;
}

export function computeSubtotal() {}

export function discountTotal() {}

export function taxTotal() {}

export function total() {}

export function amountDue() {}
