import React from "react";
import "./filters.css";

function DropdownFilter(props) {
  return (
    <div className="filter">
      <select
        style={{ height: "28px" }}
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
      >
        <option value="">All</option>
        {props.list.map((item, index) => {
          return (
            <option className="dropdownOption" key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default DropdownFilter;
