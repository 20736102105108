import React from "react";

import { Link, useNavigate, useParams, useState } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { SortingTable } from "../../../components/Tables/Contacts/SortingTable";
import "bootstrap/dist/css/bootstrap.min.css";

import "./Contacts.css";
import ReportBox from "../../../components/Reports/ReportBox";
import DateSelector from "../../../components/Forms/DateSelector";
import Dropdown from "../../../components/Forms/Dropdown";
import * as BsIcons from "react-icons/bs";
import { api_url } from "../../../util/environment";
import { currentBusiness } from "../../../features/authentication/util";
import {
    deleteCustomer,
    deleteVendor,
    getCustomersWithCompanies,
    getVendorsWithCompanies,
} from "../../Site/api";

export default class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            contacts: props.contacts,
            popup: {
                show: false,
                id: null,
            },
            category: null,
        };

        this.rowClick = this.rowClick.bind(this);
    }

    componentDidMount() {
        this.getAll();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.category !== prevProps.category) {
            this.getAll();
        }
    }

    getAll = async () => {
        if (this.props.category == "customers") {
            this.setState({ category: "customers" });
            getCustomersWithCompanies().then((data) => {
                this.setState({ contacts: data });
            });
        } else if (this.props.category == "vendors") {
            this.setState({ category: "vendors" });
            getVendorsWithCompanies().then((data) => {
                this.setState({ contacts: data });
            });
        } else {
            console.log("not customer or vendor");
        }
    };

    editContact = async (e) => {
        //const { id } = useParams();
        //console.log(id)

        // console.log(e.target.id);
        // console.log(this.props.navigate);
        this.props.navigate(
            "/" +
                this.props.type +
                "/" +
                this.props.category +
                "/update/" +
                e.target.id
        );
    };

    createInvoice = (id) => {
        console.log(id);
        this.props.navigate("/sales/invoices/add/" + id);
    };

    deleteContact = async (e) => {
        console.log("delete");
        this.setState({
            popup: {
                show: true,
                id: e.target.id,
            },
        });
    };

    handleDeleteTrue = async () => {
        const id = this.state.popup.id;
        if (this.props.type == "sales") {
            deleteCustomer(id).then(() => {
                this.getAll();
            });
        } else if (this.props.type == "purchases") {
            deleteVendor(id).then(() => {
                this.getAll();
            });
        } else {
            console.error("Unknown type of contact: ", this.props.type);
        }

        this.setState({
            popup: {
                show: false,
                id: null,
            },
        });
    };
    handleDeleteFalse() {
        this.setState({
            popup: {
                show: false,
                id: null,
            },
        });
    }

    rowClick = async (e, id) => {
        console.log(e.target);
        const element = e.target;
        if (!element.nextSibling) {
            console.log("last column!!!");
        } else if (element.className.includes("btn")) {
            console.log("button");
        } else if (element.className == "dropdown-item") {
            console.log("dropdown button!!");
        } else {
            //this.props.navigate("/" + this.props.category + "/" + id);
        }
    };

    render() {
        return (
            <div className="contactsContainer" key={this.props.category}>
                <div className="top-left">
                    <h1>
                        {this.props.category.charAt(0).toUpperCase() +
                            this.props.category.slice(1)}
                    </h1>
                </div>
                <div className="top-middle-left"></div>
                <div className="top-middle-right"></div>
                <div className="top-right">
                    <Link
                        to={
                            "/" +
                            this.props.type +
                            "/" +
                            this.props.category +
                            "/add"
                        }
                    >
                        <button
                            type="button"
                            className="btn btn-success"
                            style={{ marginTop: "10px" }}
                        >
                            Add{" "}
                            {this.props.category.charAt(0).toUpperCase() +
                                this.props.category.slice(
                                    1,
                                    this.props.category.length - 1
                                )}
                        </button>
                    </Link>
                </div>
                <div className="summaryReport">
                    <ReportBox
                        reportLabel={
                            "Total Number of " +
                            this.props.category.charAt(0).toUpperCase() +
                            this.props.category.slice(
                                1,
                                this.props.category.length
                            )
                        }
                        report={this.state.contacts.length}
                    />
                    <BsIcons.BsPlusCircle
                        style={{ margin: "10px", cursor: "pointer" }}
                        title={"Add another Report"}
                    />
                </div>
                <div
                    className="filterBar"
                    style={{
                        pointerEvents: "none",
                        opacity: "0.5",
                        filter: "blur(1px)",
                    }}
                >
                    <div
                        className="contactFilter1"
                        style={{ marginRight: "10px" }}
                    >
                        <select
                            style={{ height: "28px" }}
                            defaultValue={"Active"}
                        >
                            {["Current", "Active", "Obsolete"].map(
                                (item, index) => {
                                    return (
                                        <option
                                            className="dropdownOption"
                                            key={item}
                                            value={item}
                                        >
                                            {item}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                    </div>
                    <div
                        className="contactFilter2"
                        style={{ marginRight: "10px" }}
                    >
                        <DateSelector placeholder={"From"} />
                    </div>
                    <div
                        className="contactFilter3"
                        style={{ marginRight: "10px" }}
                    >
                        <DateSelector placeholder={"To"} />
                    </div>
                </div>

                <div className="contactTable hoverTable">
                    <SortingTable
                        category={this.props.category}
                        data={this.state.contacts}
                        onClickEdit={this.editContact}
                        onClickDelete={this.deleteContact}
                        onClickRow={this.rowClick}
                        onClickCreateInvoice={this.createInvoice}
                        onClickCreateEstimate={(id) =>
                            this.props.navigate("/sales/estimates/add/" + id)
                        }
                        onClickCreateSaleOrder={(id) =>
                            this.props.navigate("/sales/orders/add/" + id)
                        }
                        onClickCreateBill={(id) =>
                            this.props.navigate("/purchases/bills/add/" + id)
                        }
                        onClickCreatePurchaseOrder={(id) =>
                            this.props.navigate("/purchases/orders/add/" + id)
                        }
                    />
                </div>

                <Modal
                    show={this.state.popup.show}
                    onHide={this.handleDeleteFalse.bind(this)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you would like to delete?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.handleDeleteFalse.bind(this)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            onClick={this.handleDeleteTrue.bind(this)}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
