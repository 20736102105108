import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function TaxProfile() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Tax Profile" />
        <div>TaxProfile</div>
      </div>
    </div>
  );
}

export default TaxProfile;
