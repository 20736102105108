import currency from "currency.js";

export const ALPHANUMERICSYMBOLIC = `0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_ +=-:.,<>/?'"[]{}|`;
export const ALPHANUMERIC = `0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ `;
export const NUMERIC = "0123456789.";
export const PHONESTRING = `0123456789-()`;

export const PRECISION_DICTIONARY = {
  total: 2,
  price: 2,
  quantity: 2,
  tax: 0,
  discountForm: 1, //allow one decimal place in form e.g. 10.1%
  discount: 3, //store in calculable form e.g. 0.101
};

export function toUSD(str, category) {
  return currency(str, { precision: PRECISION_DICTIONARY[category] }).format();
}

export function toFloatg(str, category) {
  var curr = currency(str, { precision: PRECISION_DICTIONARY[category] });
  var floatStr = curr.value.toString();
  if (!floatStr.includes(".")) {
    floatStr = floatStr + ".";
  }
  const numDecimals = floatStr.toString().split(".")[1].length;
  for (var i = numDecimals; i < PRECISION_DICTIONARY[category]; i++) {
    floatStr = floatStr + "0";
  }
  return floatStr;
}

export function toPercentage(num, category) {
  var s = Number(num).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: PRECISION_DICTIONARY[category],
    maximumFractionDigits: PRECISION_DICTIONARY[category],
  });
  return s;
}

export function toFormattedNumber(str) {
  console.log(str);
  console.log(parseFloat(str));
  str = parseFloat(str).toLocaleString("en-US", {
    maximumFractionDigits: str.split(".")[0].length,
  }); // "1,234.57";
  console.log(str);
  return str;
}
export function toTwoDecimals(str) {
  str = parseFloat(str).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }); // "1,234.57";
  console.log(str);
  return str;
}

export function addCommas(str) {
  const bothParts = str.split(".");
  const integerPart = bothParts[0];
  const decimalPart = bothParts[1];
  var formatted = "";
  if (decimalPart != null) {
    formatted =
      integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + decimalPart;
  } else {
    formatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return formatted;
}
