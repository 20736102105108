import React, { useState, useRef, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { PHONESTRING } from "../../../util/formatters";
import { toFormattedNumber } from "../../../util/formatters";

function validateNumeric(event, validationString) {
  const notNeccessaryKey =
    event.key != "Backspace" &&
    event.key != "Delete" &&
    event.key != "ArrowLeft" &&
    event.key != "ArrowRight";

  if (!validationString.includes(event.key)) {
    if (notNeccessaryKey) {
      return false;
    }
  } else {
    return true;
  }
}

export default class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.initialValue || "+1-(___)-___-____" };
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({ value: this.props.initialValue });
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
    this.props.changeText(e.target.value);
    console.log(this.ref.current);
  };
  render() {
    return (
      <div style={{ width: this.props.width }}>
        {this.props.disabled ? (
          this.state.value
        ) : (
          <ContentEditable
            id={this.props.id}
            ref={this.ref}
            html={this.state.value}
            onChange={(e) => this.handleChange(e)}
            onKeyDown={(e) => {
              var caretPos = e.view.getSelection().baseOffset;
              if (
                e.key != "Backspace" &&
                e.key != "Delete" &&
                e.key != "ArrowLeft" &&
                e.key != "ArrowRight"
              ) {
                e.preventDefault();
              } else if (e.key == "Backspace") {
                e.preventDefault();
                if ("0123456789_".includes(this.state.value[caretPos - 1])) {
                  const newValue =
                    this.state.value.substring(0, caretPos - 1) +
                    "_" +
                    this.state.value.substring(caretPos);
                  this.setState({ value: newValue }, function () {
                    this.props.changeText(newValue);
                    setTimeout(() => {
                      var el = document.getElementById(this.props.id);
                      var range = document.createRange();
                      var sel = window.getSelection();
                      console.log(el.childNodes);
                      range.setStart(el.childNodes[0], caretPos - 1);
                      range.collapse(true);

                      sel.removeAllRanges();
                      sel.addRange(range);
                    }, 10);
                  });
                } else if (
                  this.state.value[caretPos - 1] == "-" ||
                  this.state.value[caretPos - 1] == "("
                ) {
                  if ("0123456789_".includes(this.state.value[caretPos - 2])) {
                    caretPos = caretPos - 1;
                  } else {
                    caretPos = caretPos - 2;
                  }
                  const newValue =
                    this.state.value.substring(0, caretPos - 1) +
                    "_" +
                    this.state.value.substring(caretPos);
                  this.setState({ value: newValue }, function () {
                    this.props.changeText(newValue);
                    setTimeout(() => {
                      var el = document.getElementById(this.props.id);
                      var range = document.createRange();
                      var sel = window.getSelection();
                      console.log(el.childNodes);
                      range.setStart(el.childNodes[0], caretPos - 1);
                      range.collapse(true);

                      sel.removeAllRanges();
                      sel.addRange(range);
                    }, 10);
                  });
                }

                console.log("backspace");
              } else if (e.key == "Delete") {
                console.log("delete");
              }

              if (validateNumeric(e, PHONESTRING)) {
                if (
                  this.state.value[caretPos] == "_" ||
                  "0123456789".includes(this.state.value[caretPos])
                ) {
                  if ("0123456789".includes(e.key)) {
                    const newValue =
                      this.state.value.substring(0, caretPos) +
                      e.key +
                      this.state.value.substring(caretPos + 1);
                    this.setState({ value: newValue }, function () {
                      this.props.changeText(newValue);
                      setTimeout(() => {
                        var el = document.getElementById(this.props.id);
                        var range = document.createRange();
                        var sel = window.getSelection();
                        console.log(el.childNodes);
                        range.setStart(el.childNodes[0], caretPos + 1);
                        range.collapse(true);

                        sel.removeAllRanges();
                        sel.addRange(range);
                      }, 10);
                    });
                  }
                } else if (this.state.value[caretPos] == ")") {
                  if (e.key == ")") {
                    const newValue =
                      this.state.value.substring(0, caretPos) +
                      e.key +
                      this.state.value.substring(caretPos + 1);
                    this.setState({ value: newValue }, function () {
                      this.props.changeText(newValue);
                      setTimeout(() => {
                        var el = document.getElementById(this.props.id);
                        var range = document.createRange();
                        var sel = window.getSelection();
                        console.log(el.childNodes);
                        range.setStart(el.childNodes[0], caretPos + 1);
                        range.collapse(true);

                        sel.removeAllRanges();
                        sel.addRange(range);
                      }, 10);
                    });
                  } else if ("0123456789".includes(e.key)) {
                    const newValue =
                      this.state.value.substring(0, caretPos + 2) +
                      e.key +
                      this.state.value.substring(caretPos + 3);
                    this.setState({ value: newValue }, function () {
                      this.props.changeText(newValue);
                      setTimeout(() => {
                        var el = document.getElementById(this.props.id);
                        var range = document.createRange();
                        var sel = window.getSelection();
                        console.log(el.childNodes);
                        range.setStart(el.childNodes[0], caretPos + 3);
                        range.collapse(true);

                        sel.removeAllRanges();
                        sel.addRange(range);
                      }, 10);
                    });
                  }
                } else if (this.state.value[caretPos] == "-") {
                  if (e.key == "-") {
                    const newValue =
                      this.state.value.substring(0, caretPos) +
                      e.key +
                      this.state.value.substring(caretPos + 1);

                    this.setState({ value: newValue }, function () {
                      this.props.changeText(newValue);
                      setTimeout(() => {
                        var el = document.getElementById(this.props.id);
                        var range = document.createRange();
                        var sel = window.getSelection();
                        console.log(el.childNodes);
                        range.setStart(el.childNodes[0], caretPos + 1);
                        range.collapse(true);

                        sel.removeAllRanges();
                        sel.addRange(range);
                      }, 10);
                    });
                  } else if ("0123456789".includes(e.key)) {
                    if (
                      this.state.value[caretPos + 1] == "_" ||
                      "0123456789".includes(this.state.value[caretPos + 1])
                    ) {
                      console.log("next");
                    } else {
                      console.log("double next");
                      caretPos = caretPos + 1;
                    }
                    const newValue =
                      this.state.value.substring(0, caretPos + 1) +
                      e.key +
                      this.state.value.substring(caretPos + 2);
                    this.setState({ value: newValue }, function () {
                      setTimeout(() => {
                        var el = document.getElementById(this.props.id);
                        var range = document.createRange();
                        var sel = window.getSelection();
                        console.log(el.childNodes);
                        range.setStart(el.childNodes[0], caretPos + 2);
                        range.collapse(true);

                        sel.removeAllRanges();
                        sel.addRange(range);
                      }, 20);
                    });
                  }
                } else if (this.state.value[caretPos] == "(") {
                  if (e.key == "(") {
                    const newValue =
                      this.state.value.substring(0, caretPos) +
                      e.key +
                      this.state.value.substring(caretPos + 1);
                    this.setState({ value: newValue }, function () {
                      this.props.changeText(newValue);
                      setTimeout(() => {
                        var el = document.getElementById(this.props.id);
                        var range = document.createRange();
                        var sel = window.getSelection();
                        console.log(el.childNodes);
                        range.setStart(el.childNodes[0], caretPos + 1);
                        range.collapse(true);

                        sel.removeAllRanges();
                        sel.addRange(range);
                      }, 10);
                    });
                  } else if ("0123456789".includes(e.key)) {
                    const newValue =
                      this.state.value.substring(0, caretPos + 1) +
                      e.key +
                      this.state.value.substring(caretPos + 2);
                    this.setState({ value: newValue }, function () {
                      this.props.changeText(newValue);
                      setTimeout(() => {
                        var el = document.getElementById(this.props.id);
                        var range = document.createRange();
                        var sel = window.getSelection();
                        console.log(el.childNodes);
                        range.setStart(el.childNodes[0], caretPos + 2);
                        range.collapse(true);

                        sel.removeAllRanges();
                        sel.addRange(range);
                      }, 20);
                    });
                  }
                }
              }
            }}
            onFocus={(e) => {
              if (this.state.value == "Phone") {
                this.setState({ value: "+1-(___)-___-____" }, function () {
                  setTimeout(() => {
                    var el = document.getElementById(this.props.id);
                    var range = document.createRange();
                    var sel = window.getSelection();
                    console.log(el.childNodes);
                    range.setStart(el.childNodes[0], 4);
                    range.collapse(true);

                    sel.removeAllRanges();
                    sel.addRange(range);
                  }, 20);
                });
              } else {
                setTimeout(() => {
                  var el = document.getElementById(this.props.id);
                  var range = document.createRange();
                  var sel = window.getSelection();
                  console.log(el.childNodes);
                  range.setStart(el.childNodes[0], 4);
                  range.collapse(true);

                  sel.removeAllRanges();
                  sel.addRange(range);
                }, 20);
              }
            }}
          />
        )}
      </div>
    );
  }
}
