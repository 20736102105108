import React from "react";
import PermissionGate from "../../../features/authentication/components/PermissionGate";
import { mapObject } from "../../../util/jsonUtil";
import SettingsSidebar from "../SettingsSidebar";

const permissions = {
    Settings: [
        {
            id: 1,
            identifier: "manage_users",
            name: "Manage Users",
            description: "View/Edit users",
            membership: "lite,pro,premium",
            label: "Settings",
        },
        {
            id: 2,
            identifier: "manage_roles",
            name: "Manage Roles",
            description: "View/Edit roles",
            membership: "lite,pro,premium",
            label: "Settings",
        },
        {
            id: 3,
            identifier: "view_permissions",
            name: "View Permissions",
            description: "View permissions",
            membership: "lite,pro,premium",
            label: "Settings",
        },
        {
            id: 4,
            identifier: "customize_documents",
            name: "Customize Documents",
            description: "View/Edit default document settings",
            membership: "pro,premium",
            label: "Settings",
        },
        {
            id: 5,
            identifier: "manage_payment_methods",
            name: "Manage Payment Methods",
            description: "View/Edit payment methods",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 6,
            identifier: "manage_dates_and_currency",
            name: "Manage Dates and Currency",
            description: "View/Edit date information and currency",
            membership: "lite,pro,premium",
            label: "Settings",
        },
        {
            id: 7,
            identifier: "manage_sales_taxes",
            name: "Manage Sales Taxes",
            description: "View/Edit sales taxes",
            membership: "lite,pro,premium",
            label: "Settings",
        },
        {
            id: 8,
            identifier: "manage_default_accounts",
            name: "Manage Default Accounts",
            description: "View/Edit default accounts",
            membership: "lite,pro,premium",
            label: "Settings",
        },
        {
            id: 9,
            identifier: "manage_payouts",
            name: "Manage Payouts",
            description: "View/Edit bank payouts",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 10,
            identifier: "manage_business_profile",
            name: "Manage Business Profile",
            description: "View/Edit business profile",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 11,
            identifier: "manage_company_signatory",
            name: "Manage company signatory",
            description: "View/Edit Company Signatory",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 12,
            identifier: "manage_funding_account",
            name: "Manage Funding Account",
            description: "View/Edit funding account",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 13,
            identifier: "manage_tax_profile",
            name: "Manage Tax Profile",
            description: "View/Edit tax profile",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 14,
            identifier: "manage_payroll_history_import",
            name: "Import Payroll History",
            description: "View/Edit payroll history",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 15,
            identifier: "manage_billing",
            name: "Manage Billing",
            description: "View/Edit billing information",
            membership: "premium",
            label: "Settings",
        },
        {
            id: 16,
            identifier: "data_export",
            name: "Data Export",
            description: "Ability to export data",
            membership: "lite,pro,premium",
            label: "Settings",
        },
        {
            id: 17,
            identifier: "data_import",
            name: "Data Import",
            description: "Ability to import data",
            membership: "lite,pro,premium",
            label: "Settings",
        },
    ],
    General: [
        {
            id: 18,
            identifier: "launchpad",
            name: "View Launchpad",
            description: "View the launchpad page",
            membership: "lite,pro,premium",
            label: "General",
        },
        {
            id: 19,
            identifier: "dashboard",
            name: "Manage Dashboard",
            description: "View/Edit the dashboard page",
            membership: "lite,pro,premium",
            label: "General",
        },
        {
            id: 20,
            identifier: "advisors",
            name: "Manage Advisors",
            description: "View/Edit the advisors page",
            membership: "premium",
            label: "General",
        },
        {
            id: 21,
            identifier: "tax_filing",
            name: "Tax Filings",
            description: "View/Edit the tax filings page",
            membership: "premium",
            label: "General",
        },
        {
            id: 22,
            identifier: "console",
            name: "Manage Console",
            description: "View the console page",
            membership: "premium",
            label: "General",
        },
    ],
    Reports: [
        {
            id: 23,
            identifier: "income_statement",
            name: "Income Statement",
            description: "View/Edit the income statement",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 24,
            identifier: "balance_sheet",
            name: "Balance Sheet",
            description: "View/Edit the balance sheet",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 25,
            identifier: "cash_flow_statement",
            name: "Cash Flow Statement",
            description: "View/Edit the cash flow statement",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 26,
            identifier: "sales_tax_report",
            name: "Sales Tax Report",
            description: "View/Edit sales tax report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 27,
            identifier: "payroll_wage_and_tax_report",
            name: "Payroll Wage and Tax Report",
            description: "View/Edit payroll wage and tax report",
            membership: "premium",
            label: "Reports",
        },
        {
            id: 28,
            identifier: "payroll_benefits_and_deductions_report",
            name: "Payroll Benefits and Deductions Report",
            description: "View/Edit payroll benefits and deductions report",
            membership: "premium",
            label: "Reports",
        },
        {
            id: 29,
            identifier: "income_by_customer",
            name: "Income by Customer",
            description: "View income by customer report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 30,
            identifier: "income_by_product",
            name: "TODO: Income by Product",
            description: "View income by product report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 31,
            identifier: "aged_receivables",
            name: "Aged Receivables",
            description: "View/Edit aged receivables report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 32,
            identifier: "purchases_by_vendor",
            name: "Purchases by Vendor",
            description: "View/Edit purchases by vendor report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 33,
            identifier: "cost_of_goods_sold",
            name: "TODO: Cost of Goods Sold",
            description: "View/Edit cost of goods sold report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 34,
            identifier: "aged_payables",
            name: "Aged Payables",
            description: "View/Edit aged payables report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 35,
            identifier: "account_balances",
            name: "Account Balances",
            description: "View/Edit account balances report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 36,
            identifier: "trial_balance",
            name: "Trial Balance",
            description: "View/Edit trial balance report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
        {
            id: 37,
            identifier: "general_ledger",
            name: "General Ledger",
            description: "View/Edit general ledger report",
            membership: "lite,pro,premium",
            label: "Reports",
        },
    ],
    Sales: [
        {
            id: 38,
            identifier: "customers",
            name: "Manage Customers",
            description: "View/Edit customers",
            membership: "lite,pro,premium",
            label: "Sales",
        },
        {
            id: 39,
            identifier: "budgetary_estimates",
            name: "Manage Budgetary Estimates",
            description: "View/Edit budgetary estimates",
            membership: "pro,premium",
            label: "Sales",
        },
        {
            id: 40,
            identifier: "estimates",
            name: "Manage Estimates",
            description: "View/Edit estimates",
            membership: "pro,premium",
            label: "Sales",
        },
        {
            id: 41,
            identifier: "sales_orders",
            name: "Manage Sales Orders",
            description: "View/Edit sales orders",
            membership: "pro,premium",
            label: "Sales",
        },
        {
            id: 42,
            identifier: "invoices",
            name: "Manage Invoices",
            description: "View/Edit invoices",
            membership: "pro,premium",
            label: "Sales",
        },
        {
            id: 43,
            identifier: "recurring_invoices",
            name: "Manage Recurring Invoices",
            description: "View/Edit recurring invoices",
            membership: "pro,premium",
            label: "Sales",
        },
        {
            id: 44,
            identifier: "sales_products_and_services",
            name: "Manage Sales Side Products and Services",
            description: "View/Edit sales side products and services",
            membership: "lite,pro,premium",
            label: "Sales",
        },
        {
            id: 45,
            identifier: "sales_categories",
            name: "Manage Sales Side Categories",
            description: "View/Edit sales side categories",
            membership: "lite,pro,premium",
            label: "Sales",
        },
        {
            id: 46,
            identifier: "customer_statements",
            name: "Manage Customer Statements",
            description: "View/Edit customer statements",
            membership: "lite,pro,premium",
            label: "Sales",
        },
        {
            id: 47,
            identifier: "checkouts",
            name: "Manage Checkouts",
            description: "View/Edit checkouts",
            membership: "premium",
            label: "Sales",
        },
        {
            id: 48,
            identifier: "payments",
            name: "Manage Payments",
            description: "View/Edit payments",
            membership: "premium",
            label: "Sales",
        },
    ],
    Purchases: [
        {
            id: 49,
            identifier: "vendors",
            name: "Manage Vendors",
            description: "View/Edit vendors",
            membership: "lite,pro,premium",
            label: "Purchases",
        },
        {
            id: 50,
            identifier: "purchase_orders",
            name: "Manage Purchase Orders",
            description: "View/Edit purchase orders",
            membership: "pro,premium",
            label: "Purchases",
        },
        {
            id: 51,
            identifier: "bills",
            name: "Manage Bills",
            description: "View/Edit bills",
            membership: "pro,premium",
            label: "Purchases",
        },
        {
            id: 52,
            identifier: "recurring_bills",
            name: "Manage Recurring Bills",
            description: "View/Edit recurring bills",
            membership: "pro,premium",
            label: "Purchases",
        },
        {
            id: 53,
            identifier: "purchases_products_and_services",
            name: "Manage Purchases Side Products and Services",
            description: "View/Edit purchases side products and services",
            membership: "lite,pro,premium",
            label: "Purchases",
        },
        {
            id: 54,
            identifier: "purchases_categories",
            name: "Manage Purchases Side Categories",
            description: "View/Edit purchases side categories",
            membership: "lite,pro,premium",
            label: "Purchases",
        },
        {
            id: 55,
            identifier: "vendor_statements",
            name: "Manage Vendor Statements",
            description: "View/Edit vendor statements",
            membership: "pro,premium",
            label: "Purchases",
        },
    ],
    Accounting: [
        {
            id: 56,
            identifier: "transactions",
            name: "Manage Transactions",
            description: "View/Edit transactions",
            membership: "lite,pro,premium",
            label: "Accounting",
        },
        {
            id: 57,
            identifier: "reconciliation",
            name: "Manage Reconciliation",
            description: "View/Edit reconciliation",
            membership: "lite,pro,premium",
            label: "Accounting",
        },
        {
            id: 58,
            identifier: "chart_of_accounts",
            name: "Manage Chart of Accounts",
            description: "View/Edit chart of accounts",
            membership: "lite,pro,premium",
            label: "Accounting",
        },
    ],
    Inventory: [
        {
            id: 59,
            identifier: "stock",
            name: "Manage Stock",
            description: "View/Edit stock",
            membership: "premium",
            label: "Inventory",
        },
        {
            id: 60,
            identifier: "work_orders",
            name: "Manage Work Orders",
            description: "View/Edit work orders",
            membership: "premium",
            label: "Inventory",
        },
        {
            id: 61,
            identifier: "parts_list",
            name: "Manage Parts List",
            description: "View/Edit parts list",
            membership: "premium",
            label: "Inventory",
        },
        {
            id: 62,
            identifier: "assemblies",
            name: "Manage Assemblies",
            description: "View/Edit assemblies",
            membership: "premium",
            label: "Inventory",
        },
        {
            id: 63,
            identifier: "packing_lists",
            name: "Manage Packing Lists",
            description: "View/Edit packing lists",
            membership: "premium",
            label: "Inventory",
        },
        {
            id: 64,
            identifier: "bill_of_materials",
            name: "Manage Bill of Materials",
            description: "View/Edit bill of materials",
            membership: "premium",
            label: "Inventory",
        },
    ],
    Management: [
        {
            id: 65,
            identifier: "throughput",
            name: "Manage Throughput",
            description: "View/Edit throughput",
            membership: "lite,pro,premium",
            label: "Management",
        },
        {
            id: 66,
            identifier: "constraints",
            name: "Manage Constraints",
            description: "View/Edit constraints",
            membership: "lite,pro,premium",
            label: "Management",
        },
        {
            id: 67,
            identifier: "what_if_analysis",
            name: "Manage What-If Analysis",
            description: "View/Edit what-if analysis",
            membership: "lite,pro,premium",
            label: "Management",
        },
    ],
    Banking: [
        {
            id: 68,
            identifier: "asp_money",
            name: "Manage ASP Money",
            description: "View/Edit ASP money",
            membership: "premium",
            label: "Banking",
        },
        {
            id: 69,
            identifier: "connected_accounts",
            name: "Manage Connected Accounts",
            description: "View/Edit connected accounts",
            membership: "premium",
            label: "Banking",
        },
        {
            id: 70,
            identifier: "payouts",
            name: "Manage Payouts",
            description: "View/Edit payouts",
            membership: "premium",
            label: "Banking",
        },
        {
            id: 71,
            identifier: "insurance",
            name: "Manage Insurance",
            description: "View/Edit insurance",
            membership: "premium",
            label: "Banking",
        },
    ],
    Payroll: [
        {
            id: 72,
            identifier: "run_payroll",
            name: "Run Payroll",
            description: "Run payroll",
            membership: "premium",
            label: "Payroll",
        },
        {
            id: 73,
            identifier: "employees",
            name: "Manage Employees",
            description: "View/Edit employees",
            membership: "premium",
            label: "Payroll",
        },
        {
            id: 74,
            identifier: "timesheets",
            name: "Manage Timesheets",
            description: "View/Edit timesheets",
            membership: "premium",
            label: "Payroll",
        },
        {
            id: 75,
            identifier: "payroll_transactions",
            name: "Manage Payroll Transactions",
            description: "View/Edit payroll transactions",
            membership: "premium",
            label: "Payroll",
        },
        {
            id: 76,
            identifier: "taxes",
            name: "Manage Taxes",
            description: "View/Edit taxes",
            membership: "premium",
            label: "Payroll",
        },
        {
            id: 77,
            identifier: "tax_forms",
            name: "Manage Tax Forms",
            description: "View/Edit tax forms",
            membership: "premium",
            label: "Payroll",
        },
        {
            id: 78,
            identifier: "covid_19",
            name: "COVID-19",
            description: "View COVID-19 related information",
            membership: "premium",
            label: "Payroll",
        },
    ],
};

function Permissions() {
    return (
        <div className="page-container">
            <div style={{ display: "flex" }}>
                <SettingsSidebar active="Permissions" />

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "calc(100% - var(--sidebar-width))",
                    }}
                >
                    Permissions. View is a read only permission. Edit is a
                    create/update/delete permission.
                    {mapObject(permissions).map((permission) => {
                        console.log(permission);
                        return (
                            <div style={{ width: "50%" }}>
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {permission[0]}:
                                </div>{" "}
                                {permission[1].map((p) => {
                                    return (
                                        <div style={{ marginLeft: "10px" }}>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {p.name}
                                            </div>{" "}
                                            - {p.description}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Permissions;
