import { useNavigate } from "react-router-dom";
import Contacts from "./Contacts";

const WrappedContacts = (props) => {
  return (
    <Contacts
      title={props.title}
      navigate={useNavigate()}
      contacts={props.contacts}
      category={props.category}
      type={props.type}
    />
  );
};

export default WrappedContacts;
