import React from "react";
import * as TiIcons from "react-icons/ti";

function AdditionalContact(props) {
  return (
    <div>
      Additional Contact
      <div className="row g-3">
        <div className="mb-3 col-sm-7 contact">
          <input
            type="text"
            className="form-control"
            id={"contactName" + props.index}
            placeholder="Contact Name"
            value={props.additionalContactList[props.index]["name"]}
            onChange={(e) => props.onChange(e, props.id, "name")}
          />
        </div>
        <div className="mb-3 col">
          <input
            type="text"
            className="form-control"
            id={"contactPosition" + props.index}
            placeholder="Position"
            value={props.additionalContactList[props.index]["position"]}
            onChange={(e) => props.onChange(e, props.id, "position")}
          />
        </div>
      </div>
      <div className="row g-3 lastRow">
        <div className="mb-3 col-sm-7">
          <input
            type="email"
            className="form-control"
            id={"contactEmail" + props.index}
            placeholder="Email address"
            value={props.additionalContactList[props.index]["email"]}
            onChange={(e) => props.onChange(e, props.id, "email")}
          />
        </div>
        <div className="mb-3 col">
          <input
            type="text"
            className="form-control"
            id={"contactPhoneNumber" + props.index}
            placeholder="Phone Number"
            value={props.additionalContactList[props.index]["phone"]}
            onChange={(e) => props.onChange(e, props.id, "phone")}
          />
        </div>
      </div>
      <div onClick={props.onClick} className="deleteIcon">
        <TiIcons.TiDeleteOutline />
      </div>
      <br></br>
    </div>
  );
}

export default AdditionalContact;
