import React from "react";
import { TiDeleteOutline } from "react-icons/ti";
import styled from "styled-components";

const CloseDiv = styled.div`
  color: black;
  cursor: pointer;
  &:hover {
    color: darkred !important;
  }
`;

function CloseIcon(props) {
  return (
    <CloseDiv onClick={props.onClick}>
      <TiDeleteOutline />
    </CloseDiv>
  );
}

export default CloseIcon;
