import React from "react";
import { useNavigate } from "react-router-dom";

import { MdOutlineArrowForwardIos } from "react-icons/md";

import "./Reports.css";

function Reports() {
  let nav = useNavigate();
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        maxWidth: "100vh",
      }}
    >
      <h1>Reports</h1>
      {/* -----------------------financial statements--------------------- */}
      <div
        style={{
          display: "flex",
          border: "1px solid rgb(180, 194, 203)",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{ width: "50%" }}>
          {" "}
          <div
            style={{
              margin: "30px 0 0 30px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Financial Statements
          </div>
          <div style={{ margin: "10px 30px 0 30px", whiteSpace: "pre-wrap" }}>
            Get a clear picture of how your business is doing. Use these core
            statements to better understand your financial health.
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              borderBottom: "1px solid gray",
              margin: "30px 30px 0 0",
            }}
            onClick={() => nav("/reports/income-statement")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">
                Profit & Loss (Income Statement)
              </div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              Shows your business’s net profit and summarizes your revenues and
              expenses in a given time period.
            </div>
          </div>
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              borderBottom: "1px solid gray",
              margin: "30px 30px 0 0",
            }}
            onClick={() => nav("/reports/balance-sheet")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Balance Sheet</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              A snapshot of your finances on a given day. The balance sheet
              calculates your business’s worth (equity) by subtracting what you
              owe (liabilities) from what you own (assets).
            </div>
          </div>
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",

              margin: "30px 30px 30px 0",
            }}
            onClick={() => nav("/reports/cash-flow-statement")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Cash Flow</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              Shows how much money is entering and leaving your business. The
              cash flow statement tells you how much cash you have on hand for a
              specific time period.
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------taxes--------------------- */}
      <div
        style={{
          display: "flex",
          border: "1px solid rgb(180, 194, 203)",
          borderRadius: "10px",
          marginBottom: "10px",
          pointerEvents: "none",
          opacity: "0.5",
          filter: "blur(1px)",
        }}
      >
        <div style={{ width: "50%" }}>
          {" "}
          <div
            style={{
              margin: "30px 0 0 30px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Taxes
          </div>
          <div style={{ margin: "10px 30px 0 30px", whiteSpace: "pre-wrap" }}>
            A detailed look at the taxes you owe, based on sales taxes you
            collect and pay.
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",

              margin: "30px 30px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Sales Tax Report</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              A breakdown of taxes collected from sales and paid on purchases.
              You can use this information to prepare sales tax returns and
              calculate your balance or refund.
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------payroll--------------------- */}
      <div
        style={{
          display: "flex",
          border: "1px solid rgb(180, 194, 203)",
          borderRadius: "10px",
          marginBottom: "10px",
          pointerEvents: "none",
          opacity: "0.5",
          filter: "blur(1px)",
        }}
      >
        <div style={{ width: "50%" }}>
          {" "}
          <div
            style={{
              margin: "30px 0 0 30px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Payroll
          </div>
          <div style={{ margin: "10px 30px 0 30px", whiteSpace: "pre-wrap" }}>
            Track payroll costs, including employee wages and
            benefits/deductions, and your payroll tax liabilities.
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              borderBottom: "1px solid gray",
              margin: "30px 30px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Payroll Wage & Tax Report</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              A breakdown of employee wage and payroll tax amounts.
            </div>
          </div>
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              margin: "30px 30px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">
                Payroll Benefits & Deductions Report
              </div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              Amounts added to or deducted from your employees’ regular wages.
              Includes information for year-end reporting.
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------customers--------------------- */}
      <div
        style={{
          display: "flex",
          border: "1px solid rgb(180, 194, 203)",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{ width: "50%" }}>
          {" "}
          <div
            style={{
              margin: "30px 0 0 30px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Customers
          </div>
          <div style={{ margin: "10px 30px 0 30px", whiteSpace: "pre-wrap" }}>
            Stay on top of overdue invoices and identify high-value or
            late-paying customers.
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              borderBottom: "1px solid gray",
              margin: "30px 30px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Income by Customer</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              A breakdown of paid and unpaid income for every customer.
            </div>
          </div>
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              margin: "30px 30px 0 0",
            }}
            onClick={() => nav("/reports/aging-summary-ar")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Aged Receivables</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              Unpaid and overdue invoices for the last 30, 60, and 90+ days.
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------Vendors--------------------- */}
      <div
        style={{
          display: "flex",
          border: "1px solid rgb(180, 194, 203)",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{ width: "50%" }}>
          {" "}
          <div
            style={{
              margin: "30px 0 0 30px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Vendors
          </div>
          <div style={{ margin: "10px 30px 0 30px", whiteSpace: "pre-wrap" }}>
            Track your business spending. See where your money goes, and how
            much you owe to vendors.
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              borderBottom: "1px solid gray",
              margin: "30px 30px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Purchases by Vendor</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              A breakdown of purchases and bills from every vendor.
            </div>
          </div>
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              margin: "30px 30px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Aged Payables</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              Unpaid and overdue bills for the last 30, 60, and 90+ days.
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------Detailed Reporting--------------------- */}
      <div
        style={{
          display: "flex",
          border: "1px solid rgb(180, 194, 203)",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{ width: "50%" }}>
          {" "}
          <div
            style={{
              margin: "30px 0 0 30px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Detailed Reporting
          </div>
          <div style={{ margin: "10px 30px 0 30px", whiteSpace: "pre-wrap" }}>
            Dig into the details of your business’s transactions, balances, and
            accounts.
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              borderBottom: "1px solid gray",
              margin: "30px 30px 0 0",
            }}
            onClick={() => nav("/reports/account-balances")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Account Balances</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              Summary view of balances and activity for all accounts.
            </div>
          </div>
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              borderBottom: "1px solid gray",
              margin: "30px 30px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">Trial Balance</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              The sum of debits and credits for all accounts on a single day.
              Helps you check for discrepancies to ensure your accounts balance.
            </div>
          </div>
          <div
            className="reportLink"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              margin: "30px 30px 0 0",
            }}
            onClick={() => nav("/reports/general-ledger")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "blue",
                fontSize: "20px",
              }}
            >
              <div className="reportButtonTitle">General Ledger</div>
              <MdOutlineArrowForwardIos />
            </div>

            <div style={{ whiteSpace: "pre-wrap" }}>
              Your complete financial activity: A detailed list of all
              transactions and totals for every account in your Chart of
              Accounts.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
