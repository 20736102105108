export function debitShouldAdd(category) {
  if (category == "Assets" || category == "Expenses") {
    return true;
  } else if (
    category == "Liabilities" ||
    category == "Equity" ||
    category == "Income"
  ) {
    return false;
  } else {
    console.error("Invalid category", category);
  }
}

export function isCategory(category) {
  if (
    category == "Assets" ||
    category == "Expenses" ||
    category == "Liabilities" ||
    category == "Equity" ||
    category == "Income"
  ) {
    return true;
  } else {
    return false;
  }
}
