import React from "react";
import "./TileButtonRaised.css";
function TileButtonRaised(props) {
  return (
    <div className="tileButtonRaised" onClick={props.onClick}>
      <div className="tileContainer">
        <div className="tileImage">{props.image}</div>
        <div className="tileTitle">{props.title}</div>
      </div>
    </div>
  );
}

export default TileButtonRaised;
