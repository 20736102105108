import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Input from "../Forms/Input";

function DeleteConfirmationWithNotes(props) {
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (document.getElementById("reason-for-deletion")) {
      document
        .getElementById("reason-for-deletion")
        .classList.remove("is-invalid");
    }
  }, [reason]);

  return (
    <Modal
      show={props.popup.show}
      onHide={() => {
        setReason("");
        props.handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <form class="needs-validation">
          <label htmlFor="labledInput" className="form-label">
            {"Are you sure you want to delete?"}
          </label>
          <input
            type="text"
            className="form-control"
            id={"reason-for-deletion"}
            placeholder={"Please provide a reason for deletion..."}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <div id="reason-for-deletion-feedback" class="invalid-feedback">
            A reason for deletion is required.
          </div>
        </form>{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setReason("");
            props.handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="danger"
          onClick={() => {
            if (reason === "") {
              if (
                !document
                  .getElementById("reason-for-deletion")
                  .classList.contains("is-invalid")
              ) {
                document
                  .getElementById("reason-for-deletion")
                  .classList.add("is-invalid");
              }
            } else {
              props.handleDelete(reason);
            }
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationWithNotes;
