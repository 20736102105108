export const profileSidebarData = [
  {
    title: "Personal Information",
    path: "/profile/personal-information",
    membership: ["lite", "pro", "premium"],
  },
  {
    title: "Password",
    path: "/profile/password",
    membership: ["lite", "pro", "premium"],
  },
  {
    title: "Notifications",
    path: "/profile/notifications",
    membership: ["lite", "pro", "premium"],
  },
  {
    title: "Businesses",
    path: "/profile/businesses",
    membership: ["lite", "pro", "premium"],
  },
  {
    title: "Subscription",
    path: "/profile/subscription",
    membership: ["lite", "pro", "premium"],
  },
];
