import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { SettingsContext } from "../../../App";
import { getRollsWithPermissions } from "../../../features/authentication/api";
import SettingsSidebar from "../SettingsSidebar";

// const rolls = [
//     {
//         name: "Admin",
//         permissions: [
//             "Sales",
//             "Purchases",
//             "Accounting",
//             "Inventory",
//             "Management",
//             "Banking",
//             "Payroll",
//             "Reports",
//             "Settings",
//         ],
//     },
//     {
//         name: "Purchasing Agent",
//         permissions: ["Purchases", "Inventory"],
//     },
//     {
//         name: "Sales Agent",
//         permissions: ["Sales", "Inventory"],
//     },
//     {
//         name: "Accountant",
//         permissions: ["Accounting"],
//     },
//     {
//         name: "Inventory Manager",
//         permissions: ["Inventory"],
//     },
// ];

function Rolls() {
    let { user } = useContext(SettingsContext);

    const [rolls, setRolls] = useState(null);

    useEffect(() => {
        getRollsWithPermissions(user?.business?.id).then((data) => {
            console.log(data);
            setRolls(data);
        });
    }, []);

    return (
        <div className="page-container">
            <div style={{ display: "flex" }}>
                <SettingsSidebar active="Rolls" />

                <div
                    style={{
                        display: "flex",
                        width: "calc(100% - var(--sidebar-width))",
                    }}
                >
                    <div
                        style={{
                            width: "50%",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <div className="transactionsTable">
                            <table
                                width="100%"
                                style={{
                                    "table-layout": "fixed",
                                    marginTop: "10px",
                                }}
                            >
                                <thead className="transactionsTableHeader">
                                    <tr style={{ height: "40px" }}>
                                        <th
                                            style={{
                                                textIndent: "10px",
                                                width: "30%",
                                            }}
                                        >
                                            Name
                                        </th>

                                        <th
                                            style={{
                                                textIndent: "10px",
                                                width: "30%",
                                            }}
                                        >
                                            Permissions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rolls?.map((item, index) => {
                                        return (
                                            <tr className="transactionRow">
                                                <td style={{ display: "flex" }}>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.admin ? "All" : ""}
                                                    {item.permissions.map(
                                                        (subitem, j) => {
                                                            return (
                                                                <div>
                                                                    {
                                                                        subitem.identifier
                                                                    }
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rolls;
