import React from "react";

import ContentEditable from "react-contenteditable";
import { NUMERIC } from "../../../util/formatters";
import { toFormattedNumber } from "../../../util/formatters";
import { toFloatg } from "../../../util/formatters";
import { toUSD } from "../../../util/formatters";

function validateNumeric(
  event,
  validationString,
  current,
  maxLength,
  maxDecimals
) {
  const decimalPortion = current.split(".")[1];
  console.log(event.view.getSelection().baseOffset);
  console.log(current.indexOf("."));

  const notNeccessaryKey =
    event.key != "Backspace" &&
    event.key != "Delete" &&
    event.key != "ArrowLeft" &&
    event.key != "ArrowRight";

  // validate decimal portion doesn't exceed limit
  if (current.includes(".")) {
    //if typing in decimal part of number
    if (event.view.getSelection().baseOffset > current.indexOf(".")) {
      if (decimalPortion.length >= maxDecimals) {
        if (notNeccessaryKey) {
          if (
            event.view.getSelection().baseOffset -
              event.view.getSelection().extentOffset ==
            0
          ) {
            console.log("PREVENT DEFUALT");
            event.preventDefault();
          }
        }
      }
    }
  }
  if (!validationString.includes(event.key)) {
    if (notNeccessaryKey) {
      event.preventDefault();
    }
  } else if (event.key == ".") {
    if (current.toString().includes(".")) {
      event.preventDefault();
    }
  }
  if (current.length >= maxLength) {
    if (notNeccessaryKey) {
      if (
        event.view.getSelection().baseOffset -
          event.view.getSelection().extentOffset ==
        0
      ) {
        event.preventDefault();
      }
    }
  }
}

export default class PriceInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: toUSD(props.initialValue, "price") || "$0.00",
      focused: false,
    };
  }

  componentDidUpdate(prevProps) {
    console.log("update");
    if (this.props.initialValue !== prevProps.initialValue) {
      if (this.state.focused) {
        this.setState({
          value: this.props.initialValue || "0.00",
        });
      } else {
        this.setState({
          value: toUSD(this.props.initialValue, "price") || "$0.00",
        });
      }
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
    const storeFormat = toFloatg(e.target.value, "price");
    this.props.changeText(storeFormat);
  };
  render() {
    return (
      <div style={{ width: this.props.width }}>
        {this.props.disabled ? (
          this.state.value
        ) : (
          <ContentEditable
            id={this.props.id ? this.props.id : "content-editable"}
            html={this.state.value}
            onChange={(e) => this.handleChange(e)}
            onKeyDown={(e) =>
              validateNumeric(
                e,
                NUMERIC,
                this.state.value,
                this.props.limit,
                this.props.decimalLimit
              )
            }
            onBlur={(e) => {
              if (this.props.negative) {
                this.setState({
                  value: "(" + toUSD(this.state.value, "price") + ")",
                  focused: false,
                });
              } else {
                this.setState({
                  value: toUSD(this.state.value, "price"),
                  focused: false,
                });
              }
            }}
            onFocus={(e) => {
              this.setState({
                value: toFloatg(this.state.value.replace(/\(/g, ""), "price"),

                focused: true,
              });
              // e.target.select();
              if (this.props.id) {
                setTimeout(() => {
                  var el = document.getElementById(this.props.id);
                  var range = document.createRange();
                  range.selectNodeContents(el);
                  var sel = window.getSelection();
                  sel.removeAllRanges();
                  sel.addRange(range);
                }, 20);
              }
            }}
          />
        )}
      </div>
    );
  }
}
