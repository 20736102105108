export function toKebabCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
}

export function pixelStringToNumber(str) {
  return parseInt(str.replace("px", ""));
}
