import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function PaymentMethods() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Payment Methods" />
        <div>PaymentMethods</div>
      </div>
    </div>
  );
}

export default PaymentMethods;
