import { currentBusiness } from "../../../features/authentication/util";

import { api_url } from "../../../util/environment";

export async function getExpenseTransactions() {
    const response = await fetch(
        api_url + "/accounting/transactions/expense/user/" + currentBusiness()
    );
    return response.json();
}

export async function getIncomeTransactions() {
    const response = await fetch(
        api_url + "/accounting/transactions/income/user/" + currentBusiness()
    );
    return response.json();
}
