import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { COLUMNS } from "./columns";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoTrashOutline } from "react-icons/io5";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./table.css";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { adjustProductStock } from "../../../pages/Inventory/api";
// import './table.css'

export const SortingTable = (props) => {
  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, [])
  //const data = useMemo(() => props.data, [])
  console.log("in SORTING TABLE");
  console.log(props.data);

  const tableInstance = useTable(
    {
      columns: columns,
      data: props.data,
    },
    useSortBy,
    usePagination
  ); // initialState: { pageIndex : user.settings.page.tablePageNumber }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize } = state;
  return (
    <>
      <table
        {...getTableProps()}
        width="100%"
        style={{ "table-layout": "fixed" }}
      >
        <colgroup>
          <col style={{ width: "60%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span>▾</span>
                      ) : (
                        <span>▴</span>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
              <th style={{ textAlign: "center", marginRight: "30px" }}>
                Actions
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={"tableRows"}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
                <td
                  style={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {/* <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip
                        placement="bottom"
                        className="in"
                        id="tooltip-bottom"
                      >
                        Decrement {row.original.name} Stock
                      </Tooltip>
                    }
                  >
                    <div
                      className="adjustStockIcon"
                      onClick={() => {
                        adjustProductStock(row.original.id, -1).then(() =>
                          props.refresh()
                        );
                      }}
                    >
                      {" "}
                      <RiArrowDownSFill />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip
                        placement="bottom"
                        className="in"
                        id="tooltip-bottom"
                      >
                        Increment {row.original.name} Stock
                      </Tooltip>
                    }
                  >
                    <div
                      className="adjustStockIcon"
                      onClick={() => {
                        adjustProductStock(row.original.id, 1).then(() =>
                          props.refresh()
                        );
                      }}
                    >
                      {" "}
                      <RiArrowUpSFill />
                    </div>
                  </OverlayTrigger> */}

                  <div
                    className="editIcon"
                    onClick={() => props.editProduct(row.original.id)}
                  >
                    <MdOutlineModeEditOutline />
                  </div>
                  <div
                    className="trashIcon"
                    style={{ fontSize: "20px", color: "blue" }}
                    onClick={() => props.deleteProduct(row.original.id)}
                  >
                    <IoTrashOutline />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="tableFooter" style={{ userSelect: "none" }}>
        <div>
          <span>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: "50px" }}
              min={"1"}
              max={pageCount}
            />
          </span>{" "}
          Items per page:{" "}
          <select
            style={{ width: "50px", height: "2em" }}
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </Button>
          <span style={{ marginRight: "10px" }}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </Button>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </div>
      </div>
    </>
  );
};
