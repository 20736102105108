import React from "react";
import ImagePreview from "../../../../pages/Sales/Invoices/Form/ImagePreview";
import { getDate, getDateLong } from "../../../../util/dates";
import { addMoney, subtractMoney } from "../../../../util/money";
import { toUSD } from "../../../../util/formatters";

function OutstandingInvoicesHeader(props) {
  const calculateOverdue = (list) => {
    const invoicesOverdue = list.filter(
      (invoice) => new Date(invoice.due_date) < new Date()
    );

    const result = invoicesOverdue.reduce(
      (previousValue, currentValue) =>
        addMoney(
          previousValue,
          subtractMoney(currentValue["amount_due"], currentValue["amount_paid"])
        ),
      0
    );
    return result;
  };
  const calculateYetDue = (list) => {
    const invoicesYetDue = list.filter(
      (invoice) => new Date(invoice.due_date) > new Date()
    );

    const result = invoicesYetDue.reduce(
      (previousValue, currentValue) =>
        addMoney(
          previousValue,
          subtractMoney(currentValue["amount_due"], currentValue["amount_paid"])
        ),
      0
    );
    return result;
  };
  const calculateTotal = (list) => {
    console.log(list);
    const result = list.reduce(
      (previousValue, currentValue) =>
        addMoney(
          previousValue,
          subtractMoney(currentValue["amount_due"], currentValue["amount_paid"])
        ),
      0
    );
    return result;
  };
  const headerStyle = {
    display: "flex",
    flexDirection: "column",
    height: "340px",
  };
  console.log(props);
  const Underline = () => (
    <hr
      style={{
        borderTop: "1px solid #000000",
        opacity: "0.2",
        margin: "0px",
      }}
      width="100%"
    />
  );
  return (
    <div style={headerStyle}>
      <div style={{ display: "flex", width: "100%", height: "40%" }}>
        <div
          style={{ width: "50%", display: "flex", alignItems: "flex-start" }}
        >
          <div style={{ width: "50%", height: "60%" }}>
            <ImagePreview url={"/1_hCBlqxgSsc1acciJoWDwVA.jpeg"} />
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <h4>Statement of Account</h4>
          <div>{props.title}</div>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", height: "60%" }}>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ marginBottom: "15px" }}>Bill to </div>
          <div style={{ fontWeight: "bold" }}>
            {props.customer.primary_name}
          </div>
          <div>{props.customer.billing_address}</div>
          <div>
            {props.customer.billing_city} , {props.customer.billing_state}{" "}
            {props.customer.billing_zip_code}
          </div>
          <div>{props.customer.billing_country}</div>
        </div>
        <div
          style={{
            width: "50%",
            textAlign: "right",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <h5>United States Dollar (USD)</h5>
          <div>As of {getDateLong(new Date())} </div>
          <Underline />
          <table width="100%">
            <thead>
              <tr>
                <th width="70%"></th>
                <th width="30%"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Overdue</td>
                <td>{toUSD(calculateOverdue(props.lineItems), "total")}</td>
              </tr>
              <tr>
                <td>Not yet due</td>
                <td>{toUSD(calculateYetDue(props.lineItems), "total")}</td>
              </tr>
              <tr>
                <td>Outstanding Balance (USD) </td>
                <td>{toUSD(calculateTotal(props.lineItems), "total")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OutstandingInvoicesHeader;
