import React from 'react'
import './ReportBox.css'

function ReportBox(props) {
  return (
    <div class="reportBox">
        <h6>{props.reportLabel}</h6>
        <p>{props.report}</p>
    </div>
  )
}

export default ReportBox