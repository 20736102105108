import React, { useState, useRef, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { NUMERIC } from "../../../util/formatters";
import { toFormattedNumber } from "../../../util/formatters";

function validateNumeric(
  event,
  validationString,
  current,
  maxLength,
  maxDecimals
) {
  const decimalPortion = current.split(".")[1];
  console.log(event.view.getSelection().baseOffset);
  console.log(current.indexOf("."));

  const notNeccessaryKey =
    event.key != "Backspace" &&
    event.key != "Delete" &&
    event.key != "ArrowLeft" &&
    event.key != "ArrowRight";

  // validate decimal portion doesn't exceed limit
  if (current.includes(".")) {
    //if typing in decimal part of number
    if (event.view.getSelection().baseOffset > current.indexOf(".")) {
      if (decimalPortion.length >= maxDecimals) {
        if (notNeccessaryKey) {
          if (
            event.view.getSelection().baseOffset -
              event.view.getSelection().extentOffset ==
            0
          ) {
            console.log("PREVENT DEFUALT");
            event.preventDefault();
          }
        }
      }
    }
  }
  if (!validationString.includes(event.key)) {
    if (notNeccessaryKey) {
      event.preventDefault();
    }
  } else if (event.key == ".") {
    if (current.toString().includes(".")) {
      event.preventDefault();
    }
  }
  if (current.length >= maxLength) {
    if (notNeccessaryKey) {
      if (
        event.view.getSelection().baseOffset -
          event.view.getSelection().extentOffset ==
        0
      ) {
        console.log("PREVENT DEFUALT");
        event.preventDefault();
      }
    }
  }
}

export default class NumericInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.initialValue || props.placeholder || "1" };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({ value: this.props.initialValue });
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
    this.props.changeText(e.target.value);
  };
  render() {
    return (
      <div style={{ width: this.props.width }}>
        {this.props.disabled ? (
          this.state.value
        ) : (
          <ContentEditable
            html={this.state.value}
            onChange={(e) => this.handleChange(e)}
            onKeyDown={(e) =>
              validateNumeric(
                e,
                NUMERIC,
                this.state.value,
                this.props.limit,
                this.props.decimalLimit
              )
            }
            onBlur={(e) => {
              if (this.state.value == "" || null) {
                this.setState({
                  value: this.props.placeholder,
                });
              } else {
                const bothParts = this.state.value.split(".");
                const integerPart = bothParts[0];
                const decimalPart = bothParts[1];
                var formatted = "";
                console.log(decimalPart);
                if (decimalPart != null) {
                  formatted =
                    integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    "." +
                    decimalPart;
                } else {
                  formatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }

                this.setState({
                  value: formatted,
                });
              }
            }}
            onFocus={(e) => {
              if (this.state.value == this.props.placeholder) {
                this.setState({ value: "" });
              } else {
                this.setState({ value: this.state.value.replace(/,/g, "") });
              }
            }}
          />
        )}
      </div>
    );
  }
}
