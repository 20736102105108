import React, { useState, useEffect } from "react";
import { getThroughput } from "../../pages/Dashboards/api";
import { daysInMonth, getWeekNumber } from "../../util/dates";
import { toUSD } from "../../util/formatters";
import { multiply, subtractMoney } from "../../util/money";
import BarDashboard from "./BarDashboard";
import GeneralDashboard from "./GeneralDashboard";

function WeeklyNetThroughput() {
  const [throughputSums, setThroughputSums] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  useEffect(() => {
    getThroughput().then((data) => {
      var sums = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i in data) {
        sums[new Date(data[i].issue_date).getMonth()] += subtractMoney(
          multiply(data[i].price, data[i].quantity),
          multiply(data[i].tvc, data[i].quantity)
        );
      }
      console.log(sums);
      for (let i in sums) {
        sums[i] = sums[i] / (daysInMonth(i + 1, new Date().getFullYear()) / 7);
        console.log(daysInMonth(i + 1, new Date().getFullYear()) / 7);
      }
      setThroughputSums(sums);
    });
  }, []);

  var weeklyNetThroughputOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Monthly Average Weekly Throughput",
      },
    },
    grouped: false,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Month",
          font: {
            size: 18,
            weight: "bold",
          },
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            });
          },
        },

        grid: {
          color: "#ccc",
          borderColor: "black",
          tickColor: "black",
        },
      },
    },
  };
  var weeklyNetThroughputData = {
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "4-Week Net Profit",
        data: throughputSums,
        backgroundColor: "rgb(55, 63, 180)",
      },
    ],
  };

  return (
    <GeneralDashboard
      title={"Weekly Net Throughput Dashboard"}
      subtitle={
        "Current Average Weekly Net Throughput: " +
        toUSD(
          throughputSums.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ) / getWeekNumber(new Date()),
          "total"
        )
      }
      subheader={<div style={{ margin: "30px" }}> </div>}
      mainTableHeader={"Monthly Average Weekly Net Throughput"}
      mainTableData={[
        ["January", throughputSums[0]],
        ["February", throughputSums[1]],
        ["March", throughputSums[2]],
        ["April", throughputSums[3]],
        ["May", throughputSums[4]],
        ["June", throughputSums[5]],
        ["July", throughputSums[6]],
        ["August", throughputSums[7]],
        ["September", throughputSums[8]],
        ["October", throughputSums[9]],
        ["November", throughputSums[10]],
        ["December", throughputSums[11]],
      ]}
      sideTableOne={
        <table style={{ width: "120px" }}>
          <thead>
            <tr style={{ border: "1px solid black" }}>
              <th colspan="2" style={{ textAlign: "center" }}>
                Quarterly Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black" }}>Q1</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  throughputSums[0] + throughputSums[1] + throughputSums[2],
                  "total"
                )}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>Q2</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  throughputSums[3] + throughputSums[4] + throughputSums[5],
                  "total"
                )}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>Q3</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  throughputSums[6] + throughputSums[7] + throughputSums[8],
                  "total"
                )}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>Q4</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  throughputSums[9] + throughputSums[10] + throughputSums[11],
                  "total"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      }
      sideTableTwo={
        <table style={{ width: "120px" }}>
          <thead>
            <tr style={{ border: "1px solid black" }}>
              <th style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    whiteSpace: "pre-wrap",
                    width: "100px",
                  }}
                >
                  Year To Date Total
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  throughputSums.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue,
                    0
                  ),
                  "total"
                )}
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <th style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    whiteSpace: "pre-wrap",
                    width: "100px",
                  }}
                >
                  Year To Date Average
                </div>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  throughputSums.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue,
                    0
                  ) / 12,
                  "total"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      }
      chart={
        throughputSums.every((item) => item === 0) ? (
          <>
            <div
              style={{
                pointerEvents: "none",
                opacity: "0.3",
                filter: "blur(2px)",
                height: "100%",
                width: "100%",
              }}
            >
              <BarDashboard
                options={weeklyNetThroughputOptions}
                data={{
                  labels: [
                    "Jan",
                    "Feb",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                  datasets: [
                    {
                      label: "Monthly Average Weekly Throughput",
                      data: Array.from(
                        { length: 12 },
                        () => Math.random() * 100
                      ),
                      backgroundColor: "rgb(55, 63, 180)",
                    },
                  ],
                }}
              />
            </div>
            <div style={{ position: "absolute", fontWeight: "bold" }}>
              No throughput has been recorded
            </div>
          </>
        ) : (
          <BarDashboard
            options={weeklyNetThroughputOptions}
            data={weeklyNetThroughputData}
          />
        )
      }
    />
  );
}

export default WeeklyNetThroughput;
