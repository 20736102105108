import React from "react";
import "./OutlineButton.css";

function OutlineButton(props) {
  var fontSize = null;
  if (props.size === "small") {
    fontSize = "var(--font-size-small)";
  } else if (props.size === "medium") {
    fontSize = "var(--font-size-medium)";
  } else if (props.size === "large") {
    fontSize = "var(--font-size-large)";
  } else {
    console.error("Invalid size for OutlineButton");
  }
  return (
    <button
      className="outline-button"
      style={{
        backgroundColor: "var(--background)",
        color: "var(--main-button-color",
        border: "1px solid var(--main-button-color)",
        borderRadius: "20px",
        fontSize: fontSize,
      }}
      onClick={(e) => props.onClick(e)}
      id={props.id}
    >
      {props.title}
    </button>
  );
}

export default OutlineButton;
