import React from "react";
import Account from "./Account.js";
import * as AiIcons from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";

import "./FlexibleAccount.css";

function FlexibleAccount(props) {
  return (
    <div
      className="flexAccount"
      style={{ display: "flex", "flex-direction": "column", width: "100%" }}
    >
      <div className="accountHeader" style={{ width: "100%", padding: "10px" }}>
        <strong>{props.type}</strong>
        <AiIcons.AiOutlineQuestionCircle
          title={props.description}
          style={{ marginLeft: "10px" }}
        />
      </div>
      {props.accounts.map((item, index) => {
        return (
          <div key={item.id}>
            {item.type == props.type ? (
              <Account
                id={item.id}
                type={props.type}
                category={props.category}
                subcategory={props.subcategory}
                handleOpenEdit={props.handleOpenEdit}
                account_number={item.account_number}
                name={item.name}
                description={item.description}
              />
            ) : null}
          </div>
        );
      })}
      <div
        id={props.type}
        data-category={props.category}
        data-subcategory={props.subcategory}
        className="addAccountButton"
        onClick={props.handleOpenAdd}
      >
        <BsPlusCircle
          style={{ margin: "10px", cursor: "pointer" }}
          title={"Add another Account"}
        />
        Add a new Account
      </div>
    </div>
  );
}

export default FlexibleAccount;
