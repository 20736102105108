import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListPage from "../../../../components/Lists/ListPage";
import ReportBox from "../../../../components/Reports/ReportBox";
import DropdownFilter from "../../../../components/Filters/DropdownFilter";
import DateFilter from "../../../../components/Filters/DateFilter";
import {
  approveDocument,
  sendDocument,
  deleteDocument,
} from "../../../Sales/api";
import { ColumnFilter } from "../../../../components/Tables/Estimates/ColumnFilter";

import { toUSD } from "../../../../util/formatters";

import { daysUntil, daysSince, formatDate } from "../../../../util/dates";
import DeleteConfirmation from "../../../../components/Popups/DeleteConfirmation";

import SendInvoice from "../../../Sales/Invoices/Send/SendInvoice";
import ErrorPopup from "../../../../components/Popups/ErrorPopup";
import {
  approveRecurringBill,
  getRecurringBillsWithSchedules,
} from "../../api";

const columns = [
  {
    Header: "#",
    accessor: "id",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: ColumnFilter,
  },
  {
    Header: "Next Invoice",
    accessor: "next_generation",
    Filter: ColumnFilter,
    Cell: (props) => {
      return formatDate(new Date(props.value + " UTC"));
    },
  },
  {
    Header: "Schedule",
    accessor: "period_type",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Starting Date",
    accessor: "starting_date",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (props) => {
      return formatDate(new Date(props.value + " UTC"));
    },
  },
  {
    Header: "Ending Date",
    accessor: "ending_date",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (props) => {
      if (props.value == null) {
        return "Never";
      } else {
        return formatDate(new Date(props.value + " UTC"));
      }
    },
  },
  {
    Header: "Amount Due",
    accessor: "amount_due",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (props) => toUSD(props.value, "total"),
  },
];

//Page to define a ListPage by describing table data, summary data, and row actions
function RecurringBills() {
  let navigate = useNavigate();
  const [dropdownFilter, setDropdownFilter] = useState("");
  const [dateFilterFrom, setDateFilterFrom] = useState("");
  const [dateFilterTo, setDateFilterTo] = useState("");

  const [data, setData] = useState([]);

  const [popup, setPopup] = useState({ show: false, id: null });

  const [sendPopup, setSendPopup] = useState({
    show: false,
    toEmail: null,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  function refresh() {
    getRecurringBillsWithSchedules().then((data) => {
      console.log(data);
      setData(data);
    });
  }

  function handleClose() {
    setPopup({ show: false, id: null });
  }

  async function handleDelete() {
    console.log("DELETE" + popup.id);
    deleteDocument(popup.id).then((data) => {
      console.log(data);
      getRecurringBillsWithSchedules().then((data) => {
        console.log(data);
        setData(data);
        setPopup({ show: false, id: null });
      });
    });
  }

  return (
    <div>
      <ListPage
        title={"Recurring Bills"}
        singular={"Recurring Bill"}
        link={"/purchases/recurring-bills/add"}
        summaryBoxes={[
          <ReportBox
            reportLabel={"Total Number of Recurring Bills"}
            report={data.length}
          />,
          <ReportBox
            reportLabel={"Total Number Active"}
            report={data.filter((item) => item.status == "active").length}
          />,
        ]}
        data={data}
        filters={[
          <DropdownFilter
            value={dropdownFilter}
            setValue={setDropdownFilter}
            list={["Draft", "Active", "Expired"]}
          />,
          <DateFilter
            from={dateFilterFrom}
            setFrom={setDateFilterFrom}
            to={dateFilterTo}
            setTo={setDateFilterTo}
          />,
        ]}
        filterValue={dropdownFilter}
        filterName={"status"}
        dateFilterValues={[dateFilterFrom, dateFilterTo]}
        columnFilter={ColumnFilter}
        columns={columns}
        statusActions={{
          draft: [
            "Approve",
            (id) => approveRecurringBill(id).then(() => refresh()),
          ],
          active: [
            "View Latest",
            (id) => {
              console.log("view latest" + id);
            },
          ],
          expired: ["Archive", console.log],
        }}
        actions={[
          {
            name: "View",
            method: () => console.log("view"),
          },
          {
            name: "Edit",
            method: (id) => navigate("/sales/recurring-invoices/update/" + id),
          },
          {
            name: "Duplicate",
            method: () => console.log("duplicate"),
          },
          {
            name: "DIVIDER",
          },
          {
            name: "Export as PDF",
            method: () => console.log("export"),
          },
          {
            name: "Print",
            method: () => console.log("print"),
          },
          {
            name: "Delete",
            method: (id) => setPopup({ show: true, id: id }),
          },
        ]}
      />
      <DeleteConfirmation
        popup={popup}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default RecurringBills;
