import React from "react";
import ThroughputAverages from "../../components/Dashboards/ThroughputAverages";
import MonthlyNetProfit from "../../components/Dashboards/MonthlyNetProfit";
import WeeklyNetThroughput from "../../components/Dashboards/WeeklyNetThroughput";
import "./Dashboard.css";

function Dashboard() {
  return (
    <div
      style={{
        marginLeft: "300px",
        marginTop: "50px",
        position: "relative",
      }}
    >
      <h1>Dashboard</h1>
      {/* <img style={{ width: "600px", height: "auto" }} src={underConstruction} /> */}
      <div style={{ position: "relative" }}>
        <div className="topDashboardContainer">
          <MonthlyNetProfit />
          <WeeklyNetThroughput />
        </div>
        <div>
          <ThroughputAverages />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
