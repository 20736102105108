export const settingsSidebarData = [
  {
    title: "User Management",
    subnav: [
      {
        title: "Users",
        path: "/settings/users",
      },
      {
        title: "Rolls",
        path: "/settings/rolls",
      },
      {
        title: "Permissions",
        path: "/settings/permissions",
      },
    ],
    membership: ["lite", "pro", "premium"],
  },

  {
    title: "Sales & Payments",
    subnav: [
      {
        title: "Invoice Customization",
        path: "/settings/invoice-customization",
      },
      {
        title: "Payment Methods",
        path: "/settings/payment-methods",
      },
    ],
    membership: ["pro", "premium"],
  },
  {
    title: "Accounting",
    subnav: [
      {
        title: "Dates & Currency",
        path: "/settings/dates-and-currency",
      },
      {
        title: "Sales Taxes",
        path: "/settings/sales-taxes",
      },
      {
        title: "Default Accounts",
        path: "/settings/default-accounts",
      },
    ],
    membership: ["lite", "pro", "premium"],
  },
  {
    title: "Banking",
    subnav: [
      {
        title: "Payouts",
        path: "/settings/payouts",
      },
    ],
    membership: ["premium"],
  },
  {
    title: "Payroll",
    subnav: [
      {
        title: "Business Profile",
        path: "/settings/payroll/business-profile",
      },
      {
        title: "Company Signatory",
        path: "/settings/payroll/company-signatory",
      },
      {
        title: "Funding Account",
        path: "/settings/payroll/funding-account",
      },
      {
        title: "Tax Profile",
        path: "/settings/payroll/tax-profile",
      },
      {
        title: "Payroll History Import",
        path: "/settings/payroll/history-import",
      },
      {
        title: "Billing",
        path: "/settings/payroll/billing",
      },
    ],
    membership: ["premium"],
  },
  {
    title: "Other",
    subnav: [
      {
        title: "Data Export",
        path: "/settings/data-export",
      },
      {
        title: "Data Import",
        path: "/settings/data-import",
      },
    ],
    membership: ["lite", "pro", "premium"],
  },
];
