import React, { useState } from "react";
import DatePickerEx from "./DateSelector";
import IntInput from "./documents/IntInput";
import StandardDropdown from "./StandardDropdown";
import Input from "./Input";
import { schedule } from "../../util/schedules";

const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

function daysIntoYear(month, day) {
  //using 2022 to calculate the day number of a month and day tuple since there is no leap day
  return (
    (Date.UTC("2022", month, parseInt(day) + 1) - Date.UTC("2022", 0, 0)) /
      24 /
      60 /
      60 /
      1000 -
    1
  );
}

//props:
//Date startDate
//setter setStartDate
//Date endDate
//setter setEndDate
//int period
//setter setPeriod
//string type
//setter setType
//string typeAlternative
//setter setTypeAlternative
//string dayOfWeek
//setter setDayOfWeek
//string dayOfMonth
//setter setDayOfMonth
//string month
//setter setMonth
//string endType
//setter setEndType
//int after
//setter setAfter

function ScheduleForm(props) {
  const types = ["Daily", "Weekly", "Monthly", "Yearly", "Custom"];

  const typesAlternative = ["Day", "Week", "Month", "Year"];
  const typesAlternativePlural = ["Days", "Weeks", "Months", "Years"];

  const monthDays = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
    "31st",
  ]; //[...Array(32).keys()].slice(1).map((i) => `${i}${nth(i)}`);

  const daysSlice = [0, 3, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0]; // Number of options to remove from the monthDays list for each month

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const ending = ["On", "After", "Never"];

  if (props.period != 1) {
    var alt = props.typeAlternative;
    if (!alt.includes("s")) {
      alt = alt + "s";
      props.setTypeAlternative(alt);
    }
    if (props.type != "Custom") {
      //all other types have a props.period of 1 (e.g. monthly would repeat with a props.period of 1 month)
      props.setPeriod(1);
    }
  } else {
    var alt = props.typeAlternative;
    if (alt.includes("s")) {
      alt = alt.slice(0, -1);
      props.setTypeAlternative(alt);
    }
  }

  const endSetter = (val) => {
    props.setEndType(val);
    if (val == "On") {
      props.setEndDate(new Date());
    } else {
      console.log("setting to null");
      props.setEndDate(null);
    }
    if ((val == "Never" || val == "On") && props.after !== null) {
      //null tells the scheduler that the number left to generate is indefinite until endDate
      props.setAfter(null);
    } else if (val == "After" && props.after == null) {
      props.setAfter("1");
    }
  };

  props.setPeriodStart(null);
  props.setPeriodType(null);

  //initialize props.typeAlternative when switching to Custom
  if (props.type == "Custom" && props.typeAlternative == "") {
    props.setTypeAlternative("Day");
    props.setPeriodType("Daily");
  }

  if (
    props.type == "Daily" ||
    props.typeAlternative == "Day" ||
    props.typeAlternative == "Days"
  ) {
    props.setPeriodStart(null);
    props.setPeriodType("Daily");
  } else if (
    props.type == "Weekly" ||
    props.typeAlternative == "Week" ||
    props.typeAlternative == "Weeks"
  ) {
    props.setPeriodStart(weekdays.indexOf(props.dayOfWeek));
    props.setPeriodType("Weekly");
  } else if (
    props.type == "Monthly" ||
    props.typeAlternative == "Month" ||
    props.typeAlternative == "Months"
  ) {
    props.setPeriodStart(monthDays.indexOf(props.dayOfMonth));
    props.setPeriodType("Monthly");
  } else if (
    props.type == "Yearly" ||
    props.typeAlternative == "Year" ||
    props.typeAlternative == "Years"
  ) {
    const maxNumberOfDays =
      monthDays.length - 1 - daysSlice[months.indexOf(props.month).toString()];
    const chosenIndex = monthDays.indexOf(props.dayOfMonth).toString();

    if (chosenIndex > maxNumberOfDays) {
      const difference = chosenIndex - maxNumberOfDays;
      props.setDayOfMonth(monthDays[chosenIndex - difference]);
    }
    props.setPeriodStart(
      daysIntoYear(
        months.indexOf(props.month).toString(),
        monthDays.indexOf(props.dayOfMonth).toString()
      )
    );
    props.setPeriodType("Yearly");
  }

  var slicedDays = [];
  if (props.periodType == "Yearly") {
    slicedDays = JSON.parse(JSON.stringify(monthDays));
    for (
      var i = 0;
      i < daysSlice[months.indexOf(props.month).toString()];
      i++
    ) {
      slicedDays.pop();
    }
    console.log(slicedDays);
  }

  if (
    props.type != "Monthly" &&
    props.type != "Yearly" &&
    props.type != "Custom" &&
    props.dayOfMonth != "1st"
  ) {
    props.setDayOfMonth("1st");
  }
  if (
    props.type == "Custom" &&
    props.typeAlternative != "Month" &&
    props.typeAlternative != "Months" &&
    props.typeAlternative != "Year" &&
    props.typeAlternative != "Years" &&
    props.dayOfMonth != "1st"
  ) {
    props.setDayOfMonth("1st");
  }

  return (
    <div
      style={{
        boxShadow: "0 0 20px rgb(201, 201, 201)",
        minHeight: "300px",
        width: "700px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          margin: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <h5>Set Schedule</h5>{" "}
      </div>
      <table width="100%">
        <thead></thead>
        <tbody>
          <tr>
            <td
              style={{
                width: "15%",
                verticalAlign: "top",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                Repeat
              </div>
            </td>
            <td style={{ width: "85%" }}>
              <div
                style={{
                  marginLeft: "30px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <StandardDropdown
                  list={types}
                  value={props.type}
                  onChange={props.setType}
                />
                {props.type == "Weekly" ? (
                  <>
                    every
                    <StandardDropdown
                      list={weekdays}
                      value={props.dayOfWeek}
                      onChange={props.setDayOfWeek}
                    />
                  </>
                ) : null}
                {props.type == "Monthly" ? (
                  <>
                    on the{" "}
                    <StandardDropdown
                      list={monthDays}
                      value={props.dayOfMonth}
                      onChange={props.setDayOfMonth}
                    />{" "}
                    day of every month
                  </>
                ) : null}
                {props.type == "Yearly" ? (
                  <>
                    every{" "}
                    <StandardDropdown
                      list={months}
                      value={props.month}
                      onChange={props.setMonth}
                    />{" "}
                    on the{" "}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <StandardDropdown
                        list={slicedDays}
                        value={props.dayOfMonth}
                        onChange={props.setDayOfMonth}
                      />{" "}
                      day of the month{" "}
                    </div>
                  </>
                ) : null}
                {props.type == "Custom" ? (
                  <>
                    every{" "}
                    <div
                      style={{
                        display: "flex",
                        minWidth: "100px",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <IntInput
                        limit={4}
                        initialValue={props.period.toString()}
                        changeText={props.setPeriod}
                      />
                      {props.period != 1 ? (
                        <StandardDropdown
                          list={typesAlternativePlural}
                          value={props.typeAlternative}
                          onChange={props.setTypeAlternative}
                        />
                      ) : (
                        <StandardDropdown
                          list={typesAlternative}
                          value={props.typeAlternative}
                          onChange={props.setTypeAlternative}
                        />
                      )}
                    </div>
                    {props.typeAlternative == "Week" ||
                    props.typeAlternative == "Weeks" ? (
                      <>
                        on{" "}
                        <StandardDropdown
                          list={weekdays}
                          value={props.dayOfWeek}
                          onChange={props.setDayOfWeek}
                        />
                      </>
                    ) : null}
                    {props.typeAlternative == "Month" ||
                    props.typeAlternative == "Months" ? (
                      <>
                        on the{" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <StandardDropdown
                            list={monthDays}
                            value={props.dayOfMonth}
                            onChange={props.setDayOfMonth}
                          />{" "}
                          day of the month{" "}
                        </div>
                      </>
                    ) : null}
                    {props.typeAlternative == "Year" ||
                    props.typeAlternative == "Years" ? (
                      <>
                        in{" "}
                        <StandardDropdown
                          list={months}
                          value={props.month}
                          onChange={props.setMonth}
                        />{" "}
                        on the{" "}
                        <StandardDropdown
                          list={slicedDays}
                          value={props.dayOfMonth}
                          onChange={props.setDayOfMonth}
                        />{" "}
                        day of the month
                      </>
                    ) : null}
                  </>
                ) : null}
                {monthDays.indexOf(props.dayOfMonth).toString() > 27 &&
                props.periodType != "Yearly" ? (
                  <div
                    style={{
                      whiteSpace: "normal",
                      textAlign: "left",
                      marginRight: "30px",
                      fontStyle: "italic",
                    }}
                  >
                    The document will be created on the {props.dayOfMonth} day
                    of each month, or on the last day for months ending earlier.
                  </div>
                ) : null}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "30px",
                }}
              >
                Start on
              </div>
            </td>
            <td style={{ display: "flex" }}>
              <div
                style={{
                  marginLeft: "40px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginTop: "30px",
                }}
              >
                <DatePickerEx
                  placeholder={"From"}
                  date={new Date(props.startDate)}
                  setDate={props.setStartDate}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                End
              </div>
            </td>
            <td style={{ display: "flex" }}>
              <div
                style={{
                  marginLeft: "30px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginTop: "30px",
                }}
              >
                <StandardDropdown
                  list={ending}
                  value={props.endType}
                  onChange={endSetter}
                />
                {props.endType == "On" ? (
                  <DatePickerEx
                    placeholder={"To"}
                    date={new Date(props.endDate)}
                    setDate={props.setEndDate}
                  />
                ) : null}
                {props.endType == "After" ? (
                  <>
                    <IntInput
                      limit={4}
                      initialValue={props.after}
                      changeText={props.setAfter}
                    />{" "}
                    {"\u00A0"} {props.after != 1 ? "Documents" : "Document"}
                  </>
                ) : null}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "20px",
            borderBottom: "1px solid black",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              backgroundColor: "white",
              padding: "0 10px",
              marginLeft: "0",
            }}
          >
            Preview
          </span>
        </div>
        {schedule(
          props.period,
          props.periodType,
          props.periodStart,
          props.startDate,
          props.endDate,
          props.after
        ).map((item, index) => {
          return <div>{item}</div>;
        })}{" "}
      </div>
    </div>
  );
}

export default ScheduleForm;
