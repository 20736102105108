import { getCustomers } from "../pages/Sales/api";
import useFetch from "./useFetch";

const useCustomers = (companies = false) => {
  const {
    loading: loadingCustomers,
    error: customersError,
    data: customers,
  } = useFetch(getCustomers);

  return { loadingCustomers, customersError, customers };
};

export default useCustomers;
