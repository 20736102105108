import { formatDate } from "../../util/dates";
import { api_url } from "../../util/environment";
import { addMoney, subtractMoney } from "../../util/money";
import { generate } from "../../util/schedules";
import { postTransaction } from "../Accounting/api";

import { currentBusiness } from "../../features/authentication/util";

export async function getBudgetaryEstimates() {
    const response = await fetch(
        api_url +
            "/sales/documents/budgetary-estimates/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getInvoices() {
    const response = await fetch(
        api_url + "/sales/documents/invoices/user/" + currentBusiness()
    );
    return response.json();
}

export async function getEstimates() {
    const response = await fetch(
        api_url + "/sales/documents/estimates/user/" + currentBusiness()
    );
    return response.json();
}

export async function getSaleOrders() {
    const response = await fetch(
        api_url + "/sales/documents/sale-orders/user/" + currentBusiness()
    );
    return response.json();
}

export async function getRecurringInvoices() {
    const response = await fetch(
        api_url +
            "/sales/documents/recurring-invoices/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getRecurringInvoicesWithSchedules() {
    const response = await fetch(
        api_url +
            "/sales/documents/schedules/recurring-invoices/user/" +
            currentBusiness()
    );
    return response.json();
}
export async function getActiveRecurringInvoices() {
    const response = await fetch(
        api_url +
            "/sales/documents/schedules/active/recurring-invoices/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getSchedules(id) {
    const response = await fetch(
        api_url + "/sales/documents/" + id + "/schedules"
    );
    return response.json();
}

/*------------------------taxes--------------------------*/

export async function getItemTaxes(id) {
    const response = await fetch(
        api_url + "/sales/document-items/" + id + "/taxes"
    );
    return response.json();
}

export async function postLineItemTax(lineItemId, taxId) {
    const postTax = await fetch(
        api_url + "/sales/document-items/" + lineItemId + "/taxes/" + taxId,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        }
    );
    return postTax.json();
}

export async function deleteLineItemTax(lineItemId, taxId) {
    const deleteTax = await fetch(
        api_url + "/sales/document-items/" + lineItemId + "/taxes/" + taxId,
        {
            method: "DELETE",
        }
    );
    return deleteTax.json();
}

/*--------------------customers------------------------------*/
export async function getCustomer(id) {
    const response = await fetch(api_url + "/sales/customers/" + id);
    return response.json();
}

/*---------------------misc---------------------------------*/
export async function generateRecurring(id) {
    console.log("generate recurring");
    fetch(api_url + "/sales/documents/" + id + "/schedules").then((res) => {
        res.json().then((schedules) => {
            console.log(schedules);
            schedules[0].next_generation = new Date(
                schedules[0].next_generation + " UTC"
            );
            schedules[0].starting_date = new Date(
                schedules[0].starting_date + " UTC"
            );
            schedules[0].ending_date = new Date(
                schedules[0].ending_date + " UTC"
            );
            var generated = generate(schedules[0]);
            var schedule_id = schedules[0].id;
            if (generated) {
                console.log("TRUE");
                delete generated["id"];
                fetch(api_url + "/site/schedules/" + schedule_id, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(generated),
                });
                console.log(generated);
            } else {
                console.log("FALSE");
                delete schedules[0]["id"];
                schedules[0].next_generation = null;
                fetch(api_url + "/site/schedules/" + schedule_id, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(schedules[0]),
                });
                expireRecurringInvoice(schedules[0].document_id);
            }
        });
    });

    return;
}

export async function postDocument(body) {
    const postDocument = await fetch(
        api_url + "/sales/documents/user/" + currentBusiness(),
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    return await postDocument.json();
}

export async function deleteDocument(id) {
    const post = await fetch(api_url + "/sales/documents/" + id, {
        method: "DELETE",
    });

    return await post.json();
}

export async function deleteAllDocuments() {
    const post = await fetch(
        api_url + "/sales/documents/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}

export async function getDocumentItemsQuantityPricings(id) {
    console.log("hello");
    const response = await fetch(
        api_url + "/sales/document-items/" + id + "/quantity-pricing"
    );
    return response.json();
}

export async function postDocumentItemQuantityPricing(body) {
    const post = await fetch(
        api_url + "/sales/document-items-quantity-pricing/",
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    return await post.json();
}

export async function putDocumentItemQuantityPricing(body, id) {
    const post = await fetch(
        api_url + "/sales/document-items-quantity-pricing/" + id,
        {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    return await post.json();
}

export async function deleteDocumentItemQuantityPricing(id) {
    const post = await fetch(api_url + "/sales/document-items/" + id, {
        method: "DELETE",
    });

    return await post.json();
}

export async function postLineItem(body) {
    console.log(body);
    const postLineItem = await fetch(api_url + "/sales/document-items", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
    return await postLineItem.json();
}

export async function getSalesProducts() {
    const response = await fetch(
        api_url + "/site/products-and-services/sales/user/" + currentBusiness()
    );
    return response.json();
}

/*----------------------------status-----------------------------------*/

//Change status from draft to unsent TODO: +Accounts Receivable/ + Retained Earnings
export async function approveDocument(id) {
    //get current invoice info
    const response = await fetch(api_url + "/sales/documents/" + id);
    const data = await response.json();
    delete data[0]["id"];
    data[0]["status"] = "unsent";
    data[0]["updated_at"] = Date.now();
    return updateDocument(data[0], id); //return promise so caller can wait for update to refresh page
}
export async function approveRecurringInvoice(id) {
    //get current invoice info
    const response = await fetch(api_url + "/sales/documents/" + id);
    const data = await response.json();
    delete data[0]["id"];
    data[0]["status"] = "active";
    data[0]["updated_at"] = Date.now();
    return updateDocument(data[0], id); //return promise so caller can wait for update to refresh page
}
export async function expireRecurringInvoice(id) {
    //get current invoice info
    const response = await fetch(api_url + "/sales/documents/" + id);
    const data = await response.json();
    delete data[0]["id"];
    data[0]["status"] = "expired";
    data[0]["updated_at"] = Date.now();
    return updateDocument(data[0], id); //return promise so caller can wait for update to refresh page
}

export async function sendDocument(id) {
    //get current invoice info
    const response = await fetch(api_url + "/sales/documents/" + id);
    const data = await response.json();
    delete data[0]["id"];
    data[0]["status"] = "sent";
    data[0]["updated_at"] = Date.now();
    return updateDocument(data[0], id); //return promise so caller can wait for update to refresh page
}

export async function updateDocument(body, id) {
    await fetch(api_url + "/sales/documents/" + id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
}

export async function getCustomersByCompany(id) {
    const response = await fetch(
        api_url + "/site/companies/" + id + "/customers"
    );
    return response.json();
}

export async function payInvoice(id, amount, account, date) {
    //get current invoice info
    const response = await fetch(api_url + "/sales/documents/" + id);
    const data = await response.json();
    delete data[0]["id"];
    const due = data[0]["amount_due"];
    const paid = data[0]["amount_paid"];
    const newPaid = addMoney(paid, amount);
    console.log(newPaid);
    if (parseFloat(subtractMoney(due, newPaid)) <= 0) {
        data[0]["status"] = "paid";
        console.log("paid");
    } else {
        data[0]["status"] = "partial";
        console.log("partial");
    }
    data[0]["amount_paid"] = newPaid;

    data[0]["updated_at"] = Date.now();

    postTransaction({
        date: date,
        description: "Invoice Payment",
        account_id: account,
        category:
            "Invoice #" +
            data[0]["invoice_number"] +
            " | Payment from " +
            data[0]["to_name"],
        amount: amount,
    });

    return updateDocument(data[0], id); //return promise so caller can wait for update to refresh page
}

/*--------------------Customers-----------------------*/

export async function getCustomers() {
    const response = await fetch(
        api_url + "/sales/customers/user/" + currentBusiness()
    );
    return response.json();
}

export async function deleteAllCustomers() {
    const post = await fetch(
        api_url + "/sales/customers/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}
