import React, { useEffect } from "react";
import { SettingsContext } from "../../App";
import { mapObject } from "../../util/jsonUtil";
import { getUser } from "../Site/api";
import ProfileSidebar from "./ProfileSidebar";

function PersonalInformation() {
  const [userInfo, setUserInfo] = React.useState({});
  let { user } = React.useContext(SettingsContext);
  useEffect(() => {
    getUser(user.userId).then((data) => {
      console.log(data);
      setUserInfo(data[0]);
    });
  }, []);

  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <ProfileSidebar active="Personal Information" />
        <div>
          {mapObject(userInfo).map((item, index) => {
            return (
              <div>
                {" "}
                <div>{item[0]}</div>
                {item[1]}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
