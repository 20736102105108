import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Transactions.css";
import SearchableWithButton from "../../../components/Forms/SearchableWithButton";
import TransactionItem from "./TransactionItem";
import { formatDate, getDate } from "../../../util/dates";
import {
  deleteTransaction,
  getAccounts,
  getTransactions,
  postTransaction,
  putTransaction,
} from "../api";
import { findIndex, isEqual } from "../../../util/jsonUtil";
import { useNavigate } from "react-router-dom";
import EditTransactionPopup from "./GeneralTransation/EditTransactionPopup";
import DeleteConfirmation from "../../../components/Popups/DeleteConfirmation";
import DeleteConfirmationWithNotes from "../../../components/Popups/DeleteConfirmationWithNotes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import SaleOrPurchasePopup from "./SaleOrPurchase/SaleOrPurchasePopup";

function Transactions() {
  let navigate = useNavigate();
  const [accountFilter, setAccountFilter] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [transactionsList, setTransactionsList] = useState([]);
  const [transactionsListOrig, setTransactionsListOrig] = useState([]);

  const [accounts, setAccounts] = useState(null);

  const [cashIndex, setCashIndex] = useState(null);

  const [shiftIndex, setShiftIndex] = useState(0); // Used for shift-clicking multiple transactions

  const [popup, setPopup] = useState({ show: false, type: null });
  const [popupTwo, setPopupTwo] = useState({ show: false, type: null });

  const [deletionPopup, setDeletionPopup] = useState({
    show: false,
    index: null,
  });

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (countChecked() == 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  }, [transactionsList]);

  useEffect(() => {
    if (accountFilter != null) {
      setTransactionsList(
        transactionsListOrig.filter((t) => t.account == accountFilter)
      );
    } else {
      setTransactionsList(transactionsListOrig);
    }
  }, [accountFilter]);

  const refresh = () => {
    getAccounts().then((data) => {
      setAccounts(data);
      //keep track of which index the uncategorized income and uncategorized expense are at for setting the searchable dropdown
      const cashIndex = findIndex(data, "name", "Petty Cash");
      setCashIndex(cashIndex);

      getTransactions().then((transactionData) => {
        for (let i in transactionData) {
          // if (transactionsList[i]) {
          //   console.log("CHECKED ALREADY");
          //   transactionData[i].checked = transactionsList[i].checked;
          // } else {
          //   transactionData[i].checked = false;
          // }
          transactionData[i].checked = false;
          var listIndex = findIndex(data, "id", transactionData[i].account_id);
          transactionData[i].account = listIndex;
        }
        var list = transactionData.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        setTransactionsList(list);
        setTransactionsListOrig(JSON.parse(JSON.stringify(list)));
      });
    });
  };

  const addIncome = () => {
    setPopupTwo({ show: true, type: "Sale" });
  };

  const addExpense = () => {
    setPopupTwo({ show: true, type: "Purchase" });
  };

  const editTransaction = (i) => {
    setPopup({ show: true, type: "Edit", transaction: i });
  };

  const generalTransaction = () => {
    setPopup({ show: true, type: "Income" });
  };

  const countChecked = () => {
    return transactionsList.filter((item) => item.checked == true).length;
  };

  const handleTransactionSave = (transaction) => {
    console.log(transaction);
  };

  var accountList = null;
  if (accounts) {
    accountList = [...accounts.map((a) => [a.name])];
  }

  const reviewTransaction = async (index, bool) => {
    let newInfo = transactionsList[index];
    newInfo.reviewed = bool;
    let id = transactionsList[index].id;
    delete newInfo["id"];
    delete newInfo["owner"];
    delete newInfo["account"];
    delete newInfo["checked"];
    console.log(newInfo);
    return await putTransaction(newInfo, id);
  };

  const bulkReview = async () => {
    let transactions = transactionsList.filter((item) => item.checked == true);
    for (let i in transactions) {
      let index = findIndex(transactionsList, "id", transactions[i].id);
      await reviewTransaction(index, true);
    }
    refresh();
  };

  return (
    <div className="transactionsPage">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <h1>Transactions</h1>
        <div>
          <button
            type="button"
            className="btn btn-light"
            style={{ marginRight: "10px" }}
            onClick={() => {
              generalTransaction();
            }}
          >
            Add General Transaction
          </button>
          <button
            type="button"
            className="btn btn-light"
            style={{ marginRight: "10px" }}
            onClick={() => {
              addIncome();
            }}
          >
            Record Sale
          </button>
          <button
            type="button"
            className="btn btn-light"
            style={{ marginRight: "10px" }}
            onClick={() => {
              addExpense();
            }}
          >
            Record Purchase
          </button>
          <Link to="/accounting/transactions/journal">
            <button
              type="button"
              className="btn btn-light"
              style={{ marginRight: "10px" }}
              onClick={() => {
                console.log("add transaction");
              }}
            >
              Add Journal Transaction
            </button>
          </Link>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "250px", margin: "0 20px 20px 0 " }}>
          <SearchableWithButton
            appendID={"-searchable-top"}
            list={accountList}
            value={accountFilter}
            setValue={setAccountFilter}
            defaultValue={"All Accounts"}
            buttonList={["All Accounts"]}
            functions={[() => setAccountFilter(null)]}
          />
        </div>
        <div style={{ flex: 1, flexGrow: 1 }}>
          <label
            className="form-control"
            style={{ backgroundColor: "white", minWidth: "100px" }}
            onClick={() => {
              console.log("add transaction");
            }}
          >
            Auto-updates to your transactions
          </label>
        </div>
      </div>
      <div className="transactionsFilterBar">
        <div
          className="selectAll"
          style={{ padding: "10px", display: "flex", alignItems: "center" }}
          onClick={() => {
            for (let i in transactionsList) {
              var list = transactionsList;
              list[i].checked = !selectAll;
              setTransactionsList(JSON.parse(JSON.stringify(list)));
            }
            setSelectAll(!selectAll);
          }}
        >
          <input
            type="checkbox"
            className="selectAll"
            style={{ marginRight: "10px" }}
            checked={selectAll}
            onChange={(e) => {
              for (let i in transactionsList) {
                var list = transactionsList;
                list[i].checked = !selectAll;
                setTransactionsList(JSON.parse(JSON.stringify(list)));
              }
              setSelectAll(!selectAll);
            }}
          />{" "}
          {selectAll ? (
            <div>{countChecked() + " Selected"}</div>
          ) : (
            <div>{"Select all"}</div>
          )}
          {countChecked() > 0 && (
            <div style={{ marginLeft: "10px" }}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    placement="bottom"
                    className="in"
                    id="tooltip-bottom"
                  >
                    Mark selected transactions as Reviewed
                  </Tooltip>
                }
              >
                <div onClick={() => bulkReview()}>
                  {" "}
                  <MdOutlineCheckCircleOutline className="actionDropdown" />
                </div>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </div>
      <div className="transactionsTable">
        <table width="100%" style={{ "table-layout": "fixed" }}>
          <thead className="transactionsTableHeader">
            <tr style={{ height: "40px" }}>
              <th style={{ textIndent: "30px", width: "15%" }}>Date</th>
              <th style={{ textIndent: "30px" }}>Description</th>
              <th style={{ textIndent: "30px" }}>Account</th>
              <th style={{ textIndent: "30px" }}>Category</th>
              <th style={{ textAlign: "right", width: "10%" }}>Amount</th>
              <th
                style={{
                  textAlign: "right",
                  paddingRight: "30px",
                  width: "10%",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionsList.map((item, index) => {
              return (
                <TransactionItem
                  info={item}
                  setInfo={(key, value) => {
                    var list = transactionsList;
                    list[index][key] = value;
                    setTransactionsList(JSON.parse(JSON.stringify(list)));
                  }}
                  onDelete={() => {
                    setDeletionPopup({ show: true, index: index });
                  }}
                  onClick={(e) => {
                    console.log(e.target.tagName);
                    if (
                      e.target.tagName == "BUTTON" ||
                      e.target.tagName == "svg" ||
                      e.target.tagName == "A" ||
                      e.target.tagName == "INPUT" ||
                      e.target.tagName == "path"
                    )
                      return;
                    console.log(transactionsList[index].category);
                    var cat = transactionsList[index].category;
                    if (cat == "Journal Entry") {
                      navigate(
                        "/accounting/transactions/journal/update/" +
                          transactionsList[index].id
                      );
                    } else if (cat == "Sale") {
                      setPopupTwo({
                        show: true,
                        type: "Sale",
                        document_id: transactionsList[index].document_id,
                        transaction: transactionsList[index],
                      });
                    } else if (cat == "Purchase") {
                      setPopupTwo({
                        show: true,
                        type: "Purchase",
                        document_id: transactionsList[index].document_id,
                        transaction: transactionsList[index],
                      });
                    } else {
                      setPopup({
                        show: true,
                        type: "Edit",
                        transaction: transactionsList[index],
                      });
                    }
                  }}
                  setShiftIndex={(i) => {
                    if (shiftIndex != i) {
                      setShiftIndex(i);
                    }
                  }}
                  shiftCheck={(i) => {
                    if (shiftIndex != null && shiftIndex != i) {
                      var list = transactionsList;
                      for (
                        let j = Math.min(i, shiftIndex);
                        j <= Math.max(i, shiftIndex);
                        j++
                      ) {
                        list[j].checked = list[shiftIndex].checked;
                      }
                      setTransactionsList(JSON.parse(JSON.stringify(list)));
                    }
                  }}
                  accounts={accountList}
                  index={index}
                  navigate={navigate}
                  editTransaction={editTransaction}
                  reviewTransaction={async (i, bool) => {
                    await reviewTransaction(i, bool);
                    refresh();
                  }}
                />
              );
            })}
            {transactionsList.length == 0 ? (
              <tr>
                <td colspan="6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    No Data Available
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <EditTransactionPopup
        popup={popup}
        setPopup={setPopup}
        handleSave={handleTransactionSave}
        refresh={refresh}
      />

      <DeleteConfirmationWithNotes
        popup={deletionPopup}
        handleDelete={(reason) => {
          var form = transactionsList[deletionPopup.index];
          form.notes =
            getDate(new Date()) + " (Deletion): " + reason + "\n" + form.notes;
          const id = form["id"];
          delete form["id"];
          delete form["checked"];
          delete form["account"];
          delete form["owner"];
          putTransaction(form, id).then((data) => {
            console.log(data);
            setDeletionPopup({ show: false, index: null });
            refresh();
          });
        }}
        handleClose={() => setDeletionPopup({ show: false, index: null })}
      />
      <SaleOrPurchasePopup
        popup={popupTwo}
        setPopup={setPopupTwo}
        refresh={refresh}
      />
    </div>
  );
}

export default Transactions;
