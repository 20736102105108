//TAG:AUTHENTICATION

import { useState } from "react";

export default function useUser() {
  const getUser = () => {
    const tokenString = localStorage.getItem("asp-user-token");
    console.log(tokenString);
    const userToken = JSON.parse(tokenString);
    console.log(userToken);
    return userToken;
  };
  const [user, setUser] = useState(getUser());

  const saveUser = (userToken) => {
    localStorage.setItem("asp-user-token", JSON.stringify(userToken));
    setUser(userToken);
  };

  const clearUser = () => {
    localStorage.removeItem("asp-user-token");
    setUser(null);
  };

  return { setUser: saveUser, user, clearUser };
}
