import React from "react";
import Table from "./Table";
import { Link } from "react-router-dom";
import "./ListPage.css";

function ListPage(props) {
  const Header = () => {
    return (
      <div className="summaryBar">
        {props.summaryBoxes.map((item, index) => {
          return item;
        })}
      </div>
    );
  };
  var dateRange = null;
  if (props.dateFilterValues) {
    dateRange = [props.dateFilterValues[0], props.dateFilterValues[1]];
  }

  return (
    <div className="listPage">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <h1>{props.title}</h1>
        <Link to={props.link}>
          <button
            type="button"
            className="btn btn-success"
            style={{ marginTop: "10px" }}
          >
            Create a New {props.singular}
          </button>
        </Link>
      </div>
      <Header />
      <div style={{ display: "flex" }}>
        {props.filters
          ? props.filters.map((item, index) => {
              return item;
            })
          : null}
      </div>
      <div className={"hoverTable"}>
        <Table
          data={props.data}
          extraColumnData={props.extraColumnData}
          columnFilter={props.columnFilter}
          columns={props.columns}
          onClickRow={console.log}
          onClickDelete={console.log}
          actions={props.actions}
          statusActions={props.statusActions}
          dateRange={dateRange}
          filterValue={props.filterValue}
          filterName={props.filterName}
        />
      </div>
    </div>
  );
}

export default ListPage;
