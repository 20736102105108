import React, { useEffect, useState } from "react";
import SearchableDropdownWithCreate from "../../components/Forms/SearchableDropdownWithCreate";
import SearchableModal from "../../components/Forms/SearchableModal";
import useAccounts from "../../hooks/useAccounts";

function TaxFiling() {
  const { loading, error, accounts, accountsByCategory } = useAccounts();
  const [account1, setAccount1] = useState(null);
  const [account2, setAccount2] = useState(null);
  const [category, setCategory] = useState(null);

  const categories = {
    // Bills: [
    //   {
    //     id: 1,
    //     name: "Record Bill Payment using Cash",
    //     account1: 91,
    //     account2: 78,
    //   },
    // ],
    // Equipment: [
    //   {
    //     id: 1,
    //     name: "Purchase Equipment using Cash",
    //     account1: 87,
    //     account2: 78,
    //   },
    //   {
    //     id: 2,
    //     name: "Purchase Equipment using Credit",
    //     account1: 87,
    //     account2: 91,
    //   },
    //   {
    //     id: 3,
    //     name: "Purchase Equipment using Loan",
    //     account1: 87,
    //     account2: 105,
    //   },
    //   {
    //     id: 4,
    //     name: "Sell Equipment using Cash",
    //     account1: 78,
    //     account2: 87,
    //   },
    //   {
    //     id: 5,
    //     name: "Sell Equipment using Credit",
    //     account1: 91,
    //     account2: 87,
    //   },
    //   {
    //     id: 6,
    //     name: "Sell Equipment using Loan",
    //     account1: 105,
    //     account2: 87,
    //   },
    // ],
    // Inventory: [
    //   {
    //     id: 7,
    //     name: "Purchase Inventory using Cash",
    //     account1: 85,
    //     account2: 78,
    //   },
    //   {
    //     id: 8,
    //     name: "Purchase Inventory using Credit",
    //     account1: 85,
    //     account2: 91,
    //   },
    //   {
    //     id: 9,
    //     name: "Purchase Inventory using Loan",
    //     account1: 85,
    //     account2: 105,
    //   },
    // ],
    Invoices: [
      {
        id: 10,
        name: "Record Invoice Payment using Cash",
      },
      {
        id: 11,
        name: "Record Invoice as Sales",
        account1: 81,
        account2: 115,
      },
    ],
    Loan: [
      {
        id: 10,
        name: "Pay Loan using Cash",
        account1: 105,
        account2: 78,
      },
      {
        id: 11,
        name: "Get Loan using Cash",
        account1: 78,
        account2: 105,
      },
    ],
    Rent: [
      {
        id: 10,
        name: "Pay Rent using Cash",
        account1: 113,
        account2: 78,
      },
    ],
    Utilities: [
      {
        id: 10,
        name: "Pay Utilities using Cash",
        account1: 113,
        account2: 78,
      },
    ],
    Payroll: [],
    Taxes: [],
    Other: [],
  };

  useEffect(() => {
    console.log(accountsByCategory);
  }, [accountsByCategory]);

  if (error)
    return <p style={{ marginLeft: "500px" }}>Failed to retrieve data</p>;
  if (loading) return <p style={{ marginLeft: "500px" }}>Loading...</p>;

  return (
    <div
      style={{
        marginLeft: "300px",
        marginRight: "auto",
        marginTop: "50px",
        position: "relative",
        maxWidth: "100vh",
      }}
    >
      <h1>Tax Filing</h1>
      <SearchableModal
        width="50%"
        list={categories}
        keys={{
          name: (name) => name,
        }}
        onItemClick={(cat) => {
          console.log(cat);
          setCategory(cat);
        }}
        value={category ? category.name : ""}
        initialValue={"Select a Category"}
      />
      <SearchableModal
        width="50%"
        list={accounts}
        keys={{
          category: (category) => category,
          name: (name) => name,
          debit_credit: (debit_credit) =>
            debit_credit === "debit" ? "+" : "-",
        }}
        onItemClick={(acc) => {
          console.log(acc);
          setAccount1(acc);
        }}
        value={account1 ? account1.name : ""}
        initialValue={"Select an Account"}
      />
      <SearchableModal
        width="50%"
        list={accountsByCategory}
        keys={{
          name: (name) => name,
        }}
        onItemClick={(acc) => {
          console.log(acc);
          setAccount2(acc);
        }}
        value={account2 ? account2.name : ""}
        initialValue={"Select an Account"}
      />
    </div>
  );
}

export default TaxFiling;
