import React, { useMemo, useEffect, useState } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

function Table(props) {
  const [filtered, setFiltered] = useState(props.data);
  const [isFiltered, setIsFiltered] = useState(false);

  const columns = useMemo(() => props.columns, []);

  const tableInstance = useTable(
    {
      columns: columns,
      data: filtered,
      initialState: {
        pageSize: 20,
      },
      Filter: props.columnFilter,
    },
    useFilters,
    useSortBy,
    usePagination
  ); // initialState: { pageIndex : user.settings.page.tablePageNumber }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    setFilter,
    state,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    if (props.filterName) {
      console.log("set filter");
      setFilter(props.filterName, props.filterValue);
    }
  }, [props.filterValue]);

  useEffect(() => {
    if (props.dateRange) {
      const startDate = props.dateRange[0];
      const endDate = props.dateRange[1];
      if (startDate != "" && endDate == "") {
        let test = props.data.filter(
          (d) => new Date(d.issue_date) >= new Date(startDate)
        );

        setFiltered(test);
      } else if (startDate == "" && endDate != "") {
        let test = props.data.filter(
          (d) => new Date(d.issue_date) <= new Date(endDate)
        );

        setFiltered(test);
      } else if (startDate != "" && endDate != "") {
        let test = props.data.filter(
          (d) =>
            (new Date(d.issue_date) >= new Date(startDate)) &
            (new Date(d.issue_date) <= new Date(endDate))
        );

        setFiltered(test);
      } else {
        setFiltered(props.data); //reset
      }
    }
  }, [props.dateRange]);

  useEffect(() => {
    if (!isFiltered) {
      setFiltered(props.data);
    }
  }, [props.data]);

  return (
    <>
      <table
        {...getTableProps()}
        width="100%"
        style={{ "table-layout": "fixed" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                >
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <span>▾</span>
                    ) : (
                      <span>▴</span>
                    )
                  ) : (
                    <span className="unsorted"></span>
                  )}
                </th>
              ))}
              {props.extraColumnData
                ? Object.keys(props.extraColumnData[0]).map((item, index) => {
                    return <th>{item}</th>;
                  })
                : null}
              <th style={{ textAlign: "right", width: "5em" }}> Actions</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={"tableRows"}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ overflow: "hidden" }}
                      onClick={(e) => props.onClickRow(row.original.id)}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                {props.extraColumnData
                  ? Object.keys(props.extraColumnData[row.index]).map(
                      (item, index) => {
                        return (
                          <td>{props.extraColumnData[row.index][item]}</td>
                        );
                      }
                    )
                  : null}
                <td>
                  <div
                    style={{ display: "flex", "justify-content": "flex-end" }}
                  >
                    <button
                      id={row.original.id}
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        marginRight: "10px",
                        background: "none",
                        border: "none",
                        "font-weight": "bold",
                        color: "blue",
                      }}
                      class="link"
                      onClick={() => {
                        console.log(row.original.to_email);
                        row.original.status == "unsent"
                          ? props.statusActions[row.original.status][1](
                              row.original.id,
                              row.original.to_email
                            )
                          : props.statusActions[row.original.status][1](
                              row.original.id
                            );
                      }}
                    >
                      <div>
                        {props.statusActions
                          ? props.statusActions[row.original.status][0]
                          : null}
                      </div>
                    </button>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn-light"
                        style={{
                          "background-color": "light-gray",
                          "padding-top": "1px",
                          "padding-bottom": "1px",
                        }}
                        id="dropdown-basic"
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        {props.actions.map((item, index) => {
                          var style = {};
                          if (item.name == "Delete") {
                            style = { color: "#d11a2a" };
                          }
                          return item.name === "DIVIDER" ? (
                            <div class="dropdown-divider"></div>
                          ) : (
                            <Dropdown.Item
                              onClick={() => item.method(row.original.id)}
                              style={style}
                            >
                              {item.name}
                            </Dropdown.Item>
                          );
                        })}

                        {/* <Dropdown.Item
                          id={row.original.id}
                          onClick={props.onClickEdit}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Duplicate
                        </Dropdown.Item>
                        <div class="dropdown-divider"></div>
                        <Dropdown.Item href="#/action54">Send</Dropdown.Item>
                        <Dropdown.Item href="#/action-6">
                          Convert to Invoice
                        </Dropdown.Item>
                        <div class="dropdown-divider"></div>
                        <Dropdown.Item href="#/action-7">
                          Export as PDF
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-6">Print</Dropdown.Item>
                        <Dropdown.Item
                          id={row.original.id}
                          onClick={props.onClickDelete}
                          style={{ color: "#d11a2a" }}
                        >
                          Delete
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="tableFooter" style={{ userSelect: "none" }}>
        <div>
          <span>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: "50px" }}
              min={"1"}
              max={pageCount}
            />
          </span>{" "}
          Items per page:{" "}
          <select
            style={{ width: "50px", height: "2em" }}
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </Button>
          <span style={{ marginRight: "10px" }}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </Button>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default Table;
