import React from "react";

function OutstandingInvoicesFooter(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "auto",
      }}
    >
      <div>{props.companyName}</div>
      <div>{"Page " + props.pageNumber + " of " + props.numPages}</div>
    </div>
  );
}

export default OutstandingInvoicesFooter;
