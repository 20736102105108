import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Input from "../../../../components/Forms/Input";
import TextArea from "../../../../components/Forms/TextArea";
import ErrorPopup from "../../../../components/Popups/ErrorPopup";
import Loading from "../../../../components/Icons/Loading";

function SendInvoice(props) {
  const [sendInvoiceForm, setSendInvoiceForm] = useState({
    from: "timjevans2000@gmail.com",
    to: "bobbyevans8888@gmail.com",
    subject: props.subject,
    message: props.message,
  });

  const [attachment, setAttachment] = useState(null);

  const [loaded, setLoaded] = useState(false);

  console.log(props.sendPopup);

  const [sending, setSending] = useState(false);

  useEffect(() => {
    setSendInvoiceForm({
      from: "timjevans2000@gmail.com",
      to: props.sendPopup.toEmail,
      subject: props.subject,
      message: props.message,
      attachment: null,
    });

    if (props.sendPopup.show == true) {
      props.attachment().then((data) => {
        setAttachment(data);
        setLoaded(true);
      });
    }
    if (props.sendPopup.show == false) {
      setLoaded(false);
    }
  }, [props.sendPopup]);

  const sendInvoice = () => {
    setSending(true);
    var form = sendInvoiceForm;
    form.attachment = attachment;
    fetch("http://localhost:5000/send-invoice", {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(form),
    })
      .then((results) => results.json())
      .then((json_res) => {
        console.log(json_res);
        if (json_res == "email sent") {
          props.toSent(props.sendPopup.id);
          props.setSuccess();
        } else {
          props.setError(json_res.message || "Unknown Error");
        }
        setSending(false);
        props.closePopup();
      });
    console.log(sendInvoiceForm);
  };

  function onChange(e, key) {
    var form = JSON.parse(JSON.stringify(sendInvoiceForm));
    form[key] = e.target.value;
    setSendInvoiceForm(form);
  }

  return (
    <Modal show={props.sendPopup.show} onHide={() => props.closePopup()}>
      <Modal.Header closeButton>
        <Modal.Title>{"Send This Invoice"}</Modal.Title>
      </Modal.Header>
      {sending ? (
        <Modal.Body>
          <Loading message={"Sending"} />
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <Input
              className="mb-3 col"
              label="From"
              id="from"
              value={sendInvoiceForm.from}
              onChange={(e) => onChange(e, "from")}
            />
            <Input
              className="mb-3 col"
              label="To"
              id="to"
              value={sendInvoiceForm.to}
              onChange={(e) => onChange(e, "to")}
            />
            <Input
              className="mb-3 col"
              label="Subject"
              id="subject"
              value={sendInvoiceForm.subject}
              onChange={(e) => onChange(e, "subject")}
            />
            <TextArea
              className="mb-3 col"
              label="Message"
              id="message"
              value={sendInvoiceForm.message}
              onChange={(e) => onChange(e, "message")}
            />
            {loaded ? (
              <label type="text">
                Your document has been attached to the email
              </label>
            ) : (
              <label type="text">Attaching document to the email</label>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => props.closePopup()}>
              Cancel
            </Button>
            <Button
              variant="success"
              style={{ width: "80px" }}
              onClick={() => sendInvoice()}
            >
              Send
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

export default SendInvoice;
