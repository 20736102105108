/* -------------------------- CATEGORIES -----------------------------*/

import { api_url } from "../../util/environment";
import { postAccount } from "../Accounting/api";
import { currentBusiness } from "../../features/authentication/util";

export async function getCategories(type) {
    var response;
    if (type == "sales") {
        response = await fetch(
            api_url + "/site/categories/sales/user/" + currentBusiness()
        );
    } else if (type == "purchases") {
        response = await fetch(
            api_url + "/site/categories/purchases/user/" + currentBusiness()
        );
    } else {
        response = await fetch(
            api_url + "/site/categories/user/" + currentBusiness()
        );
    }
    return response.json();
}

export async function getCategory(id) {
    const response = await fetch(api_url + "/site/categories/" + id);
    return response.json();
}

export async function postCategories(body) {
    const response = await fetch(
        api_url + "/site/categories/user/" + currentBusiness(),
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    return response.json();
}

export async function putCategories(body, id) {
    const response = await fetch(api_url + "/site/categories/" + id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
    return response.json();
}

export async function deleteCategories(id) {
    const response = await fetch(api_url + "/site/categories/" + id, {
        method: "DELETE",
    });
    return response.json();
}

export async function getNumberOfProductsInCategory(id) {
    const response = await fetch(
        api_url + "/site/categories/" + id + "/num-products"
    );
    return response.json();
}

export async function deleteAllCategories() {
    const post = await fetch(
        api_url + "/site/categories/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}

/* ---------------------- PRODUCTS & SERVICES ------------------------*/

export async function getProduct(id) {
    const response = await fetch(api_url + "/site/products-and-services/" + id);
    return response.json();
}

export async function putProduct(body, id) {
    const response = await fetch(
        api_url + "/site/products-and-services/" + id,
        {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    return response.json();
}

export async function getProducts(type) {
    var response;
    if (type == "sales") {
        response = await fetch(
            api_url +
                "/site/products-and-services/sales/user/" +
                currentBusiness()
        );
    } else if (type == "purchases") {
        response = await fetch(
            api_url +
                "/site/products-and-services/purchases/user/" +
                currentBusiness()
        );
    } else {
        response = await fetch(api_url + "/site/categories");
    }
    return response.json();
}

export async function getProductPriceOfOne(id) {
    const response = await fetch(
        api_url + "/site/products-and-services/" + id + "/price-of-one"
    );
    return response.json();
}

export async function getProductPrice(id, quantity) {
    const response = await fetch(
        api_url +
            "/site/products-and-services/" +
            id +
            "/price-of-n/" +
            quantity
    );
    return response.json();
}

export async function getProductPricing(id) {
    const response = await fetch(
        api_url + "/site/products-and-services/" + id + "/quantity-pricing"
    );
    return response.json();
}

export async function deleteAllProducts() {
    const post = await fetch(
        api_url + "/site/products-and-services/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}

/* ----------------------------- TAXES -------------------------------*/

export async function getTaxes() {
    const response = await fetch(
        api_url + "/site/taxes/user/" + currentBusiness()
    );

    return response.json();
}

export async function postTax(body) {
    const response = await fetch(
        api_url + "/site/taxes/user/" + currentBusiness(),
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    const accountBody = {
        category: "Liabilities",
        subcategory: "Taxes Payable",
        type: "Sales Taxes",
        name: body.name,
        currency_id: 1,
        account_number: body.tax_number,
        description: "Taxes Payable",
        debit_credit: "credit",
        balance: 0,
        owner: currentBusiness(),
    };

    postAccount(accountBody);

    return response.json();
}

export async function deleteAllTaxes() {
    const post = await fetch(
        api_url + "/site/taxes/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}

/*------------------------------COMPANIES-----------------------------*/

export async function getCompanies() {
    const response = await fetch(
        api_url + "/site/companies/user/" + currentBusiness()
    );
    return response.json();
}

/*------------------------------USERS-----------------------------*/
export async function getUsers() {
    const response = await fetch(api_url + "/site/users");

    return response.json();
}

export async function getUser(id) {
    const response = await fetch(api_url + "/site/users/" + id);

    return response.json();
}

/*------------------------------Contacts-----------------------------*/
export async function getCustomersWithCompanies() {
    const response = await fetch(
        api_url + "/customerCompanies/user/" + currentBusiness()
    );
    return response.json();
}

export async function getVendorsWithCompanies() {
    const response = await fetch(
        api_url + "/vendorCompanies/user/" + currentBusiness()
    );
    return response.json();
}

export async function deleteCustomer(id) {
    const response = await fetch(api_url + "/sales/customers/" + id, {
        method: "DELETE",
    });
    return response.json();
}

export async function deleteVendor(id) {
    const response = await fetch(api_url + "/purchases/vendors/" + id, {
        method: "DELETE",
    });
    return response.json();
}
