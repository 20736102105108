import React from "react";
import styled from "styled-components";

/* Props 
    className
    label
    dropdownList
    value
    onChange
*/

const DropdownSelect = styled.select`
  &:hover {
    cursor: pointer;
  }
`;

function Dropdown(props) {
  return (
    <div className={props.className}>
      {props.label ? (
        <label htmlFor="form-select" className="form-label">
          {props.label}
        </label>
      ) : null}

      <DropdownSelect
        className="form-select picker labeled-dropdown"
        value={props.value}
        onChange={props.onChange}
      >
        {props.placeholder ? (
          <option disabled selected>
            {props.placeholder}
          </option>
        ) : null}
        {props.dropdownList.map((item, index) => {
          return (
            <option className="dropdownOption " key={item} value={item}>
              {item}
            </option>
          );
        })}
      </DropdownSelect>
    </div>
  );
}

export default Dropdown;
