import { getInvoices } from "../pages/Sales/api";
import useFetch from "./useFetch";

const useInvoices = () => {
  const {
    loading: loadingInvoices,
    error: errorInvoices,
    data: invoices,
  } = useFetch(getInvoices);
  return { loadingInvoices, errorInvoices, invoices };
};

export default useInvoices;
