import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { DropdownSidebarData } from "./DropdownSidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import { getUser, getUsers } from "../../pages/Site/api";
import { SettingsContext } from "../../App";
import SettingsIcon from "../Icons/SettingsIcon";
import BoldHoverable from "../Buttons/BoldHoverable";

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #cce2cd;
  width: var(--sidebar-width);
  height: 100vh;
  display: flex;
  float: left;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
  overflow-y: auto;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const DropdownSidebar = () => {
  var falseArr = [];
  DropdownSidebarData.forEach((item, index) => {
    falseArr.push(false);
  });
  const [submenuArr, setSubmenuArr] = useState(falseArr);
  const [users, setUsers] = useState(null);
  useEffect(() => {
    getUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  const hideAll = () => setSubmenuArr(falseArr);

  const showOne = (index) => {
    var newArr = JSON.parse(JSON.stringify(falseArr)); //deep copy
    newArr[index] = !submenuArr[index];
    console.log(newArr);
    setSubmenuArr(newArr);
  };
  let loc = useLocation();
  let nav = useNavigate();

  let { settings, user } = React.useContext(SettingsContext);

  return (
    <>
      {loc.pathname === "/" || loc.pathname === "/#/" ? null : (
        <IconContext.Provider value={{ color: "#fff" }}>
          <div className="sidebarNav">
            <SidebarNav>
              <SidebarWrap>
                {DropdownSidebarData.map((item, index) => {
                  return (
                    <>
                      {item.membership.includes(settings.membership) ? (
                        <SubMenu
                          item={item}
                          key={index}
                          show={submenuArr[index]}
                          onClick={() => showOne(index)}
                        />
                      ) : null}
                    </>
                  );
                })}
                <div
                  style={{
                    minHeight: "2em",
                    background: "#cce2cd",
                  }}
                ></div>
              </SidebarWrap>
            </SidebarNav>
            <div
              style={{
                display: "flex",
                position: "fixed",
                bottom: "0px",
                zIndex: "11",
                width: "235px",
                background: "#cce2cd",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                marginBottom: "10px",
              }}
            >
              {users ? (
                <>
                  <div>Logged in as: </div>

                  <BoldHoverable
                    onClick={() => nav("/profile/personal-information")}
                  >
                    {user.email}
                  </BoldHoverable>
                </>
              ) : null}
              <SettingsIcon onClick={() => nav("/settings/users")} />
            </div>
          </div>
        </IconContext.Provider>
      )}
    </>
  );
};

export default DropdownSidebar;
