import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MultiPage from "../../Documents/MultiPage";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { companyInfo, userInvoiceSettings } from "./Data";
import {
  getEstimateItems,
  getEstimate,
  newEstimate,
  updateEstimate,
  putLineItem,
  deleteLineItem,
  getCurrentDocumentId,
} from "../Estimates/util";
import { toFloatg } from "../../../util/formatters";
import EstimateHeader from "../Estimates/EstimateHeader";
import BudgetaryEstimateBody from "./BudgetaryEstimateBody";
import {
  dateTodayUSAformat,
  dateNdaysFromDateFormatted,
  getDate,
} from "../../../util/dates";

import { isEqual } from "../../../util/jsonUtil";

import {
  getDocumentItemsQuantityPricings,
  putDocumentItemQuantityPricing,
  deleteDocumentItemQuantityPricing,
  postDocument,
  postDocumentItemQuantityPricing,
  postLineItem,
  sendDocument,
  getSalesProducts,
  getCustomers,
} from "../api";

import { IoSettingsSharp } from "react-icons/io5";

import MainFooter from "../../Documents/Footers/MainFooter";

import SendInvoice from "../Invoices/Send/SendInvoice";
import ErrorPopup from "../../../components/Popups/ErrorPopup";
import { getTaxes, postTax } from "../../Site/api";

async function handleDownloadPdf(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 4, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  pdf.save("print.pdf");
}

async function pdfToBase64(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 2, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  return btoa(pdf.output());
}

//handling form submission, page settings, db fetching
function BudgetaryEstimatesForm() {
  let navigate = useNavigate();
  let { id } = useParams();

  const [orientation, setOrientation] = useState("portrait");
  const [lineItemsOrig, setLineItemsOrig] = useState({});
  const [lineItemsForm, setLineItemsForm] = useState([
    {
      id: -1, //negative id to denote new item
      item_name: "LineItem",
      quantity: null,
      price: null,
      discount: null,
      tax: null,
      line_total: null,
      notes: "notes",
      page_order: 0,
      invoice_id: "",
      products_and_services_id: null,
    },
  ]);
  const [allProducts, setAllProducts] = useState([]);
  const [quantityPricings, setQuantityPricings] = useState([
    [{ id: -1, starting_quantity: 1, ending_quantity: null, price: "0.00" }],
  ]);
  const [quantityPricingsOrig, setQuantityPricingsOrig] = useState();

  const [form, setForm] = useState({
    status: "draft",
    from_name: companyInfo[0].name,
    from_address: companyInfo[0].address,
    from_phone: companyInfo[0].number,
    from_website: companyInfo[0].website,
    from_email: companyInfo[0].email,

    issue_date: dateTodayUSAformat,
    net: userInvoiceSettings[0].default_net.toString(),
    due_date: dateNdaysFromDateFormatted(
      new Date(),
      parseInt(userInvoiceSettings[0].default_net)
    ),
    currency: "USD",
    po_number: "#",

    to_name: "Name",
    to_address: "Address",
    to_city: "City",
    to_zip: "Zip",
    to_state: "State",
    to_country: "Country",
    to_phone: "Phone",
    to_website: "Website",
    to_email: "Email",
    to_other: "Other",

    invoice_number: id,

    footer: "",
    tax_percentage: "0",
    discount_amount: "0",
    discount_percentage: "0",
    amount_paid: "0",
    amount_due: "0",
    type: "budgetary-estimate",

    created_at: null,
    updated_at: null,
    archived_at: null,
    deleted_at: null,
    converted_from: null,
    converted_to: null,
    customer_id: null,
    vendor_id: null,
  });
  const [allTaxes, setAllTaxes] = useState([]);
  const [taxes, setTaxes] = useState([[]]);
  const [ready, setReady] = useState(false);

  const [sendPopup, setSendPopup] = useState({
    show: false,
    toEmail: null,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [customers, setCustomers] = useState([]);

  const printRefs = useRef([]);

  useEffect(() => {
    getCustomers().then((data) => setCustomers(data));
    console.log("mount");
    if (id) {
      getEstimateItems(id).then((data) => {
        var taxList = [];
        for (var i in data) {
          taxList.push([]);
        }
        setTaxes(taxList);
        console.log(data);
        setLineItemsOrig(JSON.parse(JSON.stringify(data)));
        setLineItemsForm(data);
        var list = quantityPricings;
        for (let i in data) {
          getDocumentItemsQuantityPricings(data[i].id).then((data) => {
            console.log(data);
            list[i] = data;
            setQuantityPricings(JSON.parse(JSON.stringify(list)));
            setQuantityPricingsOrig(JSON.parse(JSON.stringify(list)));
          });
        }

        console.log("edit" + id);
      });
      getEstimate(id).then((data) => {
        console.log("GET ESTIMATE");
        console.log(data[0]);
        setForm(data[0]);
      });
    } else {
      console.log("add");
      getCurrentDocumentId().then((data) => {
        var currentForm = form;
        currentForm.invoice_number = parseInt(data[0].last_value) + 1;
        setForm(currentForm);
      });
    }

    getTaxes().then((data) => {
      setAllTaxes(data);
    });

    getSalesProducts().then((data) => {
      console.log(data);
      setAllProducts(data);
    });
  }, []);

  useEffect(() => {
    if (lineItemsForm.length != quantityPricings.length) {
      setReady(false);
    } else {
      setReady(true);
    }
  }, [lineItemsForm]);

  useEffect(() => {
    if (lineItemsForm.length != quantityPricings.length) {
      setReady(false);
    } else {
      setReady(true);
    }
  }, [quantityPricings]);

  const SecondaryHeader = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{props.upperLeft}</div>
        <div>{props.upperRight}</div>
      </div>
    );
  };

  const Footer = (props) => {
    return (
      <div
        style={{
          height: "30px",
          marginTop: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{props.lowerLeft}</div>
        <div>{props.lowerRight}</div>
      </div>
    );
  };

  const submitForm = () => {
    console.log(form);
    console.log(lineItemsForm);
    console.log(quantityPricings);
    if (id) {
      editEstimate().then(() => {
        console.log("edit");
        editQuantityPricings().then(() => {
          navigate("/sales/budgetary-estimates");
        });
      });
    } else {
      postDocument(form).then((data) => {
        console.log("POST DOCUMENT");
        const document_id = data.rows[0].id;
        postAllLineItems(document_id).then(() => {
          console.log("NAVIGATE");
          navigate("/sales/budgetary-estimates");
        });
      });
    }
  };

  const postAllLineItems = async (document_id) => {
    for (let i in lineItemsForm) {
      var lineItemsCopy = lineItemsForm[i];
      delete lineItemsCopy["id"];
      lineItemsCopy["invoice_id"] = document_id;

      postLineItem(lineItemsForm[i]).then((data) => {
        console.log("POST DOCUMENT_ITEM");
        console.log(data);
        const item_id = data[0].id;
        postQuantityPricings(item_id, i);
      });
    }
  };

  const postQuantityPricings = async (item_id, index) => {
    for (let i in quantityPricings[index]) {
      console.log("POST QUANTITY PRICING");
      var quantityPricingCopy = quantityPricings[index][i];
      delete quantityPricingCopy["id"];
      delete quantityPricingCopy["products_and_services_id"];
      quantityPricingCopy["document_items_id"] = item_id;
      postDocumentItemQuantityPricing(quantityPricingCopy);
    }
  };

  const editEstimate = async () => {
    var estimateForm = form;

    delete estimateForm["id"];
    delete estimateForm["owner"];
    console.log(estimateForm);
    updateEstimate(estimateForm, id).then((data) => {
      console.log(data);
    });

    //Loop through items and POST the ones with id < 0
    for (let i in lineItemsForm) {
      if (lineItemsForm[i].id < 0) {
        console.log("POST");
        var lineItemBody = lineItemsForm[i];
        delete lineItemBody["id"];
        lineItemBody["invoice_id"] = id;
        postLineItem(lineItemBody).then((itemId) => {
          console.log("RETURNING ID");
          console.log(itemId);
          for (let j in quantityPricings[i]) {
            var quantityBody = quantityPricings[i][j];
            console.log("POST");
            console.log(quantityBody);
            delete quantityBody["id"];
            quantityBody["document_items_id"] = itemId[0].id;
            postDocumentItemQuantityPricing(quantityBody);
          }
        });
      }
    }
    var found_i = false;
    var found_index = null;
    console.log(lineItemsForm);
    console.log(lineItemsOrig);
    for (let i in lineItemsOrig) {
      for (let j in lineItemsForm) {
        if (lineItemsOrig[i].id == lineItemsForm[j].id) {
          found_i = true;
          found_index = j;
        }
      }
      if (found_i) {
        if (!isEqual(lineItemsOrig[i], lineItemsForm[found_index])) {
          var lineItemBody = lineItemsForm[found_index];
          const lineItemId = lineItemsForm[found_index].id;
          delete lineItemBody["id"];
          putLineItem(lineItemBody, lineItemId);
        }
      } else {
        console.log("DELETE" + lineItemsOrig[i].id);
        deleteLineItem(lineItemsOrig[i].id);
      }
      found_i = false; //reset boolean
      found_index = null;
    }
    // this.putInvoice(this.props.invoice_id);
  };

  const editQuantityPricings = async () => {
    console.log(quantityPricings);
    console.log(quantityPricingsOrig);
    for (let index in quantityPricings) {
      console.log("ITEM NUMBER: " + index);
      for (let i in quantityPricings[index]) {
        if (quantityPricings[index][i].id < 0) {
          console.log("POST");

          var lineItemBody = quantityPricings[index][i];
          delete lineItemBody["id"];
          //lineItemBody["document_items_id"] = id;
          console.log(lineItemBody);
          if (lineItemBody["document_items_id"] >= 0) {
            postDocumentItemQuantityPricing(lineItemBody);
          }
        }
      }
      var found_i = false;
      var found_index = null;
      console.log(quantityPricings[index]);
      console.log(quantityPricingsOrig[index]);
      for (let i in quantityPricingsOrig[index]) {
        for (let j in quantityPricings[index]) {
          if (
            quantityPricingsOrig[index][i].id == quantityPricings[index][j].id
          ) {
            found_i = true;
            found_index = j;
          }
        }
        if (found_i) {
          if (
            !isEqual(
              quantityPricingsOrig[index][i],
              quantityPricings[index][found_index]
            )
          ) {
            var lineItemBody = quantityPricings[index][found_index];
            const lineItemId = quantityPricings[index][found_index].id;
            delete lineItemBody["id"];
            console.log("PUT " + lineItemId);
            //putLineItem(lineItemBody, lineItemId);
            putDocumentItemQuantityPricing(lineItemBody, lineItemId);
          }
        } else {
          console.log("DELETE" + quantityPricingsOrig[index][i].id);
          //deleteLineItem(quantityPricingsOrig[index][i].id);
          deleteDocumentItemQuantityPricing(quantityPricingsOrig[index][i].id);
        }
        found_i = false; //reset boolean
        found_index = null;
      }
    }
  };

  const createTax = (form) => {
    console.log("creating tax");

    const body = {
      tax_number: form.tax_number,
      name: form.name,
      percentage: toFloatg(form.percentage / 100, "discount"),
      is_recoverable: form.is_recoverable,
      is_compound: form.is_compound,
      effective_date: null,
      obsolete_date: null,
      created_at: Date.now(),
      updated_at: Date.now(),
      archived_at: null,
      deleted_at: null,
    };
    postTax(body).then((json_res) => {
      console.log(json_res);
      getTaxes().then((data) => {
        setAllTaxes(data);
      });
    });
  };

  const addLineItem = () => {
    console.log("add line item");

    var list = quantityPricings;
    list.push([
      {
        id: 0 - quantityPricings.flat().length - 1, //negative id to denote new item - starts counting from -1 if starting from empty
        starting_quantity: 1,
        ending_quantity: null,
        price: "0.00",
        document_items_id: 0 - quantityPricings.flat().length - 1,
      },
    ]);
    setQuantityPricings(JSON.parse(JSON.stringify(list)));

    const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
    newLineItems.push({
      id: 0 - lineItemsForm.length - 1, //negative id to denote new item - starts counting from -1 if starting from empty
      item_name: "LineItem",
      quantity: null,
      price: null,
      discount: null,
      tax: null,
      line_total: null,
      notes: "notes",
      page_order: lineItemsForm.length,
      invoice_id: "",
      products_and_services_id: null,
    });

    setLineItemsForm(newLineItems);
    console.log(newLineItems);
  };

  const removeLineItem = (index) => {
    var currentItemList = lineItemsForm;
    var currentQuantityList = quantityPricings;

    currentItemList.splice(index, 1);
    currentQuantityList.splice(index, 1);

    setLineItemsForm(JSON.parse(JSON.stringify(currentItemList)));
    setQuantityPricings(JSON.parse(JSON.stringify(currentQuantityList)));
  };

  function getLineItemHeight(i) {
    var height = 50;
    height = height + 24 * (quantityPricings[i].length - 1);

    return height;
  }
  function getTotalBoxHeight() {
    var height = 0; //starting height

    return height;
  }

  return (
    <div>
      <div
        style={{
          width: "900px",
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
        }}
      >
        <div
          className="top-left"
          style={{ display: "flex", marginRight: "50px" }}
        >
          <h1
            className="pageTitle"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <div style={{}}>
              {id ? "Edit Budgetary Estimate" : "New Budgetary Estimate"}{" "}
            </div>

            <IoSettingsSharp
              className="settingsIcon"
              fontSize="16px"
              style={{ marginBottom: "5px" }}
            />
          </h1>
        </div>

        <div className="invoiceButtons">
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                if (orientation == "landscape") {
                  setOrientation("portrait");
                } else {
                  setOrientation("landscape");
                }
                const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
                setLineItemsForm(newLineItems);
              }}
            >
              Change Orientation
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => handleDownloadPdf(printRefs.current, orientation)}
            >
              Download as PDF
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                submitForm();
              }}
            >
              Save
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setSendPopup({ show: true, toEmail: form.to_email });
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>

      <div style={{ fontFamily: "Georgia", fontSize: "15px" }}>
        {console.log(quantityPricings)}
        {console.log(lineItemsForm)}
        {quantityPricings.length == lineItemsForm.length ? (
          <MultiPage
            lineItems={lineItemsForm}
            orientation={orientation}
            printRefs={printRefs}
            header={
              <EstimateHeader
                setters={[setLineItemsForm, setForm]}
                getters={[lineItemsForm, form, customers]}
                title={"Budgetary Estimate"}
              />
            }
            secondaryHeader={
              <SecondaryHeader
                upperLeft={
                  "Budgetary Estimate # " + (form.invoice_number || "")
                }
                upperRight={getDate(new Date())}
              />
            }
            body={<BudgetaryEstimateBody />}
            totals={<></>}
            mainFooter={
              <MainFooter lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />
            }
            footer={
              <Footer lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />
            }
            companyInfo={companyInfo}
            setters={[
              setLineItemsForm,
              setForm,
              setAllTaxes,
              setTaxes,
              setAllProducts,
              setQuantityPricings,
            ]}
            getters={[
              lineItemsForm,
              form,
              allTaxes,
              taxes,
              allProducts,
              quantityPricings,
            ]}
            addLineItem={addLineItem}
            deleteLineItem={removeLineItem}
            getLineItemHeight={getLineItemHeight}
            getTotalBoxHeight={getTotalBoxHeight}
            ready={ready}
          />
        ) : null}
      </div>
      <SendInvoice
        sendPopup={sendPopup}
        closePopup={() => {
          setSendPopup({ show: false, id: null, toEmail: null });
        }}
        setError={(error) => setError(error)}
        setSuccess={() => setSuccess(true)}
        toSent={(id) => sendDocument(id).then(() => console.log("sent"))}
        attachment={() => pdfToBase64(printRefs.current, orientation)}
        subject={"Budgetary Estimate #" + form.invoice_number}
        message={"Here is your Budgetary Estimate"}
      />
      {error ? (
        <ErrorPopup errorMessage={error} clearError={() => setError(null)} />
      ) : null}
    </div>
  );
}

export default BudgetaryEstimatesForm;
