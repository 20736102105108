import React, { useState } from "react";
import ImagePreview from "../Invoices/Form/ImagePreview";
import AlphanumericInput from "../../../components/Forms/documents/AlphanumericInput";
import PhoneInput from "../../../components/Forms/documents/PhoneInput";
import DateInput from "../../../components/Forms/documents/DateInput";
import IntInput from "../../../components/Forms/documents/IntInput";
import { companyInfo } from "./Data";
import { formatDate } from "../../../util/dates";

import AdditionalContactIcon from "../../../components/Icons/AddAdditionalIcon";
import SearchableDropdownWithCreate from "../../../components/Forms/SearchableDropdownWithCreate";

function EstimateHeader(props) {
  const [customerSelected, setCustomerSelected] = useState(false);
  const [showCustomerMenu, setShowCustomerMenu] = useState(false);
  const onChange = (key, value) => {
    var form = props.getters[1];
    form[key] = value;
    props.setters[1](JSON.parse(JSON.stringify(form)));
  };

  var documentContactClass = "documentContact";
  if (showCustomerMenu) {
    documentContactClass = null;
  }

  return (
    <div style={{ height: "360px" }}>
      <div
        style={{
          height: "calc(160px - 0.5in)",
          borderBottom: "solid",
          borderWidth: "1px 0",
          display: "flex",
        }}
      >
        <div style={{ minWidth: "35%", marginLeft: "20px" }}>
          <ImagePreview url={"/1_hCBlqxgSsc1acciJoWDwVA.jpeg"} />
        </div>

        <div>
          <AlphanumericInput
            width={"160px"}
            limit={30}
            changeText={(e) => onChange("from_name", e)}
            initialValue={props.getters[1].from_name}
          />
          <AlphanumericInput
            width={"160px"}
            limit={30}
            changeText={(e) => onChange("from_address", e)}
            initialValue={props.getters[1].from_address}
          />
          <div style={{ display: "flex" }}>
            <PhoneInput
              width={"160px"}
              changeText={(e) => onChange("from_phone", e)}
              id={"phone-entry-1"}
              initialValue={props.getters[1].from_phone}
            />
            <AlphanumericInput
              width={"160px"}
              limit={30}
              changeText={(e) => onChange("from_website", e)}
              initialValue={props.getters[1].from_website}
            />
          </div>

          <AlphanumericInput
            width={"160px"}
            limit={30}
            changeText={(e) => onChange("from_email", e)}
            initialValue={props.getters[1].from_email}
            extraAllowableChars={"@.-"}
          />
        </div>
      </div>
      <div style={{ height: "180px", marginTop: "10px", display: "flex" }}>
        <table height="120px" style={{ width: "50%" }}>
          <thead>
            <tr>
              <th width="120px"></th>
              <th width="120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label>Issue Date:</label>
              </td>
              <td>
                <DateInput
                  initialValue={props.getters[1].issue_date}
                  changeText={(e) => onChange("issue_date", formatDate(e))}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Net: </label>
              </td>
              <td>
                <IntInput
                  initialValue={props.getters[1].net}
                  width={"120px"}
                  limit={3}
                  changeText={(e) => onChange("net", e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Expires on:</label>
              </td>
              <td>
                <DateInput
                  initialValue={props.getters[1].due_date}
                  changeText={(e) => onChange("due_date", formatDate(e))}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Currency:</label>
              </td>
              <td>
                {" "}
                <AlphanumericInput
                  width={"120px"}
                  initialValue={props.getters[1].currency}
                  limit={3}
                  changeText={(e) => onChange("currency", e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>P.O.#:</label>
              </td>
              <td>
                <AlphanumericInput
                  width={"120px"}
                  initialValue={props.getters[1].po_number}
                  limit={11}
                  changeText={(e) => onChange("po_number", e)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {!customerSelected ? (
          <div
            className={documentContactClass}
            style={{
              width: "50%",
              position: "relative",
            }}
            onClick={() => {
              setShowCustomerMenu(true);
              document.getElementById("search-bar").focus();
            }}
          >
            {showCustomerMenu ? (
              <div
                style={{
                  position: "absolute",
                  opacity: "1",
                  zIndex: 100,
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setShowCustomerMenu(false);
                  }, 200);
                }}
                disabled={false}
              >
                <SearchableDropdownWithCreate
                  searchBarID={"search-bar"}
                  list={[...props.getters[2].map((a) => [a.primary_name])]}
                  functions={[() => console.log(), () => console.log()]}
                  onItemClick={(i) => {
                    setCustomerSelected(true);
                    console.log(i);
                    console.log(props.getters[2]);
                    console.log(props.getters[2][i].to_name);
                    onChange("to_name", props.getters[2][i].primary_name);
                    onChange(
                      "to_address",
                      props.getters[2][i].shipping_address
                    );
                    onChange("to_city", props.getters[2][i].shipping_city);
                    onChange("to_state", props.getters[2][i].shipping_state);
                    onChange("to_zip", props.getters[2][i].shipping_zip_code);
                    onChange(
                      "to_country",
                      props.getters[2][i].shipping_country
                    );
                    onChange("to_phone", props.getters[2][i].primary_phone);
                    onChange("to_email", props.getters[2][i].primary_email);
                  }}
                  buttonList={[]}
                />
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  zIndex: "-100",
                  opacity: "0",
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setShowCustomerMenu(false);
                  }, 200);
                }}
                disabled={true}
              >
                <SearchableDropdownWithCreate
                  searchBarID={"search-bar"}
                  list={[["test"], ["test2"]]}
                  functions={[() => console.log(), () => console.log()]}
                  onItemClick={() => console.log()}
                  buttonList={["Create a new tax", "Add a discount (fixed)"]}
                />
              </div>
            )}
            <div className="testIcon">
              {props.title == "Bill" ||
              props.title == "Recurring Bill" ||
              props.title == "Purchase Order" ? (
                <AdditionalContactIcon title="Add Vendor" active />
              ) : (
                <AdditionalContactIcon title="Add Customer" active />
              )}
            </div>
            <div style={{ fontWeight: "bold" }}>
              {props.title == "Invoice" || props.title == "Bill"
                ? "Bill to: "
                : "Send to:"}
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_name}
                limit={30}
                changeText={(e) => onChange("to_name", e)}
              />
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_address}
                limit={30}
                changeText={(e) => onChange("to_address", e)}
              />
            </div>
            <div className="documentContactLabel">
              <div style={{ display: "flex" }}>
                <AlphanumericInput
                  width={"80px"}
                  initialValue={props.getters[1].to_city}
                  limit={30}
                  changeText={(e) => onChange("to_city", e)}
                />
                <AlphanumericInput
                  width={"80px"}
                  initialValue={props.getters[1].to_state}
                  limit={30}
                  changeText={(e) => onChange("to_state", e)}
                />
                <AlphanumericInput
                  width={"80px"}
                  initialValue={props.getters[1].to_zip}
                  limit={30}
                  changeText={(e) => onChange("to_zip", e)}
                />
              </div>
            </div>

            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_country}
                limit={30}
                changeText={(e) => onChange("to_country", e)}
              />
            </div>
            <div className="documentContactLabel">
              <PhoneInput
                initialValue={props.getters[1].to_phone}
                id={"phone-entry-2"}
                changeText={(e) => onChange("to_phone", e)}
              />
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"200px"}
                initialValue={props.getters[1].to_email}
                limit={30}
                changeText={(e) => onChange("to_email", e)}
                extraAllowableChars={"@.-"}
              />
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_other}
                limit={30}
                changeText={(e) => onChange("to_other", e)}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "50%",
              position: "relative",
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              {props.title == "Invoice" || props.title == "Bill"
                ? "Bill to: "
                : "Send to:"}
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_name}
                limit={30}
                changeText={(e) => onChange("to_name", e)}
              />
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_address}
                limit={30}
                changeText={(e) => onChange("to_address", e)}
              />
            </div>
            <div className="documentContactLabel">
              <div style={{ display: "flex" }}>
                <AlphanumericInput
                  width={"80px"}
                  initialValue={props.getters[1].to_city}
                  limit={30}
                  changeText={(e) => onChange("to_city", e)}
                />
                <AlphanumericInput
                  width={"80px"}
                  initialValue={props.getters[1].to_state}
                  limit={30}
                  changeText={(e) => onChange("to_state", e)}
                />
                <AlphanumericInput
                  width={"80px"}
                  initialValue={props.getters[1].to_zip}
                  limit={30}
                  changeText={(e) => onChange("to_zip", e)}
                />
              </div>
            </div>

            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_country}
                limit={30}
                changeText={(e) => onChange("to_country", e)}
              />
            </div>
            <div className="documentContactLabel">
              <PhoneInput
                initialValue={props.getters[1].to_phone}
                id={"phone-entry-2"}
                changeText={(e) => onChange("to_phone", e)}
              />
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"200px"}
                initialValue={props.getters[1].to_email}
                limit={30}
                changeText={(e) => onChange("to_email", e)}
                extraAllowableChars={"@.-"}
              />
            </div>
            <div className="documentContactLabel">
              <AlphanumericInput
                width={"120px"}
                initialValue={props.getters[1].to_other}
                limit={30}
                changeText={(e) => onChange("to_other", e)}
              />
            </div>
          </div>
        )}
      </div>
      <h3 style={{ textAlign: "center", margin: "20px" }}>
        {props.title} # {props.getters[1].invoice_number}
      </h3>
    </div>
  );
}

export default EstimateHeader;
