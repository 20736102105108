import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import ContactForm from "./ContactForm";

const WrappedContactForm = (props) => {
  const emptyContact = [
    {
      company_id: "",
      type: "",
      website: "",
      primary_name: "",
      primary_position: "",
      primary_email: "",
      primary_phone: "",
      shipping_address: "",
      shipping_city: "",
      shipping_zip_code: "",
      shipping_state: "",
      shipping_country: "",
      billing_address: "",
      billing_city: "",
      billing_zip_code: "",
      billing_state: "",
      billing_country: "",
      tax_number: "",
      currency: "",
      created_by: "",
      created_at: "",
      updated_at: "",
      deleted_at: "",
      company: "",
    },
  ];

  const [companyList, setCompanyList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [contact, setContact] = useState(emptyContact);
  const navigate = useNavigate();
  let { id } = useParams();

  return (
    <>
      {props.action == "add" ? (
        <ContactForm
          navigate={navigate}
          companyList={companyList}
          currencyList={currencyList}
          contact={emptyContact}
          returnPath={props.returnPath}
          category={props.category}
          type={props.type}
        />
      ) : (
        <ContactForm
          navigate={navigate}
          companyList={companyList}
          currencyList={currencyList}
          contact_id={id}
          contact={contact}
          returnPath={props.returnPath}
          category={props.category}
          type={props.type}
        />
      )}
    </>
  );
};

export default WrappedContactForm;
