import { useEffect, useState } from "react";
import { getVendors } from "../pages/Purchases/api";
import useFetch from "./useFetch";

const useVendors = (companies = false) => {
  const [vendorsError, setVendorsError] = useState(null);
  const {
    loading: loadingVendors,
    error,
    data: vendors,
  } = useFetch(getVendors);
  useEffect(() => {
    if (error) {
      setVendorsError("Failed to load vendors");
    }
  }, [error]);

  return { loadingVendors, vendorsError, vendors };
};

export default useVendors;
