import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";

function WarningMessage(props) {
  return (
    <>
      {props.message ? (
        <div
          style={{
            border: "1px solid var(--warning-color-medium)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              minHeight: "80px",
              backgroundColor: "var(--warning-color-light)",
              borderLeft: "8px solid var(--warning-color-dark)",
              borderRadius: "8px",
              padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "var(--warning-color-dark)",
                marginLeft: "0px",
                marginRight: "10px",
              }}
            >
              <RiErrorWarningLine fontSize={"30px"} />
            </div>

            <div style={{ whiteSpace: "normal" }}>{props.message}</div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default WarningMessage;
