import React from "react";

function Button(props) {
    return (
        <button
            type="button"
            className={`btn btn-${props.type ? props.type : "success"}`}
            onClick={() => {
                props.onClick();
            }}
            disabled={props.disabled}
        >
            {props.title ? props.title : "Submit"}
        </button>
    );
}

export default Button;
