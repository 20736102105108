import { isEqual } from "./jsonUtil";

export async function updateItem(original, updated, post, put, del) {
  console.log("Comparing original to updated: ");
  console.log("Original: ", original);
  console.log("Updated: ", updated);
  var found_i = false;
  var found_index = null;

  //Check if the updated array is different than the original array
  var changeDetected = false;
  if (original.length !== updated.length) {
    changeDetected = true;
  } else {
    for (let i = 0; i < original.length; i++) {
      if (!isEqual(original[i], updated[i])) {
        changeDetected = true;
        break;
      }
    }
  }

  if (!changeDetected) {
    console.log("No change detected");
    return;
  }

  //Check to see if there are any new items in the updated array
  for (let i in updated) {
    console.log(updated[i].id);
    for (let j in original) {
      if (updated[i].id == original[j].id) {
        found_i = true;
        found_index = j;
      }
    }
    if (!found_i) {
      var form = JSON.parse(JSON.stringify(updated[i]));
      delete form["id"];
      await post(form);
    }
    found_i = false;
    found_index = null;
  }

  for (let i in original) {
    for (let j in updated) {
      console.log(updated[j].id);
      if (original[i].id == updated[j].id) {
        found_i = true;
        found_index = j;
      }
    }
    if (found_i) {
      if (!isEqual(original[i], updated[found_index])) {
        // Check to see if the item in the updated array that shares the same id as the item in the original array is different
        console.log(
          "PUT updated at index: " + found_index + ": ",
          updated[found_index]
        );
        let updatedItemId = updated[found_index].id;
        var form = JSON.parse(JSON.stringify(updated[found_index]));
        delete form["id"];
        await put(form, updatedItemId);
      }
    } else {
      //Check to see if there are any items in the original array that are not in the updated array, if so delete them
      await del(original[i].id);
    }
    found_i = false;
    found_index = null;
  }
}
