import React, { useState, useEffect } from "react";
import {
  getExpenseAccounts,
  getTransactions,
} from "../../pages/Accounting/api";
import {
  getExpenseTransactions,
  getIncomeTransactions,
} from "../../pages/Accounting/api/transactionAPI";
import BarDashboard from "./BarDashboard";
import GeneralDashboard from "./GeneralDashboard";

import { toUSD } from "../../../src/util/formatters";
import { getQuarterStartDate, getWeekNumber } from "../../util/dates";

function MonthlyNetProfit() {
  const [expenseTransactions, setExpenseTransactions] = useState([]);
  const [incomeTransactions, setIncomeTransactions] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [profitSums, setProfitSums] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [subheaderValue, setSubheaderValue] = useState("");

  useEffect(() => {
    console.log(getWeekNumber(new Date("2021-01-02")));

    getIncomeTransactions().then((incomeData) => {
      console.log("Income Transactions: ", incomeData);
      setIncomeTransactions(incomeData);
      getExpenseTransactions().then((expenseData) => {
        console.log("Expense Transactions: ", expenseData);
        setExpenseTransactions(expenseData);

        setSubheaderValue("$XX,XXX");

        let ytdIncome = incomeData.filter(
          (transaction) =>
            new Date(transaction.date).getFullYear() ===
            new Date().getFullYear()
        );
        let ytdExpense = expenseData.filter(
          (transaction) =>
            new Date(transaction.date).getFullYear() ===
            new Date().getFullYear()
        );
        var sums = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let i in ytdIncome) {
          sums[new Date(ytdIncome[i].date).getMonth()] += parseFloat(
            ytdIncome[i].amount
          );
        }
        for (let i in ytdExpense) {
          sums[new Date(ytdExpense[i].date).getMonth()] -= parseFloat(
            ytdExpense[i].amount
          );
        }
        console.log("Year to Date: ", ytdIncome);
        console.log("Year to Date: ", ytdExpense);
        console.log("Profit Sums: ", sums);
        setProfitSums(sums);

        console.log(getQuarterStartDate(4));
      });
    });
  }, []);
  var monthlyNetProfitOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Monthly Net Profit",
      },
    },
    grouped: false,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Month",
          font: {
            size: 18,
            weight: "bold",
          },
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            });
          },
        },
        grid: {
          color: "#ccc",
          borderColor: "black",
          tickColor: "black",
        },
      },
    },
  };

  var monthlyNetProfitData = {
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Monthly Net Profit",
        data: profitSums,
        backgroundColor: "rgb(55, 63, 180)",
      },
    ],
  };

  return (
    <GeneralDashboard
      title={"Monthly Net Profit Dashboard"}
      subtitle={
        "Current Average Weekly Net Profit: " +
        toUSD(
          profitSums.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ) / getWeekNumber(new Date()),
          "total"
        )
      }
      subheader={<div style={{ margin: "30px" }}> </div>}
      mainTableHeader={"Monthly Total Net Profit"}
      mainTableData={[
        ["January", profitSums[0]],
        ["February", profitSums[1]],
        ["March", profitSums[2]],
        ["April", profitSums[3]],
        ["May", profitSums[4]],
        ["June", profitSums[5]],
        ["July", profitSums[6]],
        ["August", profitSums[7]],
        ["September", profitSums[8]],
        ["October", profitSums[9]],
        ["November", profitSums[10]],
        ["December", profitSums[11]],
      ]}
      sideTableOne={
        <table style={{ width: "120px" }}>
          <thead>
            <tr style={{ border: "1px solid black" }}>
              <th colspan="2" style={{ textAlign: "center" }}>
                Quarterly Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black" }}>Q1</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(profitSums[0] + profitSums[1] + profitSums[2], "total")}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>Q2</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(profitSums[3] + profitSums[4] + profitSums[5], "total")}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>Q3</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(profitSums[6] + profitSums[7] + profitSums[8], "total")}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>Q4</td>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  profitSums[9] + profitSums[10] + profitSums[11],
                  "total"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      }
      sideTableTwo={
        <table style={{ width: "120px" }}>
          <thead>
            <tr style={{ border: "1px solid black" }}>
              <th style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    whiteSpace: "pre-wrap",
                    width: "100px",
                  }}
                >
                  Year To Date Total
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  profitSums.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue,
                    0
                  ),
                  "total"
                )}
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <th style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    whiteSpace: "pre-wrap",
                    width: "100px",
                  }}
                >
                  Year To Date Average
                </div>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", border: "1px solid black" }}>
                {toUSD(
                  profitSums.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue,
                    0
                  ) / 12,
                  "total"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      }
      chart={
        profitSums.every((item) => item === 0) ? (
          <>
            <div
              style={{
                pointerEvents: "none",
                opacity: "0.3",
                filter: "blur(2px)",
                height: "100%",
                width: "100%",
              }}
            >
              <BarDashboard
                options={monthlyNetProfitOptions}
                data={{
                  labels: [
                    "Jan",
                    "Feb",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                  datasets: [
                    {
                      label: "Monthly Net Profit",
                      data: Array.from(
                        { length: 12 },
                        () => Math.random() * 100
                      ),
                      backgroundColor: "rgb(55, 63, 180)",
                    },
                  ],
                }}
              />
            </div>
            <div style={{ position: "absolute", fontWeight: "bold" }}>
              No profit has been recorded
            </div>
          </>
        ) : (
          <BarDashboard
            options={monthlyNetProfitOptions}
            data={monthlyNetProfitData}
          />
        )
      }
    />
  );
}

export default MonthlyNetProfit;
