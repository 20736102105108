import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ErrorPopup(props) {
  const [show, setShow] = useState(true);
  return (
    <Modal
      show={show}
      onHide={() => {
        props.clearError();
        setShow(false);
      }}
    >
      <Modal.Header style={{ backgroundColor: "#d11a2a", color: "white" }}>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The following error occured: <div>{props.errorMessage}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#d11a2a", border: "none" }}
          variant="secondary"
          onClick={() => {
            props.clearError();
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorPopup;
