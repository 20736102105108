import React, { useState, useEffect } from "react";
import { getExpenseAccounts } from "../../pages/Accounting/api";
import { getExpenseTransactions } from "../../pages/Accounting/api/transactionAPI";
import { getThroughput } from "../../pages/Dashboards/api";
import { getWeekNumber } from "../../util/dates";
import { multiply, subtractMoney } from "../../util/money";
import LineDashboard from "./LineDashboard";

function computeRunningAverage(arr, n) {
  const sums = [];
  for (let i = 0; i < arr.length; i++) {
    sums.push(
      arr.slice(Math.max(0, i - n + 1), i + 1).reduce((a, b) => a + b, 0)
    );
  }
  return sums.map((x) => x / Math.min(n, sums.length));
}

function ThroughputAverages() {
  const [fixedExpense, setFixedExpense] = useState([]);
  const [fixedExpenseEstimation, setFixedExpenseEstimation] = useState([]);
  const [oneWeekAverage, setOneWeekAverage] = useState([]);
  const [fourWeekAverage, setFourWeekAverage] = useState([]);
  const [sixWeekAverage, setSixWeekAverage] = useState([]);
  const [twelveWeekAverage, setTwelveWeekAverage] = useState([]);

  useEffect(() => {
    getThroughput().then((data) => {
      console.log("Throughput data: ", data);
      var sums = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,
      ];
      for (let i in data) {
        sums[getWeekNumber(new Date(data[i].issue_date))] += subtractMoney(
          multiply(data[i].price, data[i].quantity),
          multiply(data[i].tvc, data[i].quantity)
        );
      }
      setFourWeekAverage(computeRunningAverage(sums, 4));
      setSixWeekAverage(computeRunningAverage(sums, 6));
      setTwelveWeekAverage(computeRunningAverage(sums, 12));
      setOneWeekAverage(sums);
    });
    getExpenseTransactions().then((data) => {
      console.log("Expense Transactions: ", data);
      var expenseSums = Array.from({ length: 52 }, () => 0);
      var estimationSums = Array.from({ length: 52 }, () => null);
      for (let i in data) {
        expenseSums[getWeekNumber(new Date(data[i].date))] += parseFloat(
          data[i].amount
        );
      }
      const week = getWeekNumber(new Date());
      for (let i = week - 1; i < expenseSums.length; i++) {
        estimationSums[i] = expenseSums[week - 1];
      }

      console.log("Expense Sums: ", expenseSums);
      console.log("Estimation Sums: ", estimationSums);
      setFixedExpense(expenseSums);
      setFixedExpenseEstimation(estimationSums);
    });
  }, []);
  var lineOptions = {
    bezierCurve: true,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Throughput Averages",
      },
    },
    scales: {
      x: {
        type: "linear",
        min: 0,
        max: 55,
        ticks: {
          stepSize: 5,
        },
        title: {
          display: true,
          text: "Week #",
          font: {
            size: 18,
            weight: "bold",
          },
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            });
          },
        },
      },
    },
    grouped: false,
    maintainAspectRatio: false,
  };

  var lineData = {
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
    ],
    datasets: [
      {
        label: "1-Week Average",
        data: oneWeekAverage,
        borderColor: "rgb(222, 29, 232)",
        backgroundColor: "rgb(222, 29, 232)",
        lineTension: 0.2,
        pointRadius: 0,
      },
      {
        label: "4-Week Average",
        data: fourWeekAverage,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        lineTension: 0.2,
        pointRadius: 0,
      },
      {
        label: "6-Week Average",
        data: sixWeekAverage,
        borderColor: "rgb(78, 178, 67)",
        backgroundColor: "rgb(78, 178, 67)",
        lineTension: 0.2,
        pointRadius: 0,
      },
      {
        label: "12-Week Average",
        data: twelveWeekAverage,
        borderColor: "rgb(55, 63, 180)",
        backgroundColor: "rgb(55, 63, 180)",
        lineTension: 0.2,
        pointRadius: 0,
      },
      {
        label: "Fixed Expense",
        data: fixedExpense,
        borderColor: "#8B0000",
        backgroundColor: "#8B0000",
        lineTension: 0,
        pointRadius: 0,
      },
      {
        label: "Fixed Expense Estimation",
        data: fixedExpenseEstimation,
        borderColor: "#8B0000",
        backgroundColor: "#FFF",
        lineTension: 0,
        pointRadius: 0,
        borderDash: [10, 5],
      },
    ],
  };
  return (
    <div style={{ height: "400px", minWidth: "750px" }}>
      <LineDashboard options={lineOptions} data={lineData} />
    </div>
  );
}

export default ThroughputAverages;
