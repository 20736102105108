import { useEffect, useState } from "react";
import { getBills } from "../pages/Purchases/api";
import useFetch from "./useFetch";

const useBills = () => {
  const [errorBills, setErrorBills] = useState(null);
  const { loading: loadingBills, error, data: bills } = useFetch(getBills);
  useEffect(() => {
    setErrorBills("Failed to load bills");
  }, [error]);
  return { loadingBills, errorBills, bills };
};

export default useBills;
