import React, {
  useState,
  useRef,
  useEffect,
  createRef,
  cloneElement,
} from "react";
import Page from "./Page";
import LineItem from "./LineItem";

function MultiPage(props) {
  const [pageMapper, setPageMapper] = useState([
    { startIndex: 0, endIndex: null },
  ]);
  const [pageDimensions, setPageDimensions] = useState({
    landscape: {
      height: "765px",
      width: "990px",
    },
    portrait: {
      height: "990px",
      width: "765px",
    },
  });

  const lineItemHeight = 24;

  const height = pageDimensions[props.orientation].height;
  const width = pageDimensions[props.orientation].width;

  const pageHeight = parseInt(
    pageDimensions[props.orientation].height.slice(0, -2)
  );
  const mainHeaderHeight = 340;
  const secondaryHeaderHeight = 40;
  const tableHeaderHeight = 70;
  const footerHeight = 30;
  const lastPageFooter = 75;

  const margin = 50;

  //figure out lineItem indexes and pages needed to display line items when there is a change to lineItems array
  useEffect(() => {
    calculatePages();
  }, [props.lineItems]);
  useEffect(() => {
    calculatePages();
  }, [props.getters[0]]);
  useEffect(() => {
    calculatePages();
  }, [props.getters[3]]);
  useEffect(() => {
    calculatePages();
  }, [props.getters[4]]);

  function calculatePages() {
    var newPages = [{ startIndex: 0, endIndex: null }];

    const firstPageRoomAvailable =
      pageHeight -
      2 * margin -
      mainHeaderHeight -
      tableHeaderHeight -
      footerHeight;

    const nthPageRoomAvailable =
      pageHeight -
      2 * margin -
      secondaryHeaderHeight -
      tableHeaderHeight -
      footerHeight;

    var heightLeft = firstPageRoomAvailable;
    var itemHeight = "";
    var hasNextPage = false;
    for (let i in props.lineItems) {
      itemHeight = props.getLineItemHeight(i);
      heightLeft = heightLeft - itemHeight;
      console.log(heightLeft);

      // if total box ran out of room put it on the next page
      if (
        heightLeft < props.getTotalBoxHeight() + lastPageFooter &&
        !hasNextPage
      ) {
        newPages.push({ startIndex: null, endIndex: null });
        hasNextPage = true;
      } else if (heightLeft < 0) {
        newPages[newPages.length - 2].endIndex = i;
        newPages[newPages.length - 1].startIndex = i;

        heightLeft = nthPageRoomAvailable;
        hasNextPage = false;
      }
    }
    setPageMapper(newPages);
  }

  const Table = () => (
    <div
      style={{
        height: "115px",
        backgroundColor: "lightGreen",
      }}
    ></div>
  );

  //ensure there is a ref for each page
  props.printRefs.current = pageMapper.map(
    (_, i) => props.printRefs.current[i] ?? createRef()
  );

  return (
    <div>
      {pageMapper.map((item, index) => {
        var slicedItems = [];
        if (item.startIndex != null && item.endIndex == null) {
          //ensure page displays correct line items
          slicedItems = props.lineItems.slice(item.startIndex);
        } else if (item.startIndex == null && item.endIndex == null) {
          slicedItems = [];
          console.log("NO ITEMS");
        } else {
          slicedItems = props.lineItems.slice(item.startIndex, item.endIndex);
        }
        return (
          <div key={item.startIndex}>
            {index == 0 ? (
              pageMapper.length - 1 == 0 ? (
                <Page //Page 1 of 1
                  printRef={props.printRefs.current[index]}
                  header={props.header}
                  body={React.cloneElement(props.body, {
                    slicedItems: slicedItems,
                    setters: props.setters,
                    getters: props.getters,
                    indexOffset: item.startIndex,
                    endIndex: item.endIndex,
                    addLineItem: props.addLineItem,
                    deleteLineItem: props.deleteLineItem,
                    ready: props.ready,
                    createTax: props.createTax,
                  })}
                  totals={props.totals}
                  mainFooter={React.cloneElement(props.mainFooter, {
                    lowerLeft: props.companyInfo[0].name,
                    lowerRight:
                      "Page " +
                      parseInt(index + 1) +
                      " of " +
                      pageMapper.length,
                    getters: props.getters,
                    setters: props.setters,
                  })}
                  margin={"0.5in"}
                  height={height}
                  width={width}
                />
              ) : (
                <Page //Page 1 of many
                  printRef={props.printRefs.current[index]}
                  header={React.cloneElement(props.header, {
                    setters: props.setters,
                    getters: props.getters,
                  })}
                  body={React.cloneElement(props.body, {
                    slicedItems: slicedItems,
                    setters: props.setters,
                    getters: props.getters,
                    indexOffset: item.startIndex,
                    endIndex: item.endIndex,
                    addLineItem: props.addLineItem,
                    deleteLineItem: props.deleteLineItem,
                    ready: props.ready,
                  })}
                  footer={React.cloneElement(props.footer, {
                    lowerLeft: props.companyInfo[0].name,
                    lowerRight:
                      "Page " +
                      parseInt(index + 1) +
                      " of " +
                      pageMapper.length,
                  })}
                  margin={"0.5in"}
                  height={height}
                  width={width}
                />
              )
            ) : index < pageMapper.length - 1 ? (
              <Page //page m of n
                printRef={props.printRefs.current[index]}
                header={props.secondaryHeader}
                body={React.cloneElement(props.body, {
                  slicedItems: slicedItems,
                  setters: props.setters,
                  getters: props.getters,
                  indexOffset: item.startIndex,
                  endIndex: item.endIndex,
                  addLineItem: props.addLineItem,
                  deleteLineItem: props.deleteLineItem,
                  ready: props.ready,
                })}
                footer={React.cloneElement(props.footer, {
                  lowerLeft: props.companyInfo[0].name,
                  lowerRight:
                    "Page " + parseInt(index + 1) + " of " + pageMapper.length,
                })}
                margin={"0.5in"}
                height={height}
                width={width}
              />
            ) : (
              <Page //Page n of n
                printRef={props.printRefs.current[index]}
                header={props.secondaryHeader}
                body={React.cloneElement(props.body, {
                  slicedItems: slicedItems,
                  setters: props.setters,
                  getters: props.getters,
                  indexOffset: item.startIndex,
                  endIndex: item.endIndex,
                  addLineItem: props.addLineItem,
                  deleteLineItem: props.deleteLineItem,
                  ready: props.ready,
                  ready: props.ready,
                })}
                totals={props.totals}
                mainFooter={React.cloneElement(props.mainFooter, {
                  lowerLeft: props.companyInfo[0].name,
                  lowerRight:
                    "Page " + parseInt(index + 1) + " of " + pageMapper.length,
                  getters: props.getters,
                  setters: props.setters,
                })}
                margin={"0.5in"}
                height={height}
                width={width}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default MultiPage;
