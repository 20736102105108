import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import MOCK_DATA from "./MOCK_DATA.json";
import { COLUMNS } from "./columns";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import * as AiIcons from "react-icons/ai";
// import './table.css'

export const SortingTable = (props) => {
  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, [])
  //const data = useMemo(() => props.data, [])

  const tableInstance = useTable(
    {
      columns: columns,
      data: props.data,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: "company_name",
            desc: false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  ); // initialState: { pageIndex : user.settings.page.tablePageNumber }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  return (
    <>
      <table
        {...getTableProps()}
        className="sortingTable"
        width="100%"
        style={{ "table-layout": "fixed" }}
      >
        <col style={{ width: "10%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "25%" }} />
        <col style={{ width: "17%" }} />
        <col style={{ width: "13%" }} />
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>Status</th>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                >
                  {column.render("Header")}

                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <span>▾</span>
                    ) : (
                      <span>▴</span>
                    )
                  ) : (
                    <span className="unsorted"></span>
                  )}
                </th>
              ))}

              <th style={{ textAlign: "center" }}> Actions</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={"tableRows"}
                onClick={(e) => props.onClickRow(e, row.original.id)}
              >
                <td>Active</td>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} style={{ overflow: "hidden" }}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                <td>
                  <div style={{ display: "flex", "justify-content": "center" }}>
                    {/* <button id={row.original.id} type="button" className="btn btn-secondary rowButton"  style={{ 'marginTop': '2px', 'marginBottom': '2px'}}>Default</button> */}
                    <button
                      id={row.original.id}
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        marginRight: "10px",
                        background: "none",
                        border: "none",
                        "font-weight": "bold",
                        color: "blue",
                        pointerEvents: "none",
                        opacity: "0",
                        filter: "blur(2px)",
                      }}
                      class="link"
                    >
                      Default
                    </button>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn-light"
                        style={{
                          "background-color": "light-gray",
                          "padding-top": "1px",
                          "padding-bottom": "1px",
                        }}
                        id={"dropdown-basic-" + i}
                      ></Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">View</Dropdown.Item>
                        <Dropdown.Item
                          id={row.original.id}
                          onClick={props.onClickEdit}
                        >
                          Edit
                        </Dropdown.Item>
                        <div class="dropdown-divider"></div>
                        {console.log(props.category)}
                        {props.category == "customers" ? (
                          <>
                            {" "}
                            <Dropdown.Item
                              id={row.original.id}
                              onClick={(e) => {
                                props.onClickCreateEstimate(e.target.id);
                              }}
                            >
                              Create Estimate
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={row.original.id}
                              onClick={(e) => {
                                props.onClickCreateSaleOrder(e.target.id);
                              }}
                            >
                              Create Sale Order
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={row.original.id}
                              onClick={(e) => {
                                props.onClickCreateInvoice(e.target.id);
                              }}
                            >
                              Create Invoice
                            </Dropdown.Item>
                          </>
                        ) : (
                          <>
                            {" "}
                            <Dropdown.Item
                              id={row.original.id}
                              onClick={(e) => {
                                props.onClickCreateBill(e.target.id);
                              }}
                            >
                              Create Bill
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={row.original.id}
                              onClick={(e) => {
                                props.onClickCreatePurchaseOrder(e.target.id);
                              }}
                            >
                              Create Purchase Order
                            </Dropdown.Item>
                          </>
                        )}
                        <div class="dropdown-divider"></div>
                        <Dropdown.Item href="#/action-6">
                          Create Statement
                        </Dropdown.Item>

                        <div class="dropdown-divider"></div>
                        <Dropdown.Item
                          id={row.original.id}
                          onClick={props.onClickDelete}
                          style={{ color: "#d11a2a" }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {/* <td><button id={row.original.id} type="button" className="btn btn-danger rowButton " onClick={props.onClickDelete} style={{ 'marginTop': '2px'}}>Delete</button></td> */}
                  {/* <td><button id={row.original.id} type="button" className="btn btn-light rowButton " onClick={props.onClickDelete} style={{ 'marginTop': '2px'}}><AiIcons.AiOutlineDown/></button></td> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="tableFooter" style={{ userSelect: "none" }}>
        <div>
          <span>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: "50px" }}
              min={"1"}
              max={pageCount}
            />
          </span>{" "}
          Items per page:{" "}
          <select
            style={{ width: "50px", height: "2em" }}
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </Button>
          <span style={{ marginRight: "10px" }}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </Button>
          <Button
            className="btn btn-light"
            style={{ padding: "2px" }}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </div>
      </div>
    </>
  );
};
