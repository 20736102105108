import React, { Checkbox } from "react";
import { Link } from "react-router-dom";

import "./ContactForm.css";

import SearchableDropdown from "../../../components/Forms/SearchableDropdown";
import GeneralInfoForm from "./GeneralInfoForm";
import AddressForm from "./AddressForm";
import Input from "../../../components/Forms/Input";
import Dropdown from "../../../components/Forms/Dropdown";
import { api_url } from "../../../util/environment";
import { currentBusiness } from "../../../features/authentication/util";

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact: {
                id: "",
                company_id: "",
                type: "",
                primary_name: "",
                primary_position: "",
                primary_email: "",
                primary_phone: "",
                tax_number: "",
                shipping_address: "",
                shipping_city: "",
                shipping_zip_code: "",
                shipping_state: "",
                shipping_country: "",
                billing_address: "",
                billing_city: "",
                billing_zip_code: "",
                billing_state: "",
                billing_country: "",
                website: "",
                currency: "USD",
                created_by: "",
                created_at: null,
                updated_at: null,
                deleted_at: null,
            },
            additionalContacts: [],
            additionalContactsOrig: [],
            sameAsShipping: true,
            company: "",
            currencyList: [],
            companyList: [],
        };
        console.log("in constructor");
    }

    componentDidMount() {
        fetch(api_url + "/site/currencies")
            .then((results) => results.json())
            .then((data) => {
                this.setState({ currencyList: data });
            });
        if (this.props.contact_id) {
            try {
                fetch(
                    api_url +
                        "/" +
                        this.props.type +
                        "/" +
                        this.props.category +
                        "/" +
                        this.props.contact_id
                )
                    .then((results) => results.json())
                    .then((data) => {
                        this.setState({ contact: data[0] });
                        console.log(data[0]);

                        if (
                            data[0]["billing_address"] !=
                                data[0].shipping_address ||
                            data[0]["billing_city"] != data[0].shipping_city ||
                            data[0]["billing_zip_code"] !=
                                data[0].shipping_zip_code ||
                            data[0]["billing_state"] !=
                                data[0].shipping_state ||
                            data[0]["billing_country"] !=
                                data[0].shipping_country
                        ) {
                            this.setState({ sameAsShipping: false });
                        }

                        fetch(
                            api_url +
                                "/site/companies/user/" +
                                currentBusiness()
                        )
                            .then((results) => results.json())
                            .then((companyData) => {
                                this.setState({ companyList: companyData });
                                console.log(companyData);
                                for (var i in companyData) {
                                    if (
                                        companyData[i]["id"] ==
                                        data[0].company_id
                                    ) {
                                        if (
                                            this.state.company !=
                                            companyData[i]["name"]
                                        ) {
                                            this.setState({
                                                company: companyData[i]["name"],
                                            });
                                            console.log(
                                                "setting company to " +
                                                    companyData[i]["name"]
                                            );
                                        }
                                    }
                                }
                            });
                    });
                fetch(
                    api_url +
                        "/" +
                        this.props.type +
                        "/" +
                        this.props.category +
                        "/" +
                        this.props.contact_id +
                        "/additional-contacts"
                )
                    .then((results) => results.json())
                    .then((data) => {
                        var contactArr = JSON.parse(JSON.stringify(data)); //deep copy of data array
                        console.log(contactArr);
                        var key_index = 1;
                        data.forEach((v) => {
                            v.key = key_index;
                            key_index++;
                        });
                        this.setState({ additionalContacts: data });
                        this.setState({ additionalContactsOrig: contactArr });
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }

    onSubmitForm = async (e) => {
        e.preventDefault();
        console.log("Form submitted!");
        //check company existence and find company id
        const target_company = this.state.company;
        var company_id = 0;
        var companyExists = false;
        for (var company in this.state.companyList) {
            console.log(this.state.companyList[company]["name"]);
            console.log(target_company);
            if (this.state.companyList[company]["name"] == target_company) {
                companyExists = true;
                company_id = this.state.companyList[company]["id"];
                console.log("company Exists");
                break;
            }
        }

        //If company doesn't exist, then create it
        if (!companyExists) {
            console.log("company doesn't exist");
            const body = {
                name: target_company,
            };
            const response = await fetch(
                api_url + "/site/companies/user/" + currentBusiness(),
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(body),
                }
            );
            const json = await response.json();
            console.log(json.rows[0].id);
            company_id = json.rows[0].id;
        }

        const body = {
            company_id: company_id,
            type: this.state.contact.type,
            website: this.state.contact.website,
            primary_name: this.state.contact.primary_name,
            primary_position: this.state.contact.primary_position,
            primary_email: this.state.contact.primary_email,
            primary_phone: this.state.contact.primary_phone,

            shipping_address: this.state.contact.shipping_address,
            shipping_city: this.state.contact.shipping_city,
            shipping_zip_code: this.state.contact.shipping_zip_code,
            shipping_state: this.state.contact.shipping_state,
            shipping_country: this.state.contact.shipping_country,
            billing_address: this.state.contact.billing_address,
            billing_city: this.state.contact.billing_city,
            billing_zip_code: this.state.contact.billing_zip_code,
            billing_state: this.state.contact.billing_state,
            billing_country: this.state.contact.billing_country,
            tax_number: this.state.contact.tax_number,
            currency: this.state.contact.currency,
            created_by: null,
            created_at: this.state.contact.created_at,
            updated_at: this.state.contact.updated_at,
            deleted_at: this.state.contact.deleted_at,
        };

        if (this.state.sameAsShipping) {
            body["billing_address"] = this.state.contact.shipping_address;
            body["billing_city"] = this.state.contact.shipping_city;
            body["billing_zip_code"] = this.state.contact.shipping_zip_code;
            body["billing_state"] = this.state.contact.shipping_state;
            body["billing_country"] = this.state.contact.shipping_country;
        }
        console.log(body);
        console.log(this.state.additionalContacts);

        // if add
        if (!this.props.contact_id) {
            body["created_at"] = Date.now();
            body["updated_at"] = Date.now();
            //Create main contact
            console.log("ADDING");
            const response = await fetch(
                api_url +
                    "/" +
                    this.props.type +
                    "/" +
                    this.props.category +
                    "/user/" +
                    currentBusiness(),
                {
                    method: "POST",
                    mode: "cors",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(body),
                }
            );

            const json_res = await response.json();
            var contact_id = json_res.rows[0].id;
            console.log(contact_id);

            //create additional_contact for each additional contact
            for (var i in this.state.additionalContacts) {
                console.log(this.state.additionalContacts[i]);
                const response = await fetch(
                    api_url + "/site/additional-contacts",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            name: this.state.additionalContacts[i]["name"],
                            position:
                                this.state.additionalContacts[i]["position"],
                            email: this.state.additionalContacts[i]["email"],
                            phone: this.state.additionalContacts[i]["phone"],
                            customer_id:
                                this.props.category == "customers"
                                    ? contact_id
                                    : null,
                            vendor_id:
                                this.props.category == "vendors"
                                    ? contact_id
                                    : null,
                        }),
                    }
                );
            }
        } else {
            //for each original contact
            //try to find the original in updated list
            //if you can find it, compare the two and PUT if neccessary
            //if you can't find it, DELETE it

            console.log("EDITING");
            console.log(this.state.additionalContactsOrig);
            console.log(this.state.additionalContacts);

            var originalList = this.state.additionalContactsOrig;
            var updatedList = this.state.additionalContacts;

            //Loop through updatedList and POST the ones with id == ''
            for (var i in updatedList) {
                if (updatedList[i].id == "") {
                    console.log("POST");
                    const response = await fetch(
                        api_url + "/site/additional-contacts",
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                name: updatedList[i]["name"],
                                position: updatedList[i]["position"],
                                email: updatedList[i]["email"],
                                phone: updatedList[i]["phone"],
                                customer_id:
                                    this.props.category == "customers"
                                        ? this.state.contact.id
                                        : null,
                                vendor_id:
                                    this.props.category == "vendors"
                                        ? this.state.contact.id
                                        : null,
                            }),
                        }
                    );
                }
            }
            var found_i = false;
            var found_index = null;
            for (var i in originalList) {
                for (var j in updatedList) {
                    if (originalList[i].id == updatedList[j].id) {
                        found_i = true;
                        console.log("found!");
                        found_index = j;
                    }
                }
                if (found_i) {
                    if (
                        originalList[i].name != updatedList[found_index].name ||
                        originalList[i].position !=
                            updatedList[found_index].position ||
                        originalList[i].email !=
                            updatedList[found_index].email ||
                        originalList[i].phone != updatedList[found_index].phone
                    ) {
                        console.log("PUT");
                        const response = await fetch(
                            api_url +
                                "/site/additional-contacts/" +
                                originalList[i].id,
                            {
                                method: "PUT",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    name: updatedList[found_index]["name"],
                                    position:
                                        updatedList[found_index]["position"],
                                    email: updatedList[found_index]["email"],
                                    phone: updatedList[found_index]["phone"],
                                }),
                            }
                        );
                    }
                } else {
                    console.log("DELETE");
                    console.log(originalList[i].id);
                    fetch(
                        api_url +
                            "/site/additional-contacts/" +
                            originalList[i].id,
                        {
                            method: "DELETE",
                        }
                    );
                }
                found_i = false; //reset boolean
                found_index = null;
            }
            body["updated_at"] = Date.now();
            console.log(body.updated_at);
            console.log(body.created_at);

            const response = await fetch(
                api_url +
                    "/" +
                    this.props.type +
                    "/" +
                    this.props.category +
                    "/" +
                    this.props.contact_id,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(body),
                }
            );
        }

        this.props.navigate(this.props.returnPath);
    };

    /*
    Description: updates field in state.contact to keep track of controlled components
    Inputs: e: input change event
            key: key of field to change in state.contacts
    */
    handleFormChange = (e, key) => {
        var field = this.state.contact;
        field[key] = e.target.value;
        this.setState({ contact: field });
    };

    /*
    Description: updates field in state to keep track of controlled components
    Inputs: e: input change event
            key: key of field to change in state
    */
    handleCompanyChange = (e, key) => {
        this.setState({ company: e.target.value });
    };

    //Description: toggles this.state.sameAsShipping
    handleCheck = () => {
        console.log("check");
        this.setState({ sameAsShipping: !this.state.sameAsShipping });
    };

    //Description: allows child component to set the additionalContacts list
    setAdditionalContactList = (value) => {
        console.log("setting additional contact list");
        console.log(value);
        this.setState({ additionalContacts: value });
    };

    //Description: changes appropriate nested state value for additionalContacts list
    onAdditionalContactChange = (e, id, key) => {
        console.log(e);
        console.log(id);
        console.log(key);
        var contacts = this.state.additionalContacts;
        for (var i in contacts) {
            if (contacts[i]["key"] == id) {
                contacts[i][key] = e.target.value;
            }
        }
        this.setState({ additionalContacts: contacts });
    };

    handleEnter(event) {
        console.log(event.key);
        if (event.key == "Enter") {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            if (form.elements[index].type != "submit") {
                // if the form element isn't the submit button, then focus on next element and prevent submission
                form.elements[index + 1].focus();
                event.preventDefault();
            }
        }
    }

    render() {
        console.log(this.state.currencyList);

        //event.key != 'Enter'
        return (
            <div className="contactForm">
                <form onSubmit={this.onSubmitForm} onKeyDown={this.handleEnter}>
                    <div className="formHeader">
                        <h2>
                            {this.props.category.charAt(0).toUpperCase() +
                                this.props.category.slice(
                                    1,
                                    this.props.category.length - 1
                                )}{" "}
                            Information
                        </h2>
                        <div className="submitContactTop">
                            <button
                                type="submit"
                                className="btn btn-success "
                                style={{
                                    "padding-right": "18px",
                                    "padding-left": "18px",
                                }}
                                id={"saveCustomerButtonTop"}
                            >
                                Save
                            </button>
                            <button
                                className="btn btn-light "
                                onClick={() => {
                                    this.props.navigate(-1);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>

                    <div className="general">
                        <GeneralInfoForm
                            companyList={this.state.companyList}
                            additionalContactList={
                                this.state.additionalContacts
                            }
                            formValues={this.state}
                            onChange={this.handleFormChange}
                            setAdditionalContactList={
                                this.setAdditionalContactList
                            }
                            onAdditionalContactChange={
                                this.onAdditionalContactChange
                            }
                            onCompanyChange={this.handleCompanyChange}
                        />
                        <br></br>
                    </div>

                    <h2>Shipping</h2>
                    <div className="shipping">
                        <AddressForm
                            formValues={this.state.contact}
                            addressKey={"shipping_address"}
                            cityKey={"shipping_city"}
                            zipKey={"shipping_zip_code"}
                            stateKey={"shipping_state"}
                            countryKey={"shipping_country"}
                            onChange={this.handleFormChange}
                        />
                    </div>

                    <h2>Billing</h2>
                    <div className="billing">
                        <div className="row g-3">
                            <Input
                                className="mb-3 col-sm-9"
                                id="tax_number"
                                label="Tax Number"
                                value={this.state.contact.tax_number}
                                onChange={(e) =>
                                    this.handleFormChange(e, "tax_number")
                                }
                            />
                            <SearchableDropdown
                                required={false}
                                className="mb-3 col"
                                label="currency"
                                dropdownList={this.state.currencyList}
                                objKey="currency_code"
                                listId="currencyList"
                                value={this.state.contact.currency}
                                onChange={(e) =>
                                    this.handleFormChange(e, "currency")
                                }
                            />
                        </div>
                        <input
                            type="checkbox"
                            id="sameAsShipping"
                            name="sameAsShipping"
                            checked={this.state.sameAsShipping}
                            onChange={this.handleCheck}
                        />{" "}
                        Billing Address same as Shipping
                        {this.state.sameAsShipping ? null : (
                            <AddressForm
                                formValues={this.state.contact}
                                addressKey={"billing_address"}
                                cityKey={"billing_city"}
                                zipKey={"billing_zip_code"}
                                stateKey={"billing_state"}
                                countryKey={"billing_country"}
                                onChange={this.handleFormChange}
                            />
                        )}
                        <br></br>
                        <br></br>
                        <div className="submitContact">
                            <button
                                type="submit"
                                className="btn btn-success "
                                style={{
                                    "padding-right": "20px",
                                    "padding-left": "20px",
                                }}
                                id={"saveCustomerButtonBottom"}
                            >
                                Save
                            </button>
                            <button
                                type="cancel"
                                className="btn btn-light "
                                onClick={() => {
                                    this.props.navigate(-1);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
