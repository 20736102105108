import React, { useState, useRef, useEffect } from "react";
import DateSelector from "../../../components/Forms/DateSelector";
import HelpIcon from "../../../components/Icons/HelpIcon";
import { formatDate } from "../../../util/dates";
import { addMoney, subtractMoney } from "../../../util/money";
import { getAccounts } from "../../Accounting/api";
import MultiPage from "../MultiPage";
import ReportHeader from "../ReportHeader";
import IncomeStatementBody from "./IncomeStatementBody";

function IncomeStatement() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [date, setDate] = useState(new Date());
  const [accounts, setAccounts] = useState([]);
  const [lineItemAccounts, setLineItemAccounts] = useState([1, 2]);

  const [orientation, setOrientation] = useState("landscape");

  const printRefs = useRef([]);

  useEffect(() => {
    getAccounts().then((data) => {
      setAccounts(data);
      console.log(data);
      createIncomeStatementItems(data);
    });
  }, []);

  const createIncomeStatementItems = (accounts) => {
    var lineItems = [];
    var incomeAccounts = accounts.filter((acc) => acc.category == "Income");
    lineItems.push({ category: "Income", margin: 0 });
    var totalIncome = 0;
    for (let i in incomeAccounts) {
      incomeAccounts[i].margin = 1;
      lineItems.push(incomeAccounts[i]);
      totalIncome = addMoney(totalIncome, incomeAccounts[i].balance);
    }
    var cogsAccount = accounts.filter((acc) => acc.name == "COGS");
    lineItems.push({
      category: "Total Income",
      margin: 0,
      balance: totalIncome,
      border: 1,
    });
    cogsAccount[0].category = "Total Cost of Goods Sold";
    cogsAccount[0].margin = 0;
    cogsAccount[0].border = 1;
    lineItems.push(cogsAccount[0]);

    var grossProfit = subtractMoney(totalIncome, cogsAccount[0].balance);
    lineItems.push({
      category: "Gross Profit",
      margin: 0,
      balance: grossProfit,
      border: 2,
    });

    lineItems.push({ category: "Operating Expenses", margin: 0 });

    var expenseAccounts = accounts.filter((acc) => acc.category == "Expenses");
    var totalExpense = 0;
    for (let i in expenseAccounts) {
      expenseAccounts[i].margin = 1;
      lineItems.push(expenseAccounts[i]);
      totalExpense = addMoney(totalExpense, expenseAccounts[i].balance);
    }
    lineItems.push({
      category: "Total Operating Expense",
      margin: 0,
      balance: totalExpense,
      border: 2,
    });
    lineItems.push({
      category: "Net Profit",
      margin: 0,
      balance: subtractMoney(grossProfit, totalExpense),
      border: 3,
    });

    setLineItemAccounts(lineItems);
  };

  const getLineItemHeight = () => {
    return 25;
  };

  return (
    <div
      style={{
        width: "1000px",
        height: "100vh",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        right: "-125px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Income Statement</h1>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <button type="button" className="btn btn-light">
              Change Orientation
            </button>
          </div>
          <div>
            <button type="button" className="btn btn-light">
              Download as PDF
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "200px",
          backgroundColor: "rgb(236, 240, 243)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "50%",
            width: "60%",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            marginLeft: "30px",
          }}
        >
          {" "}
          Date Range{" "}
          <div style={{ width: "220px" }}>
            <DateSelector date={startDate} setDate={setStartDate} />
          </div>
          <div style={{ width: "220px" }}>
            <DateSelector date={endDate} setDate={setEndDate} />
          </div>
        </div>
        <div
          style={{
            height: "50%",
            width: "30%",
            alignItems: "center",
            display: "flex",
            marginLeft: "30px",
          }}
        >
          <div style={{ marginRight: "5px" }}>
            Report Type
            <HelpIcon
              message={
                "Accrual (Paid & Unpaid): Reflects all transactions, including unpaid invoices and bills. \nCash Basis (Paid): Reflects all transactions except unpaid invoices and bills"
              }
            />
          </div>

          <select>
            {["Accrual (Paid & Unpaid)", "Cash Basis (Paid)"].map(
              (item, index) => {
                return (
                  <option className="dropdownOption" key={item} value={item}>
                    {item}
                  </option>
                );
              }
            )}
          </select>
        </div>
      </div>
      <MultiPage
        date={formatDate(startDate) + " to " + formatDate(endDate)}
        lineItems={lineItemAccounts}
        orientation={orientation}
        printRefs={printRefs}
        header={
          <ReportHeader
            title={"Income Statement"}
            dateRange={[startDate, endDate]}
          />
        }
        body={<IncomeStatementBody />}
        totals={<></>}
        getLineItemHeight={getLineItemHeight}
      />
    </div>
  );
}

export default IncomeStatement;
