import React from "react";
import { pixelStringToNumber } from "../../../util/string";
import "./spinner.css";

export default function LoadingSpinner(props) {
  return (
    <div
      style={{
        width: props.size ? pixelStringToNumber(props.size) + "px" : "50px",
        height: props.size ? pixelStringToNumber(props.size) + "px" : "50px",
        border: props.size
          ? pixelStringToNumber(props.size) / 10 +
            "px solid var(--container-color)"
          : "5px solid var(--container-color)",
        borderTop: props.size
          ? pixelStringToNumber(props.size) / 10 +
            "px solid var(--main-button-color-hover)"
          : "5px solid var(--main-button-color-hover)",
        borderRadius: "50%",
        animation: "spinner 1.5s linear infinite",
      }}
    ></div>
  );
}
