import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/Forms/Input";
import TextArea from "../../../components/Forms/TextArea";
import HelpIcon from "../../../components/Icons/HelpIcon";
import SearchableWithButton from "../../../components/Forms/SearchableWithButton";

import { getIncomeAccounts, getExpenseAccounts } from "../../Accounting/api";
import { getTaxes, postCategories, getCategory, putCategories } from "../api";
import { filterObject, findIndex } from "../../../util/jsonUtil";
import SearchableModal from "../../../components/Forms/SearchableModal";
import useAccounts from "../../../hooks/useAccounts";
import { toPercentage } from "../../../util/formatters";

function CategoriesForm(props) {
  let navigate = useNavigate();
  let { id } = useParams();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedTax, setSelectedTax] = useState(null);
  const [taxes, setTaxes] = useState([]);
  const [cogsAccount, setCogsAccount] = useState(null);
  const [inventoryAccount, setInventoryAccount] = useState(null);
  const [arAccount, setArAccount] = useState(null);
  const [apAccount, setApAccount] = useState(null);

  const [category, setCategory] = useState(null);

  const {
    loading: loadingAccounts,
    error: accountsError,
    accounts: acc,
    accountsByCategory,
    accountsByType,
  } = useAccounts(true, [
    "Cost of Goods Sold",
    "Accounts Receivable",
    "Accounts Payable",
    "Inventory",
    "Checking/Savings",
    "Money in Transit",
  ]);

  useEffect(() => {
    if (props.type == "sales") {
      getIncomeAccounts().then((data) => {
        setAccounts(data);
        if (id) {
          console.log("EDIT");
          getCategory(id).then((data2) => {
            const cat = data2[0];
            setCategory(cat);
            setName(cat.name);
            setDescription(cat.description);
            console.log(data);
            console.log(cat.account);
            console.log(findIndex(data, "id", cat.account));
            setSelectedAccount(findIndex(data, "id", cat.account));

            console.log(data);
            getTaxes().then((taxData) => {
              setTaxes(taxData);
              setSelectedTax(taxData.find((tax) => tax.id == cat.tax));
            });
          });
        } else {
          getTaxes().then((taxData) => {
            setTaxes(taxData);
          });
          console.log("ADD");
        }
      });
    } else if (props.type == "purchases") {
      getExpenseAccounts().then((data) => {
        setAccounts(data);
        if (id) {
          console.log("EDIT");
          getCategory(id).then((data2) => {
            const cat = data2[0];
            setCategory(cat);
            setName(cat.name);
            setDescription(cat.description);
            console.log(data);
            console.log(cat.account);
            console.log(findIndex(data, "id", cat.account));
            setSelectedAccount(findIndex(data, "id", cat.account));

            getTaxes().then((taxData) => {
              setTaxes(taxData);
              setSelectedTax(taxes.find((tax) => tax.id == cat.tax));
            });
          });
        } else {
          getTaxes().then((taxData) => {
            setTaxes(taxData);
          });
          console.log("ADD");
        }
        console.log(data);
      });
    }
  }, []);

  useEffect(() => {
    if (acc && category) {
      setCogsAccount(
        acc.find((account) => account.id == category.cogs_account_id)
      );
      setInventoryAccount(
        acc.find((account) => account.id == category.inventory_account_id)
      );
      setArAccount(acc.find((account) => account.id == category.ar_account_id));
      setApAccount(acc.find((account) => account.id == category.ap_account_id));
    }
  }, [acc, category]);

  const buttonList = ["Create a new tax"];

  async function submit() {
    console.log(selectedAccount);
    console.log(selectedTax);
    const body = {
      name: name,
      description: description,
      type: props.type,
      account: accounts[selectedAccount].id,
      tax: selectedTax ? selectedTax.id : null,
      cogs_account_id: cogsAccount ? cogsAccount.id : null,
      inventory_account_id: inventoryAccount ? inventoryAccount.id : null,
      ar_account_id: arAccount ? arAccount.id : null,
      ap_account_id: apAccount ? apAccount.id : null,
    };
    console.log(body);

    if (id) {
      delete body["id"];
      putCategories(body, id).then((data) => {
        navigate("/" + props.type + "/categories");
      });
    } else {
      postCategories(body).then((data) => {
        navigate("/" + props.type + "/categories");
      });
    }
  }

  return (
    <div className="addProducts">
      <h1>Add a Category</h1>
      <h5
        className="productSubheading"
        style={{ whiteSpace: "pre-wrap", fontWeight: "normal" }}
      >
        Categories are a way to organize products and services. You can
        categorize products and services to understand performance on a per
        category basis.
      </h5>
      <div className="addProductForm">
        <div className="formItem">
          <Input
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="formItem">
          <TextArea
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="formItem">
        {props.type == "sales" ? (
          <>
            Income Account
            <span style={{ marginLeft: "10px" }}>
              <HelpIcon message="Account in which sale of products or services in this category will be recognized" />
            </span>
          </>
        ) : (
          <>
            Expense Account
            <span style={{ marginLeft: "10px" }}>
              <HelpIcon message="Account in which purchase of products or services in this category will be recognized" />
            </span>
          </>
        )}
      </div>
      <SearchableWithButton
        appendID={"-searchable-1"}
        list={[...accounts.map((a) => [a.name])]}
        value={selectedAccount}
        setValue={setSelectedAccount}
        defaultValue={"Select an Account"}
      />
      <div className="formItem">
        {props.type == "sales" ? (
          <SearchableModal
            label="Cost of Goods Sold Account"
            help={
              <HelpIcon message="Account to track cost associated with products sold under this category" />
            }
            list={filterObject(accountsByType, ["Cost of Goods Sold"])}
            keys={{
              name: (name) => name,
            }}
            onItemClick={(item) => {
              setCogsAccount(item);
            }}
            value={cogsAccount ? cogsAccount.name : null}
          />
        ) : null}

        <SearchableModal
          label="Inventory Account"
          help={
            <HelpIcon message="Account to track inventory valuation of products held under this category" />
          }
          list={filterObject(accountsByType, ["Inventory"])}
          keys={{
            name: (name) => name,
          }}
          onItemClick={(item) => {
            setInventoryAccount(item);
          }}
          value={inventoryAccount ? inventoryAccount.name : null}
        />
        {props.type == "sales" ? (
          <SearchableModal
            label="Accounts Receivable Account"
            help={
              <HelpIcon message="Account to track claims to payment for products sold under this category" />
            }
            list={filterObject(accountsByType, ["Accounts Receivable"])}
            keys={{
              name: (name) => name,
            }}
            onItemClick={(item) => {
              setArAccount(item);
            }}
            value={arAccount ? arAccount.name : "Select Accounts Receivable"}
          />
        ) : (
          <SearchableModal
            label="Accounts Payable Account"
            help={
              <HelpIcon message="Account to track claims to payment for products purchased under this category" />
            }
            list={filterObject(accountsByType, ["Accounts Payable"])}
            keys={{
              name: (name) => name,
            }}
            onItemClick={(item) => {
              setApAccount(item);
            }}
            value={apAccount ? apAccount.name : "Select Accounts Payable"}
          />
        )}
        <SearchableModal
          label="Applicable Tax"
          help={
            <HelpIcon message="Tax applied to all products and services in this category" />
          }
          list={taxes}
          keys={{
            name: (name) => name,
            percentage: (percentage) => toPercentage(percentage, "tax"),
          }}
          onItemClick={(item) => {
            setSelectedTax(item);
          }}
          value={selectedTax ? selectedTax.name : "Select a Tax"}
        />
      </div>

      {/* <div className="formItem">
        Applicable Tax
        <span style={{ marginLeft: "10px" }}>
          <HelpIcon message="Tax applied to all products and services in this category" />
        </span>
      </div>
      <SearchableWithButton
        appendID={"-searchable-2"}
        list={[...taxes.map((a) => [a.name])]}
        value={selectedTax}
        setValue={setSelectedTax}
        defaultValue={"Select a Tax"}
      /> */}

      <div
        className="formItem"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <div>
          <button
            type="submit"
            className="btn btn-success "
            style={{ "padding-right": "20px", "padding-left": "20px" }}
            onClick={() => submit()}
          >
            Save
          </button>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-light "
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default CategoriesForm;
