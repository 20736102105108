import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SettingsContext } from "../../../App";
import Button from "../../../components/Buttons/Button";
import Input from "../../../components/Forms/Input";
import SearchableModal from "../../../components/Forms/SearchableModal";
import SuccessMessage from "../../../components/Messages/SuccessMessage";
import WarningMessage from "../../../components/Messages/WarningMessage";
import {
    getRolls,
    getUserBussinessRoll,
    inviteUser,
    updateUserBussinessRoll,
} from "../../../features/authentication/api";
import SettingsSidebar from "../SettingsSidebar";

function UserForm() {
    let { user } = useContext(SettingsContext);
    const [rolls, setRolls] = useState(null);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [roll, setRoll] = useState(null);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    let id = useParams().id;

    useEffect(() => {
        console.log(id);

        getRolls(user?.business?.id).then((data) => {
            console.log(data);
            setRolls(data);
            if (id) {
                // edit
                getUserBussinessRoll(user?.business?.id, id).then((data2) => {
                    setRoll(data.find((r) => r.id === data2.roll_id));
                    setName(data2.name);
                    setEmail(data2.email);
                });
            }
        });
    }, []);

    let nav = useNavigate();

    const handleNew = async (e) => {
        try {
            let businessName = user?.business?.doing_business_as;
            let businessId = user?.business?.id;
            let roll_id = roll?.id;
            const response = await inviteUser({
                from: "timjevans2000@gmail.com",
                to: email,
                name,
                businessName,
                businessId,
                roll_id,
            });
            if (response.error) {
                setError(response.error);
                setSuccess(null);
                return true;
            }
            if (response.success) {
                setSuccess(response.success);
                setError(null);
                return false;
            }
            return true;
        } catch (error) {
            console.log(error);
            return true;
        }
    };

    const handleEdit = async (e) => {
        console.log("edit");
        console.log(roll);
        try {
            const reponse = await updateUserBussinessRoll(
                user?.business?.id,
                id,
                roll.id
            );
            if (reponse.error) {
                setError(reponse.error);
                setSuccess(null);
                return true;
            }
            if (reponse.success) {
                setSuccess(reponse.success);
                setError(null);
                return false;
            }
            return false;
        } catch (error) {
            console.log(error);
            return true;
        }
    };

    return (
        <div className="page-container">
            <div style={{ display: "flex" }}>
                <SettingsSidebar active="Users" />

                <div
                    style={{
                        justifyContent: "center",
                        width: "calc(100% - var(--sidebar-width))",
                    }}
                >
                    <div
                        style={{
                            height: "300px",
                            width: "500px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <h1>Invite a New User</h1>
                        {error && <WarningMessage message={error} />}
                        {success && <SuccessMessage message={success} />}

                        <div
                            style={{
                                border: "1px solid var(--separator-color)",
                                borderRadius: "10px",
                                minHeight: "200px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                }}
                            >
                                <Input
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={id}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                }}
                            >
                                <Input
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={id}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "20px",
                                }}
                            >
                                <SearchableModal
                                    label="Roll"
                                    list={rolls}
                                    keys={{
                                        name: (name) => name,
                                    }}
                                    onItemClick={(item) => {
                                        setRoll(item);
                                    }}
                                    initialValue={"Select a roll"}
                                    value={roll?.name}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                            }}
                        >
                            <Button
                                title="Invite"
                                onClick={(e) => {
                                    if (id) {
                                        handleEdit(e).then((error) => {
                                            if (!error) {
                                                setEmail("");
                                                setName("");
                                                setRoll(null);
                                                nav(-1);
                                            }
                                        });
                                    } else {
                                        if (!email || !name || !roll)
                                            return setError(
                                                "Please fill all the fields"
                                            );
                                        else {
                                            setError(null);
                                            handleNew(e).then((error) => {
                                                if (!error) {
                                                    setEmail("");
                                                    setName("");
                                                    setRoll(null);
                                                }
                                            });
                                        }
                                    }
                                }}
                            />
                            <Button
                                title="Cancel"
                                type="light"
                                onClick={() => nav(-1)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserForm;
