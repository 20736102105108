import React from "react";
import { getDate, getDateLong, getDateShort, getTime } from "../../util/dates";
import { companyInfo } from "./AgingSummaryAR/Data";

function ReportHeader(props) {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div
            style={{ marginTop: "5px", fontSize: "14px", fontWeight: "bold" }}
          >
            {getTime(new Date())}
          </div>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {getDate(new Date())}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {companyInfo[0].name}
          </div>
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            {props.title}
          </div>
          <div style={{ fontSize: "14px", fontWeight: "bold" }}>
            {props.dateRange
              ? "Date Range: " +
                getDateShort(props.dateRange[0]) +
                " to " +
                getDateShort(props.dateRange[1])
              : "As of " + getDateLong(props.date)}
          </div>
        </div>

        <div style={{ opacity: "0" }}>
          <div>11:43 AM</div>
          <div>06/21/22</div>
        </div>
      </div>
      <hr
        style={{
          borderTop: "3px solid #000000",
          opacity: "1",
          margin: "4px 0px 20px 0px",
        }}
        width="100%"
      />
    </div>
  );
}

export default ReportHeader;
