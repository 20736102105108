import React, { useState } from "react";
import AlphanumericInput from "../../../components/Forms/documents/AlphanumericInput";
import NumericInput from "../../../components/Forms/documents/NumericInput";
import CurrencyInput from "../../../components/Forms/CurrencyInput";
import { PRECISION_DICTIONARY } from "../../../util/formatters";
import PriceInput from "../../../components/Forms/documents/PriceInput";
import {
  computeLineSubtotal,
  multiply,
  subtractMoney,
} from "../../../util/money";
import { toUSD } from "../../../util/formatters";
import ContentEditable from "react-contenteditable";
import { IoTrashOutline } from "react-icons/io5";
import SearchableDropdownWithCreate from "../../../components/Forms/SearchableDropdownWithCreate";
import { toPercentage } from "../../../util/formatters";
import IntInput from "../../../components/Forms/documents/IntInput";
import { getProductPricing } from "../../Site/api";

function BudgetaryEstimateBody(props) {
  const [showLineTaxMenu, setShowLineTaxMenu] = useState(false);
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [quantityPricings, setQuantityPricings] = useState([
    [{ starting_quantity: 1, ending_quantity: null, price: "0.00" }],
  ]);
  const [idCounter, setIdCounter] = useState(-2);
  React.useEffect(() => {
    return () => {
      console.log("UNMOUNTED");
    };
  }, []);

  var quantityPricingsList = props.getters[5];
  var itemList = props.slicedItems;

  if (!props.ready) {
    quantityPricingsList = [];
    itemList = [];
    console.log("not ready");
  }

  return (
    <table>
      <thead>
        <tr style={{ borderBottom: "3px solid #000" }}>
          <th width="50%">Item</th>
          <th style={{ width: "20%", textAlign: "right" }}>Quantity Range</th>
          <th style={{ width: "30%", textAlign: "right" }}>Price</th>
          <th style={{ width: "0%" }}></th>
        </tr>
      </thead>

      {itemList.map((item, index) => {
        index = index + parseInt(props.indexOffset);

        const taxGetter = props.getters[3][index] || [];

        return (
          <tbody
            key={"line-item-" + item.id}
            style={{ borderBottom: "1px solid #000" }}
            className="hoverableRow"
          >
            <tr
              style={{
                height: "1.5em",
                textAlign: "right",
              }}
            >
              <td style={{ textAlign: "left" }}>
                <AlphanumericInput
                  initialValue={props.getters[0][index].item_name}
                  changeText={(e) => {
                    var arr = props.getters[0];
                    arr[index].item_name = e;
                    props.setters[0](JSON.parse(JSON.stringify(arr)));
                  }}
                />
              </td>
              <td colSpan={2}>
                {console.log(props.getters[5])}
                {props.getters[5][index].map((item, index2) => {
                  var endingQuantityContents = "∞";
                  if (props.getters[5][index][index2].ending_quantity != null) {
                    endingQuantityContents =
                      props.getters[5][index][index2].ending_quantity;
                  }

                  var endingQuantityPlaceholder = "";
                  if (props.getters[5][index].length == index2 + 1) {
                    endingQuantityPlaceholder = "∞";
                  }
                  return (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "20%" }}>
                          <div>
                            <label>
                              {
                                props.getters[5][index][index2]
                                  .starting_quantity
                              }
                            </label>
                          </div>
                        </div>
                        <div style={{ width: "20%" }}>
                          <NumericInput
                            initialValue={
                              endingQuantityContents.toString() || null
                            }
                            limit={11}
                            decimalLimit={PRECISION_DICTIONARY["quantity"]}
                            changeText={(val) => {
                              var pricings = props.getters[5];

                              pricings[index][index2].ending_quantity =
                                parseInt(val);

                              if (!props.getters[5][index][index2 + 1]) {
                                console.log(props.getters[0][index]);
                                console.log(props.getters[5][index]);
                                pricings[index].push({
                                  id: 0 - props.getters[5].flat().length - 1, //negative id to denote new item - starts counting from -1 if starting from empty
                                  starting_quantity: parseInt(val) + 1,
                                  ending_quantity: null,
                                  price: "0.00",
                                  document_items_id: props.getters[0][index].id,
                                });
                              } else {
                                pricings[index][index2 + 1].starting_quantity =
                                  parseInt(val) + 1;
                              }

                              props.setters[5](
                                JSON.parse(JSON.stringify(pricings))
                              );
                            }}
                            placeholder={endingQuantityPlaceholder}
                          />
                        </div>
                      </div>
                      {/* {props.getters[5][index].length == index2 + 1 && index2 != 0 ? (
                          <div
                            className="hoverableRow"
                            style={{ width: "1em" }}
                          >
                            <div
                              className="rowDelete"
                              onClick={() => {
                                var pricings = props.getters[5][index];
                                pricings[pricings.length - 2].ending_quantity =
                                  "";
                                pricings.pop();
                                props.setters[5](
                                  JSON.parse(JSON.stringify(pricings))
                                );
                                console.log(pricings);
                              }}
                            >
                              <IoTrashOutline />
                            </div>
                          </div>
                        ) : null} */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <PriceInput
                            initialValue={props.getters[5][index][index2].price}
                            limit={11}
                            decimalLimit={2}
                            changeText={(val) => {
                              var pricings = props.getters[5];
                              pricings[index][index2].price = val;
                              props.setters[5](
                                JSON.parse(JSON.stringify(pricings))
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </td>
              <td></td>

              <td
                className="rowDelete"
                style={{ opacity: "0", position: "absolute" }}
                onClick={() => {
                  props.deleteLineItem(index);
                }}
              >
                <IoTrashOutline />
              </td>
            </tr>{" "}
            <tr>
              <td>
                <div className="notesSection" style={{ marginLeft: "30px" }}>
                  <AlphanumericInput
                    initialValue={props.getters[0][index].notes}
                    limit={22}
                    changeText={(e) => {
                      var arr = props.getters[0];
                      arr[index].notes = e;
                      props.setters[0](JSON.parse(JSON.stringify(arr)));
                    }}
                  />
                </div>
              </td>
              <td></td>
              {/* <td colSpan={2}>
                {taxGetter.map((subitem, j) => {
                  return (
                    <div style={{ display: "flex" }}>
                      <div
                        className="taxRow"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "55.5%",
                        }}
                        key={subitem.id}
                      >
                        <div className="itemTax" style={{ display: "flex" }}>
                          <IoTrashOutline
                            style={{
                              marginLeft: "0px",
                              marginRight: "0px",
                            }}
                            className="taxDelete"
                            onClick={() => {
                              var currentTaxList = props.getters[3];
                              console.log(currentTaxList);
                              currentTaxList[index].splice(j, 1);
                              props.setters[3](
                                JSON.parse(JSON.stringify(currentTaxList))
                              );
                            }}
                          />
                          <div
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {subitem.name}{" "}
                          </div>{" "}
                        </div>

                        <div
                          style={{
                            width: "17%",
                            textAlign: "right",
                          }}
                        >
                          {toPercentage(subitem.percentage, "tax")}
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "44.4%" }}>
                        <span
                          className="taxSubtotal"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {toUSD(
                            multiply(item.line_total, subitem.percentage),
                            "total"
                          )}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <button
                  className="discountOrTaxButton"
                  onClick={() => {
                    setShowLineTaxMenu(index);
                    document.getElementById("search-bar-" + index).focus();
                  }}
                >
                  {" "}
                  Add Tax/Discount
                </button>
                {console.log(showLineTaxMenu)}
                {showLineTaxMenu === index ? (
                  <td style={{ position: "absolute" }}>
                    <div
                      onBlur={() => {
                        setTimeout(() => {
                          setShowLineTaxMenu(false);
                        }, 200);
                      }}
                      disabled={true}
                    >
                      <SearchableDropdownWithCreate
                        searchBarID={"search-bar-" + index}
                        list={[
                          ...props.getters[2].map((a) => [
                            a.name + " ",
                            toPercentage(a.percentage, "tax"),
                            a.id,
                          ]),
                        ]}
                        functions={[
                          () => console.log(),
                          () => {
                            var items = props.getters[0];
                            items[index].discount = "0.00";
                            props.setters[0](JSON.parse(JSON.stringify(items)));
                          },
                        ]}
                        onItemClick={(e) => {
                          var taxes = props.getters[3];
                          taxes[index].push(props.getters[2][e]);
                          props.setters[3](JSON.parse(JSON.stringify(taxes)));
                        }}
                        buttonList={[
                          "Create a new aax",
                          "Add a discount (fixed)",
                        ]}
                      />
                    </div>
                  </td>
                ) : (
                  <td
                    style={{
                      position: "absolute",
                      zIndex: "-100",
                      opacity: "0",
                    }}
                  >
                    <div
                      onBlur={() => {
                        setTimeout(() => {
                          setShowLineTaxMenu(false);
                        }, 2000);
                      }}
                      disabled={true}
                    >
                      <SearchableDropdownWithCreate
                        searchBarID={"search-bar-" + index}
                        list={["test", "test2"]}
                        functions={[
                          () => console.log("first"),
                          () => console.log("second"),
                          () => console.log("third"),
                        ]}
                      />
                    </div>
                  </td>
                )}
              </td> */}
            </tr>
          </tbody>
        );
      })}
      {props.endIndex == null && props.indexOffset != null ? (
        <div
          className="addItemRow"
          style={{ position: "absolute", width: "5em" }}
        >
          <p
            className="discountOrTaxButton"
            data-html2canvas-ignore="true"
            onClick={() => {
              setShowProductMenu(true);
              document.getElementById("search-bar-product").focus();
            }}
          >
            Add an Item
          </p>
          {showProductMenu ? (
            <td style={{ position: "absolute" }}>
              <div
                onBlur={() => {
                  setTimeout(() => {
                    setShowProductMenu(false);
                  }, 200);
                }}
                disabled={true}
              >
                <SearchableDropdownWithCreate
                  searchBarID={"search-bar-product"}
                  list={[
                    ...props.getters[4].map((a) => [
                      a.name + " ",
                      a.code_number,
                      a.id,
                    ]),
                  ]}
                  functions={[props.addLineItem]}
                  onItemClick={(e) => {
                    console.log(props.getters[4][e]);
                    var form = props.getters[0];
                    const product_id = props.getters[4][e].id;

                    getProductPricing(product_id).then((data) => {
                      console.log(data);
                      var oldList = props.getters[5];
                      oldList.push(data);
                      props.setters[5](oldList);
                      console.log(
                        data.filter((pricing) => pricing.starting_quantity == 1)
                      );
                      form.push({
                        id: idCounter, //negative id to denote new item - starts counting from -1 if starting from empty
                        item_name: props.getters[4][e].name,
                        quantity: "1",
                        price: data.filter(
                          (pricing) => pricing.starting_quantity == 1
                        )[0].price,
                        discount: null,
                        tax: "0.0",
                        line_total: data.filter(
                          (pricing) => pricing.starting_quantity == 1
                        )[0].price,
                        notes: "notes",
                        page_order: props.getters[0].length,
                        invoice_id: "",
                        products_and_services_id: product_id,
                      });
                      props.setters[0](JSON.parse(JSON.stringify(form)));
                      //add empty array for taxes
                      var oldTaxes = props.getters[3];
                      oldTaxes.push([]);
                      props.setters[3](oldTaxes);

                      setIdCounter(idCounter - 1);
                    });
                  }}
                  buttonList={["Create a new Product"]}
                />
              </div>
            </td>
          ) : (
            <td
              style={{
                position: "absolute",
                zIndex: "-100",
                opacity: "0",
              }}
            >
              <div
                onBlur={() => {
                  setTimeout(() => {
                    setShowLineTaxMenu(false);
                  }, 2000);
                }}
                disabled={true}
              >
                <SearchableDropdownWithCreate
                  searchBarID={"search-bar-product"}
                  list={["test", "test2"]}
                  functions={[
                    () => console.log("first"),
                    () => console.log("second"),
                    () => console.log("third"),
                  ]}
                />
              </div>
            </td>
          )}
        </div>
      ) : null}
    </table>
  );
}
export default BudgetaryEstimateBody;
