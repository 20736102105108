import React from "react";
import ProfileSidebar from "./ProfileSidebar";

function Emails() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <ProfileSidebar active="Emails" />
        <div>Emails</div>
      </div>
    </div>
  );
}

export default Emails;
