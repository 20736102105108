import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Label from "../../components/Descriptions/Label";
import { Flexbox } from "../../components/Layouts/Layouts";
import useBill from "../../hooks/accounting/useBill";
import { mapObject } from "../../util/jsonUtil";
import LoadingSpinner from "../../components/Icons/LoadingSpinner/LoadingSpinner";
function Console() {
  const [splits, setSplits] = React.useState(["f"]);

  const { operations, setters, models, loading, errors } = useBill(true, true);
  console.log(errors);
  console.log(models);
  return (
    <div className="page-container">
      <div>
        <h1>Experiment with pricing strategies</h1>

        {mapObject(errors).map((error) => {
          return <h1>{error[1] ? error[0] + ": " + error[1] : null}</h1>;
        })}
        {mapObject(loading).map((error) => {
          return (
            <h1>
              {error[1] ? (
                <>
                  {error[0] + ": " + error[1]}
                  <LoadingSpinner size="25" />
                </>
              ) : null}
            </h1>
          );
        })}
        <h1></h1>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            // if (!values.email) {
            //   errors.email = "Required";
            // } else if (
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            // ) {
            //   errors.email = "Invalid email address";
            // }
            if (!values["num-units"]) {
              errors["num-units"] = "Required";
            }
            if (!values["avg-cost"]) {
              errors["avg-cost"] = "Required";
            }
            for (let i = 0; i < splits.length; i++) {
              if (!values["price-" + i]) {
                errors["price-" + i] = "Required";
              }

              if (!values["percent-" + i]) {
                errors["percent-" + i] = "Required";
              }
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              let units = values["num-units"];
              let cost = units * values["avg-cost"];
              let revenue = 0;

              for (let i = 0; i < splits.length; i++) {
                let price = values["price-" + i];
                let percent = values["percent-" + i];
                let unitsSold = units * (percent / 100);
                revenue += unitsSold * price;
              }

              var profit = revenue - cost;
              console.log(profit);
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div style={{ marginBottom: "30px" }}>
                <Flexbox width="50%" justifyContent="space-between">
                  <Label label="Units sold" />
                  <div>
                    <Field name="num-units" />
                    <ErrorMessage name="num-units" component="div" />
                  </div>
                </Flexbox>
              </div>

              {splits.map((split, index) => {
                return (
                  <>
                    <Flexbox width="50%" justifyContent="space-between">
                      <Label label="Price" />
                      <div>
                        <Field name={"price-" + index} />
                        <ErrorMessage name={"price-" + index} component="div" />
                      </div>
                    </Flexbox>
                    <Flexbox width="50%" justifyContent="space-between">
                      <Label label="Expected Percentage" />
                      <div>
                        <Field name={"percent-" + index} />
                        <ErrorMessage
                          name={"percent-" + index}
                          component="div"
                        />
                      </div>
                    </Flexbox>
                  </>
                );
              })}

              <div style={{ marginTop: "30px" }}>
                <Flexbox width="50%" justifyContent="space-between">
                  <Label label="Average Cost" />
                  <div>
                    <Field name="avg-cost" />
                    <ErrorMessage name="avg-cost" component="div" />
                  </div>
                </Flexbox>
              </div>

              <button type="submit" disabled={isSubmitting}>
                Calculate
              </button>
              <button
                onClick={() => {
                  setSplits([...splits, "f"]);
                }}
              >
                Add Customer split
              </button>
              <button
                onClick={() => {
                  setSplits(splits.slice(0, splits.length - 1));
                }}
              >
                Remove Customer split
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Console;
