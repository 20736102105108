import "./ChartOfAccountsTabs.css";

import React from "react";
import FlexibleAccount from "../../../components/Accounting/FlexibleAccount";
import Input from "../../../components/Forms/Input";
import TextArea from "../../../components/Forms/TextArea";
import Dropdown from "../../../components/Forms/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected || 0,
    };
  }

  handleChange(index) {
    this.setState({ selected: index });
  }
  render() {
    return (
      <div>
        <ul className="inline">
          {this.props.children.map((elem, index) => {
            let style = index == this.state.selected ? "selected" : "";
            return (
              <li
                className={style}
                key={index}
                onClick={this.handleChange.bind(this, index)}
              >
                {elem.props.title}
              </li>
            );
          })}
        </ul>
        <div className="tab">{this.props.children[this.state.selected]}</div>
      </div>
    );
  }
}

class Panel extends React.Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default class ChartOfAcountsTabs extends React.Component {
  constructor(props) {
    super(props);
  }

  renderHeader(type) {
    if (type == "add") {
      return (
        <>
          Add an Account to: <br></br>
          <strong>{this.props.popup.id}</strong>
        </>
      );
    } else if (type == "edit") {
      return <>Edit {this.props.form["category"]} Account</>;
    } else {
      return <>Add an Account</>;
    }
  }

  render() {
    console.log("re-rendering");
    const desc =
      "Use this to track purchases made using a credit card. Create an account for each credit card you use in your business. Purchases using your credit card, and payments to your credit card, should be recorded in the relevant credit card category.";
    const accounts = [
      {
        category: "Assets",
        subcategory: "Current Assets",
        type: "Checking/Savings",
        name: "US Bank Bozeman",
        currency_id: "1",
        account_number: 1000,
        description: "US Bank Bozeman account",
        debit_credit: "debit",
        balance: 5474.41,
      },
    ];
    return (
      <div className="accountTabs">
        <Tabs selected={0}>
          <Panel title="Assets">
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Current Assets"}
              type={"Checking/Savings"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Current Assets"}
              type={"Money in Transit"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Current Assets"}
              type={"Accounts Receivable"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Fixed Assets"}
              type={"Inventory"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Fixed Assets"}
              type={"Property, Plant, Equipment"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Fixed Assets"}
              type={"Depreciation and Amortization"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Fixed Assets"}
              type={"Vendor Prepayments and Vendor Credits"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Fixed Assets"}
              type={"Fixed Assets"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Current Assets"}
              type={"Other Current Assets"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Assets"}
              subcategory={"Fixed Assets"}
              type={"Other Long-Term Assets"}
              description={desc}
              accounts={this.props.accounts}
            />
          </Panel>
          <Panel title="Liabilities">
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Credit Card"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Loan and Line of Credit"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Accounts Payable"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Sales Taxes"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Due For Payroll"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Due to You and Other Business Owners"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Customer Prepayments and Customer Credits"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Current Liabilities"}
              type={"Other Current Liabilities"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Liabilities"}
              subcategory={"Long-Term Liabilities"}
              type={"Long Term Liabilities"}
              description={desc}
              accounts={this.props.accounts}
            />
          </Panel>
          <Panel title="Income">
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Income"}
              type={"Income"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Income"}
              type={"Discount"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Income"}
              type={"Other Income"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Income"}
              type={"Uncategorized Income"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Income"}
              type={"Gain On Foreign Exchange"}
              description={desc}
              accounts={this.props.accounts}
            />
          </Panel>
          <Panel title="Expenses">
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Expenses"}
              type={"Operating Expense"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Expenses"}
              type={"Cost of Goods Sold"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Expenses"}
              type={"Payment Processing Fee"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Expenses"}
              type={"Payroll Expense"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Expenses"}
              type={"Uncategorized Expense"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Expenses"}
              type={"Loss on Foreign Exchange"}
              description={desc}
              accounts={this.props.accounts}
            />
          </Panel>
          <Panel title="Equity">
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Equity"}
              subcategory={"Equity"}
              type={"Business Owner Contribution and Drawing"}
              description={desc}
              accounts={this.props.accounts}
            />
            <FlexibleAccount
              handleOpenAdd={this.props.handleOpenAdd}
              handleOpenEdit={this.props.handleOpenEdit}
              category={"Equity"}
              subcategory={"Equity"}
              type={"Retained Earnings: Profit"}
              description={desc}
              accounts={this.props.accounts}
            />
          </Panel>
        </Tabs>
        <Modal
          show={this.props.popup.show}
          onHide={this.props.handleClosePopup}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {console.log(this.props.popup.add)}
              {console.log(this.props.form)}
              {this.renderHeader(this.props.popup.type)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.popup.type == "main" ? (
              <>
                <Dropdown
                  className="mb-3 col"
                  label="Account Type"
                  id="type"
                  value={this.props.form["type"]}
                  onChange={(e) => this.props.onChange(e, "type")}
                  dropdownList={[
                    "Checking/Savings",
                    "Money in Transit",
                    "Accounts Receivable",
                    "Inventory",
                    "Property, Plant, Equipment",
                    "Depreciation and Amortization",
                    "Vendor Prepayments and Vendor Credits",
                    "Fixed Assets",
                    "Other Current Assets",
                    "Other Long-Term Assets",
                    "Credit Card",
                    "Loan and Line of Credit",
                    "Accounts Payable",
                    "Sales Taxes",
                    "Due For Payroll",
                    "Due to You and Other Business Owners",
                    "Customer Prepayments and Customer Credits",
                    "Other Short-Term Liability",
                    "Other Long-Term Liability",
                    "Income",
                    "Discount",
                    "Other Income",
                    "Uncategorized Income",
                    "Gain On Foreign Exchange",
                    "Operating Expense",
                    "Cost of Goods Sold",
                    "Payment Processing Fee",
                    "Payroll Expense",
                    "Uncategorized Expense",
                    "Loss On Foreign Exchange",
                    "Business Owner Contribution and Drawing",
                    "Retained Earnings: Profit",
                  ]}
                />
              </>
            ) : null}
            <div>
              <Input
                className="mb-3 col"
                label="Account Name"
                id="name"
                value={this.props.form["name"]}
                onChange={(e) => this.props.onChange(e, "name")}
              />
            </div>
            <div>
              <Input
                className="mb-3 col"
                label="Account Number"
                id="account_number"
                value={this.props.form["account_number"]}
                onChange={(e) => this.props.onChange(e, "account_number")}
              />
            </div>
            <div>
              <TextArea
                className="mb-3"
                label="Description"
                id="description"
                value={this.props.form["description"]}
                onChange={(e) => this.props.onChange(e, "description")}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.popup.type == "add" ||
            this.props.popup.type == "main" ? (
              <Button
                variant="success"
                onClick={this.props.save}
                style={{ "padding-right": "20px", "padding-left": "20px" }}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="success"
                onClick={this.props.update}
                style={{ "padding-right": "20px", "padding-left": "20px" }}
              >
                Save
              </Button>
            )}

            <Button variant="secondary" onClick={this.props.handleClosePopup}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
