import React, { useState, useEffect } from "react";
import { getAccountInfo } from "../authentication/api";
import useUser from "../authentication/hooks/useUser";

const useSettings = (settingsInit = null) => {
    const { user, setUser, clearUser } = useUser();

    const [settings, setSettings] = useState({});

    useEffect(() => {
        console.log("Changing settings for user: " + user?.email, settingsInit);
        if (!user) return;
        if (user?.account) {
            setSettings({
                language: "en",
                membership: user.account.membership, // lite, pro, premium
                max_users: user.account.max_users,
                invoice_customization: {
                    logo: null,
                    color: "#000000",
                    font: "Arial",
                    font_size: 12,
                    font_color: "#000000",
                    background_color: "#ffffff",
                    prepend_string: "inv-00",
                },
            });
        }
    }, [user, settingsInit]);

    return { settings, user, setUser, clearUser };
};

export default useSettings;
