import React from "react";
import Input from "../../../components/Forms/Input";
import TextArea from "../../../components/Forms/TextArea";
/* Props 
    formValues, onChange
*/
function AddressForm(props) {
  return (
    <div className="address">
      <TextArea
        className="mb-3"
        id={props.addressKey}
        label="Address"
        value={props.formValues[props.addressKey]}
        onChange={(e) => props.onChange(e, props.addressKey)}
      />

      <div className="row g-3">
        <Input
          className="mb-3 col"
          id={props.cityKey}
          label="City"
          value={props.formValues[props.cityKey]}
          onChange={(e) => props.onChange(e, props.cityKey)}
        />
        <Input
          className="mb-3 col"
          id={props.zipKey}
          label="Zip Code"
          value={props.formValues[props.zipKey]}
          onChange={(e) => props.onChange(e, props.zipKey)}
        />
      </div>
      <div className="row g-3">
        <Input
          className="mb-3 col"
          id={props.stateKey}
          label="Province/State"
          value={props.formValues[props.stateKey]}
          onChange={(e) => props.onChange(e, props.stateKey)}
        />
        <Input
          className="mb-3 col"
          id={props.countryKey}
          label="Country"
          value={props.formValues[props.countryKey]}
          onChange={(e) => props.onChange(e, props.countryKey)}
        />
      </div>
    </div>
  );
}

export default AddressForm;
