import React, { useState, useEffect } from "react";

function Loading(props) {
  const [loadingDots, setLoadingDots] = useState(".");

  function nextLoadingDot() {
    if (loadingDots.length >= 3) {
      setLoadingDots("");
    } else {
      setLoadingDots(loadingDots.concat("."));
    }
  }

  useEffect(() => {
    const timerId = setInterval(nextLoadingDot, 500);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [loadingDots]);

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "5em" }}>
        {props.message}
        {loadingDots}
      </div>
    </div>
  );
}

export default Loading;
