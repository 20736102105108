import React from "react";
import "./UnderlineButton.css";

function UnderlineButton(props) {
  return (
    <>
      {props.size === "small" ? (
        <span
          className="underlineButton"
          style={{ fontSize: "14px" }}
          onClick={(e) => props.onClick(e)}
          id={props.id}
        >
          {props.title}
        </span>
      ) : (
        <span
          className="underlineButton"
          style={{ fontSize: "22px" }}
          onClick={(e) => props.onClick(e)}
          id={props.id}
        >
          {props.title}
        </span>
      )}
    </>
  );
}

export default UnderlineButton;
