import useFetch from "./useFetch";
import { getSalesProducts } from "../pages/Sales/api";
import { useEffect, useState } from "react";
import { getCategories, getProductPricing } from "../pages/Site/api";
import { isEmpty } from "../util/jsonUtil";

const useSalesProducts = (
  sortByCategory = false,
  includeQuantityPricing = false
) => {
  const [salesProductsByCategory, setSalesProductsByCategory] = useState({});
  const [salesProductsWithPricing, setSalesProductsWithPricing] = useState([]);
  const [loadingSalesProductsByCategory, setLoadingSalesProductsByCategory] =
    useState(true);
  const {
    loading: loadingSalesProducts,
    error: errorSalesProducts,
    data: salesProducts,
  } = useFetch(getSalesProducts);

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: categories,
  } = useFetch(getCategories);

  //waits until quantity pricing is fetched
  useEffect(() => {
    (async () => {
      let withPricing = {};
      if (
        salesProductsWithPricing.length == 0 &&
        salesProducts &&
        includeQuantityPricing
      ) {
        withPricing = [...salesProducts];
        for (let i in salesProducts) {
          await getProductPricing(salesProducts[i].id).then((data) => {
            withPricing[i].quantityPricing = data;
            console.log("GETTING");
          });
        }
        console.log("SETTING");
        setSalesProductsWithPricing(withPricing);
      }
      if (
        isEmpty(withPricing) &&
        (!isEmpty(withPricing) || !includeQuantityPricing) &&
        categories &&
        sortByCategory
      ) {
        withPricing = [...salesProducts];
        var obj = {};
        for (let i in categories) {
          obj[categories[i].name] = withPricing.filter(
            (product) => product.category_id === categories[i].id
          );
          for (let j in obj[categories[i].name]) {
            obj[categories[i].name][j].sales_category = categories[i];
          }
        }
        setSalesProductsByCategory(obj);
        setLoadingSalesProductsByCategory(false);
      }
    })();
  }, [salesProducts, categories]);
  return {
    loadingSalesProducts,
    loadingSalesProductsByCategory,
    errorSalesProducts,
    salesProducts,
    salesProductsByCategory,
    salesProductsWithPricing,
  };
};

export default useSalesProducts;
