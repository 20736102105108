import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function FundingAccount() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Funding Account" />
        <div>FundingAccount</div>
      </div>
    </div>
  );
}

export default FundingAccount;
