import React from "react";
import { SettingsContext } from "../../App";
import BoldHoverable from "../../components/Buttons/BoldHoverable";
import useUser from "../../features/authentication/hooks/useUser";

import { SidebarLink, SidebarLinkActive } from "../Settings/SettingsSidebar";

import { profileSidebarData } from "./ProfileSidebarData";

function ProfileSidebar(props) {
  const { settings, logout } = React.useContext(SettingsContext);

  return (
    <div
      style={{
        backgroundColor: "var(--container-color)",
        width: "calc(var(--settings-sidebar-width))",
        height: "100vh",
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h5 style={{ margin: "18px" }}>Profile</h5>
        {profileSidebarData.map((item, index) => {
          return (
            <div>
              {item.membership.includes(settings.membership) ? (
                <>
                  {item.title == props.active ? (
                    <SidebarLinkActive>{item.title}</SidebarLinkActive>
                  ) : (
                    <SidebarLink to={item.path}>{item.title}</SidebarLink>
                  )}
                </>
              ) : null}
            </div>
          );
        })}
        <div
          style={{
            marginTop: "auto",
            position: "fixed",
            bottom: "0px",
            marginBottom: "10px",
          }}
        >
          <BoldHoverable
            onClick={() => {
              logout();
            }}
          >
            Log Out
          </BoldHoverable>
        </div>
      </div>
    </div>
  );
}

export default ProfileSidebar;
