import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListPage from "../../../../components/Lists/ListPage";
import ReportBox from "../../../../components/Reports/ReportBox";
import DropdownFilter from "../../../../components/Filters/DropdownFilter";
import DateFilter from "../../../../components/Filters/DateFilter";
import {
  approveDocument,
  sendDocument,
  deleteDocument,
  getInvoices,
  getActiveRecurringInvoices,
  postDocument,
  generateRecurring,
  getItemTaxes,
  postLineItemTax,
  postLineItem,
} from "../../api";

import { ColumnFilter } from "../../../../components/Tables/Estimates/ColumnFilter";

import { toUSD } from "../../../../util/formatters";

import { daysUntil, daysSince } from "../../../../util/dates";
import DeleteConfirmation from "../../../../components/Popups/DeleteConfirmation";

import SendInvoice from "../../Invoices/Send/SendInvoice";
import ErrorPopup from "../../../../components/Popups/ErrorPopup";
import { getEstimateItems } from "../../Estimates/util";
import Payment from "../../../../components/Popups/Payment";
import { subtractMoney } from "../../../../util/money";

const columns = [
  {
    Header: "#",
    accessor: "id",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: ColumnFilter,
  },
  {
    Header: "Due",
    accessor: "due_date",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (props) => {
      const daysUntilDue = daysUntil(new Date(props.value));
      if (daysUntilDue > 0) {
        return "in " + daysUntil(new Date(props.value)) + " days";
      } else if (daysUntilDue == 0) {
        return "Today";
      } else {
        return daysSince(new Date(props.value)) - 1 + " days ago";
      }
    },
  },
  {
    Header: "Issue Date",
    accessor: "issue_date",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Amount Due",
    accessor: "amount_due",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (props) =>
      toUSD(
        subtractMoney(
          props.row.original.amount_due,
          props.row.original.amount_paid
        ),
        "total"
      ),
  },
];

//Page to define a ListPage by describing table data, summary data, and row actions
function Invoices() {
  let navigate = useNavigate();
  const [dropdownFilter, setDropdownFilter] = useState("");
  const [dateFilterFrom, setDateFilterFrom] = useState("");
  const [dateFilterTo, setDateFilterTo] = useState("");

  const [data, setData] = useState([]);

  const [popup, setPopup] = useState({ show: false, id: null });

  const [sendPopup, setSendPopup] = useState({
    show: false,
    toEmail: null,
  });
  const [paymentPopup, setPaymentPopup] = useState({
    show: false,
    id: null,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    checkForRecurringInvoices();
    refresh();
  }, []);

  function refresh() {
    getInvoices().then((data) => {
      console.log(data);
      setData(data);
    });
  }

  const checkForRecurringInvoices = () => {
    getActiveRecurringInvoices().then((data) => {
      for (let i in data) {
        var nextGenerationDate = new Date(data[i].next_generation + " UTC");
        var today = new Date();
        nextGenerationDate.setHours(8, 0, 0, 0);
        if (today.getTime() > nextGenerationDate.getTime()) {
          console.log("DUE");
          //Create Invoice from recurring invoice
          delete data[i]["period"];
          delete data[i]["period_type"];
          delete data[i]["period_start"];
          delete data[i]["starting_date"];
          delete data[i]["ending_date"];
          delete data[i]["next_generation"];
          delete data[i]["document_id"];
          var id = data[i].id;
          delete data[i]["id"];
          data[i].type = "invoice";
          data[i].converted_from = id;
          data[i].updated_at = null;
          data[i].status = "unsent";
          postDocument(data[i]).then((doc) => {
            var document_id = doc.rows[0].id;
            generateRecurring(id);
            console.log("POST DOCUMENT");
            console.log(id);
            getEstimateItems(id).then((items) => {
              console.log("GOT ITEMS");
              console.log(items);
              var line_item_ids = [];
              for (let i in items) {
                var lineItemsCopy = items[i];
                line_item_ids[i] = items[i].id;
                delete lineItemsCopy["id"];
                lineItemsCopy["invoice_id"] = document_id;

                postLineItem(items[i]).then((data) => {
                  console.log("POST ITEMS");
                  console.log(data);
                  console.log("GETTING TAXES FROM " + line_item_ids[i]);
                  getItemTaxes(line_item_ids[i]).then((taxes) => {
                    console.log("GOT TAXES");
                    console.log(taxes);
                    for (let j in taxes) {
                      postLineItemTax(data[0].id, taxes[j].id);
                    }
                  });
                });
              }
            });
          });
          console.log(data[i]);
          //update next_generation for recurring invoice
        } else {
          console.log("NOT DUE");
        }
      }
    });
  };

  function handleClose() {
    setPopup({ show: false, id: null });
  }

  async function handleDelete() {
    console.log("DELETE" + popup.id);
    deleteDocument(popup.id).then((data) => {
      console.log(data);
      getInvoices().then((data) => {
        console.log(data);
        setData(data);
        setPopup({ show: false, id: null });
      });
    });
  }

  return (
    <div>
      <ListPage
        title={"Invoices"}
        singular={"Invoice"}
        link={"/sales/invoices/add"}
        summaryBoxes={[
          <ReportBox
            reportLabel={"Total Number of Invoices"}
            report={data.length}
          />,
          <ReportBox
            reportLabel={"Recurring Invoices for the day will show up 8 a.m."}
            report={""}
          />,
        ]}
        data={data}
        filters={[
          <DropdownFilter
            value={dropdownFilter}
            setValue={setDropdownFilter}
            list={["Draft", "Unsent", "Sent", "Partial", "Paid", "Expired"]}
          />,
          <DateFilter
            from={dateFilterFrom}
            setFrom={setDateFilterFrom}
            to={dateFilterTo}
            setTo={setDateFilterTo}
          />,
        ]}
        filterValue={dropdownFilter}
        filterName={"status"}
        dateFilterValues={[dateFilterFrom, dateFilterTo]}
        columnFilter={ColumnFilter}
        columns={columns}
        statusActions={{
          draft: ["Approve", (id) => approveDocument(id).then(() => refresh())],
          unsent: [
            "Send",
            (id, email) => {
              sendDocument(id).then(() => refresh());
              //setSendPopup({ show: true, id: id, toEmail: email });
              console.log(id + email);
            },
          ],
          sent: [
            "Make Payment",
            (id) => setPaymentPopup({ show: true, id: id }),
          ],
          partial: [
            "Make Payment",
            (id) => setPaymentPopup({ show: true, id: id }),
          ],
          paid: ["Archive", (id) => console.log("archive" + id)],
          expired: ["Archive", console.log],
        }}
        actions={[
          {
            name: "View",
            method: () => console.log("view"),
          },
          {
            name: "Edit",
            method: (id) => navigate("/sales/invoices/update/" + id),
          },
          {
            name: "DIVIDER",
          },
          {
            name: "Duplicate",
            method: () => console.log("duplicate"),
          },
          {
            name: "Send",
            method: () => console.log("send"),
          },
          {
            name: "DIVIDER",
          },
          {
            name: "Export as PDF",
            method: () => console.log("export"),
          },
          {
            name: "Print",
            method: () => console.log("print"),
          },
          {
            name: "Delete",
            method: (id) => setPopup({ show: true, id: id }),
          },
        ]}
      />
      <DeleteConfirmation
        popup={popup}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
      <Payment
        popup={paymentPopup}
        handleClose={() => setPaymentPopup({ show: false, id: null })}
        refresh={() => refresh()}
      />
      <SendInvoice
        sendPopup={sendPopup}
        closePopup={() => {
          setSendPopup({ show: false, id: null, toEmail: null });
        }}
        setError={(error) => setError(error)}
        setSuccess={() => setSuccess(true)}
        toSent={(id) => sendDocument(id).then(() => refresh())}
      />
      {error ? (
        <ErrorPopup errorMessage={error} clearError={() => setError(null)} />
      ) : null}
    </div>
  );
}

export default Invoices;
