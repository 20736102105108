import React from "react";
import { useNavigate } from "react-router-dom";

import { BsShieldLockFill } from "react-icons/bs";

import Button from "../../../components/Buttons/Button";

function PermissionGate({
    permissions,
    children,
    requiredPermissions,
    isAdmin,
}) {
    let nav = useNavigate();

    let userPermissions = permissions;
    let allowed = true;
    console.log(userPermissions);
    let missingPermissions = [];
    for (let i = 0; i < requiredPermissions.length; i++) {
        if (!userPermissions.includes(requiredPermissions[i])) {
            allowed = false;
            missingPermissions.push(requiredPermissions[i]);
        }
    }

    if (!allowed && !isAdmin) {
        return (
            <div className="page-container">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                    }}
                >
                    <BsShieldLockFill style={{ marginRight: "5px" }} /> You are
                    missing the following permission(s) to access this page:{" "}
                    {missingPermissions.join(", ")}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        onClick={() => nav(-1)}
                        title="Go Back"
                        type="light"
                    ></Button>
                </div>
            </div>
        );
    }
    return <>{children}</>;
}
export default PermissionGate;
