import React, { useState } from "react";
import TileButton from "../../components/Buttons/TileButton";

import { useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../components/Popups/DeleteConfirmation";

import { SettingsContext } from "../../App";
import { Grid } from "@mui/material";
import { clearDatabase } from "../../util/api";

function Launchpad() {
    let navigate = useNavigate();
    const [popup, setPopup] = useState({ show: false });
    const { settings } = React.useContext(SettingsContext);
    return (
        <div className="page-container">
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    maxWidth: "60%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h1>Launchpad</h1>
                    <div>
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                setPopup({ show: true });
                            }}
                        >
                            Clear Database
                        </button>
                    </div>
                </div>

                <div>
                    {console.log(settings)}
                    Membership level: <b>{settings.membership}</b>
                </div>

                <h3
                    style={{
                        alignSelf: "center",
                        textDecoration: "underline",
                    }}
                >
                    Beginner
                </h3>
                <div>
                    <Grid container>
                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TileButton
                                image={<img src={"/addPersonIcon.PNG"}></img>}
                                title={"Add Customers"}
                                onClick={() => navigate("/sales/customers/add")}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TileButton
                                image={<img src={"/newDocumentIcon.PNG"}></img>}
                                title={"Create Estimates"}
                                onClick={() => navigate("/sales/estimates/add")}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TileButton
                                image={
                                    <img src={"/moneyDocumentIcon.PNG"}></img>
                                }
                                title={"Create Invoices"}
                                onClick={() => navigate("/sales/invoices/add")}
                            />
                        </Grid>
                    </Grid>
                </div>
                <h3
                    style={{
                        marginTop: "50px",
                        alignSelf: "center",
                        textDecoration: "underline",
                    }}
                >
                    Advanced
                </h3>

                <Grid container>
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TileButton
                            image={<img src={"/addPersonIcon.PNG"}></img>}
                            title={"Add Customers"}
                            onClick={() => navigate("/sales/customers/add")}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TileButton
                            image={<img src={"/newDocumentIcon.PNG"}></img>}
                            title={"Create Budgetary Estimates"}
                            onClick={() =>
                                navigate("/sales/budgetary-estimates/add")
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TileButton
                            image={<img src={"/newDocumentIcon.PNG"}></img>}
                            title={"Create Estimates"}
                            onClick={() => navigate("/sales/estimates/add")}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TileButton
                            image={<img src={"/newDocumentIcon.PNG"}></img>}
                            title={"Create Sale Order"}
                            onClick={() => navigate("/sales/orders/add")}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TileButton
                            image={<img src={"/moneyDocumentIcon.PNG"}></img>}
                            title={"Create Invoices"}
                            onClick={() => navigate("/sales/invoices/add")}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TileButton
                            image={<img src={"/statsIcon.PNG"}></img>}
                            title={"Check Reports"}
                            onClick={() => navigate("/reports")}
                        />
                    </Grid>
                </Grid>
                <DeleteConfirmation
                    popup={popup}
                    handleClose={() => setPopup({ show: false })}
                    handleDelete={() => {
                        clearDatabase();
                        setPopup({ show: false });
                    }}
                />
            </div>
        </div>
    );
}

export default Launchpad;
