import { debitShouldAdd } from "../../util/accounting";
import { api_url } from "../../util/environment";
import { addMoney, subtractMoney } from "../../util/money";

import {
    currentTokenString,
    currentBusiness,
} from "../../features/authentication/util";

export async function getAccounts() {
    const response = await fetch(
        api_url + "/accounting/chart-of-accounts/user/" + currentBusiness()
    );
    return response.json();
}

export async function getAssetAccounts() {
    const response = await fetch(
        api_url +
            "/accounting/chart-of-accounts/assets/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getLiabilityAccounts() {
    const response = await fetch(
        api_url +
            "/accounting/chart-of-accounts/liabilities/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getIncomeAccounts() {
    const response = await fetch(
        api_url +
            "/accounting/chart-of-accounts/income/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getExpenseAccounts() {
    const response = await fetch(
        api_url +
            "/accounting/chart-of-accounts/expense/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getEquityAccounts() {
    const response = await fetch(
        api_url +
            "/accounting/chart-of-accounts/equity/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function postAccount(body) {
    const response = await fetch(
        api_url + "/accounting/chart-of-accounts/user/" + currentBusiness(),
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    return response.json();
}

/*---------------------Transactions-----------------------------*/

export async function getTransactionJournalEntries(id) {
    const response = await fetch(
        api_url + "/accounting/transactions/" + id + "/journal-entries"
    );
    return response.json();
}

export async function postJournalEntry(body) {
    if (body.account_id) {
        const account = await fetch(
            api_url + "/accounting/chart-of-accounts/" + body.account_id
        );
        const accountBody = await account.json();
        const accountId = accountBody[0]["id"];
        delete accountBody[0]["id"];
        delete accountBody[0]["owner"];

        if (debitShouldAdd(accountBody[0]["category"])) {
            if (body.debit) {
                accountBody[0]["balance"] = addMoney(
                    accountBody[0]["balance"],
                    body.debit
                );
            } else {
                accountBody[0]["balance"] = subtractMoney(
                    accountBody[0]["balance"],
                    body.credit
                );
            }
        } else {
            if (body.debit) {
                accountBody[0]["balance"] = subtractMoney(
                    accountBody[0]["balance"],
                    body.debit
                );
            } else {
                accountBody[0]["balance"] = addMoney(
                    accountBody[0]["balance"],
                    body.credit
                );
            }
        }

        const putAccount = await fetch(
            api_url + "/accounting/chart-of-accounts/" + accountId,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(accountBody[0]),
            }
        );
        console.log(accountBody[0]);
    }
    const response = await fetch(api_url + "/accounting/journal-entries/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });

    return await response.json();
}

export async function putJournalEntry(body, id) {
    const account = await fetch(
        api_url + "/accounting/chart-of-accounts/" + body.account_id
    );
    const accountBody = await account.json();

    const oldJournal = await fetch(
        api_url + "/accounting/journal-entries/" + id
    );
    const oldJournalBody = await oldJournal.json();

    const accountId = accountBody[0]["id"];
    delete accountBody[0]["id"];
    delete accountBody[0]["owner"];
    if (debitShouldAdd(accountBody[0]["category"])) {
        accountBody[0]["balance"] = addMoney(
            accountBody[0]["balance"],
            subtractMoney(body.debit, oldJournalBody[0].debit)
        );
        accountBody[0]["balance"] = subtractMoney(
            accountBody[0]["balance"],
            subtractMoney(body.credit, oldJournalBody[0].credit)
        );
    } else {
        accountBody[0]["balance"] = subtractMoney(
            accountBody[0]["balance"],
            subtractMoney(body.debit, oldJournalBody[0].debit)
        );
        accountBody[0]["balance"] = addMoney(
            accountBody[0]["balance"],
            subtractMoney(body.credit, oldJournalBody[0].credit)
        );
    }

    const putAccount = await fetch(
        api_url + "/accounting/chart-of-accounts/" + accountId,
        {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(accountBody[0]),
        }
    );

    await fetch(api_url + "/accounting/journal-entries/" + id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
}

export async function deleteJournalEntry(id) {
    const oldJournal = await fetch(
        api_url + "/accounting/journal-entries/" + id
    );
    const oldJournalBody = await oldJournal.json();
    if (oldJournalBody[0].account_id) {
        const account = await fetch(
            api_url +
                "/accounting/chart-of-accounts/" +
                oldJournalBody[0].account_id
        );
        const accountBody = await account.json();

        const accountId = accountBody[0]["id"];
        delete accountBody[0]["id"];
        if (debitShouldAdd(accountBody[0]["category"])) {
            if (oldJournalBody[0].debit) {
                accountBody[0]["balance"] = subtractMoney(
                    accountBody[0]["balance"],
                    oldJournalBody[0].debit
                );
            } else {
                accountBody[0]["balance"] = addMoney(
                    accountBody[0]["balance"],
                    oldJournalBody[0].credit
                );
            }
        } else {
            if (oldJournalBody[0].debit) {
                accountBody[0]["balance"] = subtractMoney(
                    accountBody[0]["balance"],
                    oldJournalBody[0].debit
                );
            } else {
                accountBody[0]["balance"] = addMoney(
                    accountBody[0]["balance"],
                    oldJournalBody[0].credit
                );
            }
        }

        accountBody[0]["balance"] = subtractMoney(
            accountBody[0]["balance"],
            oldJournalBody[0].amount
        );
        delete accountBody[0]["owner"];
        const putAccount = await fetch(
            api_url + "/accounting/chart-of-accounts/" + accountId,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(accountBody[0]),
            }
        );
        console.log(accountBody[0]);
    }
    const post = await fetch(api_url + "/accounting/journal-entries/" + id, {
        method: "DELETE",
    });

    return await post.json();
}

export async function getTransactions() {
    const response = await fetch(
        api_url + "/accounting/transactions/user/" + currentBusiness()
        // {
        //   headers: {
        //     Authorization: "Bearer " + currentTokenString(),
        //   },
        // }
    );
    return response.json();
}
export async function getTransaction(id) {
    const response = await fetch(api_url + "/accounting/transactions/" + id);
    return response.json();
}

export async function postTransaction(body) {
    // if (body.account_id) {
    //   const account = await fetch(
    //     api_url + "/accounting/chart-of-accounts/" + body.account_id
    //   );
    //   const accountBody = await account.json();
    //   const accountId = accountBody[0]["id"];
    //   delete accountBody[0]["id"];
    //   delete accountBody[0]["owner"];
    //   accountBody[0]["balance"] = addMoney(
    //     accountBody[0]["balance"],
    //     body.amount
    //   );
    //   const putAccount = await fetch(
    //     api_url + "/accounting/chart-of-accounts/" + accountId,
    //     {
    //       method: "PUT",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify(accountBody[0]),
    //     }
    //   );
    //   console.log(accountBody[0]);
    // }

    const response = await fetch(
        api_url + "/accounting/transactions/user/" + currentBusiness(),
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    return await response.json();
}

export async function putTransaction(body, id) {
    // if (body.account_id) {
    //   const account = await fetch(
    //     api_url + "/accounting/chart-of-accounts/" + body.account_id
    //   );
    //   const accountBody = await account.json();

    //   const oldTransaction = await fetch(
    //     api_url + "/accounting/transactions/" + id
    //   );
    //   const oldTransactionBody = await oldTransaction.json();

    //   const accountId = accountBody[0]["id"];
    //   delete accountBody[0]["id"];
    //   delete accountBody[0]["owner"];
    //   accountBody[0]["balance"] = addMoney(
    //     accountBody[0]["balance"],
    //     subtractMoney(body.amount, oldTransactionBody[0].amount)
    //   );
    //   console.log(subtractMoney(body.amount, oldTransactionBody[0].amount));
    //   console.log(body);
    //   console.log(oldTransactionBody);
    //   const putAccount = await fetch(
    //     api_url + "/accounting/chart-of-accounts/" + accountId,
    //     {
    //       method: "PUT",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify(accountBody[0]),
    //     }
    //   );
    //   console.log(accountBody[0]);
    // }
    const res = await fetch(api_url + "/accounting/transactions/" + id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
    return await res.json();
}

export async function deleteTransaction(id) {
    // const oldTransaction = await fetch(
    //   api_url + "/accounting/transactions/" + id
    // );
    //const oldTransactionBody = await oldTransaction.json();
    // if (oldTransactionBody[0].account_id) {
    //   const account = await fetch(
    //     api_url +
    //       "/accounting/chart-of-accounts/" +
    //       oldTransactionBody[0].account_id
    //   );
    //   const accountBody = await account.json();

    //   const accountId = accountBody[0]["id"];
    //   delete accountBody[0]["id"];
    //   accountBody[0]["balance"] = subtractMoney(
    //     accountBody[0]["balance"],
    //     oldTransactionBody[0].amount
    //   );
    //   delete accountBody[0]["owner"];
    //   const putAccount = await fetch(
    //     api_url + "/accounting/chart-of-accounts/" + accountId,
    //     {
    //       method: "PUT",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify(accountBody[0]),
    //     }
    //   );
    //   console.log(accountBody[0]);
    // }
    const oldJournalEntries = await fetch(
        api_url + "/accounting/transactions/" + id + "/journal-entries"
    );
    const oldJournalEntriesBody = await oldJournalEntries.json();
    for (let i = 0; i < oldJournalEntriesBody.length; i++) {
        await deleteJournalEntry(oldJournalEntriesBody[i].id);
    }

    const post = await fetch(api_url + "/accounting/transactions/" + id, {
        method: "DELETE",
    });

    return await post.json();
}

export async function deleteAllTransactions() {
    const post = await fetch(
        api_url + "/accounting/transactions/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}

export async function deleteAllAccounts() {
    const post = await fetch(
        api_url + "/accounting/chart-of-accounts/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}

export async function getGeneralLedger() {
    const response = await fetch(
        api_url + "/reports/general-ledger/user/" + currentBusiness()
    );
    return response.json();
}

export async function getGeneralLedgerAccountView() {
    const response = await fetch(
        api_url +
            "/reports/general-ledger/user/" +
            currentBusiness() +
            "/account-view"
    );
    return response.json();
}

export async function getCashAccounts() {
    // Checking/Savings, Buesiness Owner Contribution and Drawing, Loan and Line of Credit, Accounts Payable, Credit Card, Money in Transit
}

export async function getCategoryAccounts() {
    //Assets, Liability, Income, Expense, Equity
}
