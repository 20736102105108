import { dateNdaysFromDateFormatted, formatDate } from "./dates";

const scheduleObj = {
  id: 1,
  period: 1,
  period_type: "daily", //["daily", "weekly", "monthly", "yearly"]
  period_start: null, //[null,     "0-6",  0-30, 0-364]
  starting_date: "8/27/2022",
  ending_date: "9/30/2022",
  next_generation: "8/28/2022",
  left_to_generate: 1,
  document_id: 30,
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function numLeftToGenerate() {
  return 69;
}

const daysSlice = [0, 3, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0]; // Number of options to remove from the monthDays list for each month

export function schedule(
  period,
  type,
  period_start,
  startDate,
  endDate,
  num_left
) {
  if (endDate) {
    endDate = endDate.toString();
  }
  if (parseInt(num_left) > 0) {
    console.log("Still Good");
  }
  var left = null;
  if (num_left) {
    left = parseInt(num_left).toString();
  }
  var gen = generate({
    id: 1,
    period: period,
    period_type: type, //["daily", "weekly", "monthly", "yearly"]
    period_start: period_start, //[null,     "0-6",  0-30, 0-364]
    starting_date: startDate,
    ending_date: endDate,
    next_generation: null,
    left_to_generate: left,
    document_id: 30,
  });
  var returnList = [];
  if (gen) {
    returnList.push(0 + ": " + formatDate(new Date(gen.next_generation)));
  }
  for (var i = 1; i < 30; i++) {
    gen = generate(gen);
    if (gen) {
      returnList.push(i + ": " + formatDate(new Date(gen.next_generation)));
    }
  }

  return returnList;
}

export function generate(scheduleObj) {
  var updated = JSON.parse(JSON.stringify(scheduleObj));

  if (scheduleObj.period_type == "Daily") {
    //if next_generation hasn't been calculated yet
    if (!scheduleObj.next_generation) {
      updated.next_generation = new Date(scheduleObj.starting_date);
    }
    //else calculate new next_generation from previous next_generation
    else {
      updated.next_generation = new Date(scheduleObj.next_generation).addDays(
        parseInt(scheduleObj.period)
      );
    }
  }

  if (scheduleObj.period_type == "Weekly") {
    var d = null;

    //if next_generation hasn't been calculated yet
    if (!scheduleObj.next_generation) {
      d = new Date(scheduleObj.starting_date); //Shift date to specified day of the week that is closest to starting date
      var days = [1, 0, 6, 5, 4, 3, 2];
      for (var i = 0; i < scheduleObj.period_start; i++) {
        days.unshift(days.pop());
      }
      d.setDate(d.getDate() + days[d.getDay()]);
    }
    //else calculate new next_generation from previous next_generation
    else {
      d = new Date(scheduleObj.next_generation);
      d.setDate(d.getDate() + 7 * scheduleObj.period);
    }
    updated.next_generation = d;
  }

  if (scheduleObj.period_type == "Monthly") {
    var d = null;
    const desired_day = parseInt(scheduleObj.period_start) + 1;
    const longestMonth = 31;

    //if next_generation hasn't been calculated yet
    if (!scheduleObj.next_generation) {
      d = new Date(scheduleObj.starting_date); //Shift date to specified day of the week that is closest to starting date

      if (d.getDate() > desired_day) {
        const maxNumberOfDays = longestMonth - daysSlice[d.getMonth() + 1];
        const chosenIndex = desired_day;
        d.setMonth(d.getMonth() + 1);
        if (maxNumberOfDays < chosenIndex) {
          d.setDate(maxNumberOfDays);
        } else {
          d.setDate(desired_day);
        }
      } else {
        const maxNumberOfDays = longestMonth - daysSlice[d.getMonth()];
        const chosenIndex = desired_day;
        if (maxNumberOfDays < chosenIndex) {
          d.setDate(maxNumberOfDays);
        } else {
          d.setDate(desired_day);
        }
      }
    }
    //else calculate new next_generation from previous next_generation
    else {
      d = new Date(scheduleObj.next_generation);
      const maxNumberOfDays =
        longestMonth - daysSlice[(d.getMonth() + 1 * scheduleObj.period) % 12];
      const chosenIndex = desired_day;
      if (maxNumberOfDays < chosenIndex) {
        console.log("undesired");
        d.setMonth(d.getMonth() + 1 * scheduleObj.period, maxNumberOfDays);
      } else {
        console.log("desired");
        d.setMonth(d.getMonth() + 1 * scheduleObj.period, desired_day);
      }
    }
    updated.next_generation = d;
  }

  if (scheduleObj.period_type == "Yearly") {
    var d = null;
    var newd = null;
    const desired_day = parseInt(scheduleObj.period_start);

    //if next_generation hasn't been calculated yet
    if (!scheduleObj.next_generation) {
      d = new Date(scheduleObj.starting_date);

      newd = new Date(d.getFullYear(), 0, 1);

      newd.setDate(newd.getDate() + desired_day);
      newd.setHours(8, 0, 0, 0);
      d.setHours(8, 0, 0, 0);
      if (newd.getTime() < d.getTime()) {
        newd = new Date(d.getFullYear() + 1, 0, 1);
        newd.setDate(newd.getDate() + desired_day);
      }

      updated.next_generation = newd;
    }
    //else calculate new next_generation from previous next_generation
    else {
      d = new Date(
        scheduleObj.next_generation.getFullYear() + 1 * scheduleObj.period,
        scheduleObj.next_generation.getMonth(),
        scheduleObj.next_generation.getDate()
      );

      updated.next_generation = d;
    }
  }
  if (updated.left_to_generate != null && updated.ending_date == null) {
    if (parseInt(updated.left_to_generate) > 0) {
      updated.left_to_generate = parseInt(updated.left_to_generate) - 1;
      return updated;
    } else {
      return false;
    }
  }

  if (updated.ending_date != null) {
    var end = new Date(updated.ending_date);
    var next = new Date(updated.next_generation);
    end.setHours(8, 0, 0, 0);
    next.setHours(8, 0, 0, 0);
    if (end < next) {
      console.log(false);
      console.log(end.getTime());
      console.log(next.getTime());
      return false;
    } else {
      return updated;
    }
  } else {
    return updated;
  }
}
