import React from "react";
import { addCommas } from "../../../util/formatters";

function AgingSummaryLineItem(props) {
  return (
    <tr style={{ height: "22px !important" }}>
      <td style={{ fontWeight: "bold", paddingLeft: "10px" }}>
        {props.item.name}
      </td>
      {/* underline if received from props */}
      <td style={{ textAlign: "right", paddingRight: "5px" }}>
        {addCommas(props.item.current)}
        {props.underline}
      </td>{" "}
      <td style={{ textAlign: "right", paddingRight: "5px" }}>
        {addCommas(props.item.firstThirty)}
        {props.underline}
      </td>
      <td style={{ textAlign: "right", paddingRight: "5px" }}>
        {addCommas(props.item.secondThirty)}
        {props.underline}
      </td>
      <td style={{ textAlign: "right", paddingRight: "5px" }}>
        {addCommas(props.item.thirdThirty)}
        {props.underline}
      </td>
      <td style={{ textAlign: "right", paddingRight: "5px" }}>
        {addCommas(props.item.fourthThirty)}
        {props.underline}
      </td>
      <td style={{ textAlign: "right", paddingRight: "5px" }}>
        {addCommas(props.item.total)}
        {props.underline}
      </td>
    </tr>
  );
}

export default AgingSummaryLineItem;
