//TAG:AUTHENTICATION

import React, { useState } from "react";
import { SettingsContext } from "../../App";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Forms/Input";
import SuccessMessage from "../../components/Messages/SuccessMessage";
import WarningMessage from "../../components/Messages/WarningMessage";
import { changePassword } from "../../features/authentication/api";
import ProfileSidebar from "./ProfileSidebar";

function Password() {
  const [originalPassword, setOriginalPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const [success, setSuccess] = useState(null);

  const settings = React.useContext(SettingsContext);

  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <ProfileSidebar active="Password" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "calc(100% - var(--sidebar-width))",
          }}
        >
          <div style={{ width: "40%" }}>
            <WarningMessage message={error} />
            <SuccessMessage message={success} />
            <h1>Change Password</h1>
            <div
              style={{
                width: "100%",
                border: "1px solid var(--separator-color)",
                borderRadius: "10px",
                minHeight: "10px",
                padding: "20px",
              }}
            >
              <Input
                label="Original Password"
                onChange={(e) => setOriginalPassword(e.target.value)}
              />
              <Input
                label="New Password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Input
                label="Confirm New Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div style={{ marginTop: "20px" }}></div>
              <Button
                title="Change Password"
                onClick={async () => {
                  if (newPassword !== confirmPassword) {
                    setError("New password and confirm password must match");
                  } else {
                    setError(null);
                    let response = await changePassword({
                      email: settings.user.email,
                      oldPassword: originalPassword,
                      newPassword: newPassword,
                    });
                    if (response.error) {
                      setError(response.error);
                      setSuccess(null);
                    } else if (response.success) {
                      setSuccess(response.success);
                      setError(null);
                    }
                  }
                  console.log({
                    original: originalPassword,
                    new: newPassword,
                    confirm: confirmPassword,
                    email: settings.user.email,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
