import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function SalesTaxes() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Sales Taxes" />
        <div>SalesTaxes</div>
      </div>
    </div>
  );
}

export default SalesTaxes;
