import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../../App";
import Button from "../../../components/Buttons/Button";
import DeleteIcon from "../../../components/Icons/DeleteIcon";
import EditIcon from "../../../components/Icons/EditIcon";
import { getUsersOfBusiness } from "../../../features/authentication/api";
import SettingsSidebar from "../SettingsSidebar";

function UserManagement() {
    let nav = useNavigate();

    let { settings, user } = useContext(SettingsContext);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        getUsersOfBusiness(user?.business?.id).then((data) => {
            setUsers(data);
        });
    }, []);
    return (
        <div className="page-container">
            <div style={{ display: "flex" }}>
                <SettingsSidebar active="Users" />
                <div style={{ width: "calc(100% - var(--sidebar-width))" }}>
                    <h1 style={{ margin: "30px" }}>
                        {user?.business?.doing_business_as} Users
                    </h1>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <div style={{ width: "80%" }}>
                            <Button
                                disabled={users?.length >= settings.max_users}
                                title="Add new user"
                                onClick={() => nav("/settings/users/add")}
                            />{" "}
                            {users?.length} / {settings.max_users}
                            <div className="transactionsTable">
                                {
                                    users?.find(
                                        (item) => item.is_owner === true
                                    ).name
                                }
                                <table
                                    width="100%"
                                    style={{
                                        "table-layout": "fixed",
                                        marginTop: "10px",
                                    }}
                                >
                                    <thead className="transactionsTableHeader">
                                        <tr style={{ height: "40px" }}>
                                            <th
                                                style={{
                                                    textIndent: "10px",
                                                    width: "30%",
                                                }}
                                            >
                                                Name
                                            </th>
                                            <th
                                                style={{
                                                    textIndent: "30px",
                                                    width: "30%",
                                                }}
                                            >
                                                Email
                                            </th>
                                            <th
                                                style={{
                                                    textIndent: "10px",
                                                    width: "30%",
                                                }}
                                            >
                                                Role
                                            </th>
                                            <th
                                                style={{
                                                    textAlign: "right",
                                                    paddingRight: "10px",
                                                    width: "10%",
                                                }}
                                            >
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[
                                            users?.find(
                                                (item) => item.is_owner === true
                                            ),
                                        ].map((item, index) => {
                                            if (!item) return null;
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.name
                                                            ? item.name
                                                            : "Name Unavailable"}
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        {item.roll_name} (Owner)
                                                    </td>
                                                    <td
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-around",
                                                        }}
                                                    >
                                                        <EditIcon disabled />
                                                        <DeleteIcon inactive />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {users?.map((item, index) => {
                                            console.log(item);
                                            return (
                                                <>
                                                    {!item.is_owner ? (
                                                        <tr>
                                                            <td>
                                                                {item.name
                                                                    ? item.name
                                                                    : "Name Unavailable"}
                                                            </td>
                                                            <td>
                                                                {item.email}
                                                            </td>
                                                            <td>
                                                                {item.roll_name}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "space-around",
                                                                }}
                                                            >
                                                                <EditIcon
                                                                    onClick={() =>
                                                                        nav(
                                                                            "/settings/users/update/" +
                                                                                item.id
                                                                        )
                                                                    }
                                                                />
                                                                <DeleteIcon />
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserManagement;
