import React, { useState } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { formatDate } from "../../../util/dates";

const DateDiv = styled.button`
  border: none;
  padding: 0px !important;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
`;

function DateInput(props) {
  const id = "date-input";
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dateSelected, setDateSelected] = useState(false);

  return (
    <>
      {props.disabled ? (
        props.initialValue
      ) : (
        <>
          {" "}
          <DateDiv
            id={props.id ? props.id : id}
            onClick={() => {
              console.log("click");
              setShowCalendar(!showCalendar);
              setDateSelected(true);
            }}
            onBlur={() => console.log("blur")}
          >
            {dateSelected ? formatDate(startDate) : props.initialValue}
          </DateDiv>
          <div>
            {showCalendar ? (
              <div style={{ position: "absolute", zIndex: "200" }}>
                <DatePicker
                  selected={startDate}
                  placeholderText={"select date"}
                  inline
                  onChange={(e) => {
                    console.log("change");
                    setStartDate(e);
                    setShowCalendar(!showCalendar);
                    props.changeText(e);
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

export default DateInput;
