import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import styled from "styled-components";

const HelpIconDiv = styled.span`
  &:hover {
    color: #111;
    cursor: pointer;
  }
`;

function HelpIcon(props) {
  return (
    <HelpIconDiv>
      <AiOutlineQuestionCircle title={props.message} />
    </HelpIconDiv>
  );
}

export default HelpIcon;
