import AlphanumericInput from "../../../components/Forms/documents/AlphanumericInput";

const MainFooter = (props) => {
  return (
    <>
      <div style={{ marginTop: "auto", marginBottom: "30px" }}>
        <div>
          Notes & Terms
          <div className="container">
            <AlphanumericInput
              initialValue={props.getters[1].footer}
              limit={80}
              changeText={(e) => {
                var arr = props.getters[1];
                arr.footer = e;
                props.setters[1](JSON.parse(JSON.stringify(arr)));
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          height: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{props.lowerLeft}</div>
        <div>{props.lowerRight}</div>
      </div>
    </>
  );
};

export default MainFooter;
