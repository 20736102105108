import React from "react";

/* Props 
    className
    label
    id
    value
    onChange
*/
function Input(props) {
    return (
        <div
            className={props.className}
            style={{ width: props.width ? props.width : "100%" }}
        >
            {props.label ? (
                <label htmlFor="Input" className="form-label">
                    {props.label}
                </label>
            ) : null}

            <input
                type={props.type ? props.type : "text"}
                className="form-control"
                id={props.id ? props.id : "common-input"}
                disabled={props.disabled}
                autoComplete={"new-password"}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
            />
        </div>
    );
}

export default Input;
