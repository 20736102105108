import React from "react";

import ContentEditable from "react-contenteditable";
import { NUMERIC } from "../../../util/formatters";
import { toFormattedNumber } from "../../../util/formatters";
import { toFloatg } from "../../../util/formatters";
import { toPercentage } from "../../../util/formatters";

function validateNumeric(
  event,
  validationString,
  current,
  maxLength,
  maxDecimals
) {
  const decimalPortion = current.split(".")[1];
  console.log(event.view.getSelection().baseOffset);
  console.log(current.indexOf("."));

  const notNeccessaryKey =
    event.key != "Backspace" &&
    event.key != "Delete" &&
    event.key != "ArrowLeft" &&
    event.key != "ArrowRight";

  // validate decimal portion doesn't exceed limit
  if (current.includes(".")) {
    //if typing in decimal part of number
    if (event.view.getSelection().baseOffset > current.indexOf(".")) {
      if (decimalPortion.length >= maxDecimals) {
        if (notNeccessaryKey) {
          event.preventDefault();
        }
      }
    }
  }
  if (!validationString.includes(event.key)) {
    if (notNeccessaryKey) {
      event.preventDefault();
    }
  } else if (event.key == ".") {
    if (current.toString().includes(".")) {
      event.preventDefault();
    }
  }
  if (current.length >= maxLength) {
    if (notNeccessaryKey) {
      if (
        event.view.getSelection().baseOffset -
          event.view.getSelection().extentOffset ==
        0
      ) {
        event.preventDefault();
      }
    }
  }
}

export default class PercentageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.initialValue || "0.0%" };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: toFloatg(this.props.initialValue * 100, "discountForm"),
      });
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
    const storeFormat = toFloatg(e.target.value / 100, "discount");
    this.props.changeText(storeFormat);
  };
  render() {
    return (
      <div style={{ width: this.props.width }}>
        {this.props.disabled ? (
          this.state.value
        ) : (
          <ContentEditable
            html={this.state.value}
            onChange={(e) => this.handleChange(e)}
            onKeyDown={(e) =>
              validateNumeric(
                e,
                NUMERIC,
                this.state.value,
                this.props.limit,
                this.props.decimalLimit
              )
            }
            onBlur={(e) =>
              this.setState({
                value: toPercentage(this.state.value / 100, "discountForm"),
              })
            }
            onFocus={(e) =>
              this.setState({
                value: toFloatg(this.state.value, "discountForm"),
              })
            }
          />
        )}
      </div>
    );
  }
}
