import React from "react";
import Label from "../../components/Descriptions/Label";
import MonthAndDayInput from "../../components/Forms/Dates/MonthAndDayInput";
import Dropdown from "../../components/Forms/Dropdown";
import HelpIcon from "../../components/Icons/HelpIcon";
import SettingsSidebar from "./SettingsSidebar";

const DATE_FORMATS = [
  "MM/DD/YYYY",
  "DD/MM/YYYY",
  "YYYY/MM/DD",
  "MM-DD-YYYY",
  "DD-MM-YYYY",
  "YYYY-MM-DD",
];

function DatesAndCurrency() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Dates & Currency" />
        <div
          style={{
            justifyContent: "center",
            width: "calc(100% - var(--sidebar-width))",
          }}
        >
          <div
            style={{
              height: "300px",
              width: "500px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h1>Dates & Currency</h1>
            <div
              style={{
                border: "1px solid var(--separator-color)",
                borderRadius: "10px",
                height: "200px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <Label label="Fiscal Year End" />
                <MonthAndDayInput />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                Date Format
                <div>
                  <Dropdown dropdownList={DATE_FORMATS} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                Business Currency
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    USD ($) - U.S. dollar
                  </div>
                  <HelpIcon message="Currency used in all reports. Cannot be changed after business account is created." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatesAndCurrency;
