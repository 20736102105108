import React from "react";
import { getDate, getTime } from "../../../../util/dates";

function OutstandingInvoicesSecondaryHeader() {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>{getDate(new Date())}</div>
      <div>{getTime(new Date())}</div>
    </div>
  );
}

export default OutstandingInvoicesSecondaryHeader;
