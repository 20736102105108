import React from "react";

function StandardDropdown(props) {
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      {" "}
      <select
        className="form-select picker"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      >
        {props.placeholder ? (
          <option disabled selected>
            {props.placeholder}
          </option>
        ) : null}
        {props.list.map((item, index) => {
          return (
            <option className="dropdownOption" key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default StandardDropdown;
