import React from "react";
import { settingsSidebarData } from "./SettingsSidebarData";

import styled from "styled-components";

import { Link } from "react-router-dom";
import { SettingsContext } from "../../App";

export const SidebarLink = styled(Link)`
  display: flex;
  color: #737373;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: 30px;
  text-decoration: none;
  font-size: 16px;
  transition: 0.1s ease-out;
  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
`;

export const SidebarLinkActive = styled.div`
  display: flex;
  color: #737373;
  background: #d8d8d8;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: 30px;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;

function SettingsSidebar(props) {
  const { settings } = React.useContext(SettingsContext);

  return (
    <div
      style={{
        backgroundColor: "var(--container-color)",
        width: "calc(var(--settings-sidebar-width))",
        height: "100vh",
      }}
    >
      <div style={{ marginLeft: "10px", marginRight: "10px" }}>
        <h5 style={{ margin: "18px" }}>Settings</h5>
        {settingsSidebarData.map((item, index) => {
          return (
            <div>
              {item.membership.includes(settings.membership) ? (
                <>
                  {item.title}
                  {item.subnav.map((subitem, j) => {
                    return (
                      <>
                        {subitem.title == props.active ? (
                          <SidebarLinkActive>{subitem.title}</SidebarLinkActive>
                        ) : (
                          <SidebarLink to={subitem.path}>
                            {subitem.title}
                          </SidebarLink>
                        )}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SettingsSidebar;
