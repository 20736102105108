import React, { useState, useRef, useEffect } from "react";
import MultiPage from "../MultiPage";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import { agingItems } from "./Data";

import { getAgingItems } from "../util";

async function handleDownloadPdf(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 4, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  pdf.save("print.pdf");
}

//handling form submission, page settings, db fetching
function AgingSummaryAR() {
  const [orientation, setOrientation] = useState("landscape");
  const [lineItems, setLineItems] = useState([]);
  const printRef1 = useRef(null);
  const printRef2 = useRef(null);
  const printRef3 = useRef(null);
  const printRef4 = useRef(null);
  const printRef5 = useRef(null);

  const printRefs = [printRef1, printRef2];

  const dbLineItems = agingItems;

  useEffect(() => {
    //const newLineItems = JSON.parse(JSON.stringify(dbLineItems));

    getAgingItems().then((data) => {
      console.log(data);
      setLineItems(data);
    });
  }, []);

  return (
    <div>
      <button
        onClick={() => {
          const newLineItems = JSON.parse(JSON.stringify(lineItems));
          newLineItems.push(lineItems.length + 1);
          setLineItems(newLineItems);
        }}
        style={{ position: "fixed" }}
      >
        add line item
      </button>
      <button
        onClick={() => {
          const newLineItems = JSON.parse(JSON.stringify(lineItems));
          newLineItems.splice(Math.floor(Math.random() * 30), 1);
          console.log(newLineItems);
          setLineItems(newLineItems);
        }}
        style={{ position: "fixed", marginLeft: "120px" }}
      >
        remove line item
      </button>
      <button
        type="button"
        className="btn btn-light"
        style={{ marginLeft: "1000px" }}
        onClick={() => handleDownloadPdf(printRefs, orientation)}
      >
        Download as PDF
      </button>
      <button
        type="button"
        className="btn btn-light"
        onClick={() => {
          if (orientation == "landscape") {
            setOrientation("potrait");
          } else {
            setOrientation("landscape");
          }
          const newLineItems = JSON.parse(JSON.stringify(lineItems));
          setLineItems(newLineItems);
        }}
      >
        Change Orientation
      </button>
      <MultiPage
        lineItems={lineItems}
        orientation={orientation}
        printRefs={printRefs}
      />
    </div>
  );
}

export default AgingSummaryAR;
