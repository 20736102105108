import React, { useState } from "react";
import { formatDate } from "../../../util/dates";
import { toUSD } from "../../../util/formatters";
import DateInput from "../../../components/Forms/documents/DateInput";
import AlphanumericInput from "../../../components/Forms/documents/AlphanumericInput";
import SearchableWithButton from "../../../components/Forms/SearchableWithButton";
import { MdOutlineModeEditOutline, MdCheckCircle } from "react-icons/md";

import {
  MdOutlineArrowDropDownCircle,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import PriceInput from "../../../components/Forms/documents/PriceInput";

import Dropdown from "react-bootstrap/Dropdown";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { putTransaction } from "../api";

function TransactionItem(props) {
  const [select, setSelect] = useState(false);
  return (
    <tr className="transactionRow" onClick={(e) => props.onClick(e)}>
      <td style={{ display: "flex", alignItems: "center" }}>
        <div style={{ padding: "10px" }}>
          <input
            type="checkbox"
            checked={props.info.checked}
            onClick={(e) => {
              console.log(e.shiftKey);

              if (e.shiftKey) {
                props.shiftCheck(props.index);
              } else {
                props.setInfo("checked", !props.info.checked);
              }
            }}
            onKeyDown={(e) => {
              console.log(e);
              if (e.key == "Shift" && !e.repeat) {
                props.setShiftIndex(props.index);
              }
            }}
          />{" "}
        </div>
        <div style={{ fontWeight: "bold" }}>
          <DateInput
            initialValue={props.info.date}
            changeText={(e) => props.setInfo("date", formatDate(e))}
            disabled
          />
        </div>
      </td>
      <td style={{ fontWeight: "bold" }}>
        <AlphanumericInput
          initialValue={props.info.description}
          changeText={(val) => props.setInfo("description", val)}
          disabled
        />
      </td>
      <td>
        {props.info.category == "Journal Entry" ? null : (
          <div style={{ position: "relative", marginRight: "30px" }}>
            {/* <SearchableWithButton
              appendID={"-searchable-" + props.index}
              list={props.accounts}
              value={props.info.account}
              setValue={(e) => props.setInfo("account", e)}
              defaultValue={"Select an Account"}
            /> */}
            {props.accounts[props.info.account]}
          </div>
        )}
      </td>
      <td>
        {props.info.category == "Journal Entry" ? (
          "Journal Entry"
        ) : (
          <AlphanumericInput
            initialValue={props.info.category}
            changeText={(val) => props.setInfo("category", val)}
            disabled
          />
        )}
      </td>

      <td style={{ fontWeight: "bold", textAlign: "right" }}>
        <PriceInput
          initialValue={props.info.amount}
          decimalLimit={2}
          limit={15}
          changeText={(val) => props.setInfo("amount", val)}
          disabled
        />
      </td>
      <td
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingLeft: "30px",
        }}
      >
        <div>
          {console.log(props.info.reviewed)}
          {props.info.reviewed ? (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip placement="left" className="in" id="tooltip-left">
                  Reviewed
                </Tooltip>
              }
            >
              <div>
                <div
                  style={{ color: "green", borderColor: "green" }}
                  onClick={() => {
                    props.reviewTransaction(props.index, false);
                  }}
                >
                  <MdCheckCircle className="actionDropdown" />
                </div>
              </div>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip placement="left" className="in" id="tooltip-left">
                  Mark as Reviewed
                </Tooltip>
              }
            >
              <div
                onClick={() => {
                  props.reviewTransaction(props.index, true);
                }}
              >
                {" "}
                <MdOutlineCheckCircleOutline className="actionDropdown" />
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div>
          {props.info.category == "Journal Entry" ? (
            <Dropdown>
              <Dropdown.Toggle
                className="btn-light"
                style={{
                  "background-color": "light-gray",
                  "padding-top": "1px",
                  "padding-bottom": "1px",
                }}
                id="dropdown-basic"
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item onClick={() => console.log(props.info)} disabled>
                  Refund
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() =>
                    props.navigate(
                      "/accounting/transactions/journal/update/" + props.info.id
                    )
                  }
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    props.navigate(
                      "/accounting/transactions/journal/view/" + props.info.id
                    )
                  }
                >
                  View journal entries
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => console.log("clicl")} disabled>
                  Upload Receipt
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => props.onDelete(props.index)}
                  style={{ color: "#d11a2a" }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                className="btn-light"
                style={{
                  "background-color": "light-gray",
                  "padding-top": "1px",
                  "padding-bottom": "1px",
                }}
                id="dropdown-basic"
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item onClick={() => console.log(props.info)} disabled>
                  Refund
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => props.editTransaction(props.info)}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    props.navigate(
                      "/accounting/transactions/journal/view/" + props.info.id
                    )
                  }
                >
                  View journal entries
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => console.log("clicl")} disabled>
                  Upload Receipt
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => props.onDelete(props.index)}
                  style={{ color: "#d11a2a" }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TransactionItem;
