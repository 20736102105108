import React, { useEffect, useId, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import useClickOutside from "../../hooks/useClickOutside";

import styled from "styled-components";
import { isEmpty, isEqual } from "../../util/jsonUtil";

import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import CloseIcon from "../Icons/CloseIcon";
import LoadingSpinner from "../Icons/LoadingSpinner/LoadingSpinner";

//styled div that highlights on hover
const Item = styled.div`
  color: black;
  &:hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
  &:focus {
    background-color: #e6e6e6;
    outline: none;
  }
`;

function SearchableModal(props) {
  const id = useId();

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [hideKeyItems, setHideKeyItems] = useState([]);
  const modalRef = useRef();

  useClickOutside(modalRef, () => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    if (!open) {
      document
        .getElementById(
          props.id ? "scroll-area-" + props.id : "scroll-area-" + id
        )
        .scrollTo({ top: 0, behavior: "instant" });

      // document.getElementById(props.id ? props.id : id).focus();

      setSearchTerm("");
    }
  }, [open]);

  //   const ButtonDiv = styled.div`
  //     &:hover button > div.clearIcon {
  //       visibility: visible !important;
  //       pointer-events: auto;
  //     }
  //   `;
  useEffect(() => {
    const scrollArea = document.getElementById(
      props.id ? "scroll-area-" + props.id : "scroll-area-" + id
    );
    if (scrollArea) {
      setHasScrollbar(scrollArea.scrollHeight > scrollArea.clientHeight);
    } else {
      setHasScrollbar(false);
    }
    //make an array of false of the same length as Object.keys(props.list).length
    if (props.list) {
      let hideKeyItemsArr = new Array(Object.keys(props.list).length).fill(
        false
      );
      setHideKeyItems(hideKeyItemsArr);
    }
  }, []);

  useEffect(() => {
    //set hideKeyItems back to false
    if (props.list) {
      let hideKeyItemsArr = new Array(Object.keys(props.list).length).fill(
        false
      );
      setHideKeyItems(hideKeyItemsArr);
    }
  }, [searchTerm]);

  function underlineMatches(item, keys, term) {
    const keyList = Object.keys(keys);
    var flexItems = [];
    // console.log(keyList);
    var found = false;
    for (let i in keyList) {
      const displayStr = keys[keyList[i]](item[keyList[i]]);
      const underlined = underlineMatch(displayStr, term);
      if (underlined != null) {
        found = true;
        flexItems.push(<div>{underlined}</div>);
      } else {
        flexItems.push(<div>{displayStr}</div>);
      }
    }
    if (!found) return null;
    return (
      <>
        {flexItems.map((item, index) => {
          return <>{item}</>;
        })}
      </>
    );
  }

  function underlineMatch(str, searchTerm) {
    const lowercaseStr = str.toLowerCase();
    const lowercaseSubstr = searchTerm.toLowerCase();
    //find match index
    const matchIndex = lowercaseStr.indexOf(lowercaseSubstr);
    var letters = [];
    if (matchIndex === -1) {
      return null;
    }
    for (var i = 0; i < str.length; i++) {
      if (matchIndex <= i && i < matchIndex + lowercaseSubstr.length) {
        letters.push(
          <span
            style={{
              margin: "0px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            {str[i]}
          </span>
        );
      } else {
        letters.push(
          <span
            style={{
              margin: "0px",
            }}
          >
            {str[i]}
          </span>
        );
      }
    }
    return letters;
  }

  let foundMatch = false;

  return (
    <>
      <div
        style={{
          position: "relative",
          width: props.width ? props.width : "100%",
        }}
      >
        {props.label ? (
          <label className="form-label">
            {props.label}

            <span style={{ marginLeft: "10px" }}>{props.help}</span>
          </label>
        ) : null}
        <div ref={modalRef}>
          <button
            className="form-control"
            style={{
              borderBottomLeftRadius: open ? "0px" : "5px",
              borderBottomRightRadius: open ? "0px" : "5px",
              transition: "0.2s",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "block",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
            onClick={() => {
              if (!open) {
                const searchBar = document.getElementById(
                  props.id ? "search-bar-" + props.id : "search-bar-" + id
                );
                if (searchBar) searchBar.focus();
              }
              setOpen(!open);
            }}
            id={props.id ? props.id : "searchable-modal-button"}
          >
            {/* <div
              className="clearIcon"
              style={{
                position: "absolute",
                visibility: "hidden",
                pointerEvents: "none",
              }}
            >
              <CloseIcon />
            </div> */}
            {props.value
              ? props.value
              : props.initialValue
              ? props.initialValue
              : "Select"}
            {open ? (
              <RiArrowUpSFill
                style={{
                  fontSize: "18px",
                  position: "absolute",
                  right: "2%",
                  marginTop: "3px", // to center the icon due to 24px viewbox
                  pointerEvents: "none",
                }}
              />
            ) : (
              <RiArrowDownSFill
                style={{
                  fontSize: "18px",
                  position: "absolute",
                  right: "2%",
                  marginTop: "3px", // to center the icon due to 24px viewbox
                  pointerEvents: "none",
                }}
              />
            )}
          </button>

          <div
            style={{
              opacity: open ? "1" : "0",
              pointerEvents: open ? "all" : "none",
              backgroundColor: "white",
              width: "100%",
              maxHeight: "400px",
              position: "absolute",
              border: "2px solid #90a790",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              zIndex: "100",
              boxShadow: "0 10px 10px rgb(201, 201, 201)",
              // transition: "0.1s",
            }}
          >
            {hasScrollbar ? (
              <div
                style={{
                  padding: "0.5rem",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                id={props.id ? "search-box-" + props.id : "search-box-" + id}
              >
                <AiOutlineSearch
                  style={{
                    marginLeft: "1rem",
                    position: "absolute",
                    color: "black",
                  }}
                  size="1.5em"
                />

                <input
                  style={{
                    padding: " 0.5rem 0.5rem 0.5rem 3rem",
                    width: "100%",
                  }}
                  id={props.id ? "search-bar-" + props.id : "search-bar-" + id}
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      console.log("enter");
                    }
                  }}
                  tabIndex={open ? "0" : "-1"}
                ></input>
              </div>
            ) : null}

            <div
              id={props.id ? "scroll-area-" + props.id : "scroll-area-" + id}
              style={{
                overflowY: "auto",
                maxHeight: "300px",
              }}
            >
              {props.list != null ? (
                props.keys != null ? (
                  <>
                    {Array.isArray(props.list) ? (
                      <div
                        style={{
                          paddingLeft: "0.5rem",
                          paddingRight: "0.5rem",
                        }}
                      >
                        {props.list.map((item, i) => {
                          const rowItem = underlineMatches(
                            item,
                            props.keys,
                            searchTerm
                          );
                          if (rowItem != null) {
                            foundMatch = true;
                          }
                          return (
                            <>
                              {!foundMatch && i == props.list.length - 1 ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    fontWeight: "bold",
                                    pointerEvents: "none",
                                  }}
                                >
                                  No results found
                                </div>
                              ) : (
                                <Item
                                  onClick={() => {
                                    setOpen(false);
                                    props.onItemClick(item);
                                  }}
                                  style={{
                                    backgroundColor: props.value
                                      ? isEqual(
                                          props.valueKey
                                            ? item[props.valueKey]
                                            : item.name,
                                          props.value
                                        )
                                        ? "var(--selected-color)"
                                        : null
                                      : null,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {rowItem}
                                  </div>
                                </Item>
                              )}
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {Object.keys(props.list).map((item, i) => {
                          let keyHasMatch = false;
                          var rowItems = [];
                          for (var subItem in props.list[item]) {
                            const rowItem = underlineMatches(
                              props.list[item][subItem],
                              props.keys,
                              searchTerm
                            );
                            if (rowItem != null) {
                              foundMatch = true;
                              keyHasMatch = true;
                            }
                            rowItems.push(rowItem);
                          }

                          const numRowsDisplaying = rowItems.filter(
                            (item) => item != null
                          ).length;

                          return (
                            <div
                              style={{
                                borderBottom: keyHasMatch
                                  ? "1px solid var(--separator-color)"
                                  : null,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  paddingLeft: "0.5rem",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                {keyHasMatch ? (
                                  rowItems.length > 10 ? (
                                    <div
                                      onClick={(e) => {
                                        let hideKeyItemsArr = [...hideKeyItems];
                                        hideKeyItemsArr[i] =
                                          !hideKeyItemsArr[i];
                                        setHideKeyItems(hideKeyItemsArr);
                                        e.stopPropagation();
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          let hideKeyItemsArr = [
                                            ...hideKeyItems,
                                          ];
                                          hideKeyItemsArr[i] =
                                            !hideKeyItemsArr[i];
                                          setHideKeyItems(hideKeyItemsArr);
                                          e.stopPropagation();
                                        }
                                      }}
                                      tabIndex={open ? "0" : "-1"}
                                    >
                                      {hideKeyItems[i] ? (
                                        <>
                                          {item}
                                          <RiArrowDownSFill />{" "}
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {item}
                                          <RiArrowUpSFill />
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <div>{item}</div>
                                  )
                                ) : null}
                              </div>
                              {props.list[item].map((subItem, j) => {
                                return (
                                  <>
                                    {!foundMatch &&
                                    j == props.list[item].length - 1 &&
                                    i == Object.keys(props.list).length - 1 ? (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          width: "100%",
                                          fontWeight: "bold",
                                          pointerEvents: "none",
                                        }}
                                      >
                                        No results found
                                      </div>
                                    ) : (
                                      <Item
                                        onClick={() => {
                                          setOpen(false);
                                          props.onItemClick(subItem);
                                        }}
                                        style={{
                                          backgroundColor: props.value
                                            ? isEqual(
                                                props.valueKey
                                                  ? subItem[props.valueKey]
                                                  : subItem.name,
                                                props.value
                                              )
                                              ? "var(--selected-color)"
                                              : null
                                            : null,
                                          paddingLeft: "1.5rem",
                                        }}
                                        tabIndex={
                                          !hideKeyItems[i]
                                            ? open
                                              ? rowItems[j]
                                                ? "0"
                                                : "-1"
                                              : "-1"
                                            : "-1"
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setOpen(false);
                                            props.onItemClick(subItem);
                                            document
                                              .getElementById(
                                                props.id ? props.id : id
                                              )
                                              .focus();
                                          }
                                          // if (e.key === "ArrowDown") {
                                          //   // focusNextElement();
                                          //   var e2 = new window.KeyboardEvent(
                                          //     "keydown",
                                          //     {
                                          //       bubbles: true,
                                          //       key: "Tab",
                                          //       keyCode: 9,
                                          //     }
                                          //   );
                                          //   e.target.dispatchEvent(e2);

                                          //   // e.stopPropagation();
                                          //   // e.preventDefault();
                                          // }
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {hideKeyItems
                                            ? !hideKeyItems[i]
                                              ? rowItems[j]
                                              : null
                                            : null}
                                        </div>
                                      </Item>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  "No results found"
                )
              ) : (
                "No results found"
              )}
              {props.loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                  }}
                >
                  <LoadingSpinner size="25" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchableModal;
