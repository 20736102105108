// utility api functions such as clearing the database and seeding the database

import {
    deleteAllAccounts,
    deleteAllTransactions,
} from "../pages/Accounting/api";
import { deleteAllVendors } from "../pages/Purchases/api";
import { deleteAllCustomers, deleteAllDocuments } from "../pages/Sales/api";
import {
    deleteAllCategories,
    deleteAllProducts,
    deleteAllTaxes,
} from "../pages/Site/api";

export async function clearDatabase() {
    deleteAllTransactions();
    deleteAllCustomers();
    deleteAllVendors();
    deleteAllDocuments();
    deleteAllAccounts();
    deleteAllProducts();
    deleteAllCategories();
    deleteAllTaxes();
}
