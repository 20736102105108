import React, { useState } from "react";
import "./SearchableDropdownWithCreate.css";

// libaries
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import DeleteIcon from "../Icons/DeleteIcon";

const list = [
  ["Black Sea", 4],
  ["Caribbean Sea", 66],
  ["North Sea", 2],
  ["Baltic Sea", 7],
];

const type = "tax";

function ensureFound(strArray, term) {
  var foundItems = [];
  var styledLetters = [];
  for (var i in strArray) {
    if (strArray[i][0].toLowerCase().includes(term.toLowerCase())) {
      styledLetters = underlineLongestMatch(strArray[i][0], term);
      foundItems.push([
        <div
          className="scrollableListItem"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>{styledLetters}</div>
          <div>{strArray[i][1]}</div>
        </div>,
        i,
      ]);
    }
  }
  if (foundItems.length > 0) {
    return foundItems;
  } else {
    return [[<div style={{ textAlign: "center" }}>No results found</div>]];
  }
}

function underlineLongestMatch(str, substr) {
  const lowercaseStr = str.toLowerCase();
  const lowercaseSubstr = substr.toLowerCase();
  //find match index
  const matchIndex = lowercaseStr.indexOf(lowercaseSubstr);
  var letters = [];
  for (var i = 0; i < str.length; i++) {
    if (matchIndex <= i && i < matchIndex + lowercaseSubstr.length) {
      letters.push(
        <span
          style={{
            margin: "0px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {str[i]}
        </span>
      );
    } else {
      letters.push(
        <span
          style={{
            margin: "0px",
          }}
        >
          {str[i]}
        </span>
      );
    }
  }
  return letters;

  //underline and bold from match index to (match index + substr.length)
}

function SearchableDropdownWithCreate(props) {
  const [searchTerm, setSearchTerm] = useState("");
  if (props.list == null) return null;
  var buttonList = [
    "Create a new tax",
    "Add discount (%)",
    "Add discount (fixed)",
  ];

  if (props.buttonList) {
    buttonList = props.buttonList;
  }
  console.log(props.buttonList);
  console.log(props.functions);
  return (
    <div className="searchableDropdownWithCreate">
      <div className="search" id="search-box">
        <AiOutlineSearch
          style={{ marginLeft: "1rem", position: "absolute" }}
          size="1.5em"
        />

        <input
          className="searchBar"
          id={props.searchBarID || "search-bar"}
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              console.log("enter");
              var foundList = ensureFound(props.list, searchTerm);
              if (foundList.length == 1) {
                props.onItemClick(foundList[0][1], props.index);
                document.activeElement.blur();
              }
            }
          }}
        ></input>
      </div>
      <div className="scrollableList">
        {props.list != null ? (
          props.list.length > 0 ? (
            <>
              {ensureFound(props.list, searchTerm).map((item, index) => {
                return (
                  <div
                    onMouseDown={() => props.onItemClick(item[1], props.index)}
                  >
                    {item[0]}
                  </div>
                );
              })}
            </>
          ) : (
            <div style={{ textAlign: "center" }}>No results found</div>
          )
        ) : (
          <div style={{ textAlign: "center" }}>No results found</div>
        )}
      </div>

      {buttonList.map((item, index) => {
        return (
          <>
            <hr
              className="solid"
              width="100%"
              style={{ marginTop: "0em", marginBottom: "0em" }}
            />

            <div className="createButton" onClick={props.functions[index]}>
              <div style={{ marginLeft: "0.5em" }}>
                {item == "Remove" ? (
                  <DeleteIcon fontSize={"var(--font-size-normal)"} inactive />
                ) : (
                  <MdOutlineAddCircleOutline />
                )}
              </div>
              <div style={{ marginRight: "0.5em" }}>{item}</div>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default SearchableDropdownWithCreate;
