export const userInvoiceSettings = [
  {
    currency: "USD",
    date_format: "mm/dd/yyyy",
    invoice_number_prefix: "ASP000",
    current_invoice_number: "",
    precision_dictionary: {
      total: 2,
      price: 5,
      quantity: 5,
      tax: 0,
      discount: 1,
    },
    default_issue_date: Date.now(),
    default_net: "30",

    default_footer: "Thank you for your business",
  },
];

export const companyInfo = [
  {
    name: "ASPaccounting",
    address: "34 Annette Park Dr.",
    number: "+1-(641)-485-7376",
    website: "aspaccounting.com",
    email: "accounting@asp.com",
    city: "Bozeman",
    zip: "59715",
    state: "MT",
    country: "USA",
  },
];

export const customerInfo = [
  {
    primary_name: "Jeff Shaw",
    primary_address: "1405 Riverside Dr.",
    billing_city: "Billings",
    billing_state: "Montana",
    billing_zip: "59101",
    billing_country: "USA",
    primary_phone: "406-661-5325",
    primary_email: "jeffshaw@billingslasers.net",
    other: "Attn. Jeff",
  },
];
