import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { google_analytics_id } from "../util/environment";

function initialiseAnalytics() {
    const TRACKING_ID = google_analytics_id;
    ReactGA.initialize(TRACKING_ID);
}

function usePageTracking() {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        initialiseAnalytics();
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname + location.search,
            });
            console.log("Pageview: " + location.pathname + location.search);
        }
    }, [initialized, location]);
}

export default usePageTracking;
