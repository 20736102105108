import React from "react";
import { api_url } from "../../../util/environment";

export default class ContactSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      contact: [
        {
          id: "",
          company_id: "",
          type: "",
          primary_name: "",
          primary_position: "",
          primary_email: "",
          primary_phone: "",
          tax_number: "",
          shipping_address: "",
          shipping_city: "",
          shipping_zip_code: "",
          shipping_state: "",
          shipping_country: "",
          billing_address: "",
          billing_city: "",
          billing_zip_code: "",
          billing_state: "",
          billing_country: "",
          website: "",
          currency: "USD",
          created_by: "",
          created_at: "",
          updated_at: "",
          deleted_at: "",
        },
      ],
      companyName: "",
    };
  }

  componentDidMount() {
    console.log("mounted");
    this.getInfo();
  }

  getInfo = async () => {
    const getContact = await fetch(
      api_url + "/" + this.props.category + "/" + this.props.params.id
    );
    const json = await getContact.json();
    this.setState({ contact: json });
    console.log(json);
  };

  render() {
    return (
      <div className="contactSummary">
        <p>primary_name: {this.state.contact[0]["primary_name"]}</p>
        <p>
          created_at:{" "}
          {new Date(
            this.state.contact[0]["created_at"].replace(" ", "T")
          ).toLocaleString()}
        </p>
        <p>
          updated_at:{" "}
          {new Date(
            this.state.contact[0]["updated_at"].replace(" ", "T")
          ).toLocaleString()}
        </p>
        <p>deleted_at: {this.state.contact[0]["deleted_at"]}</p>
      </div>
    );
  }
}
