import React from "react";

import "./ChartOfAccounts.css";
import ChartOfAcountsTabs from "./ChartOfAccountsTabs";
import { Link } from "react-router-dom";
import { api_url } from "../../../util/environment";
import { currentBusiness } from "../../../features/authentication/util";

export default class ChartOfAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: {
                show: false,
                type: null,
                id: null,
            },
            form: {
                name: "",
                account_number: "",
                description: "",
            },
            accounts: [],
            isLoaded: false,
            isError: false,
            error: null,
            account_id: null,
        };
        this.handleClosePopup = this.handleClosePopup.bind(this);
        this.handleOpenAdd = this.handleOpenAdd.bind(this);
        this.handleOpenPopup = this.handleOpenPopup.bind(this);
        this.handleOpenEdit = this.handleOpenEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        this.getAccounts();
    }

    getAccounts() {
        fetch(
            api_url + "/accounting/chart-of-accounts/user/" + currentBusiness()
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        accounts: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        isError: true,
                        error: error,
                    });
                }
            );
    }

    handleFormChange = (e, key) => {
        var field = this.state.form;
        field[key] = e.target.value;
        this.setState({ contact: field });
    };

    handleOpenPopup(e) {
        console.log("main add button");
        this.setState({
            popup: {
                show: true,
                type: "main",
                id: e.target.id,
            },
            form: {
                category: "",
                subcategory: "",
                type: "Checking/Savings",
                name: "",
                currency_id: 1,
                account_number: "",
                description: "",
                debit_credit: "debit",
                balance: 0,
            },
        });
    }

    handleOpenAdd(e) {
        console.log(e);
        console.log(e.target.getAttribute("data-category"));
        this.setState({
            popup: {
                show: true,
                type: "add",
                id: e.target.id,
            },
            form: {
                category: e.target.getAttribute("data-category"),
                subcategory: e.target.getAttribute("data-subcategory"),
                type: e.target.id,
                name: "",
                currency_id: 1,
                account_number: "",
                description: "",
                debit_credit: "debit",
                balance: 0,
            },
        });
    }

    handleOpenEdit(e, id, category, subcategory, type, name, num, desc) {
        this.setState({
            popup: {
                show: true,
                type: "edit",
                id: e.target.id,
            },
            form: {
                category: category,
                subcategory: subcategory,
                type: type,
                name: name,
                currency_id: 1,
                account_number: num,
                description: desc,
                debit_credit: "debit",
                balance: null,
            },
            account_id: id,
        });
    }

    handleClosePopup() {
        this.setState({
            popup: {
                show: false,
                id: null,
            },
        });
    }

    handleSave = async (e) => {
        console.log(this.state.popup.id);
        console.log(this.state.form);
        const response = await fetch(
            api_url + "/accounting/chart-of-accounts/user/" + currentBusiness(),
            {
                method: "POST",
                mode: "cors",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(this.state.form),
            }
        ).then((result) => {
            this.getAccounts();
            this.handleClosePopup();
        });
    };

    handleUpdate = async (e) => {
        console.log(this.state.account_id);
        const response = await fetch(
            api_url + "/accounting/chart-of-accounts/" + this.state.account_id,
            {
                method: "PUT",
                mode: "cors",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(this.state.form),
            }
        ).then((result) => {
            this.getAccounts();
            this.handleClosePopup();
        });
    };

    render() {
        return (
            <div className="chartOfAccounts">
                <div className="top-left">
                    <h1 className="pageTitle">Chart of Accounts</h1>
                </div>
                <div className="top-middle-left"></div>
                <div className="top-middle-right"></div>
                <div className="top-right">
                    <div>
                        <button
                            type="button"
                            className="btn btn-success"
                            style={{ marginTop: "10px" }}
                            onClick={this.handleOpenPopup}
                        >
                            Add a New Account
                        </button>
                    </div>
                </div>

                <ChartOfAcountsTabs
                    popup={this.state.popup}
                    handleClosePopup={this.handleClosePopup}
                    handleOpenAdd={this.handleOpenAdd}
                    handleOpenEdit={this.handleOpenEdit}
                    save={this.handleSave}
                    update={this.handleUpdate}
                    form={this.state.form}
                    onChange={this.handleFormChange}
                    accounts={this.state.accounts}
                />
            </div>
        );
    }
}
