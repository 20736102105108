import React from "react";

function ReportFooter(props) {
  return (
    <div style={{ display: "flex", marginTop: "auto" }}>
      <div style={{ marginLeft: "auto", fontWeight: "bold" }}>
        Page {props.pageNumber}
      </div>
    </div>
  );
}

export default ReportFooter;
