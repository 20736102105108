import React from "react";

import { NUMERIC } from "../../util/formatters";

function validateNumeric(
  event,
  validationString,
  current,
  maxLength,
  maxDecimals
) {
  const decimalPortion = current.split(".")[1];
  console.log(event.target.selectionStart);
  console.log(current.indexOf("."));

  const notNeccessaryKey =
    event.key != "Backspace" &&
    event.key != "Delete" &&
    event.key != "ArrowLeft" &&
    event.key != "ArrowRight" &&
    event.key != "Tab";

  // validate decimal portion doesn't exceed limit
  if (current.includes(".")) {
    //if typing in decimal part of number
    if (event.target.selectionStart > current.indexOf(".")) {
      console.log(decimalPortion);
      console.log(maxDecimals);
      if (decimalPortion.length >= maxDecimals) {
        if (notNeccessaryKey) {
          if (window.getSelection().type == "Caret") {
            console.log("PREVENT DEFUALT");
            event.preventDefault();
          }
        }
      }
    }
  }
  if (!validationString.includes(event.key)) {
    if (notNeccessaryKey) {
      event.preventDefault();
    }
  } else if (event.key == ".") {
    if (current.toString().includes(".")) {
      event.preventDefault();
    }
  }
  if (current.length >= maxLength) {
    if (notNeccessaryKey) {
      if (window.getSelection().type == "Caret") {
        event.preventDefault();
      }
    }
  }
}

function NumericInput(props) {
  return (
    <div>
      {props.label ? (
        <label htmlFor="Input" className="form-label">
          {props.label}
        </label>
      ) : null}
      <input
        id={props.id}
        type="text"
        className={"form-control " + props.className}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        onKeyDown={(e) => {
          if (props.value) {
            validateNumeric(
              e,
              NUMERIC,
              props.value,
              props.maxLength,
              props.maxDecimals
            );
          }
        }}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      {props.feedback}
    </div>
  );
}

export default NumericInput;
