import React, { useState } from "react";
import AlphanumericInput from "../../../components/Forms/documents/AlphanumericInput";
import NumericInput from "../../../components/Forms/documents/NumericInput";
import PriceInput from "../../../components/Forms/documents/PriceInput";
import {
  computeLineSubtotal,
  multiply,
  subtractMoney,
} from "../../../util/money";
import { toFloatg, toUSD } from "../../../util/formatters";
import ContentEditable from "react-contenteditable";
import { IoTrashOutline } from "react-icons/io5";
import SearchableDropdownWithCreate from "../../../components/Forms/SearchableDropdownWithCreate";
import { toPercentage } from "../../../util/formatters";
import { getProductPricing } from "../../Site/api";
import CreateTaxPopup from "./CreateTaxPopup";

function EstimateBody(props) {
  const [showLineTaxMenu, setShowLineTaxMenu] = useState(false);
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [showTaxPopup, setShowTaxPopup] = useState(false);

  const [idCounter, setIdCounter] = useState(-2);
  React.useEffect(() => {
    return () => {
      console.log("UNMOUNTED");
    };
  }, []);

  const createTax = (e) => {
    const index = 0;
    console.log("Creating Tax: ", e);
    var taxes = props.getters[3];
    props.createTax(e);
    e.percentage = toFloatg(e.percentage / 100, "discount");
    taxes[index].push(e);
    props.setters[3](JSON.parse(JSON.stringify(taxes)));
  };

  return (
    <table>
      <thead>
        <tr style={{ borderBottom: "3px solid #000" }}>
          <th width="25%">Item</th>
          <th style={{ width: "15%", textAlign: "right" }}>Quantity</th>
          <th style={{ width: "25%", textAlign: "right" }}>Price</th>
          <th style={{ width: "20%", textAlign: "right" }}>Line Total</th>
          <th style={{ width: "0%" }}></th>
        </tr>
      </thead>

      {props.slicedItems.map((item, index) => {
        index = index + parseInt(props.indexOffset);

        const taxGetter = props.getters[3][index] || [];
        return (
          <tbody
            key={"line-item-" + item.id}
            style={{ borderBottom: "1px solid #000" }}
            className="hoverableRow"
          >
            <tr
              style={{
                height: "1.5em",
                textAlign: "right",
              }}
              key={"line-item-" + item.id}
            >
              <td style={{ textAlign: "left" }}>
                <AlphanumericInput
                  initialValue={props.getters[0][index].item_name}
                  changeText={(e) => {
                    var arr = props.getters[0];
                    arr[index].item_name = e;
                    props.setters[0](JSON.parse(JSON.stringify(arr)));
                  }}
                />
              </td>
              <td>
                <NumericInput
                  limit={9}
                  initialValue={props.getters[0][index].quantity}
                  decimalLimit={2}
                  changeText={(e) => {
                    var arr = props.getters[0];
                    arr[index].quantity = e;

                    const correctPricing = props.getters[5][index].filter(
                      (pricing) =>
                        parseInt(pricing.starting_quantity) <= e &&
                        (parseInt(pricing.ending_quantity) >= e ||
                          pricing.ending_quantity == null)
                    );
                    if (correctPricing[0]) {
                      arr[index].price = correctPricing[0].price;
                    }

                    arr[index].line_total = computeLineSubtotal(
                      props.getters[0][index].quantity,
                      props.getters[0][index].price
                    ).toString();

                    if (item.discount) {
                      arr[index].line_total = subtractMoney(
                        arr[index].line_total,
                        item.discount
                      );
                    }
                    props.setters[0](JSON.parse(JSON.stringify(arr)));
                  }}
                />
              </td>
              <td>
                <PriceInput
                  initialValue={props.getters[0][index].price}
                  limit={15}
                  decimalLimit={2}
                  changeText={(e) => {
                    var arr = props.getters[0];
                    arr[index].price = e;

                    arr[index].line_total = computeLineSubtotal(
                      props.getters[0][index].quantity,
                      props.getters[0][index].price
                    ).toString();
                    if (item.discount) {
                      arr[index].line_total = subtractMoney(
                        arr[index].line_total,
                        item.discount
                      );
                    }
                    props.setters[0](JSON.parse(JSON.stringify(arr)));
                  }}
                />
              </td>
              {item.discount ? (
                <td>
                  <div style={{ marginRight: "60px" }}>
                    {toUSD(
                      computeLineSubtotal(
                        props.getters[0][index].quantity,
                        props.getters[0][index].price
                      ).toString(),
                      "total"
                    )}
                  </div>
                </td>
              ) : (
                <td>
                  {toUSD(
                    computeLineSubtotal(
                      props.getters[0][index].quantity,
                      props.getters[0][index].price
                    ).toString(),
                    "total"
                  )}
                </td>
              )}

              <td
                className="rowDelete"
                style={{ opacity: "0", position: "absolute" }}
                onClick={() => {
                  props.deleteLineItem(index);
                }}
              >
                <IoTrashOutline />
              </td>
            </tr>{" "}
            <tr>
              <td>
                <div className="notesSection" style={{ marginLeft: "30px" }}>
                  <AlphanumericInput
                    initialValue={props.getters[0][index].notes}
                    changeText={(e) => {
                      var arr = props.getters[0];
                      arr[index].notes = e;
                      props.setters[0](JSON.parse(JSON.stringify(arr)));
                    }}
                  />
                </div>
              </td>
              <td></td>
              <td colSpan={2}>
                {item.discount != null ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <div
                        className="taxRow"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "55.5%",
                        }}
                      >
                        <div className="itemTax" style={{ display: "flex" }}>
                          <IoTrashOutline
                            style={{
                              marginLeft: "0px",
                              marginRight: "0px",
                            }}
                            className="taxDelete"
                            onClick={() => {
                              var items = props.getters[0];
                              items[index].discount = null;
                              props.setters[0](
                                JSON.parse(JSON.stringify(items))
                              );
                            }}
                          />
                          <div
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Discount
                          </div>{" "}
                        </div>

                        <div
                          style={{
                            textAlign: "right",
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "44.4%",
                          borderBottom: "3px solid black",
                        }}
                      >
                        <span
                          className="taxSubtotal"
                          style={{
                            textAlign: "right",

                            marginRight: "60px",
                          }}
                        >
                          <PriceInput
                            initialValue={props.getters[0][index].discount}
                            limit={15}
                            decimalLimit={2}
                            negative={true}
                            changeText={(e) => {
                              var arr = props.getters[0];
                              arr[index].discount = e;
                              arr[index].line_total = subtractMoney(
                                computeLineSubtotal(item.quantity, item.price),
                                item.discount
                              );
                              props.setters[0](JSON.parse(JSON.stringify(arr)));
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div>{toUSD(item.line_total, "total")}</div>
                    </div>
                  </>
                ) : null}
                {taxGetter.map((subitem, j) => {
                  return (
                    <div style={{ display: "flex" }}>
                      <div
                        className="taxRow"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "55.5%",
                        }}
                        key={subitem.id}
                      >
                        <div className="itemTax" style={{ display: "flex" }}>
                          <IoTrashOutline
                            style={{
                              marginLeft: "0px",
                              marginRight: "0px",
                            }}
                            className="taxDelete"
                            onClick={() => {
                              var currentTaxList = props.getters[3];
                              console.log(currentTaxList);
                              currentTaxList[index].splice(j, 1);
                              props.setters[3](
                                JSON.parse(JSON.stringify(currentTaxList))
                              );
                            }}
                          />
                          <div
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {subitem.name}{" "}
                          </div>{" "}
                        </div>

                        <div
                          style={{
                            width: "17%",
                            textAlign: "right",
                          }}
                        >
                          {toPercentage(subitem.percentage, "tax")}
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "44.4%" }}>
                        <span
                          className="taxSubtotal"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {toUSD(
                            multiply(item.line_total, subitem.percentage),
                            "total"
                          )}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <button
                  className="discountOrTaxButton"
                  data-html2canvas-ignore="true"
                  onClick={() => {
                    setShowLineTaxMenu(index);
                    document.getElementById("search-bar-" + index).focus();
                  }}
                >
                  {" "}
                  Add Tax/Discount
                </button>
                {console.log(showLineTaxMenu)}
                {showLineTaxMenu === index ? (
                  <td style={{ position: "absolute" }}>
                    <div
                      onBlur={() => {
                        setTimeout(() => {
                          setShowLineTaxMenu(false);
                        }, 200);
                      }}
                      disabled={true}
                    >
                      <SearchableDropdownWithCreate
                        searchBarID={"search-bar-" + index}
                        list={[
                          ...props.getters[2].map((a) => [
                            a.name + " ",
                            toPercentage(a.percentage, "tax"),
                            a.id,
                          ]),
                        ]}
                        functions={[
                          () => setShowTaxPopup(true),
                          () => {
                            var items = props.getters[0];
                            items[index].discount = "0.00";
                            props.setters[0](JSON.parse(JSON.stringify(items)));
                          },
                        ]}
                        onItemClick={(e) => {
                          var taxes = props.getters[3];
                          taxes[index].push(props.getters[2][e]);
                          props.setters[3](JSON.parse(JSON.stringify(taxes)));
                        }}
                        buttonList={[
                          "Create a new tax",
                          "Add a discount (fixed)",
                        ]}
                      />
                    </div>
                  </td>
                ) : (
                  <td
                    style={{
                      position: "absolute",
                      zIndex: "-100",
                      opacity: "0",
                    }}
                  >
                    <div
                      onBlur={() => {
                        setTimeout(() => {
                          setShowLineTaxMenu(false);
                        }, 2000);
                      }}
                      disabled={true}
                    >
                      <SearchableDropdownWithCreate
                        searchBarID={"search-bar-" + index}
                        list={["test", "test2"]}
                        functions={[
                          () => console.log("first"),
                          () => console.log("second"),
                          () => console.log("third"),
                        ]}
                      />
                    </div>
                  </td>
                )}
              </td>
            </tr>
          </tbody>
        );
      })}
      {props.endIndex == null && props.indexOffset != null ? (
        <div
          className="addItemRow"
          style={{ position: "absolute", width: "5em" }}
        >
          <p
            className="discountOrTaxButton"
            data-html2canvas-ignore="true"
            onClick={() => {
              setShowProductMenu(true);
              document.getElementById("search-bar-product").focus();
            }}
          >
            Add an Item
          </p>
          {showProductMenu ? (
            <td style={{ position: "absolute" }}>
              <div
                onBlur={() => {
                  setTimeout(() => {
                    setShowProductMenu(false);
                  }, 200);
                }}
                disabled={true}
              >
                <SearchableDropdownWithCreate
                  searchBarID={"search-bar-product"}
                  list={[
                    ...props.getters[4].map((a) => [
                      a.name + " ",
                      a.code_number,
                      a.id,
                    ]),
                  ]}
                  functions={[props.addLineItem]}
                  onItemClick={(e) => {
                    console.log(props.getters[4][e]);
                    var form = props.getters[0];
                    const product_id = props.getters[4][e].id;
                    getProductPricing(product_id).then((data) => {
                      console.log(data);
                      var oldList = props.getters[5];
                      oldList.push(data);
                      props.setters[5](oldList);
                      console.log(
                        data.filter((pricing) => pricing.starting_quantity == 1)
                      );
                      form.push({
                        id: idCounter, //negative id to denote new item - starts counting from -1 if starting from empty
                        item_name: props.getters[4][e].name,
                        quantity: "1",
                        price: data.filter(
                          (pricing) => pricing.starting_quantity == 1
                        )[0].price,
                        discount: null,
                        tax: "0.0",
                        line_total: data.filter(
                          (pricing) => pricing.starting_quantity == 1
                        )[0].price,
                        notes: "notes",
                        page_order: props.getters[0].length,
                        invoice_id: "",
                        products_and_services_id: product_id,
                      });
                      props.setters[0](JSON.parse(JSON.stringify(form)));
                      //add empty array for taxes
                      var oldTaxes = props.getters[3];
                      oldTaxes.push([]);
                      props.setters[3](oldTaxes);

                      setIdCounter(idCounter - 1);
                    });
                  }}
                  buttonList={["Create a new Product"]}
                />
              </div>
            </td>
          ) : (
            <td
              style={{
                position: "absolute",
                zIndex: "-100",
                opacity: "0",
              }}
            >
              <div
                onBlur={() => {
                  setTimeout(() => {
                    setShowLineTaxMenu(false);
                  }, 2000);
                }}
                disabled={true}
              >
                <SearchableDropdownWithCreate
                  searchBarID={"search-bar-product"}
                  list={["test", "test2"]}
                  functions={[
                    () => console.log("first"),
                    () => console.log("second"),
                    () => console.log("third"),
                  ]}
                />
              </div>
            </td>
          )}
        </div>
      ) : null}

      <CreateTaxPopup
        taxes={props.getters[2]}
        show={showTaxPopup}
        setShow={setShowTaxPopup}
        onSubmit={(e) => createTax(e)}
      />
    </table>
  );
}
export default EstimateBody;
