import React from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";

function EditIcon(props) {
    return (
        <>
            {props.disabled ? (
                <div
                    className="editIcon"
                    style={{
                        opacity: "0.5",
                        cursor: "not-allowed",
                    }}
                >
                    <MdOutlineModeEditOutline />
                </div>
            ) : (
                <div className="editIcon" onClick={() => props.onClick()}>
                    <MdOutlineModeEditOutline />
                </div>
            )}
        </>
    );
}

export default EditIcon;
