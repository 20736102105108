import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DateSelector from "../../../components/Forms/DateSelector";
import Input from "../../../components/Forms/Input";
import { toUSD } from "../../../util/formatters";
import { subtractMoney } from "../../../util/money";
import JournalItem from "./JournalItem";
import "./JournalTransaction.css";

import {
  deleteJournalEntry,
  deleteTransaction,
  getAccounts,
  getTransaction,
  getTransactionJournalEntries,
  postJournalEntry,
  postTransaction,
  putJournalEntry,
  putTransaction,
} from "../api";
import { findIndex, isEqual } from "../../../util/jsonUtil";

import { formatDate } from "../../../util/dates";
import { IoTrashOutline } from "react-icons/io5";
import DeleteConfirmation from "../../../components/Popups/DeleteConfirmation";
import DateInput from "../../../components/Forms/documents/DateInput";
import AlphanumericInput from "../../../components/Forms/documents/AlphanumericInput";
import TextArea from "../../../components/Forms/TextArea";
import useAccounts from "../../../hooks/useAccounts";

function JournalTransaction(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState(null);
  const [notes, setNotes] = useState(null);
  const [entries, setEntries] = useState([]);
  const [entriesOrig, setEntriesOrig] = useState([]);
  const [reviewed, setReviewed] = useState(false);

  const [popup, setPopup] = useState({ show: false, id: null });

  // const [accounts, setAccounts] = useState(null);
  const [uncategorizedExpenseIndex, setUncategorizedExpenseIndex] =
    useState(null);

  const { loading, error, accounts, accountsByCategory } = useAccounts(true);

  useEffect(() => {
    if (id) {
      console.log(";edit");
      getTransaction(id).then((data) => {
        setDate(new Date(data[0].date));
        setDescription(data[0].description);
        setNotes(data[0].notes);
        setReviewed(data[0].reviewed);
      });
      getAccounts().then((data) => {
        // setAccounts(data);
        getTransactionJournalEntries(id).then((transactionData) => {
          for (var i in transactionData) {
            var listIndex = findIndex(
              data,
              "id",
              transactionData[i].account_id
            );
            transactionData[i].account = data.find(
              (x) => x.id === transactionData[i].account_id
            );
          }
          var list = transactionData.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          setEntries(list);
          setEntriesOrig(JSON.parse(JSON.stringify(list)));
        });
      });
    } else {
      console.log("add");
      getAccounts().then((data) => {
        // setAccounts(data);
        // const expenseIndex = findIndex(data, "name", "Uncategorized Expense");
        // setUncategorizedExpenseIndex(expenseIndex);
        // console.log(expenseIndex);
        // const incomeIndex = findIndex(data, "name", "Uncategorized Income");
        // console.log(incomeIndex);
        setEntries([
          {
            description: "Write a Description",
            account_id: null,
            transaction_id: null,
            account: null,
            debit: "0.00",
            credit: null,
            tax_id: null,
            customer_id: null,
            vendor_id: null,
            document_id: null,
          },
          {
            description: "Write a Description",
            account_id: null,
            transaction_id: null,
            account: null,
            debit: null,
            credit: "0.00",
            tax_id: null,
            customer_id: null,
            vendor_id: null,
            document_id: null,
          },
        ]);
      });
    }
  }, []);

  const creditSum = () => {
    var sum = 0;
    for (let i in entries) {
      if (entries[i].credit) {
        sum += parseFloat(entries[i].credit);
      }
    }
    return sum;
  };

  const debitSum = () => {
    var sum = 0;
    for (let i in entries) {
      if (entries[i].debit) {
        sum += parseFloat(entries[i].debit);
      }
    }
    return sum;
  };

  const save = async () => {
    if (toUSD(creditSum(), "total") === toUSD(debitSum(), "total")) {
      var transactionBody = {
        date: formatDate(date),
        description: description,
        account_id: null,
        category: "Journal Entry",
        amount: debitSum(),
        notes: notes,
        reviewed: reviewed,
        document_id: null,
      };
      console.log(transactionBody);
      if (id) {
        putTransaction(transactionBody, id);

        for (let i in entries) {
          if (!entries[i].id) {
            var body = entries[i];
            console.log(body);
            console.log(body["account"]);
            body["account_id"] = body["account"].id;
            console.log(accounts[parseInt(body["account"])].id);
            delete body["account"];
            delete body["checked"];
            body["transaction_id"] = id;
            console.log("POST");
            await postJournalEntry(entries[i]);
          }
        }

        var found_i = false;
        var found_index = null;
        console.log(entries);
        console.log(entriesOrig);
        for (let i in entriesOrig) {
          for (let j in entries) {
            if (entriesOrig[i].id == entries[j].id) {
              found_i = true;
              found_index = j;
            }
          }
          if (found_i) {
            if (!isEqual(entriesOrig[i], entries[found_index])) {
              var lineItemBody = entries[found_index];
              const lineItemId = entries[found_index].id;
              if (accounts[parseInt(lineItemBody["account"])]) {
                lineItemBody["account_id"] =
                  accounts[parseInt(lineItemBody["account"])].id;
              }

              delete lineItemBody["id"];
              delete lineItemBody["account"];
              delete lineItemBody["checked"];
              console.log("PUT");
              putJournalEntry(lineItemBody, lineItemId);
            }
          } else {
            console.log("DELETE" + entriesOrig[i].id);
            deleteJournalEntry(entriesOrig[i].id);
          }
          found_i = false; //reset boolean
          found_index = null;
        }
      } else {
        postTransaction(transactionBody).then(async (data) => {
          const transactionId = data.rows[0].id;
          for (let i in entries) {
            var journalEntryBody = entries[i];
            journalEntryBody["transaction_id"] = transactionId;
            journalEntryBody["account_id"] = journalEntryBody["account"].id;
            delete journalEntryBody["account"];
            console.log(journalEntryBody);
            await postJournalEntry(journalEntryBody);
          }
        });
      }
    } else {
      console.log("ensure debits equal credits");
    }
  };

  var accountList = null;
  if (accounts) {
    accountList = [...accounts.map((a) => [a.name])];
  }

  return (
    <div className="journalPage">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <h1>Journal Entry</h1>
        <div>
          {props.editable ? (
            <>
              <button
                type="button"
                className="btn btn-success"
                style={{ marginRight: "10px" }}
                onClick={() =>
                  save().then(() => {
                    if (
                      toUSD(creditSum(), "total") === toUSD(debitSum(), "total")
                    ) {
                      navigate(-1);
                    }
                  })
                }
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-light"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-light"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  console.log("review");
                }}
              >
                Mark as reviewed
              </button>
              <IoTrashOutline
                className="deleteJournalTransaction"
                style={{
                  fontSize: "30px",
                  color: "#d11a2a",
                }}
                onClick={() => {
                  setPopup({ show: true, id: id });
                }}
              />
            </>
          ) : (
            <button
              type="button"
              className="btn btn-light"
              style={{ marginRight: "10px" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          )}
        </div>
      </div>
      {/* <div style={{ display: "flex", marginBottom: "30px" }}>
        {" "}
        <div style={{ marginRight: "30px" }}>
          Date
          <DateSelector date={date} setDate={setDate} />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "400px" }}
        >
          <label>Description</label>
          <input
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></input>
        </div>
      </div> */}
      <div className="journalTable" style={{ marginBottom: "30px" }}>
        <table width="100%" style={{ "table-layout": "fixed" }}>
          <thead>
            <tr style={{ borderBottom: "1px solid black" }}>
              <th>Date</th>
              <th>Description</th>
              <th style={{ textAlign: "right" }}>Debit</th>
              <th style={{ textAlign: "right" }}>Credit</th>
              <th style={{ width: "30px" }}></th>
            </tr>
          </thead>
          <tbody>
            {props.editable ? (
              <tr className="journalRow">
                <td>
                  <DateInput
                    initialValue={formatDate(date)}
                    changeText={(e) => setDate(e)}
                  />
                </td>
                <td>
                  <AlphanumericInput
                    initialValue={description}
                    placeholder={"write a description"}
                    changeText={(e) => setDescription(e)}
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              <tr>
                <td style={{ fontWeight: "bold" }}>{formatDate(date)}</td>
                <td style={{ fontWeight: "bold" }}>{description}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}

            {entries.map((item, index) => {
              return (
                <JournalItem
                  info={item}
                  setInfo={(key, value) => {
                    var list = entries;
                    list[index][key] = value;
                    setEntries(JSON.parse(JSON.stringify(list)));
                  }}
                  showDelete={entries.length > 2}
                  onDelete={() => {
                    var list = entries;
                    list.splice(index, 1);
                    setEntries(JSON.parse(JSON.stringify(list)));
                  }}
                  accounts={accountsByCategory}
                  index={index}
                  editable={props.editable}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
          {props.editable ? (
            <div
              className="discountOrTaxButton"
              onClick={() => {
                var list = entries;
                list.push({
                  description: "Write a Description",
                  account_id: null,
                  transaction_id: null,
                  account: uncategorizedExpenseIndex,
                  debit: "0.00",
                  credit: null,
                  tax_id: null,
                  customer_id: null,
                  vendor_id: null,
                  document_id: null,
                });
                setEntries(JSON.parse(JSON.stringify(list)));
              }}
            >
              Add Line
            </div>
          ) : null}

          <TextArea
            height="100%"
            placeholder="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            disabled={!props.editable}
          ></TextArea>
        </div>

        <div
          className="journalTotalBox"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div style={{ margin: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              {" "}
              <div>Total debits</div>
              <div>Total credits</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                {toUSD(debitSum(), "total")}
              </div>
              <div>
                {" "}
                {toUSD(creditSum(), "total") === toUSD(debitSum(), "total")
                  ? "="
                  : "\u2260"}
              </div>
              <div style={{ fontWeight: "bold" }}>
                {toUSD(creditSum(), "total")}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Difference:</div>
              {toUSD(creditSum(), "total") === toUSD(debitSum(), "total") ? (
                <div
                  style={{ fontWeight: "bold", color: "rgb(035, 199, 112)" }}
                >
                  {toUSD(
                    Math.abs(
                      parseFloat(subtractMoney(debitSum(), creditSum()))
                    ),
                    "total"
                  )}
                </div>
              ) : (
                <div
                  style={{ fontWeight: "bold", color: "rgb(196, 132, 057)" }}
                >
                  {toUSD(
                    Math.abs(
                      parseFloat(subtractMoney(debitSum(), creditSum()))
                    ),
                    "total"
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteConfirmation
        popup={popup}
        handleClose={() => setPopup({ show: false, id: null })}
        handleDelete={() => deleteTransaction(id).then(() => navigate(-1))}
      />
    </div>
  );
}

export default JournalTransaction;
