import React, { useState, useEffect, useRef, createRef } from "react";
import { Modal, Button } from "react-bootstrap";
import DateSelector from "../../../../components/Forms/DateSelector";
import Input from "../../../../components/Forms/Input";
import SearchableWithButton from "../../../../components/Forms/SearchableWithButton";
import Dropdown from "../../../../components/Forms/Dropdown";
import CurrencyInput from "../../../../components/Forms/CurrencyInput";
import TextArea from "../../../../components/Forms/TextArea";

import { MdOutlineCheckCircleOutline } from "react-icons/md";
import UnderlineButton from "../../../../components/Buttons/UnderlineButton";
import { getCustomers } from "../../../Sales/api";
import { getVendors } from "../../../Purchases/api";
import SearchableWithUnderlineButton from "../../../../components/Forms/SearchableWithUnderlineButton";
import { getTaxes } from "../../../Site/api";
import { toPercentage, toUSD } from "../../../../util/formatters";
import WarningMessage from "../../../../components/Messages/WarningMessage";
import SuccessMessage from "../../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import InfoMessage from "../../../../components/Messages/InfoMessage";
import OutlineButton from "../../../../components/Buttons/OutlineButton";
import DeleteIcon from "../../../../components/Icons/DeleteIcon";
import { addMoney, subtractMoney, sumMoney } from "../../../../util/money";
import {
  deleteJournalEntry,
  getAccounts,
  getTransactionJournalEntries,
  postJournalEntry,
  postTransaction,
  putJournalEntry,
  putTransaction,
} from "../../api";
import { getDate } from "../../../../util/dates";
import { findIndex } from "../../../../util/jsonUtil";
import { debitShouldAdd, isCategory } from "../../../../util/accounting";
import { updateItem } from "../../../../util/forms";

import { CSSTransition } from "react-transition-group";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import SearchableModal from "../../../../components/Forms/SearchableModal";
import useAccounts from "../../../../hooks/useAccounts";
import useInvoices from "../../../../hooks/useInvoices";
import useBills from "../../../../hooks/useBills";

function EditTransactionPopup(props) {
  const nodeRef = useRef(null);
  var nodeRefs = useRef([]);

  const swapButtonRef = useRef(null);

  const { loading, error, accounts, accountsByCategory, accountsByType } =
    useAccounts(true, [
      "Checking/Savings",
      "Money in Transit",
      "Accounts Payable",
      "Long Term Liabilities",
      "Accounts Receivable",
    ]);
  const { loadingInvoices, errorInvoices, invoices } = useInvoices();
  const { loadingBills, errorBills, bills } = useBills();

  const [startDate, setStartDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [account, setAccount] = useState(null);
  const [accountOneList, setAccountOneList] = useState([]);
  const [accountTwoList, setAccountTwoList] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [cashAccounts, setCashAccounts] = useState([]);
  const [categoryAccounts, setCategoryAccounts] = useState([]);
  const [amount, setAmount] = useState("0.00");
  const [type, setType] = useState(null);
  const [taxes, setTaxes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [notes, setNotes] = useState("");
  const [previousNotes, setPreviousNotes] = useState("");
  const [reviewed, setReviewed] = useState(false);

  const [category, setCategory] = useState({
    id: 10,
    name: "General Transaction",
    account1: null,
    account2: null,
  });
  const categoryListInit = {
    Bills: [],
    // Equipment: [
    //   {
    //     id: 1,
    //     name: "Purchase Equipment using Cash",
    //     account1: 12,
    //     account2: 3,
    //   },
    // ],
    // Inventory: [
    //   {
    //     id: 1,
    //     name: "Pay Vendor for Inventory Purchase",
    //     account1: 16,
    //     account2: 3,
    //   },
    // ],
    Expenses: [
      {
        id: 1,
        name: "Pay Vendor for Expense",
        account1: 16,
        account2: 3,
        accountOneList: ["Accounts Payable"],
        accountTwoList: ["Checking/Savings", "Money in Transit"],
      },
      {
        id: 1,
        name: "Recognize Expense",
        account1: 72,
        account2: 16,
        accountOneList: ["Expenses"],
        accountTwoList: ["Accounts Payable"],
      },
    ],
    Financial: [
      {
        id: 1,
        name: "Borrowing Cash",
        account1: 3, //Petty Cash
        account2: 30, //Notes Payable
        accountOneList: ["Checking/Savings", "Money in Transit"],
        accountTwoList: ["Long Term Liabilities"],
      },
    ],
    Invoices: [],
    Revenue: [
      {
        id: 1,
        name: "Receive Payment from Customer",
        account1: 3,
        account2: 6,
        accountOneList: ["Checking/Savings", "Money in Transit"],
        accountTwoList: ["Accounts Receivable"],
      },
      {
        id: 2,
        name: "Recognize Revenue from Customer",
        account1: 6,
        account2: 37,
        accountOneList: ["Accounts Receivable"],
        accountTwoList: ["Income"],
      },
    ],
    Other: [
      {
        id: 10,
        name: "General Transaction",
        account1: null,
        account2: null,
      },
    ],
  };
  const [categoryList, setCategoryList] = useState(categoryListInit);

  const [categoryAccountList, setCategoryAccountList] = useState([]);

  const [valid, setValid] = useState(false);

  const [transactionSplits, setTransactionSplits] = useState([
    {
      amount: "0.00",
      category: null,
      tax: null,
      customer: null,
      vendor: null,
      document_id: null,
      showCustomer: false,
      showVendor: false,
    },
  ]);

  const [journalEntriesOrig, setJournalEntriesOrig] = useState([]);

  const [warningMessage, setWarningMessage] = useState(null);

  useEffect(() => {
    getCustomers().then((data) => {
      console.log(data);
      setCustomers(data);
    });
    getVendors().then((data) => {
      console.log(data);
      setVendors(data);
    });
    getTaxes().then((data) => {
      console.log(data);
      setTaxes(data);
    });
    getAccounts().then((data) => {
      console.log(data);
      setAllAccounts(data);
      setCashAccounts(data);
      setCategoryAccounts(data);
      setCategoryAccountList([...data.map((a) => [a.name])]);
    });
  }, []);

  useEffect(() => {
    if (invoices && categoryList.Invoices.length == 0) {
      console.log(invoices);
      var newCategoryList = categoryList;
      newCategoryList["Invoices"] = invoices
        .filter(
          (i) =>
            i.status != "paid" &&
            parseFloat(i.amount_due) > parseFloat(i.amount_paid)
        )
        .map((i) => {
          return {
            id: i.id,
            name:
              "Invoice #" +
              i.invoice_number +
              " | Payment from " +
              i.to_name +
              " | " +
              toUSD(subtractMoney(i.amount_due, i.amount_paid), "total") +
              " Outstanding",
            account1: 3,
            account2: 6,
            document_id: i.id,
            accountOneList: ["Checking/Savings", "Money in Transit"],
            accountTwoList: ["Accounts Receivable"],
          };
        });

      console.log(newCategoryList);
      setCategoryList(newCategoryList);
    }
    if (bills && categoryList.Bills.length == 0) {
      console.log(bills);
      var newCategoryList = categoryList;
      newCategoryList["Bills"] = bills
        .filter(
          (i) =>
            i.status != "paid" &&
            parseFloat(i.amount_due) > parseFloat(i.amount_paid)
        )
        .map((i) => {
          return {
            id: i.id,
            name:
              "Bill #" +
              i.invoice_number +
              " | Payment to " +
              i.from_name +
              " | " +
              toUSD(subtractMoney(i.amount_due, i.amount_paid), "total") +
              " Outstanding",
            account1: 16,
            account2: 3,
            document_id: i.id,
            accountOneList: ["Accounts Payable"],
            accountTwoList: ["Checking/Savings", "Money in Transit"],
          };
        });
      console.log(newCategoryList);
      setCategoryList(newCategoryList);
    }
  }, [invoices, bills]);

  useEffect(() => {
    setAccountOneList(accountsByCategory);
    setAccountTwoList(accountsByCategory);
    console.log(accountsByCategory);
  }, [accountsByCategory]);

  // useEffect(() => {}, [accountsByType]);

  useEffect(() => {
    var difference = subtractMoney(
      amount,
      sumMoney(transactionSplits.map((split) => split.amount))
    );
    // if (transactionSplits.length > 0) {
    //   let valid = true;
    //   transactionSplits.forEach((split) => {
    //     if (parseFloat(split.amount) == 0) valid = false;
    //   });
    //   if (valid) {
    //     console.log(warningMessage);
    //     if (warningMessage) {
    //       if (
    //         warningMessage.includes("Amount") ||
    //         warningMessage.includes("Category")
    //       ) {
    //         setWarningMessage(null);
    //       }
    //     }
    //   }
    // }
    if (difference == 0) {
      // if (warningMessage) {
      //   if (warningMessage.includes("The total of the split amounts is")) {
      //     setWarningMessage(null);
      //   }
      // }
      setWarningMessage(null);
    } else if (difference > 0) {
      setWarningMessage(
        <>
          The total of the split amounts is <b>{toUSD(difference, "total")}</b>{" "}
          less than the original transaction. The total must equal{" "}
          {toUSD(amount, "total")}
        </>
      );
    } else if (difference < 0) {
      setWarningMessage(
        <>
          The total of the split amounts is{" "}
          <b>{toUSD(Math.abs(difference), "total")}</b> more than the original
          transaction. The total must equal {toUSD(amount, "total")}
        </>
      );
    }

    // set the first split back to the original amount
    if (transactionSplits.length == 1) {
      let newTransactionSplits = [...transactionSplits];
      if (newTransactionSplits[0].amount != amount) {
        newTransactionSplits[0].amount = amount;
        setTransactionSplits(newTransactionSplits);
      }
    }

    nodeRefs.current = transactionSplits.map(
      (_, i) => nodeRefs.current[i] ?? createRef()
    );
  }, [transactionSplits]);

  useEffect(() => {
    //set first index of transactionsSplit to the amount of the original transaction
    if (transactionSplits.length == 1) {
      let newTransactionSplits = [...transactionSplits];
      newTransactionSplits[0].amount = amount;
      setTransactionSplits(newTransactionSplits);
    }
  }, [amount]);

  useEffect(() => {
    console.log(category.account1);
    console.log(category.account2);
    if (accounts) {
      setAccount(accounts.find((a) => a.id == category.account1));
      //update transactionSplits and set the category of the zeroth index to category.account2
      let splits = [...transactionSplits];
      splits[0].category = accounts.find((a) => a.id == category.account2);
      setTransactionSplits(splits);
    }

    // Set document id to track payment of invoices and bills
    if (category.document_id) {
      // set document_id of all transactionSplits
      let splits = [...transactionSplits];
      for (let i in splits) {
        splits[i].document_id = category.document_id;
      }
      setTransactionSplits(splits);
    }

    // set lists of accounts for account1 and account2+
    if (category.accountOneList && accountsByType) {
      var accountObj = {};
      category.accountOneList.forEach((a) => {
        if (isCategory(a)) {
          accountObj[a] = accountsByCategory[a];
        } else {
          accountObj[a] = accountsByType[a];
        }
      });
      setAccountOneList(accountObj);
    } else {
      if (accountsByCategory) {
        setAccountOneList(accountsByCategory);
      }
    }
    if (category.accountTwoList && accountsByType) {
      var accountObj = {};
      category.accountTwoList.forEach((a) => {
        if (isCategory(a)) {
          accountObj[a] = accountsByCategory[a];
        } else {
          accountObj[a] = accountsByType[a];
        }
      });
      setAccountTwoList(accountObj);
    } else {
      if (accountsByCategory) {
        setAccountTwoList(accountsByCategory);
      }
    }
  }, [category]);

  var customerList = null;
  if (customers && customers != []) {
    customerList = [...customers.map((a) => [a.primary_name])];
  }
  var vendorList = null;
  if (vendors && vendors != []) {
    vendorList = [...vendors.map((a) => [a.primary_name])];
  }
  var taxList = null;
  if (taxes && taxes != []) {
    taxList = [
      ...taxes.map((a) => [
        a.name,
        toPercentage(a.percentage, "tax") +
          ": " +
          toUSD(amount * parseFloat(a.percentage), "total"),
      ]),
    ];
  }

  var splitTaxList = [];
  if (taxes && taxes != []) {
    for (var i = 0; i < transactionSplits.length; i++) {
      console.log(transactionSplits[i]);
      splitTaxList[i] = [
        ...taxes.map((a) => [
          a.name,
          toPercentage(a.percentage, "tax") +
            ": " +
            toUSD(
              transactionSplits[i].amount * parseFloat(a.percentage),
              "total"
            ),
        ]),
      ];
    }
    console.log(splitTaxList);
  }

  var cashAccountList = null;
  if (cashAccounts && cashAccounts != []) {
    cashAccountList = [...cashAccounts.map((a) => [a.name])];
  }

  useEffect(() => {
    if (props.popup.show) {
      if (props.popup.type == "Income") {
        setType("Deposit");
      } else if (props.popup.type == "Expense") {
        setType("Withdrawal");
      } else {
        console.error(
          "Invalid transaction type for component EditTransactionPopup: " +
            props.popup.type
        );
      }
    }

    // Set variables for transaction edit
    let transaction = props.popup.transaction;
    if (transaction) {
      setStartDate(new Date(transaction.date));
      setDescription(transaction.description);
      setAccount(accounts.find((a) => a.id == transaction.account_id));
      setAmount(transaction.amount);
      setPreviousNotes(transaction.notes);
      setReviewed(transaction.reviewed);

      // // set the category in the first transactionSplit
      let newTransactionSplits = [];
      // newTransactionSplits[0].category = findIndex(
      //   categoryAccounts,
      //   "name",
      //   transaction.category
      // );
      // set the rest of the transactionSplits
      getTransactionJournalEntries(transaction.id).then((data) => {
        console.log(data);
        setJournalEntriesOrig(data);
        for (let i = 0; i < data.length; i++) {
          let split = data[i];
          if (split.document_id != null) {
            if (category.document_id != split.document_id) {
              var found = null;
              found = categoryList.Invoices.find(
                (a) => a.document_id == split.document_id
              );
              if (!found) {
                found = categoryList.Bills.find(
                  (a) => a.document_id == split.document_id
                );
              }
              if (found) {
                setCategory(found);
              }
            }
          }
          console.log(split);
          console.log(transaction);
          if (split.account_id != transaction.account_id) {
            console.log(split);
            let newSplit = {
              id: split.id,
              amount: split.debit || split.credit,
              category: accounts.find((a) => a.id == split.account_id),
              tax: findIndex(taxes, "id", split.tax_id),
              customer: findIndex(customers, "id", split.customer_id),
              vendor: findIndex(vendors, "id", split.vendor_id),
              document_id: split.document_id,
              showCustomer: split.customer_id != null,
              showVendor: split.vendor_id != null,
            };
            console.log(newSplit);
            newTransactionSplits.push(newSplit);
          } else {
            var prevCategory = cashAccounts.filter(
              (account) => account.id == split.account_id
            )[0].category;
            console.log(prevCategory);
            // setType based on account debits and credits
            if (split.debit != null) {
              if (debitShouldAdd(prevCategory)) {
                setType("Deposit");
              } else {
                setType("Withdrawal");
              }
            } else if (split.credit != null) {
              if (debitShouldAdd(prevCategory)) {
                setType("Withdrawal");
              } else {
                setType("Deposit");
              }
            }
          }
        }
        console.log(newTransactionSplits);
        setTransactionSplits(newTransactionSplits);
      });
      // setTransactionSplits(newTransactionSplits);
    }
  }, [props.popup.type]);

  const resetState = () => {
    setStartDate(new Date());
    setDescription("");
    setAccount(null);
    setType(null);
    setAmount("0.00");
    setNotes("");
    setPreviousNotes("");
    setTransactionSplits([
      {
        amount: "0.00",
        category: null,
        tax: null,
        customer: null,
        vendor: null,
        document_id: null,
        showCustomer: false,
        showVendor: false,
      },
    ]);
    setCategory({
      id: 10,
      name: "General Transaction",
      account1: null,
      account2: null,
    });
    setCategoryAccountList([...allAccounts.map((a) => [a.name])]);
  };

  const save = async () => {
    const mainForm = {
      date: startDate,
      description: description,
      account: account,
      type: type,
    };
    console.log(mainForm);
    console.log(transactionSplits);
    if (props.popup.type == "Edit") {
      if (checkValidEdit()) {
        console.log("edit");
        const transactionForm = {
          date: getDate(startDate),
          description: description,
          account_id: account.id,
          category: transactionSplits[0].category.name,
          amount: amount,
          notes: getDate(new Date()) + ": " + notes + "\n" + previousNotes,
          reviewed: reviewed,
          document_id: null,
        };
        console.log(transactionForm);
        console.log(props.popup.transaction.id);
        putTransaction(transactionForm, props.popup.transaction.id).then(
          async (data) => {
            // console.log(data);
            // props.setPopup({ show: false, type: null });
            // resetState();
            // props.refresh();
          }
        );
        var journalEntries = [];

        // check if the cash account is getting debitted or creditted
        // and set the debit and credit accounts accordingly
        var cash_account_id = account.id;
        var shouldDebit = null;
        if (type == "Deposit") {
          if (debitShouldAdd(account.category)) {
            console.log("Depositing by debiting");
            shouldDebit = true;
          } else {
            console.log("Depositing by crediting");
            shouldDebit = false;
          }
        } else if (type == "Withdrawal") {
          if (debitShouldAdd(account.category)) {
            console.log("Withdrawing by crediting");
            shouldDebit = false;
          } else {
            console.log("Withdrawing by debiting");
            shouldDebit = true;
          }
        }

        // If the cash account should debit, create it first, else create the journal entries for the splits first and then the cash account
        if (shouldDebit) {
          // Create the journal entry for the cash account
          var cash_journal_form = {
            description: "Cash",
            account_id: cash_account_id,
            transaction_id: props.popup.transaction.id,
            debit: amount,
            credit: null,
            tax_id: null,
            customer_id: null,

            vendor_id: null,
            document_id: null,
          };
          console.log("CASH JOURNAL ENTRY: ", cash_journal_form);
          journalEntries.push(cash_journal_form);

          // Create the journal entries for the splits
          for (let i in transactionSplits) {
            let split = transactionSplits[i];

            var tax_id = null;
            if (split.tax != null && split.tax != -1) {
              // -1 indicates not found from findIndex()
              tax_id = taxes[split.tax].id;
            }
            var customer_id = null;
            if (split.customer != null && split.customer != -1) {
              customer_id = customers[split.customer].id;
            }

            var vendor_id = null;
            if (split.vendor != null && split.vendor != -1) {
              vendor_id = vendors[split.vendor].id;
            }

            var split_journal_form = {
              description: "Split",
              account_id: split.category.id,
              transaction_id: props.popup.transaction.id,
              debit: null,
              credit: split.amount,
              tax_id: tax_id,
              customer_id: customer_id,
              vendor_id: vendor_id,
              document_id: null,
            };
            console.log("SPLIT JOURNAL ENTRY: ", split_journal_form);
            journalEntries.push(split_journal_form);

            //TODO: If there is a tax, make a journal entry for the tax
          }
        } else {
          // Create the journal entries for the splits
          for (let i in transactionSplits) {
            let split = transactionSplits[i];

            var tax_id = null;
            if (split.tax != null && split.tax != -1) {
              // -1 indicates not found from findIndex()
              tax_id = taxes[split.tax].id;
            }
            var customer_id = null;
            if (split.customer != null && split.customer != -1) {
              customer_id = customers[split.customer].id;
            }

            var vendor_id = null;
            if (split.vendor != null && split.vendor != -1) {
              vendor_id = vendors[split.vendor].id;
            }

            var split_journal_form = {
              description: "Split",
              account_id: split.category.id,
              transaction_id: props.popup.transaction.id,
              debit: split.amount,
              credit: null,
              tax_id: tax_id,
              customer_id: customer_id,
              vendor_id: vendor_id,
              document_id: null,
            };
            console.log("SPLIT JOURNAL ENTRY: ", split_journal_form);
            journalEntries.push(split_journal_form);

            //TODO: If there is a tax, make a journal entry for the tax
          }

          // Create the journal entry for the cash account
          var cash_journal_form = {
            description: "Cash",
            account_id: cash_account_id,
            transaction_id: props.popup.transaction.id,
            debit: null,
            credit: amount,
            tax_id: null,
            customer_id: null,

            vendor_id: null,
            document_id: null,
          };
          console.log("CASH JOURNAL ENTRY: ", cash_journal_form);
          journalEntries.push(cash_journal_form);
        }

        for (let i = 0; i < journalEntries.length; i++) {
          if (journalEntriesOrig[i]) {
            journalEntries[i].id = journalEntriesOrig[i].id;
          } else {
            journalEntries[i].id = -1;
          }
          // journalEntries[i].id = i;
        }
        console.log(journalEntries);
        console.log(journalEntriesOrig);
        await updateItem(
          journalEntriesOrig,
          journalEntries,
          (form) => postJournalEntry(form),
          (form, id) => putJournalEntry(form, id),
          (id) => deleteJournalEntry(id)
        );
        props.setPopup({ show: false, type: null });
        resetState();
        props.refresh();
        console.log("RESETTTTT");
      } else {
        console.log("invalid edit");
      }
    } else {
      if (checkValid()) {
        console.log("save");

        // create first note with original tag and date
        let formattedNotes = null;
        if (notes != "" && notes != null) {
          formattedNotes = getDate(new Date()) + " (Original): " + notes;
        } else {
          formattedNotes = getDate(new Date()) + " (Original)";
        }

        // Save main transaction to database
        const transactionForm = {
          date: getDate(startDate),
          description: description,
          account_id: account.id,
          category: transactionSplits[0].category.name,
          amount: amount,
          notes: formattedNotes,
          reviewed: reviewed,
          document_id: null,
        };

        postTransaction(transactionForm).then(async (data) => {
          console.log(data.rows[0].id);
          const id = data.rows[0].id;

          console.log(transactionForm);
          console.log("MAIN ACCOUNT ID: ", account.id);
          console.log(cashAccounts);

          // check if the cash account is getting debitted or creditted
          // and set the debit and credit accounts accordingly
          var cash_account_id = account.id;
          var shouldDebit = null;
          if (type == "Deposit") {
            if (debitShouldAdd(account.category)) {
              console.log("Depositing by debiting");
              shouldDebit = true;
            } else {
              console.log("Depositing by crediting");
              shouldDebit = false;
            }
          } else if (type == "Withdrawal") {
            if (debitShouldAdd(account.category)) {
              console.log("Withdrawing by crediting");
              shouldDebit = false;
            } else {
              console.log("Withdrawing by debiting");
              shouldDebit = true;
            }
          }

          // If the cash account should debit, create it first, else create the journal entries for the splits first and then the cash account
          if (shouldDebit) {
            // Create the journal entry for the cash account
            var cash_journal_form = {
              description: "Cash",
              account_id: cash_account_id,
              transaction_id: id,
              debit: amount,
              credit: null,
              tax_id: null,
              customer_id: null,

              vendor_id: null,
              document_id: transactionSplits[0].document_id
                ? transactionSplits[0].document_id
                : null, //bill and invoice payments should only have one split and that split should have a document_id
            };
            console.log("CASH JOURNAL ENTRY: ", cash_journal_form);
            await postJournalEntry(cash_journal_form);

            // Create the journal entries for the splits
            for (let i in transactionSplits) {
              let split = transactionSplits[i];

              var tax_id = null;
              if (split.tax != null && split.tax != -1) {
                // -1 indicates not found from findIndex()
                tax_id = taxes[split.tax].id;
              }
              var customer_id = null;
              if (split.customer != null && split.customer != -1) {
                customer_id = customers[split.customer].id;
              }

              var vendor_id = null;
              if (split.vendor != null && split.vendor != -1) {
                vendor_id = vendors[split.vendor].id;
              }

              var split_journal_form = {
                description: "Split",
                account_id: split.category.id,
                transaction_id: id,
                debit: null,
                credit: split.amount,
                tax_id: tax_id,
                customer_id: customer_id,
                vendor_id: vendor_id,
                document_id: split.document_id ? split.document_id : null,
              };
              console.log("SPLIT JOURNAL ENTRY: ", split_journal_form);
              await postJournalEntry(split_journal_form);

              //TODO: If there is a tax, make a journal entry for the tax
            }
          } else {
            // Create the journal entries for the splits
            for (let i in transactionSplits) {
              let split = transactionSplits[i];

              var tax_id = null;
              if (split.tax != null && split.tax != -1) {
                // -1 indicates not found from findIndex()
                tax_id = taxes[split.tax].id;
              }
              var customer_id = null;
              if (split.customer != null && split.customer != -1) {
                customer_id = customers[split.customer].id;
              }

              var vendor_id = null;
              if (split.vendor != null && split.vendor != -1) {
                vendor_id = vendors[split.vendor].id;
              }

              var split_journal_form = {
                description: "Split",
                account_id: split.category.id,
                transaction_id: id,
                debit: split.amount,
                credit: null,
                tax_id: tax_id,
                customer_id: customer_id,
                vendor_id: vendor_id,
                document_id: null,
              };
              console.log("SPLIT JOURNAL ENTRY: ", split_journal_form);
              await postJournalEntry(split_journal_form);

              //TODO: If there is a tax, make a journal entry for the tax
            }

            // Create the journal entry for the cash account
            var cash_journal_form = {
              description: "Cash",
              account_id: cash_account_id,
              transaction_id: id,
              debit: null,
              credit: amount,
              tax_id: null,
              customer_id: null,

              vendor_id: null,
              document_id: null,
            };
            console.log("CASH JOURNAL ENTRY: ", cash_journal_form);
            await postJournalEntry(cash_journal_form);
          }

          props.setPopup({ show: false, type: null });
          resetState();
          props.refresh();
        });
      } else {
        console.log("invalid");
      }
    }
  };

  const checkValid = () => {
    // setWarningMessage(null);
    // check validity of form
    if (description == "") {
      setValid(false);
      // setWarningMessage("Description cannot be empty");
      return false;
    } else if (account == null) {
      setValid(false);
      // setWarningMessage("Account cannot be empty");
      return false;
    } else if (parseFloat(amount) == "0.00") {
      setValid(false);
      // setWarningMessage("Amount cannot be zero");
      return false;
    } else if (type == null) {
      setValid(false);
      // setWarningMessage("Type cannot be empty");
      return false;
    } else if (warningMessage != null) {
      setValid(false);
      return false;
    } else {
      var valid = true;
      for (var i = 0; i < transactionSplits.length; i++) {
        if (transactionSplits[i].amount == "0.00") {
          valid = false;
        } else if (transactionSplits[i].category == null) {
          // setWarningMessage("Category cannot be empty");
          valid = false;
        } else if (transactionSplits[i].category == account) {
          // setWarningMessage("Category cannot be the same as the account");
          valid = false;
        } else {
          valid = true;
        }
      }
      setValid(valid);
      return valid;
    }
  };

  const checkValidEdit = () => {
    if (!checkValid()) {
      setValid(false);
      return false;
    } else if (notes == "") {
      setValid(false);
      //setWarningMessage("Notes cannot be empty");
      return false;
    } else {
      setValid(true);
      return true;
    }
  };

  const removeSplit = (index) => {
    const newSplits = [...transactionSplits];
    newSplits.splice(index, 1);
    setTransactionSplits(newSplits);
  };

  return (
    <Modal
      scrollable={true}
      size="lg"
      show={props.popup.show}
      onHide={() => {
        props.setPopup({ show: false, type: null });
        resetState();
      }}
      style={{ height: "800px !important", width: "800px !important" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {props.popup.type == "Edit" ? (
            "Edit Transaction"
          ) : (
            <>Add {props.popup.type}</>
          )}{" "}
        </Modal.Title>
      </Modal.Header>

      <div
        style={{
          overflowY: "auto",
          height: "550px",
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div style={{ width: "40%" }}>
              <label className="form-label">Date</label>
              <DateSelector date={startDate} setDate={setStartDate} />
            </div>
            <div style={{ width: "40%" }}>
              <Input
                label="Description"
                placeholder="Write a Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          {transactionSplits.length == 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label className="form-label">Amount</label>
                <CurrencyInput
                  value={amount}
                  onChange={(val) => {
                    setAmount(val);
                  }}
                  maxLength={11}
                  maxDecimals={2}
                />
              </div>
              <div style={{ width: "40%" }}>
                {/* <label className="form-label">Category</label>
                <SearchableWithButton
                  appendID={"-searchable-categories"}
                  list={[
                    ["Assets"],
                    ["Liabilities"],
                    ["Equity"],
                    ["Income"],
                    ["Expenses"],
                    ["Invoices"],
                    ["Bills"],
                  ]}
                  value={category}
                  setValue={(e) => {
                    setCategory(e);
                  }}
                  defaultValue={"Select a Category"}
                /> */}
                <SearchableModal
                  label="Category"
                  list={categoryList}
                  keys={{ name: (name) => name }}
                  value={category ? category.name : null}
                  onItemClick={(e) => {
                    setCategory(e);
                  }}
                />
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ width: "40%" }}>
              {/* <label className="form-label">Account 1</label>
              <SearchableWithButton
                appendID={"-searchable-accounts"}
                list={cashAccountList}
                value={account}
                setValue={(e) => setAccount(e)}
                defaultValue={"Select Account 1"}
              /> */}
              <SearchableModal
                label={
                  <div>
                    Account 1{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {account
                        ? debitShouldAdd(account.category)
                          ? "+"
                          : "-"
                        : null}
                    </span>
                  </div>
                }
                initialValue="Select Account 1"
                list={accountOneList}
                keys={{ name: (name) => name }}
                value={account ? account.name : null}
                onItemClick={(e) => {
                  setAccount(e);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {transactionSplits.length > 1 ? (
                <div
                  style={{
                    position: "absolute",
                    marginTop: "40px",
                    zIndex: "50",
                    width: "5em",
                  }}
                >
                  {category ? (
                    category.name == "General Transaction" ? (
                      <>
                        {" "}
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <IoArrowForwardCircleOutline
                            onClick={(e) => {
                              if (type == "Deposit") {
                                setType("Withdrawal");
                              } else {
                                setType("Deposit");
                              }
                            }}
                            className={"swap-arrow-alt-" + type}
                            fontSize="25px"
                            ref={swapButtonRef}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: "0.7em",
                            whiteSpace: "normal",
                            textAlign: "center",
                          }}
                        >
                          {type == "Deposit"
                            ? "Withdrawing from"
                            : "Depositing into"}
                        </div>
                      </>
                    ) : null
                  ) : null}
                </div>
              ) : (
                <>
                  {category ? (
                    category.name == "General Transaction" ? (
                      <>
                        <div>
                          <IoArrowForwardCircleOutline
                            onClick={(e) => {
                              if (type == "Deposit") {
                                setType("Withdrawal");
                              } else {
                                setType("Deposit");
                              }
                            }}
                            className={"swap-arrow-" + type}
                            fontSize="25px"
                            ref={swapButtonRef}
                          />
                        </div>

                        <div
                          style={{
                            fontSize: "12px",
                            whiteSpace: "normal",
                            textAlign: "center",
                          }}
                        >
                          {type == "Deposit"
                            ? "Withdrawing from"
                            : "Depositing into"}
                        </div>
                      </>
                    ) : null
                  ) : null}
                </>
              )}
            </div>
            <div style={{ width: "40%" }}>
              {/* {props.popup.type == "Income" ? (
                <Dropdown
                  label="Type"
                  dropdownList={["Deposit"]}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
              ) : props.popup.type == "Edit" ? (
                <Dropdown
                  label="Type"
                  dropdownList={["Deposit", "Withdrawal"]}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
              ) : (
                <Dropdown
                  label="Type"
                  dropdownList={["Withdrawal"]}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
              )} */}
              {transactionSplits.length == 1 ? (
                <>
                  {/* <label className="form-label">Account 2</label>
                  <SearchableWithButton
                    appendID={"-searchable-accounts-2"}
                    list={categoryAccountList}
                    value={transactionSplits[0].category}
                    setValue={(e) => {
                      let newSplits = [...transactionSplits];
                      newSplits[0].category = e;
                      setTransactionSplits(newSplits);
                    }}
                    defaultValue={"Select Account 2"}
                  /> */}
                  <SearchableModal
                    label={
                      <div>
                        Account 2{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {transactionSplits[0]
                            ? transactionSplits[0].category
                              ? debitShouldAdd(
                                  transactionSplits[0].category.category
                                )
                                ? "-"
                                : "+"
                              : null
                            : null}
                        </span>
                      </div>
                    }
                    initialValue="Select Account 2"
                    list={accountTwoList}
                    keys={{ name: (name) => name }}
                    value={
                      transactionSplits[0].category
                        ? transactionSplits[0].category.name
                        : null
                    }
                    onItemClick={(e) => {
                      let newSplits = [...transactionSplits];
                      newSplits[0].category = e;
                      setTransactionSplits(newSplits);
                    }}
                  />
                </>
              ) : (
                <>
                  {/* <label className="form-label">Category</label>
                  <SearchableWithButton
                    appendID={"-searchable-categories"}
                    list={categoryList}
                    value={category}
                    setValue={(e) => {
                      setCategory(e);
                    }}
                    defaultValue={"Select a Category"}
                  /> */}
                </>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            {console.log(transactionSplits)}
            {transactionSplits[0].showCustomer &&
            transactionSplits.length == 1 ? (
              <div style={{ width: "40%" }}>
                <label className="form-label">Customer</label>
                <SearchableWithButton
                  appendID={"-searchable-customers"}
                  list={customerList}
                  value={transactionSplits[0].customer}
                  setValue={(e) => {
                    // update customer in first transaction split
                    let newSplits = [...transactionSplits];
                    newSplits[0].customer = e;
                    setTransactionSplits(newSplits);
                  }}
                  defaultValue={"Select a Customer"}
                />
              </div>
            ) : null}
            {transactionSplits[0].showVendor &&
            transactionSplits.length == 1 ? (
              <div style={{ width: "40%" }}>
                <label className="form-label">Vendor</label>
                <SearchableWithButton
                  appendID={"-searchable-vendors"}
                  list={vendorList}
                  value={transactionSplits[0].vendor}
                  setValue={(e) => {
                    // update customer in first transaction split
                    let newSplits = [...transactionSplits];
                    newSplits[0].vendor = e;
                    setTransactionSplits(newSplits);
                  }}
                  defaultValue={"Select a Vendor"}
                />
              </div>
            ) : null}
          </div>

          {console.log(transactionSplits)}
          {transactionSplits.length == 1 ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                <SearchableWithUnderlineButton
                  appendID={"-searchable-taxes"}
                  list={taxList}
                  value={transactionSplits[0].tax}
                  setValue={(e) => {
                    // update tax in first transaction split
                    let newSplits = [...transactionSplits];
                    newSplits[0].tax = e;
                    setTransactionSplits(newSplits);
                  }}
                  defaultValue={"Include sales tax"}
                  buttonList={["Remove"]}
                  functions={[
                    () => {
                      let newTransactionSplits = [...transactionSplits];
                      newTransactionSplits[0].tax = null;
                      setTransactionSplits(newTransactionSplits);
                    },
                    () => {
                      console.log("second");
                    },
                  ]}
                />
              </div>
              {props.popup.type == "Income" || props.popup.type == "Edit" ? (
                <div style={{ marginRight: "20px" }}>
                  {" "}
                  {transactionSplits[0].showCustomer ? (
                    <UnderlineButton
                      title={"Remove customer"}
                      size={"small"}
                      onClick={(e) => {
                        let newSplits = [...transactionSplits];
                        newSplits[0].customer = null;
                        newSplits[0].showCustomer = false;
                        setTransactionSplits(newSplits);
                      }}
                      id={"remove-customer"}
                    />
                  ) : (
                    <UnderlineButton
                      title={"Add customer"}
                      size={"small"}
                      onClick={(e) => {
                        let newSplits = [...transactionSplits];
                        newSplits[0].showCustomer = true;
                        setTransactionSplits(newSplits);
                      }}
                      id={"add-customer"}
                    />
                  )}
                </div>
              ) : null}
              {props.popup.type == "Expense" || props.popup.type == "Edit" ? (
                <div style={{ marginRight: "20px" }}>
                  {transactionSplits[0].showVendor ? (
                    <UnderlineButton
                      title={"Remove vendor"}
                      size={"small"}
                      onClick={(e) => {
                        let newSplits = [...transactionSplits];
                        newSplits[0].vendor = null;
                        newSplits[0].showVendor = false;
                        setTransactionSplits(newSplits);
                      }}
                      id={"remove-vendor"}
                    />
                  ) : (
                    <UnderlineButton
                      title={"Add vendor"}
                      size={"small"}
                      onClick={(e) => {
                        let newSplits = [...transactionSplits];
                        newSplits[0].showVendor = true;
                        setTransactionSplits(newSplits);
                      }}
                      id={"add-vendor"}
                    />
                  )}
                </div>
              ) : null}
            </div>
          ) : null}

          {/* ---------------------split transactions----------------------- */}
          {transactionSplits.length > 1 ? (
            <div
              style={{
                marginTop: "0px",
                marginBottom: "50px",
                position: "relative",
              }}
            >
              <div
                style={{
                  marginLeft: "5%",
                  borderLeft: "1px solid black",
                  marginTop: "-20px",
                }}
              >
                {/* {type == "Deposit" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",

                      borderBottom: "10px solid black",
                      marginLeft: "-10px",
                      transition: "2s",
                      transform: "scale(0.95)",
                    }}
                  ></div>
                ) : null} */}
                <CSSTransition
                  in={type == "Deposit"}
                  nodeRef={nodeRef}
                  timeout={200}
                  classNames="split-arrows"
                  unmountOnExit
                >
                  <div
                    ref={nodeRef}
                    style={{
                      position: "absolute",
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",

                      borderBottom: "10px solid black",
                      marginLeft: "-10px",
                    }}
                  ></div>
                </CSSTransition>

                <div style={{ marginLeft: "5%", paddingTop: "10px" }}>
                  <div style={{ fontWeight: "bold" }}>Split transaction</div>
                  <label
                    className="form-label"
                    style={{ color: "var(--disabled-font-color)" }}
                  >
                    Original amount
                  </label>
                </div>
              </div>

              <div style={{ width: "40%" }}>
                {transactionSplits.filter((e) => e.tax != null && e.tax != -1)
                  .length > 0 ? (
                  <input
                    className="form-control"
                    disabled
                    value={
                      amount +
                      "    " +
                      "Total amount with tax:  " +
                      toUSD(
                        addMoney(
                          amount,
                          sumMoney(
                            transactionSplits
                              .filter((e) => e.tax != null && e.tax != -1)
                              .map(
                                (e) =>
                                  e.amount * parseFloat(taxes[e.tax].percentage)
                              )
                          )
                        ),
                        "total"
                      )
                    }
                  ></input>
                ) : (
                  <input
                    className="form-control"
                    disabled
                    value={amount}
                  ></input>
                )}
              </div>

              {transactionSplits.map((splitTransaction, index) => {
                return (
                  <>
                    {transactionSplits.length - 1 ==
                    index ? null : transactionSplits.length - 2 == index ? (
                      // Give a curve to the last visible branch
                      <>
                        <div
                          style={{
                            height: "120px",
                            width: "5%",
                            position: "absolute",
                            borderLeft: "1px solid black",
                            borderBottom: "1px solid black",
                            marginLeft: "5%",
                            marginTop: "70px",
                            borderBottomLeftRadius: "10px",
                          }}
                        ></div>
                        <div></div>
                      </>
                    ) : (
                      // Hide the last branch
                      <div
                        style={{
                          height: "120px",
                          width: "5%",
                          position: "absolute",
                          borderLeft: "1px solid black",
                          borderBottom: "1px solid black",
                          marginLeft: "5%",
                          marginTop: "70px",
                        }}
                      ></div>
                    )}
                    {index == 0 ? (
                      // Manual extra branch for the first split
                      <div
                        style={{
                          height: "70px",
                          width: "5%",
                          position: "absolute",
                          borderLeft: "1px solid black",
                          borderBottom: "1px solid black",
                          marginLeft: "5%",
                          marginTop: "0px",
                        }}
                      ></div>
                    ) : null}
                    {/* {type == "Withdrawal" ? (
                      <div
                        style={{
                          position: "absolute",
                          width: "0",
                          height: "0",
                          borderTop: "10px solid transparent",
                          borderBottom: "10px solid transparent",

                          borderLeft: "10px solid black",
                          marginLeft: "calc(10% - 10px)",
                          marginTop: "60px",
                        }}
                      ></div>
                    ) : null} */}
                    <CSSTransition
                      in={type == "Withdrawal"}
                      nodeRef={nodeRefs[index]}
                      timeout={300}
                      classNames="split-arrows"
                      unmountOnExit
                    >
                      <div
                        ref={nodeRefs[index]}
                        style={{
                          position: "absolute",
                          width: "0",
                          height: "0",
                          borderTop: "10px solid transparent",
                          borderBottom: "10px solid transparent",

                          borderLeft: "10px solid black",
                          marginLeft: "calc(10% - 10px)",
                          marginTop: "60px",
                        }}
                      ></div>
                    </CSSTransition>

                    <div
                      style={{
                        backgroundColor: "var(--container-color)",
                        borderRadius: "10px",
                        minWidth: "50%",
                        marginLeft: "10%",
                        marginTop: "20px",
                        height: "100px",
                        display: "inline-block",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "160px",
                                marginTop: "5px",
                                marginRight: "20px",
                              }}
                            >
                              <label className="form-label">Split amount</label>
                              <CurrencyInput
                                value={transactionSplits[index].amount}
                                onChange={(val) => {
                                  // update the amount of the split
                                  let newTransactionSplits = [
                                    ...transactionSplits,
                                  ];
                                  newTransactionSplits[index].amount = val;
                                  setTransactionSplits(newTransactionSplits);
                                }}
                                maxLength={11}
                                maxDecimals={2}
                              />
                            </div>
                            <div style={{ width: "160px", marginTop: "5px" }}>
                              <SearchableModal
                                label={"Account " + parseInt(index + 2)}
                                initialValue={
                                  "Select Account " + parseInt(index + 2)
                                }
                                list={accountTwoList}
                                keys={{
                                  name: (name) => name,
                                }}
                                value={
                                  transactionSplits[index].category
                                    ? transactionSplits[index].category.name
                                    : null
                                }
                                onItemClick={(e) => {
                                  // update the category of the split
                                  let newTransactionSplits = [
                                    ...transactionSplits,
                                  ];
                                  newTransactionSplits[index].category = e;
                                  setTransactionSplits(newTransactionSplits);
                                }}
                              />
                            </div>
                            {transactionSplits[index].showCustomer ? (
                              <div
                                style={{
                                  width: "160px",
                                  marginTop: "5px",
                                  marginLeft: "20px",
                                }}
                              >
                                <label className="form-label">Customer</label>
                                <SearchableWithButton
                                  appendID={"-searchable-customers-" + index}
                                  list={customerList}
                                  value={transactionSplits[index].customer}
                                  setValue={(e) => {
                                    // update the customer of the split
                                    let newTransactionSplits = [
                                      ...transactionSplits,
                                    ];
                                    newTransactionSplits[index].customer = e;
                                    setTransactionSplits(newTransactionSplits);
                                  }}
                                  defaultValue={"Select a Customer"}
                                />
                              </div>
                            ) : null}
                            {transactionSplits[index].showVendor ? (
                              <div
                                style={{
                                  width: "160px",
                                  marginTop: "5px",
                                  marginLeft: "20px",
                                }}
                              >
                                <label className="form-label">Vendor</label>
                                <SearchableWithButton
                                  appendID={"-searchable-vendors-" + index}
                                  list={vendorList}
                                  value={transactionSplits[index].vendor}
                                  setValue={(e) => {
                                    // update the vendor of the split
                                    let newTransactionSplits = [
                                      ...transactionSplits,
                                    ];
                                    newTransactionSplits[index].vendor = e;
                                    setTransactionSplits(newTransactionSplits);
                                  }}
                                  defaultValue={"Select a Vendor"}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div style={{ marginRight: "20px" }}>
                              <SearchableWithUnderlineButton
                                appendID={"-searchable-taxes-" + index}
                                list={splitTaxList[index]}
                                value={transactionSplits[index].tax}
                                setValue={(e) => {
                                  // update the tax of the split
                                  let newTransactionSplits = [
                                    ...transactionSplits,
                                  ];
                                  newTransactionSplits[index].tax = e;
                                  setTransactionSplits(newTransactionSplits);
                                }}
                                defaultValue={"Include sales tax"}
                                buttonList={["Remove"]}
                                functions={[
                                  () => {
                                    let newTransactionSplits = [
                                      ...transactionSplits,
                                    ];
                                    newTransactionSplits[index].tax = null;
                                    setTransactionSplits(newTransactionSplits);
                                  },
                                  () => {
                                    console.log("second");
                                  },
                                ]}
                              />
                            </div>
                            {props.popup.type == "Income" ||
                            props.popup.type == "Edit" ? (
                              <div style={{ marginRight: "20px" }}>
                                {transactionSplits[index].showCustomer ? (
                                  <UnderlineButton
                                    title={"Remove customer"}
                                    size={"small"}
                                    onClick={(e) => {
                                      // update the customer of the split to be empty
                                      let newTransactionSplits = [
                                        ...transactionSplits,
                                      ];
                                      newTransactionSplits[index].customer =
                                        null;
                                      newTransactionSplits[
                                        index
                                      ].showCustomer = false;
                                      setTransactionSplits(
                                        newTransactionSplits
                                      );
                                    }}
                                    id={"remove-customer-" + index}
                                  />
                                ) : (
                                  <UnderlineButton
                                    title={"Add customer"}
                                    size={"small"}
                                    onClick={(e) => {
                                      // update the customer of the split to be empty
                                      let newTransactionSplits = [
                                        ...transactionSplits,
                                      ];
                                      newTransactionSplits[
                                        index
                                      ].showCustomer = true;
                                      setTransactionSplits(
                                        newTransactionSplits
                                      );
                                    }}
                                    id={"add-customer-" + index}
                                  />
                                )}
                              </div>
                            ) : null}
                            {props.popup.type == "Expense" ||
                            props.popup.type == "Edit" ? (
                              <div style={{ marginRight: "20px" }}>
                                {transactionSplits[index].showVendor ? (
                                  <UnderlineButton
                                    title={"Remove Vendor"}
                                    size={"small"}
                                    onClick={(e) => {
                                      // update the vendor of the split to be empty
                                      let newTransactionSplits = [
                                        ...transactionSplits,
                                      ];
                                      newTransactionSplits[index].vendor = null;
                                      newTransactionSplits[
                                        index
                                      ].showVendor = false;
                                      setTransactionSplits(
                                        newTransactionSplits
                                      );
                                    }}
                                    id={"remove-vendor-" + index}
                                  />
                                ) : (
                                  <UnderlineButton
                                    title={"Add Vendor"}
                                    size={"small"}
                                    onClick={(e) => {
                                      // update the vendor of the split to be empty
                                      let newTransactionSplits = [
                                        ...transactionSplits,
                                      ];
                                      newTransactionSplits[
                                        index
                                      ].showVendor = true;
                                      setTransactionSplits(
                                        newTransactionSplits
                                      );
                                    }}
                                    id={"add-vendor-" + index}
                                  />
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <DeleteIcon
                          fontSize={"var(--font-size-normal)"}
                          onClick={(e) => removeSplit(index)}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          ) : null}

          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <OutlineButton
              title="Split transaction"
              size="small"
              onClick={() => {
                setTransactionSplits([
                  ...transactionSplits,
                  {
                    amount: "0.00",
                    category: null,
                    tax: null,
                    customer: null,
                    vendor: null,
                    document_id: null,
                  },
                ]);
                console.log(transactionSplits);
              }}
            />

            {transactionSplits.length == 1 &&
            transactionSplits[0].tax != null &&
            transactionSplits[0].tax != -1 ? (
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-between",
                }}
              >
                Total amount with tax:
                <div style={{ marginLeft: "30px" }}>
                  {toUSD(
                    addMoney(
                      amount,
                      amount *
                        parseFloat(taxes[transactionSplits[0].tax].percentage)
                    ),
                    "total"
                  )}
                </div>
              </div>
            ) : null}
          </div>
          {warningMessage ? <WarningMessage message={warningMessage} /> : null}
          <div style={{ marginTop: "20px" }}>
            {previousNotes ? (
              <>
                <div style={{ fontWeight: "bold", marginTop: "20px" }}>
                  Note History:
                </div>
                <div style={{ marginLeft: "30px" }}>
                  {previousNotes.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>

                <div style={{ marginTop: "20px" }}>
                  <TextArea
                    label="Notes"
                    placeholder="Write the reason for change here..."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </>
            ) : (
              <div>
                <TextArea
                  label="Notes"
                  placeholder="Write a note here..."
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          border: "none",
        }}
      >
        <Button variant="success" className="mr-auto">
          <MdOutlineCheckCircleOutline /> Mark As Reviewed
        </Button>
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              resetState();
              props.setPopup({ show: false, type: null });
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
          {warningMessage ? (
            <Button
              className="disabled-input"
              variant="success"
              onClick={() => {
                save().then(() => {
                  props.handleSave("hello");
                });
              }}
              disabled
            >
              Save
            </Button>
          ) : (
            <Button
              variant="success"
              onClick={() => {
                save().then(() => {
                  props.handleSave("hello");
                });
              }}
            >
              Save
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EditTransactionPopup;
