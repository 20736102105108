import React from "react";

/* Props 
    className
    label
    defaultValue
*/
function TextArea(props) {
  return (
    <div className={props.className}>
      {props.label ? (
        <label htmlFor="labeledTextArea" className="form-label">
          {props.label}
        </label>
      ) : null}

      <textarea
        className="form-control"
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        style={{ height: props.height }}
      ></textarea>
    </div>
  );
}

export default TextArea;
