import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SortingTable } from "../../../components/Tables/ProductsAndServices/SortingTable";

import DeleteConfirmation from "../../../components/Popups/DeleteConfirmation";

import "./productsAndServices.css";

import { getProducts, getProductPriceOfOne } from "../../Site/api";
import { api_url } from "../../../util/environment";

function ProductsAndServices(props) {
  let navigate = useNavigate();
  const [productsAndServices, setProductsAndServices] = useState([]);

  const [popup, setPopup] = useState({ show: false, id: null });
  useEffect(() => {
    getProductsAndServices();
  }, []);

  //update when changing between sales/categories page and purchases/categories
  useEffect(() => {
    getProductsAndServices();
  }, [props.type]);

  async function getProductsAndServices() {
    getProducts(props.type).then((data) => {
      data.map((item, index) => {
        getProductPriceOfOne(item.id).then((priceData) => {
          console.log(priceData[0].price);
          item["price"] = priceData[0].price;
          if (data.length == index + 1) {
            setProductsAndServices(JSON.parse(JSON.stringify(data)));
          }
        });
      });
    });
  }

  function deleteProduct(e) {
    setPopup({ show: true, id: e });
  }

  function handleClose() {
    setPopup({ show: false, id: null });
  }

  async function handleDelete() {
    console.log("DELETE" + popup.id);
    return fetch(api_url + "/site/products-and-services/" + popup.id, {
      method: "DELETE",
    }).then(() => {
      getProductsAndServices();
      setPopup({ show: false, id: null });
    });
  }

  function editProduct(e) {
    console.log(e);
    navigate("/" + props.type + "/products-and-services/update/" + e);
  }

  return (
    <div className="productsList">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Products & Services ({props.type})</h1>
        <Link to={"/" + props.type + "/products-and-services/add"}>
          <button
            type="button"
            className="btn btn-success"
            style={{ marginTop: "10px" }}
          >
            Add a product or service
          </button>
        </Link>
      </div>

      <div className="productsAndServicesTable">
        <SortingTable
          data={productsAndServices}
          deleteProduct={deleteProduct}
          editProduct={editProduct}
          refresh={getProductsAndServices}
        />
      </div>
      <DeleteConfirmation
        popup={popup}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ProductsAndServices;
