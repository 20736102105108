import useFetch from "./useFetch";
import { useEffect, useState } from "react";
import { getCategories, getProductPricing } from "../pages/Site/api";
import { getPurchasesProducts } from "../pages/Purchases/api";
import { isEmpty } from "../util/jsonUtil";

const usePurchasesProducts = (
  sortByCategory = false,
  includeQuantityPricing = false
) => {
  const [purchasesProductsError, setPurchasesProductsError] = useState(null);
  const [purchasesProductsByCategory, setPurchasesProductsByCategory] =
    useState({});
  const [purchasesProductsWithPricing, setPurchasesProductsWithPricing] =
    useState([]);
  const [
    loadingPurchasesProductsByCategory,
    setLoadingPurchasesProductsByCategory,
  ] = useState(true);
  const {
    loading: loadingPurchasesProducts,
    error,
    data: purchasesProducts,
  } = useFetch(getPurchasesProducts);

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: categories,
  } = useFetch(getCategories);

  useEffect(() => {
    (async () => {
      console.log(purchasesProducts);
      let withPricing = null;
      if (
        purchasesProductsWithPricing.length == 0 &&
        purchasesProducts &&
        includeQuantityPricing
      ) {
        withPricing = [...purchasesProducts];
        for (let i in purchasesProducts) {
          await getProductPricing(purchasesProducts[i].id).then((data) => {
            withPricing[i].quantityPricing = data;
            console.log("GETTING");
          });
        }
        console.log("SETTING");
        setPurchasesProductsWithPricing(withPricing);
      }
      if (
        isEmpty(purchasesProductsByCategory) &&
        (withPricing || !includeQuantityPricing) &&
        categories &&
        sortByCategory
      ) {
        withPricing = [...purchasesProducts];
        var obj = {};
        for (let i in categories) {
          obj[categories[i].name] = withPricing.filter(
            (product) => product.purchase_category_id === categories[i].id
          );
          for (let j in obj[categories[i].name]) {
            obj[categories[i].name][j].purchase_category = categories[i];
          }
        }
        setPurchasesProductsByCategory(obj);
        setLoadingPurchasesProductsByCategory(false);
        console.log(obj);
      }
    })();
  }, [purchasesProducts, categories]);

  useEffect(() => {
    if (error && errorCategories) {
      setPurchasesProductsError(
        "Failed to load categories and purchases products"
      );
    } else if (error) {
      setPurchasesProductsError("Failed to load purchases products");
    } else if (errorCategories) {
      setPurchasesProductsError("Failed to load categories");
    }
  }, [error, errorCategories]);

  return {
    loadingPurchasesProducts,
    loadingPurchasesProductsByCategory,
    purchasesProductsError,
    purchasesProducts,
    purchasesProductsByCategory,
    purchasesProductsWithPricing,
  };
};

export default usePurchasesProducts;
