const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export function isBetween(pastDate, dateToCheck, futureDate) {
  return pastDate < dateToCheck && dateToCheck < futureDate;
}

export function getQuarterStartDate(quarter) {
  var date = new Date();
  date.setFullYear(date.getFullYear(), 3 * (quarter - 1), 1);
  return date;
}

export function daysBetween(date, futureDate) {
  return Math.round(
    (futureDate.getTime() - date.getTime()) / MILLISECONDS_IN_DAY
  );
}

export function daysUntil(futureDate) {
  return (
    Math.round((futureDate.getTime() - Date.now()) / MILLISECONDS_IN_DAY) + 1
  );
}

export function daysSince(pastDate) {
  return Math.round((Date.now() - pastDate.getTime()) / MILLISECONDS_IN_DAY);
}

export function timeUntil(futureDate) {
  return futureDate.getTime() - Date.now();
}

var dateToday = new Date();

export const dateTodayUSAformat =
  (dateToday.getMonth() + 1).toString() +
  "/" +
  dateToday.getDate() +
  "/" +
  dateToday.getFullYear();

export function dateNdaysFromNowFormatted(n) {
  var date = new Date(); // Now
  date.setDate(date.getDate() + n);
  return (
    (date.getMonth() + 1).toString() +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear()
  );
}

export function formatDate(date) {
  return (
    (date.getMonth() + 1).toString() +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear()
  );
}

export function dateNdaysFromDateFormatted(date, n) {
  date.setDate(date.getDate() + n);
  return (
    (date.getMonth() + 1).toString() +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear()
  );
}

export function getTime(date) {
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export function getDate(date) {
  return (
    (date.getMonth() + 1).toString() +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear()
  );
}

export function getDateLong(date) {
  return date.toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}
export function getDateShort(date) {
  return date.toLocaleString("default", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export function getWeekNumber(date) {
  let onejan = new Date(date.getFullYear(), 0, 1);
  let week = Math.ceil(
    ((date.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
  );
  return week - 1;
}

// Months in javascript start at 0,
// Days start at 1,
// so by using 0 as the day it will give us the last day of the previous
// month.
export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function firstDayOfYear(date) {
  return new Date(date.getFullYear(), 0, 1);
}
