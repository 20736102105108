import React from "react";
import { addMoney, subtractMoney } from "../../../../util/money";
import { toUSD } from "../../../../util/formatters";

function OutstandingInvoicesTotal(props) {
  const calculateTotal = (list) => {
    console.log(list);
    const result = list.reduce(
      (previousValue, currentValue) =>
        addMoney(
          previousValue,
          subtractMoney(currentValue["amount_due"], currentValue["amount_paid"])
        ),
      0
    );
    return result;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        textAlign: "right",
      }}
    >
      <table width="50%">
        <thead>
          <tr>
            <th width="70%"></th>
            <th width="30%"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Outstanding Balance (USD) </td>
            <td>{toUSD(calculateTotal(props.lineItems), "total")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default OutstandingInvoicesTotal;
