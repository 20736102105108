import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./addProductsAndServices.css";
import CurrencyInput from "../../../components/Forms/CurrencyInput";
import PriceInput from "../../../components/Forms/documents/PriceInput";
import Input from "../../../components/Forms/Input";
import TextArea from "../../../components/Forms/TextArea";
import SearchableDropdownWithCreate from "../../../components/Forms/SearchableDropdownWithCreate";
import { toPercentage } from "../../../util/formatters";
import HelpIcon from "../../../components/Icons/HelpIcon";

import { PRECISION_DICTIONARY } from "../../../util/formatters";

import { IoTrashOutline } from "react-icons/io5";

import { isEqual } from "../../../util/jsonUtil";
import { MdQuestionAnswer } from "react-icons/md";
import { getCategories, getCategory, getProduct } from "../../Site/api";

import SearchableWithButton from "../../../components/Forms/SearchableWithButton";
import { findIndex } from "../../../util/jsonUtil";
import { propTypes } from "react-bootstrap/esm/Image";
import { api_url } from "../../../util/environment";
import { currentBusiness } from "../../../features/authentication/util";
import NumericInput from "../../../components/Forms/NumericInput";
import SearchableModal from "../../../components/Forms/SearchableModal";
import Checkbox from "../../../components/Forms/Checkbox";

function AddProductsAndServices(props) {
    let navigate = useNavigate();
    let { id } = useParams();

    const [name, setName] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [modelNumber, setModelNumber] = useState("");
    const [mfrPartNumber, setMfrPartNumber] = useState("");
    const [mfrModelNumber, setMfrModelNumber] = useState("");
    const [sellThis, setSellThis] = useState(false);
    const [buyThis, setBuyThis] = useState(false);
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("0.00");
    const [quantityPricings, setQuantityPricings] = useState([
        { starting_quantity: 1, ending_quantity: null, price: "0.00" },
    ]);
    const [quantityPricingsOrig, setQuantityPricingsOrig] = useState([]);
    const [valid, setValid] = useState(true);
    const [incomeAccount, setIncomeAccount] = useState("");
    const [expenseAccount, setExpenseAccount] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSalesCategory, setSelectedSalesCategory] = useState(null);
    const [selectedPurchaseCategory, setSelectedPurchaseCategory] =
        useState(null);
    const [categories, setCategories] = useState([]);
    const [salesCategories, setSalesCategories] = useState([]);
    const [purchaseCategories, setPurchaseCategories] = useState([]);
    const [tvc, setTvc] = useState("0.00");
    const [stock, setStock] = useState(0);

    const [tax, setTax] = useState("");

    const [taxList, setTaxList] = useState([
        ["sales", 9],
        ["whatever", 10],
    ]);
    const [incomeList, setIncomeList] = useState([
        ["sales", 9],
        ["whatever", 10],
    ]);
    const [expenseList, setExpenseList] = useState([
        ["sales", 9],
        ["whatever", 10],
    ]);

    useEffect(() => {
        if (props.type == "sales") {
            getCategories("sales").then((data) => {
                console.log("Sales Categories: ", data);
                setSalesCategories(data);
                if (props.action == "edit") {
                    getProduct(id).then((productData) => {
                        const product = productData[0];
                        setName(product.name);
                        setPartNumber(product.code_number);
                        setModelNumber(product.model_number);
                        setDescription(product.description);
                        let found = data.find(
                            (category) => category.id == product.category_id
                        );
                        if (found) {
                            setSelectedSalesCategory(found);
                            setSellThis(true);
                        }
                        getCategories("purchases").then((data) => {
                            console.log("Purchases Categories: ", data);
                            setPurchaseCategories(data);
                            if (props.action == "edit") {
                                let found = data.find(
                                    (category) =>
                                        category.id ==
                                        product.purchase_category_id
                                );
                                if (found) {
                                    setSelectedPurchaseCategory(found);
                                    setBuyThis(true);
                                }
                            }
                        });

                        if (product.tvc) {
                            setTvc(product.tvc);
                        }
                        if (product.stock) {
                            setStock(product.stock);
                        }
                    });
                }
                //adding a product from a category action dropdown on category page
                else if (props.action == "add" && id) {
                    let found = data.find((category) => category.id == id);
                    if (found) {
                        setSelectedSalesCategory(found);
                        setSellThis(true);
                    }
                    getCategories("purchases").then((purchaseData) => {
                        setPurchaseCategories(purchaseData);
                        console.log("purchase Categories: ", purchaseData);
                        let found = purchaseData.find(
                            (category) => category.id == id
                        );
                        if (found) {
                            setSelectedPurchaseCategory(found);
                            setBuyThis(true);
                        }
                    });
                } else {
                    getCategories("purchases").then((data) => {
                        console.log("Purchases Categories: ", data);
                        setPurchaseCategories(data);
                    });
                    setSellThis(true);
                }
            });
        } else if (props.type == "purchases") {
            getCategories("purchases").then((data) => {
                console.log("Purchases Categories: ", data);
                setPurchaseCategories(data);
                if (props.action == "edit") {
                    getProduct(id).then((productData) => {
                        const product = productData[0];
                        setName(product.name);
                        setPartNumber(product.code_number);
                        setModelNumber(product.model_number);
                        setDescription(product.description);
                        let found = data.find(
                            (category) =>
                                category.id == product.purchase_category_id
                        );
                        if (found) {
                            setSelectedPurchaseCategory(found);
                            setBuyThis(true);
                        }
                        getCategories("sales").then((data) => {
                            console.log("sales Categories: ", data);
                            setSalesCategories(data);
                            if (props.action == "edit") {
                                let found = data.find(
                                    (category) =>
                                        category.id == product.category_id
                                );
                                if (found) {
                                    setSelectedSalesCategory(found);
                                    setSellThis(true);
                                }
                            }
                        });
                        if (product.tvc) {
                            setTvc(product.tvc);
                        }
                        if (product.stock) {
                            setStock(product.stock);
                        }
                    });
                }
                //adding a product from a category action dropdown on category page
                else if (props.action == "add" && id) {
                    let found = data.find((category) => category.id == id);
                    if (found) {
                        setSelectedPurchaseCategory(found);
                        setBuyThis(true);
                    }
                    getCategories("sales").then((salesData) => {
                        setSalesCategories(salesData);
                        console.log("sales Categories: ", salesData);
                        let found = salesData.find(
                            (category) => category.id == id
                        );
                        if (found) {
                            setSelectedSalesCategory(found);
                            setSellThis(true);
                        }
                    });
                } else {
                    getCategories("sales").then((data) => {
                        console.log("sales Categories: ", data);
                        setSalesCategories(data);
                    });
                    setBuyThis(true);
                }
            });
        }
        if (props.action == "edit") {
            fetch(
                api_url +
                    "/site/products-and-services/" +
                    id +
                    "/quantity-pricing"
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setQuantityPricings(data);
                    setQuantityPricingsOrig(JSON.parse(JSON.stringify(data)));
                });
        }
    }, []);

    useEffect(() => {
        var allValid = true;
        for (var i in quantityPricings) {
            console.log(quantityPricings.length);
            console.log(i + 1);
            if (
                parseInt(quantityPricings[i].starting_quantity) >
                    parseInt(quantityPricings[i].ending_quantity) ||
                (quantityPricings[i].ending_quantity == null &&
                    parseInt(i) + 1 != quantityPricings.length)
            ) {
                if (i < quantityPricings.length) {
                    console.log("true");
                    const input = document.getElementById(
                        "validationFeedback" + i
                    );
                    input.classList.add("is-invalid");
                    allValid = false;
                }
            } else {
                console.log("false");
                const input = document.getElementById("validationFeedback" + i);
                input.classList.remove("is-invalid");
            }
        }
        if (allValid) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [quantityPricings]);

    const buttonList = ["Create a new tax"];

    async function submit() {
        const form = {
            name: name,
            code_number: partNumber,

            type: props.type,
            description: description,
            category_id: selectedSalesCategory
                ? selectedSalesCategory.id
                : null,
            tvc: tvc,
            stock: stock,
            purchase_category_id: selectedPurchaseCategory
                ? selectedPurchaseCategory.id
                : null,
            model_number: modelNumber,
        };
        if (!selectedPurchaseCategory && !selectedSalesCategory) {
            alert("Please select a category");
            return false;
        }
        if (props.action == "edit") {
            console.log("EDIT");
            await fetch(api_url + "/site/products-and-services/" + id, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(form),
            })
                .then((results) => results.json())
                .then((json_res) => {
                    console.log(json_res);
                });
            for (var i in quantityPricings) {
                console.log(quantityPricings[i]);
                if (quantityPricings[i].id < 0) {
                    console.log("POST");
                    var quantityPricingBody = quantityPricings[i];
                    delete quantityPricingBody["id"];
                    quantityPricingBody["products_and_services_id"] = id;
                    console.log(quantityPricingBody);
                    fetch(api_url + "/site/quantity-pricing/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(quantityPricingBody),
                    })
                        .then((results) => results.json())
                        .then((json_res) => {
                            console.log(json_res);
                        });
                }
            }
            var found_i = false;
            var found_index = null;
            for (var i in quantityPricingsOrig) {
                for (var j in quantityPricings) {
                    if (quantityPricingsOrig[i].id == quantityPricings[j].id) {
                        found_i = true;
                        found_index = j;
                    }
                }
                if (found_i) {
                    if (
                        !isEqual(
                            quantityPricingsOrig[i],
                            quantityPricings[found_index]
                        )
                    ) {
                        console.log("PUT");
                        var quantityPricingBody = quantityPricings[found_index];
                        delete quantityPricingBody["id"];
                        if (quantityPricingBody.ending_quantity == "") {
                            quantityPricingBody.ending_quantity = null;
                        }
                        fetch(
                            api_url +
                                "/site/quantity-pricing/" +
                                quantityPricingsOrig[found_index].id,
                            {
                                method: "PUT",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(quantityPricingBody),
                            }
                        )
                            .then((results) => results.json())
                            .then((json_res) => {
                                console.log(json_res);
                            });
                    }
                } else {
                    console.log("DELETE" + quantityPricingsOrig[i].id);

                    fetch(
                        api_url +
                            "/site/quantity-pricing/" +
                            quantityPricingsOrig[i].id,
                        {
                            method: "DELETE",
                        }
                    );
                }
                found_i = false; //reset boolean
                found_index = null;
            }
        } else {
            console.log(form);
            await fetch(
                api_url +
                    "/site/products-and-services/user/" +
                    currentBusiness(),
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(form),
                }
            )
                .then((results) => results.json())
                .then((json_res) => {
                    console.log(json_res);
                    const new_id = json_res.rows[0].id;
                    for (var i in quantityPricings) {
                        var body = {
                            starting_quantity:
                                quantityPricings[i].starting_quantity,
                            ending_quantity:
                                quantityPricings[i].ending_quantity == ""
                                    ? null
                                    : quantityPricings[i].ending_quantity,
                            price: quantityPricings[i].price,
                            products_and_services_id: new_id,
                        };
                        fetch(api_url + "/site/quantity-pricing", {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(body),
                        })
                            .then((results) => results.json())
                            .then((json_res) => {
                                console.log(json_res);
                            });

                        console.log(body);
                    }
                });
        }

        console.log(valid);
        return true;
    }

    return (
        <div className="addProducts">
            <h1>Add a Product or Service</h1>
            <h5
                className="productSubheading"
                style={{ whiteSpace: "pre-wrap", fontWeight: "normal" }}
            >
                Products and services that you buy from vendors can be used as
                items on Bills to the record purchases, and the ones that you
                sell to customers can be used as items on Invoices to record
                sales.
            </h5>
            <div className="addProductForm">
                <div className="formItem">
                    <Input
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="formItem">
                    <Input
                        label="Part Number / Code Number"
                        value={partNumber}
                        onChange={(e) => setPartNumber(e.target.value)}
                    />
                    <Input
                        label="Model Number"
                        value={modelNumber}
                        onChange={(e) => setModelNumber(e.target.value)}
                    />
                    {/* <Input
            label="Manufacturer's Part Number"
            value={mfrPartNumber}
            onChange={(e) => setMfrPartNumber(e.target.value)}
          />
          <Input
            label="Manufacturer's Model Number"
            value={mfrModelNumber}
            onChange={(e) => setMfrModelNumber(e.target.value)}
          /> */}
                </div>
                <div className="formItem">
                    <Checkbox
                        label="Sell this"
                        value={sellThis}
                        onChange={(val) => {
                            if (!val) {
                                setSelectedSalesCategory(null);
                            }
                            setSellThis(val);
                        }}
                    />
                    {sellThis && (
                        <SearchableModal
                            label={"Sales Category"}
                            help={
                                <HelpIcon message="Account in which sale of products or services in this category will be recognized" />
                            }
                            list={salesCategories}
                            keys={{ name: (name) => name }}
                            onItemClick={(val) => setSelectedSalesCategory(val)}
                            value={
                                selectedSalesCategory
                                    ? selectedSalesCategory.name
                                    : "Select a Category"
                            }
                        />
                    )}
                </div>
                <div className="formItem">
                    {" "}
                    <Checkbox
                        label="Buy this"
                        value={buyThis}
                        onChange={(val) => {
                            if (!val) {
                                setSelectedPurchaseCategory(null);
                            }
                            setBuyThis(val);
                        }}
                    />
                    {buyThis && (
                        <SearchableModal
                            label={"Purchase Category"}
                            help={
                                <HelpIcon message="Account in which purchase of products or services in this category will be recognized" />
                            }
                            list={purchaseCategories}
                            keys={{ name: (name) => name }}
                            onItemClick={(val) =>
                                setSelectedPurchaseCategory(val)
                            }
                            value={
                                selectedPurchaseCategory
                                    ? selectedPurchaseCategory.name
                                    : "Select a Category"
                            }
                        />
                    )}
                </div>

                <div className="formItem">
                    <TextArea
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="formItem">
                    <NumericInput
                        label="Number in Stock"
                        value={stock}
                        onChange={(e) => setStock(e)}
                        maxDecimals={0}
                    />
                </div>
                <div className="formItem">
                    <label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                    >
                        Truly Variable Cost
                    </label>
                    <CurrencyInput
                        value={tvc}
                        onChange={(val) => {
                            setTvc(val);
                        }}
                        maxLength={11}
                        maxDecimals={2}
                    />
                </div>
                <div className="formItem">
                    {quantityPricings.map((item, index) => {
                        var endingQuantityContents = "∞";
                        if (quantityPricings[index].ending_quantity != null) {
                            endingQuantityContents =
                                quantityPricings[index].ending_quantity;
                        }

                        var endingQuantityPlaceholder = "";
                        if (quantityPricings.length == index + 1) {
                            endingQuantityPlaceholder = "∞";
                        }
                        return (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{ width: "40%" }}>
                                        <label
                                            className="form-label"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            Price
                                        </label>
                                        <CurrencyInput
                                            value={
                                                quantityPricings[index].price
                                            }
                                            onChange={(val) => {
                                                var pricings = quantityPricings;
                                                pricings[index].price = val;
                                                setQuantityPricings(
                                                    JSON.parse(
                                                        JSON.stringify(pricings)
                                                    )
                                                );
                                            }}
                                            maxLength={11}
                                            maxDecimals={2}
                                        />
                                    </div>
                                    <div style={{ width: "20%" }}>
                                        <label
                                            className="form-label"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            Starting Quantity
                                        </label>
                                        <CurrencyInput
                                            value={
                                                quantityPricings[index]
                                                    .starting_quantity
                                            }
                                            onChange={(val) => {
                                                var pricings = quantityPricings;
                                                pricings[
                                                    index
                                                ].starting_quantity = val;
                                                setQuantityPricings(
                                                    JSON.parse(
                                                        JSON.stringify(pricings)
                                                    )
                                                );
                                            }}
                                            maxLength={11}
                                            maxDecimals={
                                                PRECISION_DICTIONARY["quantity"]
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div style={{ width: "20%" }}>
                                        <label
                                            className="form-label"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            Ending Quantity
                                        </label>
                                        <CurrencyInput
                                            id={"validationFeedback" + index}
                                            className={""}
                                            value={
                                                quantityPricings[index]
                                                    .ending_quantity
                                            }
                                            onChange={(val) => {
                                                var pricings = quantityPricings;

                                                pricings[
                                                    index
                                                ].ending_quantity =
                                                    parseInt(val);

                                                if (
                                                    !quantityPricings[index + 1]
                                                ) {
                                                    pricings.push({
                                                        id:
                                                            0 -
                                                            quantityPricings.length -
                                                            1, //negative id to denote new item - starts counting from -1 if starting from empty
                                                        starting_quantity:
                                                            parseInt(val) + 1,
                                                        ending_quantity: null,
                                                        price: "0.00",
                                                    });
                                                } else {
                                                    pricings[
                                                        index + 1
                                                    ].starting_quantity =
                                                        parseInt(val) + 1;
                                                }

                                                setQuantityPricings(
                                                    JSON.parse(
                                                        JSON.stringify(pricings)
                                                    )
                                                );
                                            }}
                                            maxLength={11}
                                            maxDecimals={
                                                PRECISION_DICTIONARY["quantity"]
                                            }
                                            feedback={
                                                <div class="invalid-feedback">
                                                    {
                                                        "Ensure ending quantity > starting quantity"
                                                    }
                                                </div>
                                            }
                                            placeholder={
                                                endingQuantityPlaceholder
                                            }
                                        />
                                    </div>

                                    {/* <div style={{ position: "absolute", float: "left" }}>
                  Ensure ending quantity > starting quantity
                </div> */}
                                </div>
                                {quantityPricings.length == index + 1 &&
                                index != 0 ? (
                                    <div
                                        className="hoverableRow"
                                        style={{ width: "1em" }}
                                    >
                                        <div
                                            className="rowDelete"
                                            onClick={() => {
                                                var pricings = quantityPricings;
                                                pricings[
                                                    pricings.length - 2
                                                ].ending_quantity = "";
                                                pricings.pop();
                                                setQuantityPricings(
                                                    JSON.parse(
                                                        JSON.stringify(pricings)
                                                    )
                                                );
                                                console.log(pricings);
                                            }}
                                        >
                                            <IoTrashOutline />
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        );
                    })}
                </div>

                {/* <div className="formItem">
          Income Account
          <HelpIcon message="Account in which sale of this product or service will be recognized" />
        </div>
        <div className="formItem">
          {incomeAccount == "" ? (
            <button
              className="form-control"
              id="search-bar"
              type="text"
              onClick={() => setShowIncomeMenu(true)}
            >
              {" "}
              Select Income Account
            </button>
          ) : (
            <button
              style={{ textAlign: "left" }}
              className="form-control"
              id="search-bar"
              type="text"
              onClick={() => setShowIncomeMenu(true)}
            >
              {" "}
              {incomeAccount[0] + "  " + incomeAccount[1]}
            </button>
          )}
        </div>
        {showIncomeMenu ? (
          <div className="formItem">
            <div
              style={{
                position: "absolute",
                zIndex: "100",
              }}
            >
              <SearchableDropdownWithCreate
                list={incomeList}
                onItemClick={selectIncomeAccount}
                index={null}
                buttonList={["Create a new account"]}
                functions={[() => console.log("first")]}
              />{" "}
            </div>
          </div>
        ) : null}
        <div className="formItem">
          <label
            className="form-label"
            style={{ marginBottom: "0px", marginTop: "10px" }}
          >
            Tax
          </label>
          <div>
            {tax == "" ? (
              <button
                className="form-control"
                id="search-bar"
                type="text"
                onClick={() => setShowTaxMenu(true)}
              >
                {" "}
                Select Sales Tax
              </button>
            ) : (
              <button
                style={{ textAlign: "left" }}
                className="form-control"
                id="search-bar"
                type="text"
                onClick={() => setShowTaxMenu(true)}
              >
                {" "}
                {tax[0] + "  " + tax[1]}
              </button>
            )}
          </div>
        </div>
        {showTaxMenu ? (
          <div className="formItem">
            <div
              style={{
                position: "absolute",
                zIndex: "100",
              }}
            >
              <SearchableDropdownWithCreate
                list={taxList}
                onItemClick={selectTax}
                index={null}
                buttonList={buttonList}
                functions={[() => console.log("first")]}
              />{" "}
            </div>
          </div>
        ) : null}*/}
            </div>
            <div
                className="formItem"
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                <div>
                    <button
                        type="submit"
                        className="btn btn-success "
                        style={{
                            "padding-right": "20px",
                            "padding-left": "20px",
                        }}
                        onClick={() =>
                            submit().then((valid) => {
                                if (valid) {
                                    navigate(
                                        "/" +
                                            props.type +
                                            "/products-and-services"
                                    );
                                }
                            })
                        }
                    >
                        Save
                    </button>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn btn-light "
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddProductsAndServices;
