import React from 'react'

/* Props 
    className
    label
    dropdownList
    objKey
    listId
    value
    onChange
*/

function SearchableDropdown(props) {

  return (
    <div className={props.className}>
        <label className="form-label" htmlFor="searchableDropdown">{props.label}</label>
        <input required={props.required} className="form-control" list={props.listId} name={props.listId} id="searchableDropdown" value={props.value} onChange={props.onChange}/>

        <datalist id={props.listId} >
            {props.dropdownList.map((item, index) => {
                return(
                    <option className="companyOption" key={item.id} value={item[props.objKey]}></option>
                )
            })}
        </datalist>
    </div>
  )
}

export default SearchableDropdown