import { daysUntil } from "../../util/dates";
import { toTwoDecimals } from "../../util/formatters";
import { addMoney, subtractMoney } from "../../util/money";

export async function getInvoices() {
  const getCompanies = await fetch(
    "http://localhost:5000/sales/documents/invoices"
  );
  return await getCompanies.json();
}

//takes in a list of invoices from a company and returns its aging totals
export function getAgingTotals(invoices, companyName) {
  var agingItem = {
    name: companyName,
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "0.00",
  };
  const companyInvoices = invoices.filter(
    (invoice) =>
      invoice.to_name == companyName &&
      invoice.status != "draft" &&
      invoice.status != "unsent"
  );

  for (var i in companyInvoices) {
    const daysUntilDue = daysUntil(new Date(companyInvoices[i].due_date));
    const amount_left = subtractMoney(
      companyInvoices[i].amount_due,
      companyInvoices[i].amount_paid
    );
    console.log(amount_left + " - amount left");
    if (daysUntilDue >= 0) {
      agingItem.current = toTwoDecimals(
        addMoney(agingItem.current, amount_left).toString()
      );
    } else if (-30 <= daysUntilDue && daysUntilDue < 0) {
      agingItem.firstThirty = toTwoDecimals(
        addMoney(agingItem.firstThirty, amount_left).toString()
      );
    } else if (-60 <= daysUntilDue && daysUntilDue < -30) {
      agingItem.secondThirty = toTwoDecimals(
        addMoney(agingItem.secondThirty, amount_left).toString()
      );
    } else if (-90 <= daysUntilDue && daysUntilDue < -60) {
      agingItem.thirdThirty = toTwoDecimals(
        addMoney(agingItem.thirdThirty, amount_left).toString()
      );
    } else {
      agingItem.fourthThirty = toTwoDecimals(
        addMoney(agingItem.fourthThirty, amount_left).toString()
      );
    }
  }
  var total = "0.00";
  for (var i in agingItem) {
    if (i != "name" && i != "total") {
      total = toTwoDecimals(addMoney(agingItem[i], total).toString());
    }
  }
  agingItem.total = total;

  return agingItem;
}

function getUniqueNames(invoices) {
  return [...new Set(invoices.flat().map((item) => item.to_name))];
}

export async function getAgingItems() {
  const data = await getInvoices();
  return await helper(data);
}

async function helper(data) {
  var itemList = [];
  const companies = getUniqueNames(data);
  console.log(data);
  console.log(companies);

  for (var i in companies) {
    itemList.push(getAgingTotals(data, companies[i]));
  }
  return itemList;
}
