//TAG:AUTHENTICATION

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import WarningMessage from "../../components/Messages/WarningMessage";

import { signupUser } from "../../features/authentication/api";
import Input from "../../components/Forms/Input";
import { useNavigate } from "react-router-dom";

export default function SignUp(props) {
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [error, setError] = useState(null);
    const [businessName, setBusinessName] = useState(null);
    const [accessKey, setAccessKey] = useState(null);

    const handleSignup = async (e) => {
        e.preventDefault();
        try {
            const token = await signupUser({
                email,
                password,
                businessName,
                accessKey,
            });
            console.log(token);

            if (token.error) {
                setError(token.error);
                return true;
            } else {
                setError(null);
                props.setUser(token);
                return false;
            }
        } catch (error) {
            console.log(error);
            setError(error.message);
            return true;
        }
    };

    const reset = () => {
        setError(null);
        setEmail(null);
        setPassword(null);
    };

    let nav = useNavigate();

    return (
        <>
            <Modal
                show={props.popup.show}
                onHide={() => {
                    props.handleClose();
                    reset();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <WarningMessage message={error} />}
                    <Input
                        id="email-input-signup"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Input
                        id="password-input-signup"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Input
                        id="business-name-input"
                        label="Business Name"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                    />
                    <Input
                        id="access-key-input"
                        label="Access Key"
                        value={accessKey}
                        onChange={(e) => setAccessKey(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.handleClose();
                            reset();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        onClick={(e) => {
                            if (
                                !email ||
                                !password ||
                                !businessName ||
                                !accessKey
                            )
                                return setError("Please fill out all fields");
                            else {
                                setError(null);
                            }
                            handleSignup(e).then((error) => {
                                if (!error) {
                                    props.handleClose();
                                    nav("/launchpad");
                                }
                            });
                        }}
                    >
                        {props.submit}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
