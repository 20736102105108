import React, { useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { ALPHANUMERIC } from "../../../util/formatters";

function validate(
  event,
  validationString,
  currentLength,
  maxLength,
  extraAllowableChars
) {
  if (
    !validationString.includes(event.key) &&
    !extraAllowableChars.includes(event.key)
  ) {
    if (
      event.key != "Backspace" &&
      event.key != "Delete" &&
      event.key != "ArrowLeft" &&
      event.key != "ArrowRight"
    ) {
      event.preventDefault();
    }
  }
  if (currentLength >= maxLength) {
    if (
      event.key != "Backspace" &&
      event.key != "Delete" &&
      event.key != "ArrowLeft" &&
      event.key != "ArrowRight"
    ) {
      if (
        event.view.getSelection().baseOffset -
          event.view.getSelection().extentOffset ==
        0
      ) {
        event.preventDefault();
      }
    }
  }
}

export default class AlphanumericInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.initialValue };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({ value: this.props.initialValue });
    }
  }

  handleChange = (e) => {
    const cleaned = e.target.value.replace(/&nbsp;/g, " ");
    console.log(cleaned);
    this.setState({ value: cleaned });
    this.props.changeText(cleaned);
  };
  render() {
    return (
      <div
        style={{ width: this.props.width }}
        id={this.props.id ? this.props.id : "alphanumeric-input"}
      >
        {this.props.disabled ? (
          this.state.value
        ) : (
          <ContentEditable
            html={this.state.value}
            onChange={(e) => {
              this.handleChange(e);
            }}
            onKeyDown={(e) => {
              if (this.state.value) {
                validate(
                  e,
                  ALPHANUMERIC,
                  this.state.value.length,
                  this.props.limit,
                  this.props.extraAllowableChars
                );
              }
            }}
            placeholder={this.props.placeholder}
          />
        )}
      </div>
    );
  }
}
