import React from "react";
import { IoSettingsSharp } from "react-icons/io5";

function SettingsIcon(props) {
  return (
    <IoSettingsSharp
      color="black"
      className="settingsIcon"
      fontSize="16px"
      onClick={() => props.onClick()}
    />
  );
}

export default SettingsIcon;
