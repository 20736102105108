import "./App.css";

// import Button from "react-bootstrap/Button";
// import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownSidebar from "./components/DropdownSidebar/DropdownSidebar";
import { createContext } from "react";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";

import Launchpad from "./pages/Launchpad/Launchpad";
import Dashboard from "./pages/Dashboards/Dashboard";

//Sales
import WrappedContact from "./pages/Contacts/List/WrappedContacts";
import WrappedSummary from "./pages/Contacts/Summary/WrappedSummary";
import WrappedContactForm from "./pages/Contacts/Form/WrappedContactForm";

import EstimatesForm from "./pages/Sales/Estimates/EstimatesForm";
import Estimates from "./pages/Sales/Estimates/List/Estimates";
import BudgetaryEstimates from "./pages/Sales/BudgetaryEstimates/BudgetaryEstimates";
import BudgetaryEstimatesForm from "./pages/Sales/BudgetaryEstimates/BudgetaryEstimatesForm";

import SaleOrders from "./pages/Sales/SaleOrders/List/SaleOrders";
import SaleOrdersForm from "./pages/Sales/SaleOrders/SaleOrdersForm";

import WrappedInvoices from "./pages/Sales/Invoices/List/WrappedInvoices";
// import WrappedAddInvoice from './pages/Sales/Invoices/Form/WrappedAddInvoice';
import InvoicesForm from "./pages/Sales/Invoices/InvoicesForm";

import RecurringInvoicesForm from "./pages/Sales/RecurringInvoices/RecurringInvoicesForm";
import RecurringInvoices from "./pages/Sales/RecurringInvoices/List/RecurringInvoices";
import Checkouts from "./pages/Sales/Checkouts/Checkouts";

import Payments from "./pages/Sales/Payments/Payments";

import CustomerStatements from "./pages/Sales/CustomerStatements/CustomerStatements";

import ProductsAndServices from "./pages/Sales/ProductsAndServices/ProductsAndServices";
import AddProductsAndServices from "./pages/Sales/ProductsAndServices/AddProductsAndServices";
//Purchases
import Bills from "./pages/Purchases/Bills/List/Bills";
import BillsForm from "./pages/Purchases/Bills/BillsForm";
import PurchaseOrders from "./pages/Purchases/PurchaseOrders/List/PurchaseOrders";
import PurchaseOrdersForm from "./pages/Purchases/PurchaseOrders/PurchaseOrdersForm";
import VendorStatements from "./pages/Purchases/VendorStatements/VendorStatements";

//Accounting
import WrappedChartOfAccounts from "./pages/Accounting/ChartOfAccounts/WrappedChartOfAccounts";

//Reports
import AgingSummaryAR from "./pages/Reports/AgingSummaryAR/AgingSummaryAR";
import Reports from "./pages/Reports/Reports";
import AccountBalances from "./pages/Reports/AccountBalances/AccountBalances";
import IncomeStatement from "./pages/Reports/IncomeStatement/IncomeStatement";
import CashFlow from "./pages/Reports/CashFlow/CashFlow";
import BalanceSheet from "./pages/Reports/BalanceSheet/BalanceSheet";
import GeneralLedger from "./pages/Reports/GeneralLedger/GeneralLedger";

//Site
import Categories from "./pages/Site/Categories/Categories";
import CategoriesForm from "./pages/Site/Categories/CategoriesForm";
import RecurringBills from "./pages/Purchases/RecurringBills/List/RecurringBills";
import RecurringBillsForm from "./pages/Purchases/RecurringBills/RecurringBillsForm";
import Transactions from "./pages/Accounting/Transactions/Transactions";
import JournalTransaction from "./pages/Accounting/Transactions/JournalTransaction";
import HomePage from "./pages/LandingPage/HomePage";
import Advisors from "./pages/Advisors/Advisors";
import TaxFiling from "./pages/TaxFiling/TaxFiling";
import Reconciliation from "./pages/Accounting/Reconciliation/Reconciliation";
import HireABookkeeper from "./pages/Accounting/Bookkeepers/HireABookkeeper";
import useSettings from "./features/settings/useSettings";
import Console from "./pages/Console/Console";

//Settings
import UserManagement from "./pages/Settings/UserManagement/UserManagement";
import InvoiceCustomization from "./pages/Settings/InvoiceCustomization";
import DatesAndCurrency from "./pages/Settings/DatesAndCurrency";
import SalesTaxes from "./pages/Settings/SalesTaxes";
import DefaultAccounts from "./pages/Settings/DefaultAccounts";
import Payouts from "./pages/Settings/Payouts";
import BusinessProfile from "./pages/Settings/BusinessProfile";
import CompanySignatory from "./pages/Settings/CompanySignatory";
import FundingAccount from "./pages/Settings/FundingAccount";
import TaxProfile from "./pages/Settings/TaxProfile";
import PayrollHistoryImport from "./pages/Settings/PayrollHistoryImport";
import DataExport from "./pages/Settings/DataExport";
import PaymentMethods from "./pages/Settings/PaymentMethods";
import Billing from "./pages/Settings/Billing";
import PersonalInformation from "./pages/Profile/PersonalInformation";
import Emails from "./pages/Profile/Emails";
import Password from "./pages/Profile/Password";
import Notifications from "./pages/Profile/Notifications";
import Businesses from "./pages/Profile/Businesses";
import Subscription from "./pages/Profile/Subscription";
import DataImport from "./pages/Settings/DataImport";
import Login from "./pages/LandingPage/Login";
import useUser from "./features/authentication/hooks/useUser";
import UserForm from "./pages/Settings/UserManagement/UserForm";
import Rolls from "./pages/Settings/UserManagement/Rolls";
import Permissions from "./pages/Settings/UserManagement/Permissions";
import PermissionGate from "./features/authentication/components/PermissionGate";
import SettingsPermissionGate from "./features/authentication/components/SettingsPermissionGate";
import usePageTracking from "./hooks/usePageTracking.";

export const SettingsContext = createContext();

function App() {

    usePageTracking();

    var contacts = ["test"];
    var contacts2 = ["test"];
    const { settings, user, setUser, clearUser } = useSettings();
    console.log(settings);

    if (!user?.token) {
        return (
            <div className="App">
                    <Routes>
                        <Route
                            path="*"
                            element={<HomePage setUser={setUser} />}
                        />
                    </Routes>
            </div>
        );
    }

    var permissions = user?.user?.permissions?.map(function (obj) {
        return obj.identifier;
    });

    var isAdmin = user?.user?.is_admin;

    return (
        <div className="App">
            {
                <SettingsContext.Provider
                    value={{
                        user: user,
                        settings: settings,
                        logout: clearUser,
                    }}
                >
                        <DropdownSidebar />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <HomePage
                                        setUser={setUser}
                                        token={user?.token}
                                    />
                                }
                            />
                            <Route
                                path="/#"
                                element={
                                    <HomePage
                                        setUser={setUser}
                                        token={user?.token}
                                    />
                                }
                            />
                            <Route
                                path="/launchpad"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["launchpad"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Launchpad />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/dashboard"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["dashboard"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Dashboard />
                                    </PermissionGate>
                                }
                            />

                            {/* -----------------------------------------Sales----------------------------------------- */}

                            <Route
                                path="/sales/customers"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["customers"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedContact
                                            type={"sales"}
                                            category={"customers"}
                                            contacts={contacts}
                                            test={contacts2}
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/customers/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["customers"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedSummary
                                            type={"sales"}
                                            category={"customers"}
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/customers/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["customers"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedContactForm
                                            type={"sales"}
                                            category={"customers"}
                                            action="add"
                                            returnPath="/sales/customers"
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/customers/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["customers"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedContactForm
                                            type={"sales"}
                                            category={"customers"}
                                            action="edit"
                                            returnPath="/sales/customers"
                                        />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/estimates"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["estimates"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Estimates />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/estimates/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["estimates"]}
                                        isAdmin={isAdmin}
                                    >
                                        <EstimatesForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/estimates/add/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["estimates"]}
                                        isAdmin={isAdmin}
                                    >
                                        <EstimatesForm idType={"customer"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/estimates/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["estimates"]}
                                        isAdmin={isAdmin}
                                    >
                                        <EstimatesForm idType={"document"} />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/budgetary-estimates"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "budgetary_estimates",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <BudgetaryEstimates />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/budgetary-estimates/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "budgetary_estimates",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <BudgetaryEstimatesForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/budgetary-estimates/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "budgetary_estimates",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <BudgetaryEstimatesForm />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/orders"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["sales_orders"]}
                                        isAdmin={isAdmin}
                                    >
                                        <SaleOrders />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/orders/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["sales_orders"]}
                                        isAdmin={isAdmin}
                                    >
                                        <SaleOrdersForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/orders/add/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["sales_orders"]}
                                        isAdmin={isAdmin}
                                    >
                                        <SaleOrdersForm idType={"customer"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/orders/convert/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["sales_orders"]}
                                        isAdmin={isAdmin}
                                    >
                                        <SaleOrdersForm idType={"convert"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/orders/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["sales_orders"]}
                                        isAdmin={isAdmin}
                                    >
                                        <SaleOrdersForm idType={"document"} />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/invoices"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["invoices"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedInvoices />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/invoices/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["invoices"]}
                                        isAdmin={isAdmin}
                                    >
                                        <InvoicesForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/invoices/add/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["invoices"]}
                                        isAdmin={isAdmin}
                                    >
                                        <InvoicesForm idType={"customer"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/invoices/convert/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["invoices"]}
                                        isAdmin={isAdmin}
                                    >
                                        <InvoicesForm idType={"convert"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/invoices/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["invoices"]}
                                        isAdmin={isAdmin}
                                    >
                                        <InvoicesForm idType={"document"} />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/recurring-invoices"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "recurring_invoices",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <RecurringInvoices />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/recurring-invoices/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "recurring_invoices",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <RecurringInvoicesForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/recurring-invoices/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "recurring_invoices",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <RecurringInvoicesForm
                                            idType={"document"}
                                        />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/checkouts"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["checkouts"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Checkouts />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/payments"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["payments"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Payments />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/customer-statements"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "customer_statements",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <CustomerStatements />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/products-and-services"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "sales_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <ProductsAndServices type={"sales"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/products-and-services/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "sales_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AddProductsAndServices
                                            type={"sales"}
                                            action="add"
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/products-and-services/add/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "sales_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AddProductsAndServices
                                            type={"sales"}
                                            action="add"
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/products-and-services/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "sales_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AddProductsAndServices
                                            type={"sales"}
                                            action="edit"
                                        />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/sales/categories"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "sales_categories",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <Categories type="sales" />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/categories/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "sales_categories",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <CategoriesForm type="sales" />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/sales/categories/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "sales_categories",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <CategoriesForm type="sales" />
                                    </PermissionGate>
                                }
                            />

                            {/* ---------------------------------------PURCHASES---------------------------------------- */}

                            <Route
                                path="/purchases/vendors"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["vendors"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedContact
                                            category={"vendors"}
                                            type={"purchases"}
                                            contacts={contacts}
                                            test={contacts2}
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/vendors/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["vendors"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedSummary
                                            category={"vendors"}
                                            type={"purchases"}
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/vendors/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["vendors"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedContactForm
                                            category={"vendors"}
                                            type={"purchases"}
                                            action="add"
                                            returnPath="/purchases/vendors"
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/vendors/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["vendors"]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedContactForm
                                            category={"vendors"}
                                            type={"purchases"}
                                            action="edit"
                                            returnPath="/purchases/vendors"
                                        />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/purchases/products-and-services"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchases_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <ProductsAndServices
                                            type={"purchases"}
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/products-and-services/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchases_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AddProductsAndServices
                                            type={"purchases"}
                                            action="add"
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/products-and-services/add/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchases_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AddProductsAndServices
                                            type={"purchases"}
                                            action="add"
                                        />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/products-and-services/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchases_products_and_services",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AddProductsAndServices
                                            type={"purchases"}
                                            action="edit"
                                        />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/purchases/categories"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchases_categories",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <Categories type="purchases" />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/categories/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchases_categories",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <CategoriesForm type="purchases" />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/categories/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchases_categories",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <CategoriesForm type="purchases" />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/purchases/orders"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchase_orders",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <PurchaseOrders />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/orders/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchase_orders",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <PurchaseOrdersForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/orders/add/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchase_orders",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <PurchaseOrdersForm idType={"vendor"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/orders/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "purchase_orders",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <PurchaseOrdersForm
                                            idType={"document"}
                                        />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/purchases/bills"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["bills"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Bills />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/bills/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["bills"]}
                                        isAdmin={isAdmin}
                                    >
                                        <BillsForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/bills/add/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["bills"]}
                                        isAdmin={isAdmin}
                                    >
                                        <BillsForm idType={"vendor"} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/bills/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["bills"]}
                                        isAdmin={isAdmin}
                                    >
                                        <BillsForm idType={"document"} />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/purchases/recurring-bills"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "recurring_bills",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <RecurringBills />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/recurring-bills/add"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "recurring_bills",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <RecurringBillsForm />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/purchases/recurring-bills/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "recurring_bills",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <RecurringBillsForm
                                            idType={"document"}
                                        />
                                    </PermissionGate>
                                }
                            />

                            <Route
                                path="/purchases/vendor-statements"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "vendor_statements",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <VendorStatements />
                                    </PermissionGate>
                                }
                            />

                            {/* ---------------------------------------ACCOUNTING---------------------------------------- */}

                            <Route
                                path="/accounting/transactions"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["transactions"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Transactions />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/accounting/transactions/journal"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["transactions"]}
                                        isAdmin={isAdmin}
                                    >
                                        <JournalTransaction editable={true} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/accounting/transactions/journal/update/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["transactions"]}
                                        isAdmin={isAdmin}
                                    >
                                        <JournalTransaction editable={true} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/accounting/transactions/journal/view/:id"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["transactions"]}
                                        isAdmin={isAdmin}
                                    >
                                        <JournalTransaction editable={false} />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/accounting/reconciliation"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["reconciliation"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Reconciliation />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/accounting/chart-of-accounts"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "chart_of_accounts",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <WrappedChartOfAccounts />
                                    </PermissionGate>
                                }
                            />
                            {/* <Route
                                path="/accounting/hire-a-bookkeeper"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "vendor_statements",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <HireABookkeeper />
                                    </PermissionGate>
                                }
                            /> */}

                            {/* ---------------------------------------REPORTS---------------------------------------- */}

                            <Route path="/reports" element={<Reports />} />
                            <Route
                                path="/reports/aging-summary-ar"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "aged_receivables",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AgingSummaryAR />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/reports/account-balances"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "account_balances",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <AccountBalances />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/reports/income-statement"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "income_statement",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <IncomeStatement />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/reports/balance-sheet"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["balance_sheet"]}
                                        isAdmin={isAdmin}
                                    >
                                        <BalanceSheet />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/reports/cash-flow-statement"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "cash_flow_statement",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <CashFlow />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/reports/general-ledger"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["general_ledger"]}
                                        isAdmin={isAdmin}
                                    >
                                        <GeneralLedger />
                                    </PermissionGate>
                                }
                            />

                            {/* --------------------------------------SETTINGS----------------------------------------- */}

                            <Route
                                path="/settings/users"
                                element={
                                    <SettingsPermissionGate
                                        active="Users"
                                        permissions={permissions}
                                        requiredPermissions={["manage_users"]}
                                        isAdmin={isAdmin}
                                    >
                                        <UserManagement />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/users/add"
                                element={
                                    <SettingsPermissionGate
                                        active="Users"
                                        permissions={permissions}
                                        requiredPermissions={["manage_users"]}
                                        isAdmin={isAdmin}
                                    >
                                        <UserForm />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/users/update/:id"
                                element={
                                    <SettingsPermissionGate
                                        active="Users"
                                        permissions={permissions}
                                        requiredPermissions={["manage_users"]}
                                        isAdmin={isAdmin}
                                    >
                                        <UserForm />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/rolls"
                                element={
                                    <SettingsPermissionGate
                                        active="Rolls"
                                        permissions={permissions}
                                        requiredPermissions={["manage_roles"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Rolls />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/permissions"
                                element={
                                    <SettingsPermissionGate
                                        active="Permissions"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "view_permissions",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <Permissions />
                                    </SettingsPermissionGate>
                                }
                            />

                            <Route
                                path="/settings/invoice-customization"
                                element={
                                    <SettingsPermissionGate
                                        active="Invoice Customization"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "customize_documents",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <InvoiceCustomization />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payment-methods"
                                element={
                                    <SettingsPermissionGate
                                        active="Payment Methods"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_payment_methods",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <PaymentMethods />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/dates-and-currency"
                                element={
                                    <SettingsPermissionGate
                                        active="Dates & Currency"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_dates_and_currency",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <DatesAndCurrency />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/sales-taxes"
                                element={
                                    <SettingsPermissionGate
                                        active="Sales Taxes"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_sales_taxes",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <SalesTaxes />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/default-accounts"
                                element={
                                    <SettingsPermissionGate
                                        active="Default Accounts"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_default_accounts",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <DefaultAccounts />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payouts"
                                element={
                                    <SettingsPermissionGate
                                        active="Manage Payouts"
                                        permissions={permissions}
                                        requiredPermissions={["manage_payouts"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Payouts />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payroll/business-profile"
                                element={
                                    <SettingsPermissionGate
                                        active="Business Profile"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_business_profile",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <BusinessProfile />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payroll/company-signatory"
                                element={
                                    <SettingsPermissionGate
                                        active="Company Signatory"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_company_signatory",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <CompanySignatory />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payroll/funding-account"
                                element={
                                    <SettingsPermissionGate
                                        active="Funding Account"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_funding_account",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <FundingAccount />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payroll/tax-profile"
                                element={
                                    <SettingsPermissionGate
                                        active="Tax Profile"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_tax_profile",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <TaxProfile />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payroll/history-import"
                                element={
                                    <SettingsPermissionGate
                                        active="Payroll History Import"
                                        permissions={permissions}
                                        requiredPermissions={[
                                            "manage_payroll_history_import",
                                        ]}
                                        isAdmin={isAdmin}
                                    >
                                        <PayrollHistoryImport />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="/settings/payroll/billing"
                                element={
                                    <SettingsPermissionGate
                                        active="Billing"
                                        permissions={permissions}
                                        requiredPermissions={["manage_billing"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Billing />
                                    </SettingsPermissionGate>
                                }
                            />

                            <Route
                                path="settings/data-export"
                                element={
                                    <SettingsPermissionGate
                                        active="Data Export"
                                        permissions={permissions}
                                        requiredPermissions={["data_export"]}
                                        isAdmin={isAdmin}
                                    >
                                        <DataExport />
                                    </SettingsPermissionGate>
                                }
                            />
                            <Route
                                path="settings/data-import"
                                element={
                                    <SettingsPermissionGate
                                        active="Data Import"
                                        permissions={permissions}
                                        requiredPermissions={["data_import"]}
                                        isAdmin={isAdmin}
                                    >
                                        <DataImport />
                                    </SettingsPermissionGate>
                                }
                            />

                            {/* --------------------------------------Profile Settings------------------------------------------ */}

                            <Route
                                path="/profile/personal-information"
                                element={<PersonalInformation />}
                            />
                            <Route
                                path="/profile/emails"
                                element={<Emails />}
                            />
                            <Route
                                path="/profile/password"
                                element={<Password />}
                            />
                            <Route
                                path="/profile/notifications"
                                element={<Notifications />}
                            />
                            <Route
                                path="/profile/businesses"
                                element={<Businesses />}
                            />
                            <Route
                                path="/profile/subscription"
                                element={<Subscription />}
                            />

                            {/* --------------------------------------General------------------------------------------ */}

                            <Route
                                path="/advisors"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["advisors"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Advisors />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/tax-filing"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["tax_filing"]}
                                        isAdmin={isAdmin}
                                    >
                                        <TaxFiling />
                                    </PermissionGate>
                                }
                            />
                            <Route
                                path="/console"
                                element={
                                    <PermissionGate
                                        permissions={permissions}
                                        requiredPermissions={["console"]}
                                        isAdmin={isAdmin}
                                    >
                                        <Console />
                                    </PermissionGate>
                                }
                            />
                        </Routes>
                </SettingsContext.Provider>
            }
        </div>
    );
}

export default App;
