import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function CompanySignatory() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Company Signatory" />
        <div>CompanySignatory</div>
      </div>
    </div>
  );
}

export default CompanySignatory;
