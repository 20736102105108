import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import CurrencyInput from "../../../../components/Forms/CurrencyInput";
import Input from "../../../../components/Forms/Input";
import SearchableModal from "../../../../components/Forms/SearchableModal";
import AddAdditionalIcon from "../../../../components/Icons/AddAdditionalIcon";
import DeleteIcon from "../../../../components/Icons/DeleteIcon";
import useSalesProducts from "../../../../hooks/useSalesProducts";
import useAccounts from "../../../../hooks/useAccounts";
import { toUSD } from "../../../../util/formatters";
import { multiply, sumMoney } from "../../../../util/money";
import {
  documentItemsSchema,
  documentsSchema,
  journalEntriesSchema,
  transactionsSchema,
} from "../../../../util/schemas";
import TextArea from "../../../../components/Forms/TextArea";
import DateSelector from "../../../../components/Forms/DateSelector";
import { formatDate } from "../../../../util/dates";
import { postJournalEntry, postTransaction } from "../../api";
import { companyInfo } from "../../../Sales/Estimates/Data";
import { postDocument, postLineItem } from "../../../Sales/api";
import { getEstimate, getEstimateItems } from "../../../Sales/Estimates/util";
import { combineObjects } from "../../../../util/jsonUtil";
import { adjustProductStock } from "../../../Inventory/api";
import usePurchasesProducts from "../../../../hooks/usePurchasesProducts";
import useCustomers from "../../../../hooks/useCustomers";
import useVendors from "../../../../hooks/useVendors";

function SaleOrPurchasePopup(props) {
  const [totalPrice, setTotalPrice] = useState("0.00");

  const [document, setDocument] = useState(documentsSchema);
  const [documentItems, setDocumentItems] = useState([documentItemsSchema]);
  const [transaction, setTransaction] = useState(transactionsSchema);
  const [journalEntries, setJournalEntries] = useState([journalEntriesSchema]);
  const [cashAccount, setCashAccount] = useState(null);
  const [notes, setNotes] = useState("");
  const [date, setDate] = useState(new Date());

  const [customer, setCustomer] = useState(null);
  const [vendor, setVendor] = useState(null);

  const {
    loadingSalesProducts,
    loadingSalesProductsByCategory,
    errorSalesProducts,
    salesProducts,
    salesProductsByCategory,
    salesProductsWithPricing,
  } = useSalesProducts(true, true);

  const {
    loadingPurchasesProducts,
    loadingPurchasesProductsByCategory,
    errorPurchasesProducts,
    purchasesProducts,
    purchasesProductsByCategory,
    purchasesProductsWithPricing,
  } = usePurchasesProducts(true, true);

  const { loadingCustomers, customersError, customers } = useCustomers();
  const { loadingVendors, vendorsError, vendors } = useVendors();

  const { loading, error, accounts, accountsByCategory, accountsByType } =
    useAccounts(false, ["Checking/Savings", "Money in Transit"]);

  const [selectedProducts, setSelectedProducts] = useState([
    {
      product: null,
      quantity: null,
      price: null,
    },
  ]);

  useEffect(() => {
    setTotalPrice(
      sumMoney(
        selectedProducts.map((product) =>
          multiply(product.price, product.quantity)
        )
      )
    );
  }, [selectedProducts]);

  useEffect(() => {
    console.log(props.popup);
    if (accounts && props.popup.transaction) {
      setCashAccount(
        accounts.find(
          (account) => account.id == props.popup.transaction.account_id
        )
      );
    }
    if (props.popup.document_id) {
      getEstimate(props.popup.document_id).then((doc) => {
        let document = doc[0];
        getEstimateItems(props.popup.document_id).then((documentItems) => {
          var selected = [];
          for (let i in documentItems) {
            var prod = {};
            prod.product = salesProducts.find(
              (product) =>
                product.id == documentItems[i].products_and_services_id
            );
            if (!prod.product) {
              prod.product = purchasesProducts.find(
                (product) =>
                  product.id == documentItems[i].products_and_services_id
              );
            }
            prod.quantity = documentItems[i].quantity;
            prod.price = documentItems[i].price;
            selected.push(prod);
          }
          setSelectedProducts(selected);
        });
        setDocument(document);
        setTotalPrice(document.amount_due);
        setDate(new Date(document.issue_date));
        setNotes(document.footer);
        if (document.customer_id) {
          setCustomer(
            customers.find((customer) => customer.id == document.customer_id)
          );
        }
        if (document.vendor_id) {
          setVendor(vendors.find((vendor) => vendor.id == document.vendor_id));
        }
      });
    }
  }, [props.popup]);

  const sumQuantity = () => {
    return selectedProducts.reduce((acc, product) => {
      return acc + parseInt(product.quantity);
    }, 0);
  };

  const resetState = () => {
    setSelectedProducts([
      {
        product: null,
        quantity: null,
        price: null,
      },
    ]);
    setCashAccount(null);
    setNotes("");
    setDate(new Date());
    setCustomer(null);
    setVendor(null);
  };

  const save = async () => {
    //add
    if (true) {
      console.log("save");

      //Create document
      console.log(document);
      let newDocument = documentsSchema;
      newDocument.status = "paid";
      if (props.popup.type == "Sale") {
        newDocument.from_name = companyInfo[0].name;
        newDocument.from_address = companyInfo[0].address;
        newDocument.from_phone = companyInfo[0].number;
        newDocument.from_email = companyInfo[0].email;
        newDocument.from_website = companyInfo[0].website;
        newDocument.customer_id = customer.id;
      } else {
        newDocument.to_name = companyInfo[0].name;
        newDocument.to_address = companyInfo[0].address;
        newDocument.to_phone = companyInfo[0].number;
        newDocument.to_email = companyInfo[0].email;
        newDocument.to_website = companyInfo[0].website;
        newDocument.to_country = companyInfo[0].country;
        newDocument.to_state = companyInfo[0].state;
        newDocument.to_city = companyInfo[0].city;
        newDocument.to_zip = companyInfo[0].zip;
        newDocument.vendor_id = vendor.id;
      }

      newDocument.issue_date = formatDate(date);
      newDocument.due_date = formatDate(date);
      newDocument.amount_due = totalPrice;
      newDocument.amount_paid = totalPrice;
      newDocument.type = props.popup.type === "Sale" ? "invoice" : "bill";
      newDocument.footer = notes;
      console.log(newDocument);
      postDocument(newDocument).then(async (data) => {
        const documentId = data.rows[0].id;
        console.log(data);
        let newDocumentItems = [];
        for (let i in selectedProducts) {
          console.log(i);
          let newDocumentItem = JSON.parse(JSON.stringify(documentItemsSchema));
          newDocumentItem.item_name = selectedProducts[i].product.name;
          newDocumentItem.invoice_id = documentId;
          newDocumentItem.products_and_services_id =
            selectedProducts[i].product.id;
          newDocumentItem.quantity = selectedProducts[i].quantity;
          newDocumentItem.price = selectedProducts[i].price;
          newDocumentItem.line_total = multiply(
            selectedProducts[i].price,
            selectedProducts[i].quantity
          );
          newDocumentItems.push(newDocumentItem);
        } //Create document items
        console.log(documentItems);
        console.log(newDocumentItems);
        for (let i in newDocumentItems) {
          await postLineItem(newDocumentItems[i]);
        }
        //Create transaction
        var transactionForm = transaction;
        console.log(transaction);
        transactionForm.date = formatDate(date);
        transactionForm.description =
          props.popup.type == "Sale"
            ? "Sale of " +
              sumQuantity() +
              " Product" +
              (sumQuantity() > 1 ? "s" : "")
            : "Purchase of " +
              sumQuantity() +
              " Product" +
              (sumQuantity() > 1 ? "s" : "");
        transactionForm.account_id = cashAccount.id;
        transactionForm.category =
          props.popup.type == "Sale" ? "Sale" : "Purchase";
        transactionForm.amount = totalPrice;
        transactionForm.notes = notes;
        transactionForm.reviewed = false;
        transactionForm.document_id = documentId;
        console.log(transactionForm);
        postTransaction(transactionForm).then(async (data) => {
          const transactionId = data.rows[0].id;
          //Create journal entries
          console.log(journalEntries);
          var journalEntriesForm = JSON.parse(
            JSON.stringify(journalEntries[0])
          );
          journalEntriesForm.transaction_id = transactionId;
          journalEntriesForm.account_id = cashAccount.id;
          journalEntriesForm.document_id = documentId;
          let credits = [];
          let debits = [];
          let inventoryCredits = [];
          let inventoryDebits = [];
          let creditForm = {
            description: null, //varchar
            account_id: null, //int
            transaction_id: null, //int
            debit: null, //numeric
            credit: null, //numeric
            tax_id: null, //int
            customer_id: null, //int
            vendor_id: null, //int
            document_id: null, //int
          };
          let debitForm = {
            description: null, //varchar
            account_id: null, //int
            transaction_id: null, //int
            debit: null, //numeric
            credit: null, //numeric
            tax_id: null, //int
            customer_id: null, //int
            vendor_id: null, //int
            document_id: null, //int
          };
          if (props.popup.type == "Sale") {
            for (let i in selectedProducts) {
              await adjustProductStock(
                selectedProducts[i].product.id,
                0 - parseInt(selectedProducts[i].quantity)
              );
              let productCategory = selectedProducts[i].product.sales_category;

              let nextDebit = JSON.parse(JSON.stringify(debitForm));
              let nextCredit = JSON.parse(JSON.stringify(creditForm));

              nextDebit.description = "Sale";
              nextDebit.account_id = cashAccount.id;
              nextDebit.transaction_id = transactionId;
              nextDebit.debit = multiply(
                selectedProducts[i].price,
                selectedProducts[i].quantity
              );
              nextDebit.document_id = documentId;

              nextCredit.description = "Sale";
              nextCredit.account_id = productCategory.account;
              nextCredit.transaction_id = transactionId;
              nextCredit.credit = multiply(
                selectedProducts[i].price,
                selectedProducts[i].quantity
              );
              nextCredit.document_id = documentId;
              console.log(nextDebit);
              console.log(nextCredit);
              debits.push(nextDebit);
              credits.push(nextCredit);

              let nextInventoryDebit = JSON.parse(JSON.stringify(nextDebit));
              let nextInventoryCredit = JSON.parse(JSON.stringify(nextCredit));
              console.log(nextInventoryCredit);
              console.log(nextInventoryDebit);

              nextInventoryDebit.account_id = productCategory.cogs_account_id;
              nextInventoryCredit.account_id =
                productCategory.inventory_account_id;

              inventoryDebits.push(nextInventoryDebit);
              inventoryCredits.push(nextInventoryCredit);
            }
            let combinedDebits = combineObjects(debits, "account_id", "debit");
            for (let i in combinedDebits) {
              await postJournalEntry(combinedDebits[i]);
            }
            let combinedCredits = combineObjects(
              credits,
              "account_id",
              "credit"
            );
            for (let i in combinedCredits) {
              await postJournalEntry(combinedCredits[i]);
            }
            for (let i in inventoryDebits) {
              console.log(inventoryDebits[i]);
              await postJournalEntry(inventoryDebits[i]);
              console.log(inventoryCredits[i]);
              await postJournalEntry(inventoryCredits[i]);
            }
          } else {
            //Purchase
            for (let i in selectedProducts) {
              await adjustProductStock(
                selectedProducts[i].product.id,
                parseInt(selectedProducts[i].quantity)
              );
              let productCategory =
                selectedProducts[i].product.purchase_category;

              let nextDebit = JSON.parse(JSON.stringify(debitForm));
              let nextCredit = JSON.parse(JSON.stringify(creditForm));

              nextDebit.description = "Purchase";
              nextDebit.account_id = productCategory.inventory_account_id;
              nextDebit.transaction_id = transactionId;
              nextDebit.debit = multiply(
                selectedProducts[i].price,
                selectedProducts[i].quantity
              );
              nextDebit.document_id = documentId;

              nextCredit.description = "Purchase";
              nextCredit.account_id = cashAccount.id;
              nextCredit.transaction_id = transactionId;
              nextCredit.credit = multiply(
                selectedProducts[i].price,
                selectedProducts[i].quantity
              );
              nextCredit.document_id = documentId;
              console.log(nextDebit);
              console.log(nextCredit);
              debits.push(nextDebit);
              credits.push(nextCredit);
            }
            for (let i in debits) {
              await postJournalEntry(debits[i]);
              await postJournalEntry(credits[i]);
            }
          }
          props.setPopup({ type: null, open: false });
          props.refresh();
        });
      });
    }
    //edit
    else {
    }
  };

  return (
    <Modal
      show={props.popup.show}
      onHide={() => {
        props.setPopup({ show: false });
        resetState();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Record {props.popup.type}</Modal.Title>
      </Modal.Header>
      <div
        style={{
          overflowY: "auto",
          height: "550px",
          padding: "10px",
        }}
      >
        <DateSelector label="Date" date={date} setDate={setDate} />
        <div style={{ marginTop: "30px" }}>
          <CurrencyInput
            label={"Total Price"}
            value={toUSD(totalPrice, "total")}
            onChange={(e) => setTotalPrice(e)}
            maxDecimals={2}
            disabled
          />
        </div>

        {selectedProducts.map((product, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                backgroundColor: "var(--container-color)",
                borderRadius: "10px",
                width: "100%",
                marginTop: "20px",
                padding: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <SearchableModal
                  label="Product"
                  list={
                    props.popup.type == "Sale"
                      ? salesProductsByCategory
                      : purchasesProductsByCategory
                  }
                  keys={{
                    name: (name) => name,
                    code_number: (code_number) => code_number,
                  }}
                  value={
                    selectedProducts[index]["product"]
                      ? selectedProducts[index]["product"].name
                      : null
                  }
                  onItemClick={(e) => {
                    const oldSelectedProducts = [...selectedProducts];
                    oldSelectedProducts[index]["product"] = e;
                    if (!oldSelectedProducts[index]["quantity"]) {
                      oldSelectedProducts[index]["quantity"] = 1;
                    }
                    const correctPricing = oldSelectedProducts[
                      index
                    ].product.quantityPricing.filter(
                      (pricing) =>
                        parseInt(pricing.starting_quantity) <=
                          oldSelectedProducts[index]["quantity"] &&
                        (parseInt(pricing.ending_quantity) >=
                          oldSelectedProducts[index]["quantity"] ||
                          pricing.ending_quantity == null)
                    )[0].price;
                    oldSelectedProducts[index]["price"] = correctPricing;

                    setSelectedProducts(oldSelectedProducts);
                  }}
                  loading={
                    props.popup.type == "Sale"
                      ? loadingSalesProductsByCategory
                      : loadingPurchasesProductsByCategory
                  }
                />
              </div>
              <div style={{ width: "20%" }}>
                <Input
                  label="Quantity"
                  value={selectedProducts[index]["quantity"]}
                  onChange={(e) => {
                    const oldSelectedProducts = [...selectedProducts];
                    oldSelectedProducts[index]["quantity"] = e.target.value;
                    console.log(
                      oldSelectedProducts[index].product.quantityPricing
                    );
                    console.log(e);
                    const correctPricing = oldSelectedProducts[
                      index
                    ].product.quantityPricing.filter(
                      (pricing) =>
                        parseInt(pricing.starting_quantity) <= e.target.value &&
                        (parseInt(pricing.ending_quantity) >= e.target.value ||
                          pricing.ending_quantity == null)
                    )[0].price;
                    console.log(correctPricing);
                    oldSelectedProducts[index]["price"] = correctPricing;
                    setSelectedProducts(oldSelectedProducts);
                  }}
                />
              </div>
              <div style={{ width: "20%" }}>
                <CurrencyInput
                  label={"Price"}
                  value={selectedProducts[index]["price"]}
                  onChange={(e) => {
                    const oldSelectedProducts = [...selectedProducts];
                    oldSelectedProducts[index]["price"] = e;
                    setSelectedProducts(oldSelectedProducts);
                  }}
                  maxDecimals={2}
                  maxLength={10}
                />
              </div>
              <DeleteIcon
                fontSize={"1.5em"}
                onClick={() => {
                  const oldSelectedProducts = [...selectedProducts];
                  oldSelectedProducts.splice(index, 1);
                  setSelectedProducts(oldSelectedProducts);
                }}
              />
            </div>
          );
        })}
        <div
          style={{ maxWidth: "55%", marginBottom: "30px" }}
          onClick={() => {
            setSelectedProducts([
              ...selectedProducts,
              {
                product: null,
                quantity: null,
                price: null,
              },
            ]);
          }}
        >
          <AddAdditionalIcon title="Add Additional Product" icon={<></>} />
        </div>

        <div style={{ marginTop: "30px" }}>
          <SearchableModal
            label={props.popup.type == "Sale" ? "Customer" : "Vendor"}
            list={props.popup.type == "Sale" ? customers : vendors}
            keys={{
              primary_name: (name) => name,
            }}
            value={
              props.popup.type == "Sale"
                ? customer
                  ? customer.primary_name
                  : null
                : vendor
                ? vendor.primary_name
                : null
            }
            valueKey={"primary_name"}
            onItemClick={(e) => {
              if (props.popup.type == "Sale") {
                setCustomer(e);
              } else {
                setVendor(e);
              }
            }}
            initialValue={
              props.popup.type == "Sale"
                ? "Select a Customer"
                : "Select a Vendor"
            }
          />
          <SearchableModal
            label="Cash Account"
            list={accountsByType}
            keys={{
              name: (name) => name,
            }}
            value={cashAccount ? cashAccount.name : null}
            onItemClick={(e) => {
              setCashAccount(e);
            }}
            initialValue={"Select a Cash Account"}
          />
        </div>

        <div style={{ marginTop: "30px" }}>
          <TextArea
            label="Notes"
            placeholder="Write a note here..."
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>
      <Modal.Footer style={{ border: "none" }}>
        <Button
          variant="secondary"
          onClick={() => {
            props.setPopup({ show: false });
            resetState();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            save();
          }}
          disabled={props.popup.document_id ? true : false}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaleOrPurchasePopup;
