import React, { useState, useRef, useEffect } from "react";
import DateSelector from "../../../components/Forms/DateSelector";
import HelpIcon from "../../../components/Icons/HelpIcon";
import { formatDate } from "../../../util/dates";
import { sumMoney } from "../../../util/money";
import { getAccounts } from "../../Accounting/api";
import MultiPage from "../MultiPage";
import ReportHeader from "../ReportHeader";
import CashFlowBody from "./CashFlowBody";

function CashFlow() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [date, setDate] = useState(new Date());
  const [accounts, setAccounts] = useState([]);
  const [lineItemAccounts, setLineItemAccounts] = useState([1, 2]);

  const [orientation, setOrientation] = useState("landscape");

  const printRefs = useRef([]);

  useEffect(() => {
    getAccounts().then((data) => {
      setAccounts(data);
      console.log(data);
      createCashFlowItems(data);
    });
  }, []);

  const createCashFlowItems = (accounts) => {
    var lineItems = [];
    lineItems.push({ category: "Operating Activities", margin: 0 });

    lineItems.push({ subcategory: "Sales", margin: 1 });
    var accountsReceivable = accounts.filter(
      (acc) => acc.name == "Accounts Receivable"
    );
    var sales = accounts.filter((acc) => acc.name == "Sales");
    accountsReceivable[0].margin = 2;
    sales[0].margin = 2;
    lineItems.push(accountsReceivable[0]);
    lineItems.push(sales[0]);
    lineItems.push({ subcategory: "Purchases", margin: 1 });

    var uncategorizedExpense = accounts.filter(
      (acc) => acc.name == "Uncategorized Expense"
    );
    uncategorizedExpense[0].margin = 2;
    lineItems.push(uncategorizedExpense[0]);

    lineItems.push({ subcategory: "Inventory", margin: 1 });

    var Inventory = accounts.filter((acc) => acc.name == "Inventory");
    var otherInventory = accounts.filter(
      (acc) => acc.name == "Inventory - Other"
    );
    Inventory[0].margin = 2;
    otherInventory[0].margin = 2;
    lineItems.push(Inventory[0]);
    lineItems.push(otherInventory[0]);

    lineItems.push({ subcategory: "Payroll", margin: 1 });
    var payrollPayable = accounts.filter(
      (acc) => acc.name == "Payroll Payable"
    );
    payrollPayable[0].margin = 2;
    lineItems.push(payrollPayable[0]);
    lineItems.push({ subcategory: "Sales Taxes", margin: 1 });
    var salesTax = accounts.filter((acc) => acc.name == "Sales Tax");
    salesTax[0].margin = 2;
    lineItems.push(salesTax[0]);
    lineItems.push({ subcategory: "Other", margin: 1, balance: "0" });
    var netOperatingActivity = sumMoney([
      accountsReceivable[0].balance,
      sales[0].balance,
      uncategorizedExpense[0].balance,
      Inventory[0].balance,
      otherInventory[0].balance,
      payrollPayable[0].balance,
      salesTax[0].balance,
    ]);

    lineItems.push({
      category: "Net Cash from Operating Activities",
      margin: 0,
      balance: netOperatingActivity,
      border: true,
    });
    lineItems.push({ category: "Investing Activities", margin: 0 });

    lineItems.push({ subcategory: "Property, Plant, Equipment", margin: 1 });
    var property = accounts.filter((acc) => acc.name == "Property");
    property[0].margin = 2;
    lineItems.push(property[0]);
    lineItems.push({ subcategory: "Other", margin: 1, balance: "0" });

    var netInvestingOperating = sumMoney([property[0].balance]);
    lineItems.push({
      category: "Net Cash from Investing Activities",
      margin: 0,
      balance: netInvestingOperating,
      border: true,
    });

    lineItems.push({ category: "Financing Activities", margin: 0 });

    lineItems.push({ subcategory: "Loans and Lines of Credit", margin: 1 });
    var loans = accounts.filter((acc) => acc.name == "Loans");
    loans[0].margin = 2;
    lineItems.push(loans[0]);
    lineItems.push({ subcategory: "Owners and Shareholders", margin: 1 });
    var commonStock = accounts.filter((acc) => acc.name == "Common Stock");
    commonStock[0].margin = 2;
    lineItems.push(commonStock[0]);
    lineItems.push({ subcategory: "Other", margin: 1, balance: "0" });

    var netFinancingActivity = sumMoney([
      loans[0].balance,
      commonStock[0].balance,
    ]);
    lineItems.push({
      category: "Net Cash from Financing Activities",
      margin: 0,
      balance: netFinancingActivity,
      border: true,
    });

    lineItems.push({ category: "Overview", margin: 0 });

    lineItems.push({
      subcategory: "Starting Balance",
      margin: 1,
      balance: "0",
    });
    lineItems.push({
      subcategory: "Ending balance",
      margin: 1,
      balance: sumMoney([
        netOperatingActivity,
        netInvestingOperating,
        netFinancingActivity,
      ]),
    });

    setLineItemAccounts(lineItems);
  };

  const getLineItemHeight = () => {
    return 25.5;
  };

  return (
    <div
      style={{
        width: "1000px",
        height: "100vh",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        right: "-125px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Cash Flow Statement</h1>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <button type="button" className="btn btn-light">
              Change Orientation
            </button>
          </div>
          <div>
            <button type="button" className="btn btn-light">
              Download as PDF
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: "rgb(236, 240, 243)",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "50%",
            width: "60%",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            marginLeft: "30px",
          }}
        >
          {" "}
          Date Range{" "}
          <div style={{ width: "220px" }}>
            <DateSelector date={startDate} setDate={setStartDate} />
          </div>
          <div style={{ width: "220px" }}>
            <DateSelector date={endDate} setDate={setEndDate} />
          </div>
        </div>
      </div>
      <MultiPage
        date={formatDate(startDate) + " to " + formatDate(endDate)}
        lineItems={lineItemAccounts}
        orientation={orientation}
        printRefs={printRefs}
        header={
          <ReportHeader
            title={"Cash Flow Statement"}
            dateRange={[startDate, endDate]}
          />
        }
        body={<CashFlowBody />}
        totals={<></>}
        getLineItemHeight={getLineItemHeight}
      />
    </div>
  );
}

export default CashFlow;
