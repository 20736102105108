import React from "react";
import "./Page.css";

function Page(props) {
  const pageWrapperStyle = {
    display: "flex",

    width: props.width,
    height: props.height,
  };
  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    margin: props.margin,
    width:
      "calc(" + props.width + " - " + props.margin + " - " + props.margin + ")",
    height:
      "calc(" +
      props.height +
      " - " +
      props.margin +
      " - " +
      props.margin +
      ")",
  };
  return (
    <div ref={props.printRef} className="pageWrapper" style={pageWrapperStyle}>
      <div className="page" style={pageStyle}>
        {props.header}
        {props.body}
        {props.totals}
        {props.notesAndTerms}
        {props.mainFooter}
        {props.footer}
      </div>
    </div>
  );
}

export default Page;
