import React from "react";
import SearchableDropdownWithCreate from "./SearchableDropdownWithCreate";

//Each searchable on a page must have a unique id prop
function SearchableWithButton(props) {
  return (
    <div className="formItem">
      <button
        class="form-control "
        style={{
          backgroundColor: "white",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          display: "block",
        }}
        onClick={(evt) => {
          const menu = document.getElementById(
            "searchableMenu" + props.appendID
          );

          console.log(menu);

          var el = document
            .getElementById("searchBar" + props.appendID)
            .focus();
          var range = document.createRange();
          var sel = window.getSelection();
          // range.setStart(el.childNodes[1], 0);
          // range.collapse(true);

          // sel.removeAllRanges();
          // sel.addRange(range);

          const x = evt.target.offsetLeft;
          const y = evt.target.offsetTop;
          //Here we get the height of the button
          const height = evt.target.offsetHeight;

          //And here we position the dropdown relative to the button
          menu.style.position = "absolute";
          menu.style.top = `${y + height}px`;
          menu.style.left = `${x}px`;
          menu.style.opacity = "1";
          menu.style.zIndex = "100";
        }}
      >
        {console.log(props.value)}
        {console.log(props.list)}
        {props.value != null && props.value != -1
          ? props.list
            ? props.list[props.value].map((item, index) => {
                return " " + item + " ";
              })
            : props.defaultValue
          : props.defaultValue}
      </button>
      <div
        id={"searchableMenu" + props.appendID}
        style={{ opacity: "0", position: "fixed", left: "0px", zIndex: "-15" }}
        onBlur={() => {
          setTimeout(() => {
            const menu = document.getElementById(
              "searchableMenu" + props.appendID
            );
            menu.style.position = "fixed";
            menu.style.left = `0px`;
            menu.style.opacity = "0";
            menu.style.zIndex = "-100";
          }, 100);
        }}
      >
        <SearchableDropdownWithCreate
          list={props.list}
          onItemClick={(e) => props.setValue(e)}
          index={null}
          buttonList={props.buttonList ? props.buttonList : []}
          functions={props.functions ? props.functions : []}
          searchBarID={"searchBar" + props.appendID}
        />
      </div>
    </div>
  );
}

export default SearchableWithButton;
