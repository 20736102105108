import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function PayrollHistoryImport() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Payroll History Import" />
        <div>PayrollHistoryImport</div>
      </div>
    </div>
  );
}

export default PayrollHistoryImport;
