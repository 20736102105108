import { useEffect, useState } from "react";
import useAccounts from "../useAccounts";
import usePurchasesProducts from "../usePurchasesProducts";
import useVendors from "../useVendors";

// interface IPurchaseAPI {
//     user_id: number;
//     transaction_id?: number;
// }

// interface IPurchase {
//     date: string
//   description: string
//   account_id: number
//   category: string
//   amount: number
//   notes: string
//   reviewed: boolean
//   document_id: number
// }

function useBill(props) {
  // main form data
  const [document, setDocument] = useState({});
  const [documentItems, setDocumentItems] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [journalEntries, setJournalEntries] = useState([]);

  // form auxilaries
  const {
    loadingPurchasesProducts,
    purchasesProductsError,
    purchasesProductsByCategory,
  } = usePurchasesProducts(true, true);
  const { loadingVendors, vendorsError, vendors } = useVendors();
  const [taxes, setTaxes] = useState([]);
  const {
    loading: loadingAcounts,
    accountsError,
    accountsByType,
  } = useAccounts(true, ["Checking/Savings", "Money in Transit"]);
  console.log(props);
  useEffect(() => {
    if (props.document_id) {
      // get document
      // get document items
      // if hasTransaction(props.document_id){
      // get transaction
      // get journal entries
      //}
    }

    // get auxilaries
  }, []);

  const saveBill = () => {
    // make move
  };

  const hasSufficientFunds = (account_id, amount) => {};

  return {
    operations: {
      saveBill,
      hasSufficientFunds,
    },
    setters: {
      setDocument,
      setDocumentItems,
      setTransaction,
      setJournalEntries,
    },
    models: {
      document,
      documentItems,
      transaction,
      journalEntries,
      purchasesProductsByCategory,
      vendors,
      taxes,
      accountsByType,
    },
    loading: {
      loadingVendors,
      loadingPurchasesProducts,
      loadingAcounts,
    },
    errors: {
      vendorsError,
      purchasesProductsError,
      accountsError,
    },
  };
}

export default useBill;
