import React, { useState, useRef, useEffect } from "react";
import DateSelector from "../../../components/Forms/DateSelector";
import HelpIcon from "../../../components/Icons/HelpIcon";
import { formatDate } from "../../../util/dates";
import { addMoney, subtractMoney, sumMoney } from "../../../util/money";
import { getAccounts, getTransactions } from "../../Accounting/api";
import MultiPage from "../MultiPage";
import ReportHeader from "../ReportHeader";
import BalanceSheetBody from "./BalanceSheetBody";

// update a value in an array of objects where the object has a property with a given value
// array: the array of objects
// property: the property to check
// value: the value to check for
// updateProperty: the property to update
// updateValue: the value to update to
const updateObject = (array, property, value, updateProperty, updateValue) => {
  for (let i in array) {
    if (array[i][property] == value) {
      array[i][updateProperty] = updateValue;
    }
  }
  return array;
};

function BalanceSheet() {
  const [date, setDate] = useState(new Date());
  const [accounts, setAccounts] = useState([]);
  const [lineItemAccounts, setLineItemAccounts] = useState([]);
  const [type, setType] = useState("Accrual (Paid & Unpaid)");
  const [transactions, setTransactions] = useState([]);
  const [applicableTransactions, setApplicableTransactions] = useState([]);
  const [adjustedAccounts, setAdjustedAccounts] = useState([]);

  const [orientation, setOrientation] = useState("landscape");

  const printRefs = useRef([]);

  useEffect(() => {
    getAccounts().then((data) => {
      setAccounts(data);
      console.log("Accounts: ", data);
      createBalanceSheetItems(data);
      getTransactions().then((transactionData) => {
        console.log(transactionData);
        setTransactions(transactionData);
        //adjust(data, transactionData);
      });
    });
  }, []);

  // useEffect(() => {
  //   console.log("SELECTED DATE:");
  //   console.log(formatDate(date));
  //   console.log("TRANSACTIONS:");
  //   adjust(accounts, transactions);
  // }, [date]);

  const adjust = (accs, tx) => {
    for (let i in tx) {
      console.log(tx[i].date);
    }
    var list = tx.filter(
      (t) => new Date(t.date).getTime() <= new Date(formatDate(date)).getTime()
    );
    setApplicableTransactions(list);
    console.log(list);

    var adjusted = JSON.parse(JSON.stringify(accs));

    for (let i in list) {
      var t = list[i];
      var account = accs.filter((a) => a.id == t.account_id)[0];
      console.log(account);
      if (account) {
        var diff = null;
        if (t.type == "Debit") {
          diff = addMoney(account.balance, t.amount);
        } else {
          diff = subtractMoney(account.balance, t.amount);
        }
        console.log("ADJUSTING ACCOUNT " + account.id + " BY " + t.amount);
        adjusted = updateObject(adjusted, "id", account.id, "balance", diff);
      }
    }
    console.log(accounts);
    console.log(adjusted);
    // setAdjustedAccounts(adjusted);
    createBalanceSheetItems(adjusted);
  };

  const createBalanceSheetItems = (accounts) => {
    var shift = 0;
    var lineItems = [];
    var categories = ["Assets", "Liabilities", "Equity"];
    for (let i in categories) {
      var categoryItems = accounts.filter(
        (acc) => acc.category == categories[i]
      );
      if (i == 1) {
        lineItems.push({
          category: (categories[1] + " & " + categories[2]).toUpperCase(),
          margin: 0,
        });
      }
      if (i == 1 || i == 2) {
        lineItems.push({ category: categories[i], margin: 0, shift: 1 });
      } else {
        lineItems.push({ category: categories[i].toUpperCase(), margin: 0 });
      }

      var subCategoryList = [
        ...new Set(
          categoryItems
            .flat()
            .map((item) => item.subcategory)
            .filter((item) => item != null && item != "")
        ),
      ];
      console.log(subCategoryList);
      if (categories[i] == "Liabilities" || categories[i] == "Equity") {
        shift = 1;
      }

      for (let j in subCategoryList) {
        var subCategoryItems = accounts.filter(
          (acc) =>
            acc.category == categories[i] &&
            acc.subcategory == subCategoryList[j]
        );
        lineItems.push({
          subcategory: subCategoryList[j],
          margin: 1,
          shift: shift,
        });
        var typeList = [
          ...new Set(subCategoryItems.flat().map((item) => item.type)),
        ];
        var subcategorySum = 0;
        for (let k in typeList) {
          lineItems.push({ type: typeList[k], margin: 2, shift: shift });
          var typeItems = categoryItems.filter(
            (acc) =>
              acc.type == typeList[k] && acc.subcategory == subCategoryList[j]
          );
          for (let l in typeItems) {
            typeItems[l].margin = 3;
            typeItems[l].shift = shift;
          }
          lineItems = lineItems.concat(typeItems);
          var typeSum = sumMoney(typeItems.map((a) => a.balance));
          subcategorySum = addMoney(subcategorySum, typeSum);
          lineItems.push({
            type: "Total " + typeList[k],
            margin: 2,
            balance: typeSum,
            border: 1,
            shift: shift,
          });
        }
        lineItems.push({
          subcategory: "Total " + subCategoryList[j],
          margin: 1,
          balance: subcategorySum,
          border: 2,
          shift: shift,
        });
      }
      if (i == 0) {
        lineItems.push({
          category: ("Total " + categories[i]).toUpperCase(),
          margin: 0,
          balance: sumMoney(
            lineItems
              .filter((acc) => acc.category == "Assets")
              .map((a) => a.balance)
          ),
          border: 3,
        });
      } else if (i == 1) {
        lineItems.push({
          category: ("Total " + categories[1]).toUpperCase(),
          margin: 0,
          balance: sumMoney(
            lineItems
              .filter((acc) => acc.category == "Liabilities")
              .map((a) => a.balance)
          ),
          border: 2,
          shift: 1,
        });
      } else if (i == 2) {
        lineItems.push({
          category: ("Total " + categories[2]).toUpperCase(),
          margin: 0,
          balance: sumMoney(
            lineItems
              .filter((acc) => acc.category == "Equity")
              .map((a) => a.balance)
          ),
          border: 2,
          shift: 1,
        });
        lineItems.push({
          category: (
            "Total " +
            categories[1] +
            " & " +
            categories[2]
          ).toUpperCase(),
          margin: 0,
          balance: sumMoney(
            lineItems
              .filter(
                (acc) =>
                  acc.category == "Liabilities" || acc.category == "Equity"
              )
              .map((a) => a.balance)
          ),
          border: 3,
        });
      }
    }

    setLineItemAccounts(lineItems);
  };

  const getLineItemHeight = () => {
    return 27;
  };

  return (
    <div
      style={{
        width: "1000px",
        height: "100vh",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        right: "-125px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Balance Sheet</h1>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                if (orientation == "landscape") {
                  setOrientation("portrait");
                } else {
                  setOrientation("landscape");
                }

                const newLineItemAccounts = JSON.parse(
                  JSON.stringify(lineItemAccounts)
                );
                setLineItemAccounts(newLineItemAccounts);
              }}
            >
              Change Orientation
            </button>
          </div>
          <div>
            <button type="button" className="btn btn-light">
              Download as PDF
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          minHeight: "100px",
          backgroundColor: "rgb(236, 240, 243)",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "50%",
            width: "60%",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            marginLeft: "30px",
          }}
        >
          <div style={{ marginRight: "20px" }}>As of </div>

          <div style={{ width: "220px" }}>
            <DateSelector date={date} setDate={setDate} />
          </div>
          <div style={{ width: "220px" }}></div>
          <div
            style={{
              height: "50%",
              width: "30%",
              alignItems: "center",
              display: "flex",
              marginLeft: "30px",
            }}
          >
            <div style={{ marginRight: "5px" }}>
              Report Type
              <HelpIcon
                message={
                  "Accrual (Paid & Unpaid): Reflects all transactions, including unpaid invoices and bills. \nCash Basis (Paid): Reflects all transactions except unpaid invoices and bills"
                }
              />
            </div>

            <select
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              {["Accrual (Paid & Unpaid)", "Cash Basis (Paid)"].map(
                (item, index) => {
                  return (
                    <option className="dropdownOption" key={item} value={item}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
          </div>
        </div>
      </div>

      <MultiPage
        date={formatDate(date)}
        lineItems={lineItemAccounts}
        orientation={orientation}
        printRefs={printRefs}
        header={<ReportHeader title={"Balance Sheet"} date={date} />}
        body={<BalanceSheetBody />}
        totals={<></>}
        getLineItemHeight={getLineItemHeight}
      />
    </div>
  );
}

export default BalanceSheet;
