import React from "react";
import { useContext } from "react";
import { SettingsContext } from "../../App";
import ProfileSidebar from "./ProfileSidebar";

function Subscription() {
    let { settings } = useContext(SettingsContext);
    console.log(settings);
    return (
        <div className="page-container">
            <div style={{ display: "flex" }}>
                <ProfileSidebar active="Subscription" />
                <div>
                    Subscription:{" "}
                    <span style={{ fontWeight: "bold" }}>
                        {settings.membership}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Subscription;
