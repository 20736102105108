import { useEffect, useState } from "react";
import { getAccounts } from "../pages/Accounting/api";
import useFetch from "./useFetch";
function useAccounts(categories = false, types = []) {
  const [accountsError, setAccountsError] = useState(null);
  const { loading, error, data: accounts } = useFetch(getAccounts);
  const [accountsByCategory, setAccountsByCategory] = useState({});
  const [accountsByType, setAccountsByType] = useState({});
  console.log(accounts);

  useEffect(() => {
    if (accounts && categories) {
      setAccountsByCategory({
        Assets: accounts.filter((account) => account.category === "Assets"),
        Liabilities: accounts.filter(
          (account) => account.category === "Liabilities"
        ),
        Equity: accounts.filter((account) => account.category === "Equity"),
        Income: accounts.filter((account) => account.category === "Income"),
        Expenses: accounts.filter((account) => account.category === "Expenses"),
      });
    }
    if (accounts && types.length > 0 && Array.isArray(types)) {
      let accObj = {};

      types.forEach((type) => {
        accObj[type] = accounts.filter((account) => account.type === type);
      });
      setAccountsByType(accObj);
    }
  }, [accounts]);

  useEffect(() => {
    if (error) {
      setAccountsError("Failed to load accounts");
    }
  }, [error]);

  return {
    loading,
    accountsError,
    accounts,
    accountsByCategory,
    accountsByType,
  };
}

export default useAccounts;
