import { api_url } from "../../../util/environment";

import { currentBusiness } from "../../../features/authentication/util";

export async function getEstimateItems(id) {
    const getItems = await fetch(
        api_url + "/sales/documents/" + id + "/document-items"
    );
    return await getItems.json();
}

export async function getEstimate(id) {
    const getEstimate = await fetch(api_url + "/sales/documents/" + id);

    return await getEstimate.json();
}

export async function newEstimate(body) {
    const postEstimate = await fetch(
        api_url + "/sales/documents/user/" + currentBusiness(),
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );

    return await postEstimate.json();
}

export async function updateEstimate(body, id) {
    await fetch(api_url + "/sales/documents/" + id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
}

export async function postLineItem(body, id) {
    console.log(body);
    const postLineItem = await fetch(
        api_url + "/sales/documents/" + id + "/document-items",
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        }
    );
    return await postLineItem.json();
}

export async function putLineItem(body, id) {
    await fetch(api_url + "/sales/document-items/" + id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
}

export async function deleteLineItem(id) {
    console.log("DEEEELELETETETTE");
    await fetch(api_url + "/sales/document-items/" + id, {
        method: "DELETE",
    });
}

export async function getCurrentDocumentId() {
    var id = -1;
    const response = await fetch(api_url + "/sales/misc/current-document-id");
    await response.json().then((data) => {
        console.log(data);
        id = data.rows;
    });
    return id;
}
