export const companyInfo = [
  {
    name: "ASP Accounting",
    address: "34 Annette Park Dr.",
    number: "641-485-7376",
    website: "aspaccounting.com",
    email: "accounting@asp.com",
  },
];

export const agingItems = [
  {
    name: "Applied Spectra",
    current: "4,750.00",
    firstThirty: "11,625.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "16,375.00",
  },
  {
    name: "ARC Automotive",
    current: "340.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "340.00",
  },
  {
    name: "Auriga PIV Tech",
    current: "7,800.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "7,800.00",
  },
  {
    name: "Autoliv",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "-1,040.34",
    total: "-1,040.34",
  },
  {
    name: "Autoliv China Shanghai",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "12,270.00",
    total: "12,270.00",
  },
  {
    name: "Autoliv Romania",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "790.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "790.00",
  },
  {
    name: "BNC",
    current: "83,029.45",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "83,029.45",
  },
  {
    name: "Bosheng",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "-36.81",
    total: "-36.81",
  },
  {
    name: "C&D Shanghai",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "-15.00",
    total: "-15.00",
  },
  {
    name: "EnerSys Advanced Systems",
    current: "490.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "490.00",
  },
  {
    name: "Equipment Scientific",
    current: "3,225.00",
    firstThirty: "9,330.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "-750.00",
    total: "11,805.00",
  },
  {
    name: "FEC Technology Corp.",
    current: "7,056.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "7,056.00",
  },
  {
    name: "Hua Laser",
    current: "25,666.00",
    firstThirty: "33,600.00",
    secondThirty: "42,212.50",
    thirdThirty: "1,320.00",
    fourthThirty: "0.00",
    total: "102,798.50",
  },
  {
    name: "Kimco Design & Manufacturing",
    current: "19.80",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "19.80",
  },
  {
    name: "L-3 Applied Technologies, Inc.",
    current: "34,375.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "34,375.00",
  },
  {
    name: "L3 Communications",
    current: "21,875.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "21,875.00",
  },
  {
    name: "laboratoire de Chimie",
    current: "4,559.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "4,559.00",
  },
  {
    name: "LLNL",
    current: "0.00",
    firstThirty: "5,600.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "5,600.00",
  },
  {
    name: "LTS",
    current: "26,700.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "26,700.00",
  },
  {
    name: "Meggitt",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "5,864.99",
    total: "5,864.99",
  },
  {
    name: "Millenium Dental Technologies",
    current: "46,068.75",
    firstThirty: "15,356.25",
    secondThirty: "3,563.25",
    thirdThirty: "0.00",
    fourthThirty: "1,620.84",
    total: "66,609.09",
  },
  {
    name: "MVLZ",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "-15.00",
    total: "-15.00",
  },
  {
    name: "Notre Dame",
    current: "0.00",
    firstThirty: "3,150.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "3,150.00",
  },
  {
    name: "Opto Science",
    current: "2,340.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "2,340.00",
  },
  {
    name: "Photonic Solutions PLC",
    current: "34,400.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "34,400.00",
  },
  {
    name: "Schulz",
    current: "9,530.00",
    firstThirty: "14,878.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "24,408.00",
  },
  {
    name: "SourceLAB",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "-200.00",
    total: "-200.00",
  },
  {
    name: "Teledyne CETAC Tech.",
    current: "0.00",
    firstThirty: "4,800.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "4,800.00",
  },
  {
    name: "Transcat, Inc",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "980.00",
    fourthThirty: "0.00",
    total: "980.00",
  },
  {
    name: "TSI",
    current: "7,300.00",
    firstThirty: "7,300.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "14,600.00",
  },
  {
    name: "Universite' de Sherbrooke",
    current: "0.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "-25.00",
    total: "-25.00",
  },
  {
    name: "Valuetronics",
    current: "5,715.00",
    firstThirty: "0.00",
    secondThirty: "0.00",
    thirdThirty: "0.00",
    fourthThirty: "0.00",
    total: "5,715.00",
  },
];
