import React from "react";

export function Flexbox(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: props.justifyContent,
        width: props.width,
      }}
    >
      {props.children}
    </div>
  );
}

export function FlexboxColumn(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {props.children}
    </div>
  );
}

export function Grid(props) {
  return <div style={{ display: "grid" }}>{props.children}</div>;
}
