import React from "react";

import styled from "styled-components";

const BoldSpan = styled.span`
  color: black;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  background: none;
  cursor: pointer;
  overflow: wrap;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
  &:before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: wrap;
    visibility: hidden;
  }
`;

function BoldHoverable(props) {
  return (
    <span style={{ textAlign: "center" }}>
      <BoldSpan onClick={() => props.onClick()} title={props.children}>
        {props.children}
      </BoldSpan>
    </span>
  );
}

export default BoldHoverable;
