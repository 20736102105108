import React from "react";

import { MdOutlineModeEditOutline } from "react-icons/md";

import "./Account.css";

function Account(props) {
  return (
    <div className="accountRow">
      <div
        style={{
          width: "60px",
          "white-space": "nowrap",
          "text-overflow": "ellipsis",
        }}
      >
        {props.account_number}
      </div>
      <div
        style={{
          width: "250px",
          "white-space": "nowrap",
          "text-overflow": "ellipsis",
        }}
      >
        {props.name}
      </div>
      <div
        style={{
          width: "460px",
          "white-space": "nowrap",
          "text-overflow": "ellipsis",
        }}
      >
        {props.description}
      </div>
      <div className="editIconContainer">
        <div
          id={props.type}
          data-category={props.category}
          className="editIcon"
          onClick={(e) =>
            props.handleOpenEdit(
              e,
              props.id,
              props.category,
              props.subcategory,
              props.type,
              props.name,
              props.account_number,
              props.description
            )
          }
        >
          <MdOutlineModeEditOutline />
        </div>
      </div>
    </div>
  );
}

export default Account;
