import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SettingsContext } from "../../App";

const SidebarLink = styled(Link)`
  display: flex;
  color: #737373;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    background: #d8d8d8;
    border-left: 4px solid #90a790;
    cursor: pointer;
  }
`;

const SidebarNonLink = styled.div`
  display: flex;
  color: #737373;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  user-select: none;
  font-size: 18px;
  &:hover {
    background: #d8d8d8;
    border-left: 4px solid #90a790;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #cce2cd;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #737373;
  font-size: 18px;
  &:hover {
    background: #d8d8d8;
    cursor: pointer;
  }
`;

const SubMenu = (props) => {
  const toggleSubnav = () => {
    props.onClick();
  };

  const { settings } = React.useContext(SettingsContext);

  return (
    <>
      {props.item.path ? (
        <SidebarLink
          to={props.item.path}
          onClick={props.item.subNav && toggleSubnav}
        >
          <div>
            {props.item.icon}
            <SidebarLabel>{props.item.title}</SidebarLabel>
          </div>
          <div>
            {props.item.subNav && props.show
              ? props.item.iconOpened
              : props.item.subNav
              ? props.item.iconClosed
              : null}
          </div>
        </SidebarLink>
      ) : (
        <SidebarNonLink onClick={props.item.subNav && toggleSubnav}>
          <div>
            {props.item.icon ? props.item.icon : null}
            <SidebarLabel>{props.item.title}</SidebarLabel>
          </div>
          <div>
            {props.item.subNav && props.show
              ? props.item.iconOpened
              : props.item.subNav
              ? props.item.iconClosed
              : null}
          </div>
        </SidebarNonLink>
      )}

      {props.show &&
        props.item.subNav.map((item, index) => {
          return (
            <>
              {!item.membership ||
              item.membership.includes(settings.membership) ? (
                <DropdownLink to={item.path} key={index}>
                  {item.icon}
                  <SidebarLabel>{item.title}</SidebarLabel>
                </DropdownLink>
              ) : null}
            </>
          );
        })}
    </>
  );
};

export default SubMenu;
