import React, { useState, useEffect } from "react";
import { toPercentage, toUSD, toFloatg } from "../../../util/formatters";
import { subtractMoney, addMoney } from "../../../util/money";
import NumericInput from "../../../components/Forms/documents/NumericInput";
import PriceInput from "../../../components/Forms/documents/PriceInput";
import PercentageInput from "../../../components/Forms/documents/PercentageInput";
import { computeDiscount } from "../../../util/money";

import SearchableDropdownWithCreate from "../../../components/Forms/SearchableDropdownWithCreate";

import CreateTaxPopup from "./CreateTaxPopup";

import { IoTrashOutline } from "react-icons/io5";
import "./Estimates.css";

function EstimateTotals(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [showFixedDiscount, setShowFixedDiscount] = useState(false);
  const [showPercentageDiscount, setShowPercentageDiscount] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);
  const [showTaxMenu, setShowTaxMenu] = useState(false);

  useEffect(() => {}, []);

  const getUniqueTaxNames = (e) => {
    return [...new Set(props.getters[3].flat().map((item) => item.name))];
  };
  const createTax = (e) => {
    console.log(e);
    setSelectedTax([e.name, toFloatg(e.percentage / 100, "discount")]);
    props.createTax(e);
  };

  const getTaxPercentageByName = (name) => {
    var taxes = props.getters[3];
    for (var i in taxes) {
      for (var j in taxes[i]) {
        if (taxes[i][j].name == name && props.getters[0] != []) {
          //check props.getters[0] since it may be set after props.getters[3]
          return taxes[i][j].percentage;
        }
      }
    }
  };

  const getTaxTotalByName = (name) => {
    var taxes = props.getters[3];
    var total = 0;

    for (var i in taxes) {
      //sum up the taxes with the same name
      for (var j in taxes[i]) {
        if (taxes[i][j].name == name && props.getters[0] != []) {
          //check props.getters[0] since it may be set after props.getters[3]
          console.log(total);
          if (props.getters[0][i]) {
            total =
              total +
              parseFloat(taxes[i][j].percentage) *
                parseFloat(props.getters[0][i].line_total);
          }
        }
      }
    }
    return total;
  };

  console.log(props);

  var subtotal = "";
  var discount = "";
  var subtotalAfterDiscount = "";
  var taxTotal = "";
  var total = "";
  var amountDue = "";
  if (props.info.length > 0) {
    subtotal = props.info.reduce((prev, curr) => prev + parseFloat(curr), 0);
    discount = "0.00";
    if (parseFloat(props.getters[1].discount_amount) > 0) {
      discount = props.getters[1].discount_amount;
    } else {
      discount = computeDiscount(
        subtotal,
        props.getters[1].discount_percentage
      );
    }
    subtotalAfterDiscount = subtractMoney(subtotal, discount);

    taxTotal = "0.00";
    if (selectedTax != null) {
      taxTotal = computeDiscount(subtotalAfterDiscount, selectedTax[1]);
    }
    total = addMoney(subtotalAfterDiscount, taxTotal);

    if (props.getters[1].discount_amount > 0) {
      getUniqueTaxNames().map((item, index) => {
        total =
          total +
          computeDiscount(
            getTaxTotalByName(item),
            subtractMoney(1, props.getters[1].discount_amount / subtotal)
          );
      });
    } else if (props.getters[1].discount_percentage > 0) {
      getUniqueTaxNames().map((item, index) => {
        total =
          total +
          computeDiscount(
            getTaxTotalByName(item),
            subtractMoney(1, props.getters[1].discount_percentage)
          );
      });
    } else {
      getUniqueTaxNames().map((item, index) => {
        if (
          !(props.getters[1].discount_percentage > 0) &&
          !(props.getters[1].discount_amount > 0)
        ) {
          total = total + getTaxTotalByName(item);
        }
      });
    }

    amountDue = subtractMoney(total, props.paid);
    var form = props.getters[1];
    form.amount_due = amountDue.toString();
    props.setters[1](form);
  }

  const uniqueTaxTotals = [
    { taxName: "Sales", rate: "0.07" },
    { taxName: "Sin", rate: "0.10" },
    { taxName: "Luxury", rate: "0.06" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <table width="50%">
        <thead></thead>
        <tbody>
          <tr>
            <th>Subtotal: </th>
            <td style={{ textAlign: "right" }}>{toUSD(subtotal, "total")}</td>
          </tr>
          {getUniqueTaxNames().map((item, index) => {
            return (
              <tr>
                <th>
                  {item} {toPercentage(getTaxPercentageByName(item), "tax")}:
                </th>
                <td style={{ textAlign: "right" }}>
                  {toUSD(getTaxTotalByName(item), "total")}
                </td>
              </tr>
            );
          })}
          <tr>
            <button
              className="discountOrTaxButton"
              data-html2canvas-ignore="true"
              onClick={() => {
                setShowMenu(true);
                document.getElementById("search-bar-totals").focus();
              }}
            >
              {" "}
              Add Tax/Discount
            </button>
            {showMenu ? (
              <td style={{ position: "absolute" }}>
                <div
                  onBlur={() => {
                    setTimeout(() => {
                      setShowMenu(false);
                    }, 200);
                  }}
                  disabled={true}
                >
                  <SearchableDropdownWithCreate
                    searchBarID={"search-bar-totals"}
                    list={[
                      ...props.taxes.map((a) => [
                        a.name + " ",
                        toPercentage(a.percentage, "tax"),
                        a.id,
                      ]),
                    ]}
                    functions={[
                      () => setShowTaxMenu(true),
                      () => {
                        setShowPercentageDiscount(true);
                        setShowFixedDiscount(false);
                        var form = props.getters[1];
                        form.discount_amount = "0.00";
                        props.setters[1](JSON.parse(JSON.stringify(form)));
                      },
                      () => {
                        setShowFixedDiscount(true);
                        setShowPercentageDiscount(false);
                        var form = props.getters[1];
                        form.discount_percentage = "0.0";
                        props.setters[1](JSON.parse(JSON.stringify(form)));
                      },
                    ]}
                    onItemClick={(e) => {
                      setSelectedTax([
                        props.taxes[e].name,
                        props.taxes[e].percentage,
                      ]);
                    }}
                  />
                </div>
              </td>
            ) : (
              <td
                style={{ position: "absolute", zIndex: "-100", opacity: "0" }}
              >
                <div
                  onBlur={() => {
                    setTimeout(() => {
                      setShowMenu(false);
                    }, 2000);
                  }}
                  disabled={true}
                >
                  <SearchableDropdownWithCreate
                    searchBarID={"search-bar-totals"}
                    list={[
                      ...props.taxes.map((a) => [
                        a.name + " ",
                        toPercentage(a.percentage, "tax"),
                        a.id,
                      ]),
                    ]}
                    functions={[
                      () => console.log("first"),
                      () => console.log("second"),
                      () => console.log("third"),
                    ]}
                  />
                </div>
              </td>
            )}
          </tr>
          {showFixedDiscount ||
          parseFloat(props.getters[1].discount_amount) > 0 ? (
            <>
              <tr className="hoverableRow">
                <th>Discount (Fixed):</th>{" "}
                <td style={{ textAlign: "right" }}>
                  <PriceInput
                    limit={11}
                    decimalLimit={2}
                    initialValue={props.getters[1].discount_amount}
                    negative={true}
                    changeText={(e) => {
                      var form = props.getters[1];
                      form.discount_amount = e;
                      props.setters[1](JSON.parse(JSON.stringify(form)));
                    }}
                  />
                </td>
                <div
                  className="rowDelete"
                  style={{ opacity: "0", position: "absolute" }}
                  onClick={() => {
                    var form = props.getters[1];
                    form.discount_amount = null;
                    props.setters[1](JSON.parse(JSON.stringify(form)));
                    setShowFixedDiscount(false);
                  }}
                >
                  <IoTrashOutline />
                </div>
              </tr>
              <tr
                style={{
                  borderBottom: "3px solid ",
                  height: "1em",
                }}
              ></tr>
              <tr
                style={{
                  height: "1em",
                }}
              ></tr>
              <tr>
                <th>Subtotal:</th>
                <td style={{ textAlign: "right" }}>
                  {toUSD(
                    subtractMoney(subtotal, props.getters[1].discount_amount),
                    "total"
                  )}
                </td>
              </tr>
            </>
          ) : null}
          {showPercentageDiscount ||
          parseFloat(props.getters[1].discount_percentage) > 0 ? (
            <>
              <tr className="hoverableRow">
                {console.log(props.getters[1].discount_percentage)}
                <th style={{ display: "flex" }}>
                  <div>Discount (%): </div>
                  <div style={{ marginLeft: "30px" }}>
                    <PercentageInput
                      limit={11}
                      decimalLimit={1}
                      initialValue={toPercentage(
                        props.getters[1].discount_percentage,
                        "discountForm"
                      )}
                      changeText={(e) => {
                        var form = props.getters[1];
                        form.discount_percentage = e;
                        props.setters[1](JSON.parse(JSON.stringify(form)));
                      }}
                    />
                  </div>
                </th>{" "}
                <td style={{ textAlign: "right" }}>
                  {toUSD(
                    computeDiscount(
                      subtotal,
                      subtractMoney(props.getters[1].discount_percentage)
                    ),
                    "total"
                  )}
                </td>
                <div
                  className="rowDelete"
                  style={{ opacity: "0", position: "absolute" }}
                  onClick={() => {
                    var form = props.getters[1];
                    form.discount_percentage = null;
                    props.setters[1](JSON.parse(JSON.stringify(form)));
                    setShowPercentageDiscount(false);
                  }}
                >
                  <IoTrashOutline />
                </div>
              </tr>
              <tr
                style={{
                  borderBottom: "3px solid ",
                  height: "1em",
                }}
              ></tr>
              <tr
                style={{
                  height: "1em",
                }}
              ></tr>
              <tr>
                <th>Subtotal:</th>
                <td style={{ textAlign: "right" }}>
                  {toUSD(
                    computeDiscount(
                      subtotal,
                      subtractMoney(1, props.getters[1].discount_percentage)
                    ),
                    "total"
                  )}
                </td>
              </tr>
            </>
          ) : null}

          {selectedTax ? (
            <tr className="hoverableRow">
              <th>{selectedTax[0]} </th>{" "}
              <td style={{ textAlign: "right" }}>
                {toPercentage(selectedTax[1], "tax")}
              </td>
              <div
                className="rowDelete"
                style={{ opacity: "0", position: "absolute" }}
                onClick={() => setSelectedTax(null)}
              >
                <IoTrashOutline />
              </div>
            </tr>
          ) : null}

          {props.getters[1].discount_percentage > 0 ? (
            <>
              {getUniqueTaxNames().map((item, index) => {
                return (
                  <tr>
                    <th>
                      {item} {toPercentage(getTaxPercentageByName(item), "tax")}
                      :
                    </th>
                    <td style={{ textAlign: "right" }}>
                      {toUSD(
                        computeDiscount(
                          getTaxTotalByName(item),
                          subtractMoney(1, props.getters[1].discount_percentage)
                        ),
                        "total"
                      )}
                    </td>
                  </tr>
                );
              })}
            </>
          ) : null}
          {props.getters[1].discount_amount > 0 ? (
            <>
              {getUniqueTaxNames().map((item, index) => {
                return (
                  <tr>
                    <th>
                      {item} {toPercentage(getTaxPercentageByName(item), "tax")}
                      :
                    </th>
                    <td style={{ textAlign: "right" }}>
                      {toUSD(
                        computeDiscount(
                          getTaxTotalByName(item),
                          subtractMoney(
                            1,
                            props.getters[1].discount_amount / subtotal
                          )
                        ),
                        "total"
                      )}
                    </td>
                  </tr>
                );
              })}
            </>
          ) : null}

          {/* {uniqueTaxTotals.map((item, index) => {
            return (
              <tr>
                <th>{item.taxName} </th>{" "}
                <td style={{ textAlign: "right" }}>{item.rate}</td>
              </tr>
            );
          })}

          <tr>
            <th>Tax Total: </th>
            <td style={{ textAlign: "right" }}>{toUSD(taxTotal, "total")}</td>
          </tr> */}
        </tbody>
      </table>
      <table width="50%">
        <thead>
          <tr
            style={{
              borderBottom: "3px solid ",
              height: "1em",
            }}
          ></tr>
          <tr
            style={{
              height: "1em",
            }}
          ></tr>
        </thead>
        <tbody>
          <tr>
            <th>Total: </th>
            <td style={{ textAlign: "right" }}>{toUSD(total, "total")}</td>
          </tr>
          {/* <tr>
            <th>Paid: </th>
            <td style={{ textAlign: "right" }}>{toUSD(props.paid, "total")}</td>
          </tr>
          <tr>
            <th>Amount Due: </th>
            <td style={{ textAlign: "right" }}>{toUSD(amountDue, "total")}</td>
          </tr> */}
        </tbody>
      </table>
      <CreateTaxPopup
        taxes={props.taxes}
        show={showTaxMenu}
        setShow={setShowTaxMenu}
        onSubmit={(e) => createTax(e)}
      />
    </div>
  );
}

export default EstimateTotals;
