import React, { useEffect, useState, useRef } from "react";
import SearchableDropdownWithCreate from "../../../components/Forms/SearchableDropdownWithCreate";

import MultiPage from "../../Sales/CustomerStatements/MultiPage";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import Button from "react-bootstrap/esm/Button";
import { getBills, getVendors } from "../api";

async function handleDownloadPdf(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 4, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  pdf.save("print.pdf");
}

function VendorStatements() {
  const [customers, setCustomers] = useState([]);
  const [customerDropdownList, setCustomerDropdownList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [lineItems, setLineItems] = useState([]);

  const printRef1 = useRef(null);
  const printRef2 = useRef(null);
  const printRef3 = useRef(null);

  const printRefs = [printRef1, printRef2, printRef3];

  useEffect(() => {
    getVendors().then((data) => {
      console.log(data);
      setCustomers(data);
      setCustomerDropdownList([...data.map((a) => [a.primary_name, "", a.id])]);
    });

    getBills().then((data) => {
      console.log("Bills: ", data);
      setInvoices(data);
    });
  }, []);

  const createStatement = () => {
    console.log(customers[selectedCustomer].id);
    setShowStatement(true);
    console.log(invoices);
    const companyInvoices = invoices.filter(
      (invoice) =>
        invoice.from_name == customerDropdownList[selectedCustomer][0]
    );
    console.log(companyInvoices);
    setLineItems(companyInvoices);
  };
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "50px",
        position: "relative",
        maxWidth: "100vh",
      }}
    >
      <h1>Vendor Statements</h1>
      <div
        className="summaryReport"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: "30px" }}>
          <button
            class="btn form-control dropdown-toggle "
            id="customerDropdown"
            style={{ backgroundColor: "white" }}
            onClick={(evt) => {
              const dropDown = document.getElementById("customerDropdown");
              const menu = document.getElementById("customerMenu");

              console.log(dropDown);
              console.log(menu);

              var el = document.getElementById("search-bar").focus();
              var range = document.createRange();
              var sel = window.getSelection();
              // range.setStart(el.childNodes[1], 0);
              // range.collapse(true);

              // sel.removeAllRanges();
              // sel.addRange(range);

              const x = evt.target.offsetLeft;
              const y = evt.target.offsetTop;
              //Here we get the height of the button
              const height = evt.target.offsetHeight;

              //And here we position the dropdown relative to the button
              menu.style.position = "absolute";
              menu.style.top = `${y + height}px`;
              menu.style.left = `${x}px`;
              menu.style.opacity = "1";
            }}
            onBlur={() => {
              const menu = document.getElementById("customerMenu");
              menu.style.position = "fixed";
              menu.style.left = `0px`;
              menu.style.opacity = "0";
            }}
          >
            {selectedCustomer
              ? customerDropdownList[selectedCustomer][0]
              : "Select a Vendor"}
          </button>
        </div>
        <div
          id="customerMenu"
          style={{ position: "fixed", left: "0px", opacity: "0" }}
        >
          <div
            onBlur={() => {
              setTimeout(() => {
                const menu = document.getElementById("customerMenu");
                menu.style.position = "fixed";
                menu.style.left = `0px`;
                menu.style.opacity = "0";
              }, 200);
            }}
          >
            <SearchableDropdownWithCreate
              list={customerDropdownList}
              actions={[() => console.log(), () => console.log]}
              buttonList={[]}
              index={-1}
              onItemClick={setSelectedCustomer}
            />
          </div>
        </div>

        <div style={{ marginRight: "30px" }}>
          <Button
            style={{
              marginRight: "30px",
              backgroundColor: "white",
              color: "black",
            }}
            onClick={() => handleDownloadPdf(printRefs, "potrait")}
          >
            Download PDF
          </Button>
          <Button onClick={() => createStatement()}>Create Statement</Button>
        </div>
      </div>
      {showStatement ? (
        <MultiPage
          tableHeader={
            <thead>
              <tr>
                <th>Bill #</th>
                <th style={{ width: "20%" }}>Bill date</th>
                <th>Due date</th>
                <th style={{ textAlign: "right" }}>Total</th>
                <th style={{ textAlign: "right" }}>Paid</th>
                <th style={{ textAlign: "right" }}>Due</th>
              </tr>
            </thead>
          }
          title={"Outstanding Bills"}
          lineItems={lineItems}
          orientation={"potrait"}
          printRefs={[printRef1, printRef2, printRef3]}
          customer={customers[selectedCustomer]}
        />
      ) : null}
    </div>
  );
}

export default VendorStatements;
