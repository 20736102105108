import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MultiPage from "../../Documents/MultiPage";
import EstimateHeader from "../../Sales/Estimates/EstimateHeader";
import EstimateBody from "../../Sales/Estimates/EstimateBody";
// import EstimateTotals from "../../Sales/Estimates/EstimateTotals";
import InvoiceTotals from "../../Sales/Invoices/InvoiceTotals";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { companyInfo, userInvoiceSettings } from "../../Sales/Estimates/Data";
import {
  getEstimateItems,
  getEstimate,
  newEstimate,
  updateEstimate,
  putLineItem,
  deleteLineItem,
  getCurrentDocumentId,
} from "../../Sales/Estimates/util";
import { toFloatg } from "../../../util/formatters";

import {
  dateTodayUSAformat,
  dateNdaysFromDateFormatted,
  getDate,
} from "../../../util/dates";

import { isEqual } from "../../../util/jsonUtil";
import {
  deleteLineItemTax,
  getCustomer,
  getItemTaxes,
  getSalesProducts,
  postLineItem,
  postLineItemTax,
  sendDocument,
} from "../../Sales/api";
import MainFooter from "../../Documents/Footers/MainFooter";

import { IoSettingsSharp } from "react-icons/io5";

import SendInvoice from "../../Sales/Invoices/Send/SendInvoice";
import ErrorPopup from "../../../components/Popups/ErrorPopup";
import { getProductPricing, postTax } from "../../Site/api";
import AgingSummaryHeader from "../AgingSummaryAR/AgingSummaryHeader";
import AccountBalancesBody from "./AccountBalancesBody";
import { getAccounts } from "../../Accounting/api";
import { getTaxes } from "../../Site/api";

async function handleDownloadPdf(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 2, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  pdf.save("print.pdf");
}

async function pdfToBase64(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 2, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  return btoa(pdf.output());
}

//handling form submission, page settings, db fetching
function AccountBalances(props) {
  let navigate = useNavigate();
  let { id } = useParams();

  const [orientation, setOrientation] = useState("portrait");
  const [lineItemsOrig, setLineItemsOrig] = useState({});
  const [lineItemsForm, setLineItemsForm] = useState([
    {
      id: -1, //negative id to denote new item
      item_name: "LineItem",
      quantity: "1",
      price: "0.00",
      discount: null,
      tax: "0.0",
      line_total: "0.00",
      notes: "notes",
      page_order: "0",
      invoice_id: "",
      products_and_services_id: null,
    },
  ]);
  const [form, setForm] = useState({
    status: "draft",
    from_name: companyInfo[0].name,
    from_address: companyInfo[0].address,
    from_phone: companyInfo[0].number,
    from_website: companyInfo[0].website,
    from_email: companyInfo[0].email,

    issue_date: dateTodayUSAformat,
    net: userInvoiceSettings[0].default_net.toString(),
    due_date: dateNdaysFromDateFormatted(
      new Date(),
      parseInt(userInvoiceSettings[0].default_net)
    ),
    currency: "USD",
    po_number: "#",

    to_name: "Name",
    to_address: "Address",
    to_city: "City",
    to_zip: "Zip",
    to_state: "State",
    to_country: "Country",
    to_phone: "Phone",
    to_website: "Website",
    to_email: "Email",
    to_other: "Other",

    invoice_number: id,

    footer: "notes",
    tax_percentage: "0",
    discount_amount: "0",
    discount_percentage: "0",
    amount_paid: "0",
    amount_due: "0",

    type: "invoice",

    created_at: null,
    updated_at: null,
    archived_at: null,
    deleted_at: null,
    converted_from: null,
    converted_to: null,
    customer_id: null,
    vendor_id: null,
  });
  const [allTaxes, setAllTaxes] = useState([]);
  const [taxes, setTaxes] = useState([[]]);
  const [taxesOrig, setTaxesOrig] = useState([[]]);

  const [sendPopup, setSendPopup] = useState({
    show: false,
    toEmail: null,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [allProducts, setAllProducts] = useState([]);
  const [quantityPricing, setQuantityPricing] = useState([[]]);

  const [accounts, setAccounts] = useState([]);

  const printRefs = useRef([]);

  useEffect(() => {
    getAccounts().then((data) => setAccounts(data));
  }, []);

  const SecondaryHeader = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{props.upperLeft}</div>
        <div>{props.upperRight}</div>
      </div>
    );
  };

  const Footer = (props) => {
    return (
      <div
        style={{
          height: "30px",
          display: "flex",
          marginTop: "auto",
          justifyContent: "space-between",
        }}
      >
        <div>{props.lowerLeft}</div>
        <div>{props.lowerRight}</div>
      </div>
    );
  };

  const setCustomerInfo = (customer) => {
    var updatedForm = form;
    updatedForm.to_name = customer.primary_name;
    updatedForm.to_address = customer.billing_address;
    updatedForm.to_city = customer.billing_city;

    updatedForm.to_state = customer.billing_state;
    updatedForm.to_zip = customer.billing_zip_code;
    updatedForm.to_country = customer.billing_country;
    updatedForm.to_phone = customer.primary_phone;
    updatedForm.to_email = customer.primary_email;
    setForm(updatedForm);
  };

  const editEstimate = async () => {
    var estimateForm = form;
    delete estimateForm["id"];
    delete estimateForm["owner"];
    updateEstimate(estimateForm, id).then((data) => {
      console.log(data);
    });

    //Edit Taxes

    for (var i in taxesOrig) {
      for (var j in taxesOrig[i]) {
        var found_j = false;
        for (var k in taxes[i]) {
          if (taxesOrig[i][j].id == taxes[i][k].id) {
            found_j = true;
          }
        }
        if (!found_j) {
          deleteLineItemTax(lineItemsOrig[i].id, taxesOrig[i][j].id);
        }
      }
    }

    for (var i in taxes) {
      for (var j in taxes[i]) {
        var found_j = false;

        for (var k in taxesOrig[i]) {
          if (taxesOrig[i][k].id == taxes[i][j].id) {
            found_j = true;
          }
        }
        if (!found_j) {
          postLineItemTax(lineItemsForm[i].id, taxes[i][j].id);
        }
      }
    }

    //Edit Line Items

    // Loop through items and POST the ones with .new==true
    for (var i in lineItemsForm) {
      if (lineItemsForm[i].id < 0) {
        console.log("POST");
        var lineItemBody = lineItemsForm[i];
        delete lineItemBody["id"];
        lineItemBody["invoice_id"] = id;
        postLineItem(lineItemBody);
      }
    }
    var found_i = false;
    var found_index = null;
    console.log(lineItemsForm);
    console.log(lineItemsOrig);
    for (var i in lineItemsOrig) {
      for (var j in lineItemsForm) {
        if (lineItemsOrig[i].id == lineItemsForm[j].id) {
          found_i = true;
          found_index = j;
        }
      }
      if (found_i) {
        if (!isEqual(lineItemsOrig[i], lineItemsForm[found_index])) {
          var lineItemBody = lineItemsForm[found_index];
          const lineItemId = lineItemsForm[found_index].id;
          delete lineItemBody["id"];
          putLineItem(lineItemBody, lineItemId);
        }
      } else {
        console.log("DELETE" + lineItemsOrig[i].id);
        deleteLineItem(lineItemsOrig[i].id);
      }
      found_i = false; //reset boolean
      found_index = null;
    }
    // this.putInvoice(this.props.invoice_id);
  };

  const createTax = (form) => {
    console.log("creating tax");

    const body = {
      tax_number: form.tax_number,
      name: form.name,
      percentage: toFloatg(form.percentage / 100, "discount"),
      is_recoverable: form.is_recoverable,
      is_compound: form.is_compound,
      effective_date: null,
      obsolete_date: null,
      created_at: Date.now(),
      updated_at: Date.now(),
      archived_at: null,
      deleted_at: null,
    };

    postTax(body).then((json_res) => {
      console.log(json_res);
      getTaxes().then((data) => {
        setAllTaxes(data);
      });
    });
  };

  const addLineItem = () => {
    console.log("add line item");

    const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
    newLineItems.push({
      id: 0 - lineItemsForm.length - 1, //negative id to denote new item - starts counting from -1 if starting from empty
      item_name: "LineItem",
      quantity: "1",
      price: "0.00",
      discount: null,
      tax: "0.0",
      line_total: "0.00",
      notes: "notes",
      page_order: lineItemsForm.length,
      invoice_id: "",
      products_and_services_id: null,
    });
    setLineItemsForm(newLineItems);
    console.log(newLineItems);
    var newTaxes = JSON.parse(JSON.stringify(taxes));
    newTaxes.push([]);
    setTaxes(newTaxes);
    var newPricing = JSON.parse(JSON.stringify(quantityPricing));
    newPricing.push([]);
    setQuantityPricing(newPricing);
  };

  const removeLineItem = (index) => {
    var currentList = lineItemsForm;
    var currentTaxList = taxes;
    var currentQuantityPricingList = quantityPricing;
    currentList.splice(index, 1);
    currentTaxList.splice(index, 1);
    currentQuantityPricingList.splice(index, 1);
    setLineItemsForm(JSON.parse(JSON.stringify(currentList)));
    setTaxes(JSON.parse(JSON.stringify(currentTaxList)));
    setQuantityPricing(JSON.parse(JSON.stringify(currentQuantityPricingList)));
  };

  function getLineItemHeight(i) {
    var height = 15;
    return height;
  }
  function getTotalBoxHeight() {
    var height = 30; //starting height

    return height;
  }

  return (
    <div>
      <div
        style={{
          width: "1000px",
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
        }}
      >
        <div
          className="top-left"
          style={{ display: "flex", marginRight: "50px" }}
        >
          <h1 className="pageTitle">
            Account Balances
            <IoSettingsSharp
              className="settingsIcon"
              fontSize="16px"
              style={{ marginTop: "5px" }}
            />
          </h1>
        </div>

        <div className="invoiceButtons">
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                if (orientation == "landscape") {
                  setOrientation("portrait");
                } else {
                  setOrientation("landscape");
                }
                const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
                setLineItemsForm(newLineItems);
              }}
            >
              Change Orientation
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => handleDownloadPdf(printRefs.current, orientation)}
            >
              Download as PDF
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setSendPopup({ show: true, toEmail: form.to_email });
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>

      <div style={{ fontFamily: "Georgia", fontSize: "15px" }}>
        <MultiPage
          lineItems={accounts}
          orientation={orientation}
          printRefs={printRefs}
          header={<AgingSummaryHeader title={"Account Balances"} />}
          secondaryHeader={<AgingSummaryHeader title={"Account Balances"} />}
          body={<AccountBalancesBody />}
          totals={<>total</>}
          mainFooter={
            <Footer lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />
          }
          footer={<Footer lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />}
          companyInfo={companyInfo}
          setters={[
            setLineItemsForm,
            setForm,
            setAllTaxes,
            setTaxes,
            setAllProducts,
            setQuantityPricing,
          ]}
          getters={[
            lineItemsForm,
            form,
            allTaxes,
            taxes,
            allProducts,
            quantityPricing,
          ]}
          addLineItem={addLineItem}
          deleteLineItem={removeLineItem}
          getLineItemHeight={getLineItemHeight}
          getTotalBoxHeight={getTotalBoxHeight}
        />
      </div>
      <SendInvoice
        sendPopup={sendPopup}
        closePopup={() => {
          setSendPopup({ show: false, id: null, toEmail: null });
        }}
        setError={(error) => setError(error)}
        setSuccess={() => setSuccess(true)}
        toSent={(id) => sendDocument(id).then(() => console.log("sent"))}
        attachment={() => pdfToBase64(printRefs.current, orientation)}
        subject={"Invoice #" + form.invoice_number || ""}
        message={"Here is your Invoice"}
      />
      {error ? (
        <ErrorPopup errorMessage={error} clearError={() => setError(null)} />
      ) : null}
    </div>
  );
}

export default AccountBalances;
