export const COLUMNS = [
    {
        Header: 'Company',
        accessor: 'company_name',
    },
    {
        Header: 'Name',
        accessor: 'primary_name',
    },
    {
        Header: 'Email',
        accessor: 'primary_email',
    },
    {
        Header: 'Phone Number',
        accessor: 'primary_phone',
    }
]