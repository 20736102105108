import React from "react";
import AdditionalContactIcon from "../../../components/Icons/AddAdditionalIcon";
import AdditionalContact from "./AdditionalContact";

export default class FlexibleContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddButton: true,
      idCounter: 10,
    };
  }

  addAdditionalContact = () => {
    console.log("add");
    if (this.props.additionalContactList.length < 3) {
      var currList = this.props.additionalContactList;
      currList.push({
        key: this.state.idCounter,
        id: "",
        name: "",
        position: "",
        email: "",
        phone: "",
      });
      console.log(currList);
      this.props.setAdditionalContactList(currList);

      // this.setState(state => state.additionalContactList.push({id: this.state.idCounter}));
      if (this.state.idCounter > 50) {
        this.setState({ idCounter: 0 });
      } else {
        this.setState((state) => state.idCounter++);
      }

      console.log(this.state.idCounter);
      if (this.props.additionalContactList.length == 3) {
        console.log("setting false");
        this.setState({ showAddButton: false });
      }
    }
  };

  componentDidMount() {
    //append any default additional contacts
  }

  handleDelete = (e, index) => {
    console.log("delete");
    console.log(e);
    console.log(e.target.id);
    console.log(index);

    var currList = this.props.additionalContactList;
    currList.splice(index, 1);
    this.props.setAdditionalContactList(currList);
    // this.setState(state => state.additionalContactList.splice(index, 1));
    this.setState({ showAddButton: true });
  };

  render() {
    if (this.props.additionalContactList.length >= 3) {
      console.log("setting false");
      if (this.state.showAddButton == true) {
        this.setState({ showAddButton: false });
      }
    }
    return (
      <div>
        {/* <div>{this.state.additionalContactList.map(item => item)}</div> */}
        {console.log(this.props.additionalContactList)}
        {this.props.additionalContactList.map((item, index) => {
          console.log(item);
          return (
            <div key={item.key}>
              {
                <AdditionalContact
                  onClick={(e) => this.handleDelete(e, index)}
                  additionalContactList={this.props.additionalContactList}
                  id={item.key}
                  index={index}
                  onChange={this.props.onAdditionalContactChange}
                />
              }
            </div>
          );
        })}
        <div onClick={this.addAdditionalContact}>
          {this.state.showAddButton ? <AdditionalContactIcon /> : null}
        </div>
      </div>
    );
  }
}
