import React, { useState, useRef, useEffect } from "react";
import Page from "./Page";
import LineItemCS from "../../../components/Documents/Static/ContactStatements/CustomerStatements/LineItemCS";
import TableHeader from "./TableHeader";
import OutstandingInvoicesTotal from "../../../components/Documents/Static/ContactStatements/OutstandingInvoicesTotal";
import ImagePreview from "../Invoices/Form/ImagePreview";
import OutstandingInvoicesHeader from "../../../components/Documents/Static/ContactStatements/OutstandingInvoicesHeader";
import OutstandingInvoicesSecondaryHeader from "../../../components/Documents/Static/ContactStatements/OutstandingInvoicesSecondaryHeader";
import OutstandingInvoicesFooter from "../../../components/Documents/Static/ContactStatements/OutstandingInvoicesFooter";

const SecondaryInvoiceHeader = () => <div>header</div>;

function getLineItemHeight(lineItem) {
  return 24;
}

function MultiPage(props) {
  console.log(props.lineItems);
  const [pageMapper, setPageMapper] = useState([
    { startIndex: 0, endIndex: null },
  ]);
  const [pageDimensions, setPageDimensions] = useState({
    landscape: {
      height: "765px",
      width: "990px",
    },
    potrait: {
      height: "990px",
      width: "765px",
    },
  });

  const lineItemHeight = 24;

  const height = pageDimensions[props.orientation].height;
  const width = pageDimensions[props.orientation].width;

  const pageHeight = parseInt(
    pageDimensions[props.orientation].height.slice(0, -2)
  );
  const mainHeaderHeight = 340;
  const secondaryHeaderHeight = 40;
  const tableHeaderHeight = 70;
  const footerHeight = 30;

  const totalBoxHeight = 140;

  const margin = 50;

  //figure out lineItem indexes and pages needed to display line items when there is a change to lineItems array
  useEffect(() => {
    var newPages = [{ startIndex: 0, endIndex: null }];

    const firstPageRoomAvailable =
      pageHeight -
      2 * margin -
      mainHeaderHeight -
      tableHeaderHeight -
      footerHeight;

    const nthPageRoomAvailable =
      pageHeight -
      2 * margin -
      secondaryHeaderHeight -
      tableHeaderHeight -
      footerHeight;

    console.log(firstPageRoomAvailable);

    var heightLeft = firstPageRoomAvailable;
    var itemHeight = "";
    var hasNextPage = false;
    for (var i in props.lineItems) {
      itemHeight = getLineItemHeight(props.lineItems[i]);
      heightLeft = heightLeft - itemHeight;
      console.log(heightLeft);

      // if total box ran out of room put it on the next page
      if (heightLeft < totalBoxHeight && !hasNextPage) {
        newPages.push({ startIndex: null, endIndex: null });
        hasNextPage = true;
      } else if (heightLeft < 0) {
        newPages[newPages.length - 2].endIndex = i;
        newPages[newPages.length - 1].startIndex = i;

        heightLeft = nthPageRoomAvailable;
        hasNextPage = false;
      }
    }
    setPageMapper(newPages);
  }, [props.lineItems]);

  const Header = () => {
    const headerStyle = {
      height: "340px",
      backgroundColor: "lightGrey",
    };
    return (
      <div style={headerStyle}>
        <ImagePreview url={"/1_hCBlqxgSsc1acciJoWDwVA.jpeg"} />
      </div>
    );
  };

  const Table = () => (
    <div
      style={{
        height: "115px",
        backgroundColor: "lightGreen",
      }}
    ></div>
  );

  const Totals = () => {
    const totalsStyle = {
      minHeight: "140px",
      width: "300px",
      backgroundColor: "orange",
      alignSelf: "flex-end",
    };
    return <div style={totalsStyle}>totals</div>;
  };

  const Underline = () => (
    <hr
      style={{
        borderTop: "1px solid #000000",
        opacity: "1",
        margin: "0px",
      }}
      width="100%"
    />
  );

  return (
    <div>
      {pageMapper.map((item, index) => {
        var slicedItems = [];
        if (item.startIndex != null && item.endIndex == null) {
          //ensure page displays correct line items
          slicedItems = props.lineItems.slice(item.startIndex);
        } else if (item.startIndex == null && item.endIndex == null) {
          slicedItems = [];
          console.log("NO ITEMS");
        } else {
          slicedItems = props.lineItems.slice(item.startIndex, item.endIndex);
        }
        console.log(pageMapper);
        return (
          <>
            {index == 0 ? (
              pageMapper.length - 1 == 0 ? (
                <Page //Page 1 of 1
                  printRef={props.printRefs[index]}
                  key={item}
                  header={
                    <OutstandingInvoicesHeader
                      customer={props.customer}
                      lineItems={slicedItems}
                      title={props.title}
                    />
                  }
                  body={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {" "}
                      <table
                        width="100%"
                        style={{
                          "table-layout": "fixed",
                          borderSpacing: "15px 0px",
                          borderCollapse: "separate",
                        }}
                      >
                        {props.tableHeader}
                        <tbody>
                          {slicedItems.map((item, index) => {
                            return <LineItemCS key={item} item={item} />;
                          })}
                        </tbody>
                      </table>
                      <Underline />
                      <OutstandingInvoicesTotal lineItems={slicedItems} />{" "}
                    </div>
                  }
                  footer={
                    <OutstandingInvoicesFooter
                      companyName="ASPaccounting"
                      numPages={pageMapper.length}
                      pageNumber={index + 1}
                    />
                  }
                  margin={"0.5in"}
                  height={height}
                  width={width}
                />
              ) : (
                <Page //Page 1 of many
                  printRef={props.printRefs[index]}
                  key={item}
                  header={
                    <OutstandingInvoicesHeader
                      customer={props.customer}
                      lineItems={slicedItems}
                    />
                  }
                  body={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <table
                        width="100%"
                        style={{
                          "table-layout": "fixed",
                          borderSpacing: "15px 0px",
                          borderCollapse: "separate",
                        }}
                      >
                        {props.tableHeader}
                        <tbody>
                          {slicedItems.map((item, index) => {
                            return <LineItemCS key={item} item={item} />;
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                  footer={
                    <OutstandingInvoicesFooter
                      companyName="ASPaccounting"
                      numPages={pageMapper.length}
                      pageNumber={index + 1}
                    />
                  }
                  margin={"0.5in"}
                  height={height}
                  width={width}
                />
              )
            ) : index < pageMapper.length - 1 ? (
              <Page //page m of n
                printRef={props.printRefs[index]}
                key={item}
                header={<OutstandingInvoicesSecondaryHeader />}
                body={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <table
                      width="100%"
                      style={{
                        "table-layout": "fixed",
                        borderSpacing: "15px 0px",
                        borderCollapse: "separate",
                      }}
                    >
                      {props.tableHeader}
                      <tbody>
                        {slicedItems.map((item, index) => {
                          return <LineItemCS key={item} item={item} />;
                        })}
                      </tbody>
                    </table>
                  </div>
                }
                footer={
                  <OutstandingInvoicesFooter
                    companyName="ASPaccounting"
                    numPages={pageMapper.length}
                    pageNumber={index + 1}
                  />
                }
                margin={"0.5in"}
                height={height}
                width={width}
              />
            ) : (
              <Page //Page n of n
                printRef={props.printRefs[index]}
                key={item}
                header={<OutstandingInvoicesSecondaryHeader />}
                body={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <table
                      width="100%"
                      style={{
                        "table-layout": "fixed",
                        borderSpacing: "15px 0px",
                        borderCollapse: "separate",
                      }}
                    >
                      {props.tableHeader}
                      <tbody>
                        {slicedItems.map((item, index) => {
                          return <LineItemCS key={item} item={item} />;
                        })}
                      </tbody>
                    </table>
                    <Underline />
                    <OutstandingInvoicesTotal lineItems={slicedItems} />{" "}
                  </div>
                }
                footer={
                  <OutstandingInvoicesFooter
                    companyName="ASPaccounting"
                    numPages={pageMapper.length}
                    pageNumber={index + 1}
                  />
                }
                margin={"0.5in"}
                height={height}
                width={width}
              />
            )}
          </>
        );
      })}
    </div>
  );
}

export default MultiPage;
