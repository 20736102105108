import React from "react";

import SearchableDropdown from "../../../components/Forms/SearchableDropdown";
import Dropdown from "../../../components/Forms/Dropdown";
import Input from "../../../components/Forms/Input";
import FlexibleContactForm from "./FlexibleContactForm";

function GeneralInfoForm(props) {
  return (
    <div>
      <div className="generalInfo">
        <div className="row g-3">
          <SearchableDropdown
            className="mb-3 company col"
            label="Company"
            dropdownList={props.companyList}
            objKey="name"
            listId="companyList"
            value={props.formValues.company}
            onChange={(e) => props.onCompanyChange(e)}
            required={true}
          />

          <Input
            className="mb-3 col"
            label="Description"
            id="type"
            value={props.formValues.contact.type}
            onChange={(e) => props.onChange(e, "type")}
          />
        </div>
        <Input
          className="mb-3"
          label="Website"
          id="company_website"
          value={props.formValues.contact.website}
          onChange={(e) => props.onChange(e, "website")}
        />
        <div className="row g-3">
          <Input
            className="mb-3 col-sm-7"
            label="Contact Name"
            id="primary_contact"
            value={props.formValues.contact.primary_name}
            onChange={(e) => props.onChange(e, "primary_name")}
          />
          <Input
            className="mb-3 col"
            label="Position"
            id="primary_position"
            value={props.formValues.contact.primary_position}
            onChange={(e) => props.onChange(e, "primary_position")}
          />
        </div>

        <div className="row g-3">
          <Input
            className="mb-3 col-sm-7"
            label="Email Address"
            id="primary_email"
            value={props.formValues.contact.primary_email}
            onChange={(e) => props.onChange(e, "primary_email")}
          />
          <Input
            className="mb-3 col"
            label="Phone Number"
            id="primary_number"
            value={props.formValues.contact.primary_phone}
            onChange={(e) => props.onChange(e, "primary_phone")}
          />
        </div>
        <div className="flexibleContacts">
          <FlexibleContactForm
            additionalContactList={props.additionalContactList}
            setAdditionalContactList={props.setAdditionalContactList}
            onAdditionalContactChange={props.onAdditionalContactChange}
          />
        </div>
        {/* <div>{this.state.additionalContacts.map(item => item)}</div>
            <div onClick={this.addAdditionalContact} >{this.state.showAddAdditionalButton ? <AddAdditionalContact/> : null}</div> */}
      </div>
    </div>
  );
}

export default GeneralInfoForm;
