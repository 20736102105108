export function isEqual(obj1, obj2) {
  console.log(obj1);
  console.log(obj2);
  var same = true;
  for (var i in obj1) {
    if (obj1[i] != obj2[i]) {
      same = false;
    }
  }
  for (var i in obj2) {
    if (obj2[i] != obj1[i]) {
      same = false;
    }
  }
  return same;
}

export function findIndex(obj, key, value) {
  return obj
    .map(function (e) {
      return e[key];
    })
    .indexOf(value);
}

export function filterObject(obj, keys) {
  return keys.reduce((acc, key) => {
    if (obj.hasOwnProperty(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

// combine objects that have the same value for the given key and sum the values of the other key
export function combineObjects(objArr, keys, sumKey) {
  console.log("COMBINING: ", objArr);
  var result = [];
  var obj = {};
  objArr.forEach(function (item) {
    if (!obj[item[keys]]) {
      obj[item[keys]] = item;
    } else {
      obj[item[keys]][sumKey] += item[sumKey];
    }
  });
  for (var prop in obj) {
    result.push(obj[prop]);
  }
  return result;
}

export function mapObject(obj) {
  // return an array of [key, value] pairs
  return Object.keys(obj).map(function (key) {
    return [key, obj[key]];
  });
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
