import React from "react";
import { toUSD } from "../../util/formatters";

function GeneralDashboard(props) {
  return (
    <div style={{ height: "500px", width: "750px" }}>
      <div
        style={{
          height: "60px",
          backgroundColor: "rgb(235, 239, 244)",
          borderRadius: "10px",
        }}
      >
        <div>
          <div
            style={{
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            {props.title}
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {props.subtitle}
          </div>
        </div>
        <div
          style={{ fontSize: "14px", marginTop: "5px", textAlign: "center" }}
        >
          {props.subheader}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <table style={{ width: "200px" }}>
            <thead>
              <tr style={{ border: "1px solid black" }}>
                <th colspan="2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <div
                      style={{
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {props.mainTableHeader}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.mainTableData
                ? props.mainTableData.map((data) => {
                    return (
                      <tr>
                        <td style={{ border: "1px solid black" }}>{data[0]}</td>
                        <td
                          style={{
                            textAlign: "right",
                            border: "1px solid black",
                          }}
                        >
                          {toUSD(data[1], "total")}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          <div
            style={{
              width: "400px",
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.chart}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            {props.sideTableOne}
            {props.sideTableTwo}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralDashboard;
