import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getAccounts } from "../../pages/Accounting/api";
import { payInvoice } from "../../pages/Sales/api";
import { formatDate } from "../../util/dates";
import { toUSD } from "../../util/formatters";
import { findIndex } from "../../util/jsonUtil";
import CurrencyInput from "../Forms/CurrencyInput";
import DatePickerEx from "../Forms/DateSelector";
import Input from "../Forms/Input";
import SearchableWithButton from "../Forms/SearchableWithButton";

function Payment(props) {
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(new Date());
  const [account, setAccount] = useState(null);
  const [accountIndex, setAccountIndex] = useState(null);
  const [accounts, setAccounts] = useState(null);

  useEffect(() => {
    getAccounts().then((data) => {
      setAccounts(data);
    });
  }, []);

  var accountList = null;
  if (accounts) {
    accountList = [...accounts.map((a) => [a.name])];
  }

  const setAccountIndexHelper = (e) => {
    console.log(e);
    setAccountIndex(e);
    setAccount(accounts[parseInt(e)].id);
  };

  const submit = async () => {
    console.log(
      "making a payment of" +
        toUSD(amount, "total") +
        " for invoice #" +
        props.popup.id
    );

    return payInvoice(props.popup.id, amount, account, formatDate(date));
  };

  return (
    <Modal show={props.popup.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Record a Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label className="form-label">Date</label>
        <div style={{ width: "50%", marginBottom: "10px" }}>
          <DatePickerEx date={date} setDate={setDate} />
        </div>
        <label className="form-label">Account</label>
        <div style={{ position: "relative" }}>
          <SearchableWithButton
            appendID={"-searchable"}
            list={accountList}
            value={accountIndex}
            setValue={(e) => setAccountIndexHelper(e)}
            defaultValue={"Select an Account"}
          />
        </div>

        <label className="form-label">Price</label>
        <CurrencyInput
          value={amount}
          onChange={(val) => {
            setAmount(val);
          }}
          maxLength={11}
          maxDecimals={2}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.handleClose()}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() =>
            submit().then((id) => {
              props.refresh();
              props.handleClose();
            })
          }
        >
          Pay {toUSD(amount, "total")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Payment;
