import React from "react";
import { Modal, Button } from "react-bootstrap";

function DeleteConfirmation(props) {
  return (
    <Modal show={props.popup.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you would like to delete?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.handleClose()}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => props.handleDelete()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmation;
