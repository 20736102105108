import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Input from "../../../components/Forms/Input";
import { toFloatg } from "../../../util/formatters";

function CreateTaxPopup(props) {
  const [taxForm, setTaxForm] = useState({
    name: "",
    percentage: "test",
    tax_number: "test",
    is_recoverable: false,
    is_compound: false,
  });

  useEffect(() => {
    if (document.getElementById("validationServer03")) {
      if (props.taxes.map((a) => a.name).includes(taxForm.name)) {
        if (
          !document
            .getElementById("validationServer03")
            .classList.contains("is-invalid")
        ) {
          document
            .getElementById("validationServer03")
            .classList.add("is-invalid");
        }
      } else {
        document
          .getElementById("validationServer03")
          .classList.remove("is-invalid");
      }
    }
  }, [taxForm]);

  const setForm = (key, value) => {
    var tempForm = taxForm;
    tempForm[key] = value;
    setTaxForm(JSON.parse(JSON.stringify(tempForm)));
  };
  console.log("rerender");

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Create a New Tax</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label for="validationServer03" class="form-label">
            Tax Name
          </label>
          <input
            type="text"
            class="form-control"
            id="validationServer03"
            aria-describedby="validationServer03Feedback"
            required
            value={taxForm.name}
            onChange={(e) => setForm("name", e.target.value)}
          />
          <div id="validationServer03Feedback" class="invalid-feedback">
            Name already exists. Try using another name or select from the list
            above.
          </div>
        </div>
        <div>
          <Input
            className="mb-3 col"
            label="Tax Rate %"
            id="percentage"
            value={taxForm.percentage}
            onChange={(e) => setForm("percentage", e.target.value)}
          />
        </div>
        <div>
          <Input
            className="mb-3 col"
            label="Tax Number"
            id="tax_number"
            value={taxForm.tax_number}
            onChange={(e) => setForm("tax_number", e.target.value)}
          />
        </div>
        <div>
          <input
            type="checkbox"
            id="recoverable"
            name="is_recoverable"
            checked={taxForm.is_recoverable}
            onChange={(e) => setForm("is_recoverable", !taxForm.is_recoverable)}
          />{" "}
          This is a recoverable tax
        </div>
        <div>
          <input
            type="checkbox"
            id="recoverable"
            name="is_recoverable"
            checked={taxForm.is_compound}
            onChange={(e) => setForm("is_compound", !taxForm.is_compound)}
          />{" "}
          This is a compound tax
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setShow(false)}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            props.onSubmit(taxForm);
            props.setShow(false);
          }}
          style={{ width: "80px" }}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateTaxPopup;
