import React from "react";
import { toUSD } from "../../../../../util/formatters";
import { subtractMoney } from "../../../../../util/money";

function LineItemCS(props) {
  return (
    <tr>
      <td>{props.item.invoice_number}</td>
      <td>{props.item.issue_date}</td>
      <td>{props.item.due_date}</td>
      <td style={{ textAlign: "right" }}>
        {toUSD(props.item.amount_due, "total")}
      </td>
      <td style={{ textAlign: "right" }}>
        {toUSD(props.item.amount_paid, "total")}
      </td>
      <td style={{ textAlign: "right" }}>
        {toUSD(
          subtractMoney(props.item.amount_due, props.item.amount_paid),
          "total"
        )}
      </td>
    </tr>
  );
}

export default LineItemCS;
