import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MultiPage from "../../Documents/MultiPage";
import EstimateHeader from "../../Sales/Estimates/EstimateHeader";
import EstimateBody from "../../Sales/Estimates/EstimateBody";
import EstimateTotals from "../../Sales/Estimates/EstimateTotals";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { companyInfo, userInvoiceSettings } from "../../Sales/Estimates/Data";
import {
  getEstimateItems,
  getEstimate,
  newEstimate,
  updateEstimate,
  putLineItem,
  deleteLineItem,
  getCurrentDocumentId,
} from "../../Sales/Estimates/util";
import { toFloatg } from "../../../util/formatters";

import {
  dateTodayUSAformat,
  dateNdaysFromDateFormatted,
  getDate,
} from "../../../util/dates";

import { isEqual } from "../../../util/jsonUtil";
import {
  deleteLineItemTax,
  getItemTaxes,
  getSalesProducts,
  postLineItem,
  postLineItemTax,
  sendDocument,
} from "../../Sales/api";
import MainFooter from "../../Documents/Footers/MainFooter";

import { IoSettingsSharp } from "react-icons/io5";

import SendInvoice from "../../Sales/Invoices/Send/SendInvoice";
import ErrorPopup from "../../../components/Popups/ErrorPopup";
import { getPurchasesProducts, getVendor, getVendors } from "../api";
import { getProductPricing, postTax } from "../../Site/api";
import { getTaxes } from "../../Site/api";

async function handleDownloadPdf(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 4, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  pdf.save("print.pdf");
}

async function pdfToBase64(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 2, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  return btoa(pdf.output());
}

//handling form submission, page settings, db fetching
function PurchaseOrdersForm(props) {
  let navigate = useNavigate();
  let { id } = useParams();

  const [orientation, setOrientation] = useState("portrait");
  const [lineItemsOrig, setLineItemsOrig] = useState({});
  const [lineItemsForm, setLineItemsForm] = useState([
    {
      id: -1, //negative id to denote new item
      item_name: "LineItem",
      quantity: "1",
      price: "0.00",
      discount: null,
      tax: "0.0",
      line_total: "0.00",
      notes: "notes",
      page_order: "0",
      invoice_id: "",
      products_and_services_id: null,
    },
  ]);
  const [form, setForm] = useState({
    status: "draft",
    from_name: "Name",
    from_address: "Address",
    from_phone: "Phone",
    from_website: "Website",
    from_email: "Email",

    issue_date: dateTodayUSAformat,
    net: userInvoiceSettings[0].default_net.toString(),
    due_date: dateNdaysFromDateFormatted(
      new Date(),
      parseInt(userInvoiceSettings[0].default_net)
    ),
    currency: "USD",
    po_number: "#",

    to_name: companyInfo[0].name,
    to_address: companyInfo[0].address,
    to_city: companyInfo[0].city,
    to_zip: companyInfo[0].zip,
    to_state: companyInfo[0].state,
    to_country: companyInfo[0].country,
    to_phone: companyInfo[0].number,
    to_website: companyInfo[0].website,
    to_email: companyInfo[0].email,

    to_other: "Other",

    invoice_number: id,

    footer: "notes",
    tax_percentage: "0",
    discount_amount: "0",
    discount_percentage: "0",
    amount_paid: "0",
    amount_due: "0",

    type: "purchase-order",

    created_at: null,
    updated_at: null,
    archived_at: null,
    deleted_at: null,
    converted_from: null,
    converted_to: null,
    customer_id: null,
    vendor_id: null,
  });
  const [allTaxes, setAllTaxes] = useState([]);
  const [taxes, setTaxes] = useState([[]]);
  const [taxesOrig, setTaxesOrig] = useState([[]]);

  const [sendPopup, setSendPopup] = useState({
    show: false,
    toEmail: null,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [allProducts, setAllProducts] = useState([]);
  const [quantityPricing, setQuantityPricing] = useState([[]]);
  const [vendors, setVendors] = useState([]);

  const printRefs = useRef([]);

  useEffect(() => {
    getVendors().then((data) => setVendors(data));

    if (id && props.idType == "document") {
      getEstimateItems(id).then((data) => {
        var taxList = [];
        for (let i in data) {
          getItemTaxes(data[i].id).then((taxData) => {
            console.log(taxData);
            taxList[i] = taxData;
            setTaxes(taxList);
            setTaxesOrig(JSON.parse(JSON.stringify(taxList)));
          });
          if (data[i].products_and_services_id) {
            getProductPricing(data[i].products_and_services_id).then(
              (pricingData) => {
                var pricingList = quantityPricing;
                pricingList[i] = pricingData;
                setQuantityPricing(pricingList);
              }
            );
          } else {
            var pricingList = quantityPricing;
            pricingList[i] = [];
            setQuantityPricing(pricingList);
          }
        }

        console.log(data);
        setLineItemsOrig(JSON.parse(JSON.stringify(data)));
        setLineItemsForm(data);

        console.log("edit" + id);
      });
      getEstimate(id).then((data) => {
        setForm(data[0]);
      });
    } else {
      console.log("add");
      getCurrentDocumentId().then((data) => {
        var currentForm = form;
        currentForm.invoice_number = parseInt(data[0].last_value) + 1;
        setForm(currentForm);
        console.log(JSON.parse(JSON.stringify(currentForm)));
      });
    }
    if (id && props.idType == "vendor") {
      console.log("custo");
      getVendor(id).then((data) => setVendorInfo(data[0]));
    }

    getTaxes().then((data) => {
      setAllTaxes(data);
    });
    getPurchasesProducts().then((data) => {
      console.log(data);
      setAllProducts(data);
    });
  }, []);

  const SecondaryHeader = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{props.upperLeft}</div>
        <div>{props.upperRight}</div>
      </div>
    );
  };

  const Footer = (props) => {
    return (
      <div
        style={{
          height: "30px",
          display: "flex",
          marginTop: "auto",
          justifyContent: "space-between",
        }}
      >
        <div>{props.lowerLeft}</div>
        <div>{props.lowerRight}</div>
      </div>
    );
  };

  const setVendorInfo = (vendor) => {
    var updatedForm = form;
    updatedForm.from_name = vendor.primary_name;
    updatedForm.from_address = vendor.billing_address;
    updatedForm.from_city = vendor.billing_city;

    updatedForm.from_state = vendor.billing_state;
    updatedForm.from_zip = vendor.billing_zip_code;
    updatedForm.from_country = vendor.billing_country;
    updatedForm.from_phone = vendor.primary_phone;
    updatedForm.from_email = vendor.primary_email;
    updatedForm.from_website = vendor.website;
    setForm(updatedForm);
  };

  const submitForm = () => {
    console.log(form);
    console.log(lineItemsForm);
    if (id && props.idType == "document") {
      editEstimate().then(() => {
        navigate("/purchases/orders");
      });
    } else {
      newEstimate(form).then((data) => {
        const id = data.rows[0].id;
        for (var i in lineItemsForm) {
          var lineItemsCopy = lineItemsForm[i];
          delete lineItemsCopy["id"];
          lineItemsCopy["invoice_id"] = id;

          postLineItem(lineItemsForm[i]).then((data) => {
            console.log(data);
          });
        }
        navigate("/purchases/orders");
      });
    }
  };

  const editEstimate = async () => {
    var estimateForm = form;
    delete estimateForm["id"];
    delete estimateForm["owner"];
    updateEstimate(estimateForm, id).then((data) => {
      console.log(data);
    });

    //Edit Taxes

    for (var i in taxesOrig) {
      for (var j in taxesOrig[i]) {
        var found_j = false;
        for (var k in taxes[i]) {
          if (taxesOrig[i][j].id == taxes[i][k].id) {
            found_j = true;
          }
        }
        if (!found_j) {
          deleteLineItemTax(lineItemsOrig[i].id, taxesOrig[i][j].id);
        }
      }
    }

    for (var i in taxes) {
      for (var j in taxes[i]) {
        var found_j = false;

        for (var k in taxesOrig[i]) {
          if (taxesOrig[i][k].id == taxes[i][j].id) {
            found_j = true;
          }
        }
        if (!found_j) {
          postLineItemTax(lineItemsForm[i].id, taxes[i][j].id);
        }
      }
    }

    //Edit Line Items

    // Loop through items and POST the ones with .new==true
    for (var i in lineItemsForm) {
      if (lineItemsForm[i].id < 0) {
        console.log("POST");
        var lineItemBody = lineItemsForm[i];
        delete lineItemBody["id"];
        lineItemBody["invoice_id"] = id;
        postLineItem(lineItemBody);
      }
    }
    var found_i = false;
    var found_index = null;
    console.log(lineItemsForm);
    console.log(lineItemsOrig);
    for (var i in lineItemsOrig) {
      for (var j in lineItemsForm) {
        if (lineItemsOrig[i].id == lineItemsForm[j].id) {
          found_i = true;
          found_index = j;
        }
      }
      if (found_i) {
        if (!isEqual(lineItemsOrig[i], lineItemsForm[found_index])) {
          var lineItemBody = lineItemsForm[found_index];
          const lineItemId = lineItemsForm[found_index].id;
          delete lineItemBody["id"];
          putLineItem(lineItemBody, lineItemId);
        }
      } else {
        console.log("DELETE" + lineItemsOrig[i].id);
        deleteLineItem(lineItemsOrig[i].id);
      }
      found_i = false; //reset boolean
      found_index = null;
    }
    // this.putInvoice(this.props.invoice_id);
  };

  const createTax = (form) => {
    console.log("creating tax");

    const body = {
      tax_number: form.tax_number,
      name: form.name,
      percentage: toFloatg(form.percentage / 100, "discount"),
      is_recoverable: form.is_recoverable,
      is_compound: form.is_compound,
      effective_date: null,
      obsolete_date: null,
      created_at: Date.now(),
      updated_at: Date.now(),
      archived_at: null,
      deleted_at: null,
    };
    postTax(body).then((json_res) => {
      console.log(json_res);
      getTaxes().then((data) => {
        setAllTaxes(data);
      });
    });
  };

  const addLineItem = () => {
    console.log("add line item");

    const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
    newLineItems.push({
      id: 0 - lineItemsForm.length - 1, //negative id to denote new item - starts counting from -1 if starting from empty
      item_name: "LineItem",
      quantity: "1",
      price: "0.00",
      discount: null,
      tax: "0.0",
      line_total: "0.00",
      notes: "notes",
      page_order: lineItemsForm.length,
      invoice_id: "",
      products_and_services_id: null,
    });
    setLineItemsForm(newLineItems);
    console.log(newLineItems);
    var newTaxes = JSON.parse(JSON.stringify(taxes));
    newTaxes.push([]);
    setTaxes(newTaxes);
    var newPricing = JSON.parse(JSON.stringify(quantityPricing));
    newPricing.push([]);
    setQuantityPricing(newPricing);
  };

  const removeLineItem = (index) => {
    var currentList = lineItemsForm;
    var currentTaxList = taxes;
    var currentQuantityPricingList = quantityPricing;
    currentList.splice(index, 1);
    currentTaxList.splice(index, 1);
    currentQuantityPricingList.splice(index, 1);
    setLineItemsForm(JSON.parse(JSON.stringify(currentList)));
    setTaxes(JSON.parse(JSON.stringify(currentTaxList)));
    setQuantityPricing(JSON.parse(JSON.stringify(currentQuantityPricingList)));
  };

  function getLineItemHeight(i) {
    var height = 50;
    if (lineItemsForm[i].discount != null) {
      height = height + 50;
    }
    if (taxes[i]) {
      height = height + taxes[i].length * 25;
    }
    var notesHeight = 50;
    if (lineItemsForm[i].notes != null) {
      //calculate the number of occuracnes of '</div>' in notes strings
      var count = (lineItemsForm[i].notes.match(/<\/div>/g) || []).length;

      notesHeight = notesHeight + 25 * count;
    }
    return Math.max(height, notesHeight);
  }
  function getTotalBoxHeight() {
    var height = 140; //starting height
    const numberOfUniqueTaxes = [
      ...new Set(taxes.flat().map((item) => item.name)),
    ].length;
    console.log(numberOfUniqueTaxes);
    height = height + numberOfUniqueTaxes * 25;
    //if there is a global discount
    if (
      parseFloat(form.discount_amount) > 0 ||
      parseFloat(form.discount_percentage) > 0
    ) {
      height = height + numberOfUniqueTaxes * 25; //shows unique taxes again with updated discount
      height = height + 80; //extra border separator and another subtotal line
    }

    return height;
  }

  return (
    <div>
      <div
        style={{
          width: "800px",
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
        }}
      >
        <div
          className="top-left"
          style={{ display: "flex", marginRight: "50px" }}
        >
          <h1 className="pageTitle">
            {id && props.idType == "document"
              ? "Edit Purchase Order"
              : "New Purchase Order"}{" "}
            <IoSettingsSharp
              className="settingsIcon"
              fontSize="16px"
              style={{ marginTop: "5px" }}
            />
          </h1>
        </div>

        <div className="invoiceButtons">
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                if (orientation == "landscape") {
                  setOrientation("portrait");
                } else {
                  setOrientation("landscape");
                }
                const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
                setLineItemsForm(newLineItems);
              }}
            >
              Change Orientation
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => handleDownloadPdf(printRefs.current, orientation)}
            >
              Download as PDF
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                submitForm();
              }}
            >
              Save
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setSendPopup({ show: true, toEmail: form.to_email });
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>

      <div style={{ fontFamily: "Georgia", fontSize: "15px" }}>
        <MultiPage
          lineItems={lineItemsForm}
          orientation={orientation}
          printRefs={printRefs}
          header={
            <EstimateHeader
              setters={[setLineItemsForm, setForm]}
              getters={[lineItemsForm, form, vendors]}
              title={"Purchase Order"}
            />
          }
          secondaryHeader={
            <SecondaryHeader
              upperLeft={"Purchase Order # " + form.invoice_number}
              upperRight={getDate(new Date())}
            />
          }
          body={<EstimateBody />}
          totals={
            <EstimateTotals
              info={[...lineItemsForm.map((a) => [a.line_total])]}
              paid={form.amount_paid}
              taxes={allTaxes}
              createTax={(e) => createTax(e)}
              setters={[setLineItemsForm, setForm, setAllTaxes, setTaxes]}
              getters={[lineItemsForm, form, allTaxes, taxes]}
            />
          }
          mainFooter={
            <MainFooter lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />
          }
          footer={<Footer lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />}
          companyInfo={companyInfo}
          setters={[
            setLineItemsForm,
            setForm,
            setAllTaxes,
            setTaxes,
            setAllProducts,
            setQuantityPricing,
          ]}
          getters={[
            lineItemsForm,
            form,
            allTaxes,
            taxes,
            allProducts,
            quantityPricing,
          ]}
          addLineItem={addLineItem}
          deleteLineItem={removeLineItem}
          getLineItemHeight={getLineItemHeight}
          getTotalBoxHeight={getTotalBoxHeight}
          createTax={createTax}
        />
      </div>
      <SendInvoice
        sendPopup={sendPopup}
        closePopup={() => {
          setSendPopup({ show: false, id: null, toEmail: null });
        }}
        setError={(error) => setError(error)}
        setSuccess={() => setSuccess(true)}
        toSent={(id) => sendDocument(id).then(() => console.log("sent"))}
        attachment={() => pdfToBase64(printRefs.current, orientation)}
        subject={"Purchase Order #" + form.invoice_number}
        message={"Here is your Purchase Order"}
      />
      {error ? (
        <ErrorPopup errorMessage={error} clearError={() => setError(null)} />
      ) : null}
    </div>
  );
}

export default PurchaseOrdersForm;
