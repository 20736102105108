import React, { useEffect } from "react";
import Dropdown from "../Dropdown";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthDays = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
  "29th",
  "30th",
  "31st",
];
const daysSlice = [0, 3, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0]; // Number of options to remove from the monthDays list for each month

function MonthAndDayInput() {
  const [month, setMonth] = React.useState(months[11]);
  const [day, setDay] = React.useState(monthDays[30]);
  const [slicedDays, setSlicedDays] = React.useState(monthDays);

  useEffect(() => {
    var sliced = [];
    sliced = JSON.parse(JSON.stringify(monthDays));
    for (var i = 0; i < daysSlice[months.indexOf(month).toString()]; i++) {
      sliced.pop();
    }
    setSlicedDays(sliced);
    const maxNumberOfDays =
      monthDays.length - 1 - daysSlice[months.indexOf(month).toString()];
    const chosenIndex = monthDays.indexOf(day).toString();

    if (chosenIndex > maxNumberOfDays) {
      const difference = chosenIndex - maxNumberOfDays;
      setDay(monthDays[chosenIndex - difference]);
    }
  }, [month]);

  return (
    <div style={{ display: "flex" }}>
      <Dropdown
        value={month}
        onChange={(e) => setMonth(e.target.value)}
        dropdownList={months}
      />
      <Dropdown
        value={day}
        onChange={(e) => setDay(e.target.value)}
        dropdownList={slicedDays}
      />
    </div>
  );
}

export default MonthAndDayInput;
