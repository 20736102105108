import React from "react";

function Payments() {
  return (
    <div
      style={{
        marginLeft: "300px",
        marginRight: "auto",
        marginTop: "50px",
        position: "relative",
        maxWidth: "100vh",
      }}
    >
      <h1>Payments</h1>
      <img
        style={{ width: "600px", height: "auto" }}
        src={"/underConstruction.png"}
      />
    </div>
  );
}

export default Payments;
