import React from "react";
import { toUSD } from "../../../util/formatters";

function BalanceSheetBody(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {" "}
      <table
        width="80%"
        style={{
          "table-layout": "fixed",
          borderSpacing: "15px 0px",
          borderCollapse: "separate",
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        <thead>
          <tr>
            <th style={{ textDecoration: "underline", fontSize: "18px" }}>
              Accounts
            </th>
            <th
              style={{
                textAlign: "right",
                textDecoration: "underline",
                fontSize: "18px",
              }}
            >
              {props.date}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.slicedItems.map((item, index) => {
            var style = {
              textAlign: "right",
              paddingRight:
                (item.margin * 20 + item.shift * 20).toString() + "px",
            };

            if (item.border == 1) {
              style = {
                textAlign: "right",
                borderTop: "1px solid black",
                paddingRight:
                  (item.margin * 20 + item.shift * 20).toString() + "px",
              };
            } else if (item.border == 2) {
              style = {
                textAlign: "right",
                fontWeight: "bold",
                borderTop: "1px solid black",
                paddingRight:
                  (item.margin * 20 + item.shift * 20).toString() + "px",
              };
            } else if (item.border == 3) {
              style = {
                textAlign: "right",
                fontWeight: "bold",
                borderTop: "7px double black",
                paddingRight:
                  (item.margin * 20 + item.shift * 20).toString() + "px",
              };
            }
            return (
              <tr>
                <td
                  style={{
                    textIndent:
                      (item.margin * 20 + item.shift * 20).toString() + "px",
                    fontWeight: "bold",
                  }}
                >
                  {item.margin == 0 ? (
                    <div>{item.category}</div>
                  ) : item.margin == 1 ? (
                    <div>{item.subcategory}</div>
                  ) : item.margin == 2 ? (
                    <div>{item.type}</div>
                  ) : item.margin == 3 ? (
                    <div>{item.account_number + " " + item.name}</div>
                  ) : null}
                </td>
                <td style={style}>
                  {item.balance ? toUSD(item.balance, "total") : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default BalanceSheetBody;
