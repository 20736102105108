//TAG:AUTHENTICATION

export function currentUser() {
    return JSON.parse(localStorage.getItem("asp-user-token")).user?.id;
}

export function currentBusiness() {
    return JSON.parse(localStorage.getItem("asp-user-token")).business?.id;
}

export function currentTokenString() {
    return JSON.stringify(
        JSON.parse(localStorage.getItem("asp-user-token")).token
    );
}
