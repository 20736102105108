export const documentsSchema = {
  status: null, //invoice_status
  from_name: null, //varchar
  from_address: null, //varchar
  from_phone: null, //varchar
  from_website: null, //varchar
  from_email: null, //varchar
  issue_date: null, //varchar
  net: null, //varchar
  due_date: null, //varchar
  currency: null, //varchar
  po_number: null, //varchar
  to_name: null, //varchar
  to_address: null, //varchar
  to_city: null, //varchar
  to_zip: null, //varchar
  to_state: null, //varchar
  to_country: null, //varchar
  to_phone: null, //varchar
  to_website: null, //varchar
  to_email: null, //varchar
  to_other: null, //varchar
  invoice_number: null, //varchar
  footer: null, //varchar
  tax_percentage: null, //numeric:
  discount_amount: null, //numeric
  discount_percentage: null, //numeric
  amount_paid: null, //numeric
  amount_due: null, //numeric
  type: null, //varchar
  created_at: null, //timestamp
  updated_at: null, //timestamp
  archived_at: null, //timestamp
  deleted_at: null, //timestamp
  converted_from: null, //int
  converted_to: null, //int
  customer_id: null, //int
  vendor_id: null, //int
};

export const documentItemsSchema = {
  item_name: null, //varchar
  quantity: null, //varchar
  price: null, //numeric
  discount: null, //numeric
  tax: null, //numeric:
  line_total: null, //numeric
  notes: null, //varchar
  page_order: null, //int:
  invoice_id: null, //int
  products_and_services_id: null, //int
};

export const transactionsSchema = {
  date: null, //varchar
  description: null, //varchar
  account_id: null, //int
  category: null, //varchar
  amount: null, //numeric
  notes: null, //varchar
  reviewed: null, //boolean
  document_id: null, //int
};

export const journalEntriesSchema = {
  description: null, //varchar
  account_id: null, //int
  transaction_id: null, //int
  debit: null, //numeric
  credit: null, //numeric
  tax_id: null, //int
  customer_id: null, //int
  vendor_id: null, //int
  document_id: null, //int
};
