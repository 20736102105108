import React from "react";
import DatePickerEx from "../Forms/DateSelector";

function DateFilter(props) {
  return (
    <div style={{ display: "flex" }}>
      <div className="filter">
        <DatePickerEx
          placeholder={"From"}
          date={props.from}
          setDate={props.setFrom}
        />
      </div>
      <div className="filter">
        <DatePickerEx
          placeholder={"To"}
          date={props.to}
          setDate={props.setTo}
        />
      </div>
    </div>
  );
}

export default DateFilter;
