import React from "react";
import { toUSD } from "../../../util/formatters";

function AccountBalancesBody(props) {
  console.log(props.data);
  return (
    <table>
      <thead>
        <tr style={{ borderBottom: "3px solid #000" }}>
          <th>Account</th>
          <th>Ending Balance</th>
        </tr>
      </thead>
      <tbody>
        {props.slicedItems.map((item, index) => {
          return (
            <tr>
              <td>{item.name}</td>
              <td>{toUSD(item.balance, "total")}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default AccountBalancesBody;
