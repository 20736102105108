import React from "react";
import Dropdown from "../../components/Forms/Dropdown";
import Input from "../../components/Forms/Input";
import SettingsSidebar from "./SettingsSidebar";

function InvoiceCustomization() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Invoice Customization" />
        <div style={{ marginLeft: "30px" }}>
          <h1>Invoice Customization</h1>
          <Dropdown
            label="Default Payment Terms"
            dropdownList={[
              "Due upon receipt",
              "Due within 15 days",
              "Due within 30 days",
              "Due within 45 days",
              "Due within 60 days",
              "Due within 90 days",
            ]}
          />
          <Input label="Prepend Invoice Number" />
          <Input label="Default Invoice Title" />
          <Input label="Default Invoice Footer" />
        </div>
      </div>
    </div>
  );
}

export default InvoiceCustomization;
