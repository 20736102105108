import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListPage from "../../../components/Lists/ListPage";
import ReportBox from "../../../components/Reports/ReportBox";
import DropdownFilter from "../../../components/Filters/DropdownFilter";
import DateFilter from "../../../components/Filters/DateFilter";

import DeleteConfirmation from "../../../components/Popups/DeleteConfirmation";

import { ColumnFilter } from "../../../components/Tables/Estimates/ColumnFilter";

import {
  getCategories,
  deleteCategories,
  getNumberOfProductsInCategory,
} from "../api";

const columns = [
  {
    Header: "Name",
    accessor: "name",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Description",
    accessor: "description",
    Filter: ColumnFilter,
  },
  {
    Header: "Account",
    accessor: "account",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Applicable Tax",
    accessor: "tax",
    disableFilters: true,
    Filter: ColumnFilter,
  },
];

//Page to define a ListPage by describing table data, summary data, and row actions
function Categories(props) {
  let navigate = useNavigate();
  const [dropdownFilter, setDropdownFilter] = useState("");
  const [popup, setPopup] = useState({ show: false, id: null });
  const [data, setData] = useState([]);

  var extraColumnData = null;

  useEffect(() => {
    getAllCategories();
  }, []);

  //update when changing between sales/categories page and purchases/categories
  useEffect(() => {
    getAllCategories();
  }, [props.type]);

  function getAllCategories() {
    getCategories(props.type).then((data) => {
      console.log(data);
      setData(data);
    });
  }

  function handleClose() {
    setPopup({ show: false, id: null });
  }

  async function handleDelete() {
    console.log("DELETE" + popup.id);
    deleteCategories(popup.id).then((data) => {
      getCategories(props.type).then((data) => {
        setData(data);
        setPopup({ show: false, id: null });
      });
    });
  }

  return (
    <div>
      <ListPage
        title={"Categories" + " (" + props.type + ")"}
        singular={"Category"}
        link={"/" + props.type + "/categories/add"}
        summaryBoxes={[
          <ReportBox
            reportLabel={"Number of Categories"}
            report={data.length}
          />,
        ]}
        data={data}
        columnFilter={ColumnFilter}
        columns={columns}
        extraColumnData={extraColumnData}
        actions={[
          {
            name: "View",
            method: () => console.log("view"),
          },
          {
            name: "Edit",
            method: (id) =>
              navigate("/" + props.type + "/categories/update/" + id),
          },
          {
            name: "DIVIDER",
          },
          {
            name: "Add a Product or Service",
            method: (id) =>
              navigate("/" + props.type + "/products-and-services/add/" + id),
          },
          {
            name: "DIVIDER",
          },
          {
            name: "Delete",
            method: (id) => setPopup({ show: true, id: id }),
          },
        ]}
      />
      <DeleteConfirmation
        popup={popup}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default Categories;
