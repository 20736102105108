import { getProduct, putProduct } from "../Site/api";

export async function getPart(id) {
  var parts = JSON.parse(localStorage.getItem("inventory"));
  var part = parts.find((part) => part.id == id);
  return part;
}

export async function adjustProductStock(id, quantity) {
  try {
    getProduct(id).then(async (prod) => {
      let product = prod[0];
      product.stock = parseInt(product.stock) + quantity;
      delete product["id"];
      delete product["owner"];
      return await putProduct(product, id);
    });
  } catch (error) {
    return error;
  }
}
