import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DateSelector from "../../../components/Forms/DateSelector";
import SearchableWithButton from "../../../components/Forms/SearchableWithButton";
import HelpIcon from "../../../components/Icons/HelpIcon";
import { debitShouldAdd } from "../../../util/accounting";
import { firstDayOfYear } from "../../../util/dates";
import { toUSD } from "../../../util/formatters";
import { addMoney, subtractMoney, sumMoney } from "../../../util/money";
import {
  getAccounts,
  getGeneralLedger,
  getGeneralLedgerAccountView,
  getTransactionJournalEntries,
} from "../../Accounting/api";

import "./GeneralLedger.css";

function GeneralLedger() {
  const [startDate, setStartDate] = useState(firstDayOfYear(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [account, setAccount] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [generalLedger, setGeneralLedger] = useState([]);
  const [generalLedgerAccountView, setGeneralLedgerAccountView] = useState([]);

  const [ledgerView, setLedgerView] = useState("general"); //  general/account

  let nav = useNavigate();

  useEffect(() => {
    getAccounts().then((data) => {
      console.log("Accounts: ", data);
      setAccountList(data);
    });
    getGeneralLedger().then((data) => {
      console.log(data);
      setGeneralLedger(data);
    });

    getGeneralLedgerAccountView().then((data) => {
      console.log(data);
      setGeneralLedgerAccountView(data);
    });
  }, []);

  //Takes in an array of objects
  //Returns an array of arrays of objects
  //Group each object into an array based on the id
  const groupBy = (array, key) => {
    if (array.length === 0) return [];
    let arrayOfArrays = [];
    let currentArray = [];
    let currentId = null;
    array.forEach((element) => {
      if (element[key] !== currentId) {
        if (currentArray.length > 0) {
          arrayOfArrays.push(currentArray);
        }
        currentArray = [];
        currentId = element[key];
      }
      currentArray.push(element);
    });
    arrayOfArrays.push(currentArray);
    return arrayOfArrays;
  };

  //array of objects to an array of values for a given key
  const getValues = (array, key) => {
    let values = [];
    array.forEach((element) => {
      if (
        new Date(element.date) >= startDate &&
        new Date(element.date) <= endDate
      ) {
        values.push(element[key]);
      }
    });
    return values;
  };

  const getAccountBalance = (account, date, debitShouldAdd, currentBalance) => {
    let balance = currentBalance;
    console.log("Account: ", account);
    console.log("Date: ", date);
    console.log("Debit Should Add: ", debitShouldAdd);
    console.log("Current Balance: ", currentBalance);
    console.log("General Ledger: ", generalLedgerAccountView);
    generalLedgerAccountView.forEach((entry) => {
      if (entry.name === account && new Date(entry.date) >= date) {
        if (debitShouldAdd) {
          if (entry.debit) {
            balance = subtractMoney(balance, entry.debit);
          } else {
            balance = addMoney(balance, entry.credit);
          }
        } else {
          if (entry.debit) {
            balance = addMoney(balance, entry.debit);
          } else {
            balance = subtractMoney(balance, entry.credit);
          }
        }
      }
    });
    return balance;
  };

  console.log(groupBy([], "id"));
  console.log(groupBy(generalLedger, "id"));
  console.log(groupBy(generalLedgerAccountView, "name"));

  return (
    <div
      style={{
        marginLeft: "300px",
        marginRight: "auto",
        marginTop: "50px",
        position: "relative",
        maxWidth: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>General Ledger</h1>
        <div>
          {ledgerView === "general" ? (
            <button
              className="btn-light btn"
              style={{ marginRight: "30px" }}
              onClick={() => setLedgerView("account")}
            >
              Account View
            </button>
          ) : (
            <button
              className="btn-light btn"
              style={{ marginRight: "30px" }}
              onClick={() => setLedgerView("general")}
            >
              General View
            </button>
          )}

          <button className="btn-light btn">Export</button>
        </div>
      </div>

      <div
        style={{
          height: "200px",
          backgroundColor: "rgb(236, 240, 243)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "50%",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            marginLeft: "30px",
            marginRight: "30px",
          }}
        >
          <div>
            Account
            <div style={{ width: "200px" }}>
              {ledgerView === "general" ? (
                <input
                  class="form-control"
                  disabled
                  value={"All Accounts"}
                ></input>
              ) : (
                <SearchableWithButton
                  appendID={"-searchable-accounts"}
                  list={[...accountList.map((a) => [a.name])]}
                  value={account}
                  setValue={(e) => setAccount(e)}
                  defaultValue={"Select an Account"}
                />
              )}
            </div>
          </div>
          <div>
            Date Range{" "}
            <div style={{ display: "flex" }}>
              <div style={{ width: "220px", marginRight: "30px" }}>
                <DateSelector date={startDate} setDate={setStartDate} />
              </div>
              <div style={{ width: "220px" }}>
                <DateSelector date={endDate} setDate={setEndDate} />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "50%",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "30px",
          }}
        >
          <div>
            <div style={{ marginRight: "5px" }}>
              Report Type
              <HelpIcon
                message={
                  "Accrual (Paid & Unpaid): Reflects all transactions, including unpaid invoices and bills. \nCash Basis (Paid): Reflects all transactions except unpaid invoices and bills"
                }
              />
            </div>

            <select className="form-control" style={{ width: "200px" }}>
              {["Accrual (Paid & Unpaid)", "Cash Basis (Paid)"].map(
                (item, index) => {
                  return (
                    <option className="dropdownOption" key={item} value={item}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <div
            style={{
              width: "470px",
              marginRight: "30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                pointerEvents: "none",
                filter: "blur(1px)",
                opacity: "0.5",
              }}
            >
              <div style={{ marginRight: "5px" }}>Contact</div>

              <select className="form-control" style={{ width: "200px" }}>
                {["All Contacts"].map((item, index) => {
                  return (
                    <option className="dropdownOption" key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <div style={{ visibility: "hidden" }}>INVISIBLE</div>
            </div>
          </div>
        </div>
      </div>
      {ledgerView === "general" ? (
        <table width="100%">
          <thead>
            <tr style={{ borderBottom: "1px solid black" }}>
              <th>Date</th>
              <th>Description</th>
              <th style={{ textAlign: "right" }}>Debit</th>
              <th style={{ textAlign: "right" }}>Credit</th>
            </tr>
          </thead>
          {groupBy(generalLedger, "id").map((item, index) => {
            var itemDate = new Date(item[0].date);
            if (itemDate < startDate || itemDate > endDate) {
              return null;
            }
            return (
              <>
                <tbody
                  className="journalEntryReportItem"
                  onClick={() =>
                    nav("/accounting/transactions/journal/view/" + item[0].id)
                  }
                >
                  <tr>
                    <td style={{ fontWeight: "bold" }}>{item[0].date}</td>
                    <td style={{ fontWeight: "bold" }}>
                      {item[0].description}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {item.map((subitem, subindex) => {
                    return (
                      <tr>
                        {subitem.debit ? (
                          <>
                            <td></td>
                            <td style={{ textIndent: "30px" }}>
                              {subitem.name}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {toUSD(subitem.debit, "total")}
                            </td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td></td>
                            <td style={{ textIndent: "60px" }}>
                              {subitem.name}
                            </td>
                            <td></td>
                            <td style={{ textAlign: "right" }}>
                              {toUSD(subitem.credit, "total")}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </>
            );
          })}
        </table>
      ) : (
        <table width="100%">
          <thead>
            <tr style={{ borderBottom: "1px solid black" }}>
              <th style={{ paddingLeft: "15px" }}>Date</th>
              <th>Description</th>
              <th style={{ textAlign: "right" }}>Debit</th>
              <th style={{ textAlign: "right" }}>Credit</th>
              <th style={{ textAlign: "right", paddingRight: "15px" }}>
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            {groupBy(generalLedgerAccountView, "name").map((item, index) => {
              //Date Filtering
              var itemDate = null;
              var hasItemsWithinDateRange = false;
              for (var i = 0; i < item.length; i++) {
                itemDate = new Date(item[i].date);
                if (itemDate >= startDate && itemDate <= endDate) {
                  console.log(itemDate);
                  hasItemsWithinDateRange = true;
                }
              }
              console.log(
                "Has Items Within Date Range: " + hasItemsWithinDateRange
              );
              if (!hasItemsWithinDateRange) {
                return null;
              }

              if (account) {
                if (item[0].name !== accountList[account].name) {
                  return null;
                }
              }

              var startingBalance = getAccountBalance(
                item[0].name,
                startDate,
                debitShouldAdd(item[0].category),
                item[0].balance
              );

              var runningBalance = startingBalance;

              var totalDebit = sumMoney(getValues(item, "debit"));
              var totalCredit = sumMoney(getValues(item, "credit"));

              // Credit/Debit logic based on account type
              var totalChange = null;
              if (
                item[0].category == "Assets" ||
                item[0].category == "Expenses"
              ) {
                totalChange = subtractMoney(totalDebit, totalCredit);
              } else if (
                item[0].category == "Liabilities" ||
                item[0].category == "Equity" ||
                item[0].category == "Income"
              ) {
                totalChange = subtractMoney(totalCredit, totalDebit);
              } else {
                throw new Error("Invalid account type");
              }

              return (
                <>
                  <tr style={{ backgroundColor: "rgb(236, 240, 243)" }}>
                    <td
                      style={{
                        fontWeight: "bold",
                        paddingLeft: "15px",
                      }}
                    >
                      <div>{item[0].name}</div>
                      <div style={{ fontSize: "14px", color: "gray" }}>
                        Under: {item[0].category}
                        {" >"} {item[0].type}
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(244, 248, 251)" }}>
                    <td
                      style={{
                        fontWeight: "bold",
                        paddingLeft: "45px",
                      }}
                    >
                      <div>Starting Balance</div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "right",
                        paddingRight: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {toUSD(startingBalance, "total")}
                    </td>
                  </tr>
                  {item.map((subitem, subindex) => {
                    itemDate = new Date(subitem.date);
                    if (itemDate < startDate || itemDate > endDate) {
                      return null;
                    }

                    if (
                      item[0].category == "Assets" ||
                      item[0].category == "Expenses"
                    ) {
                      runningBalance = addMoney(runningBalance, subitem.debit);
                      runningBalance = subtractMoney(
                        runningBalance,
                        subitem.credit
                      );
                    } else if (
                      item[0].category == "Liabilities" ||
                      item[0].category == "Equity" ||
                      item[0].category == "Income"
                    ) {
                      runningBalance = addMoney(runningBalance, subitem.credit);
                      runningBalance = subtractMoney(
                        runningBalance,
                        subitem.debit
                      );
                    } else {
                      throw new Error("Invalid account type");
                    }

                    return (
                      <tr
                        className="journalEntryReportItem"
                        onClick={() =>
                          nav(
                            "/accounting/transactions/journal/view/" +
                              subitem.id
                          )
                        }
                      >
                        <td style={{ textIndent: "45px" }}>{subitem.date}</td>
                        <td>{subitem.journal_description}</td>
                        <td style={{ textAlign: "right" }}>
                          {subitem.debit ? toUSD(subitem.debit, "total") : null}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {subitem.credit
                            ? toUSD(subitem.credit, "total")
                            : null}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "15px" }}
                        >
                          {toUSD(runningBalance, "total")}
                        </td>
                      </tr>
                    );
                  })}
                  <tr style={{ backgroundColor: "rgb(244, 248, 251)" }}>
                    <td
                      style={{
                        fontWeight: "bold",
                        textIndent: "45px",
                      }}
                    >
                      <div>Totals and Ending Balance</div>
                    </td>
                    <td></td>
                    <td style={{ textAlign: "right", fontWeight: "bold" }}>
                      {toUSD(totalDebit, "total")}
                    </td>
                    <td style={{ textAlign: "right", fontWeight: "bold" }}>
                      {toUSD(totalCredit, "total")}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        paddingRight: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {toUSD(addMoney(totalChange, startingBalance), "total")}
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: "rgb(236, 240, 243)",
                      paddingBottom: "30px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "bold",
                        textIndent: "45px",
                      }}
                    >
                      <div>Balance Change</div>
                      <div style={{ fontSize: "14px", color: "gray" }}>
                        Difference between starting and ending balances
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "right",
                        paddingRight: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {toUSD(totalChange, "total")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "30px" }}></td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default GeneralLedger;
