import React, { useState, useEffect } from "react";
import TileButton from "../../components/Buttons/TileButton";
import TileButtonRaised from "../../components/Buttons/TileButtonRaised";

import { GrTest } from "react-icons/gr";
import { IoIosConstruct } from "react-icons/io";
import SubmissionPopup from "../../components/Popups/SubmissionPopup";
import { api_url } from "../../util/environment";
import { getUsers } from "../Site/api";
import "./HomePage.css";
import Login from "./Login";
import SignUp from "./SignUp";
import { useNavigate } from "react-router-dom";

function HomePage(props) {
    // let nav = useNavigate();
    const [betaPopup, setBetaPopup] = useState({ show: false });
    const [testPopup, setTestPopup] = useState({ show: false });
    const [users, setUsers] = useState(null);

    const handleClose = () => {
        setBetaPopup({ show: false });
        setTestPopup({ show: false });
    };

    useEffect(() => {
        getUsers().then((data) => {
            setUsers(data);
        });
    }, []);

    let nav = useNavigate();

    const pswds = [
        "c9d04e", // Tim
        "e23e7f", // Steve
        "f3e48d", // Chesa
        "fc22ff", // Mark
        "da00c3", // Bill
        "f1a849", // Tom
        "ef496c", // Paula
        "6b742e", // Michelle
        "5d64ca", // Bert
        "be47d1", // TestAccount
    ];

    // const handleTestSubmit = (e) => {
    //   console.log(e);
    //   if (pswds.includes(e)) {
    //     console.log("Correct");
    //     console.log(users);
    //     const userId = users.find((user) => user.accessor === e).id;
    //     localStorage.setItem("user", userId);
    //     nav("/launchpad");
    //   } else {
    //     console.log("Incorrect");
    //   }
    //   setTestPopup({ show: false });
    // };

    return (
        <div>
            <nav
                class="navbar navbar-expand-lg navbar-light bg-light"
                style={{ height: "60px" }}
            >
                <div class="container-fluid">
                    <a
                        class="navbar-brand"
                        href="#"
                        style={{
                            fontFamily:
                                "sohne-var, Helvetica Neue, Arial, sans-serif",
                        }}
                    >
                        <img
                            src="/favicon.ico"
                            alt=""
                            width="24"
                            height="24"
                            class="d-inline-block align-text-top"
                            style={{
                                margin: "0 30px 0 10px",
                            }}
                        ></img>
                        ASP Accounting
                    </a>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div
                        class="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a
                                    class="nav-link disabled"
                                    aria-current="page"
                                    href="#"
                                    style={{
                                        fontFamily:
                                            "sohne-var, Helvetica Neue, Arial, sans-serif",
                                    }}
                                >
                                    Products
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link disabled"
                                    href="#"
                                    style={{
                                        fontFamily:
                                            "sohne-var, Helvetica Neue, Arial, sans-serif",
                                    }}
                                >
                                    Developers
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    class="nav-link disabled"
                                    href="#"
                                    tabindex="-1"
                                    aria-disabled="true"
                                    style={{
                                        fontFamily:
                                            "sohne-var, Helvetica Neue, Arial, sans-serif",
                                    }}
                                >
                                    Pricing
                                </a>
                            </li>
                        </ul>
                        <div>
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a
                                        class="nav-link disabled"
                                        href="#"
                                        tabindex="-1"
                                        aria-disabled="true"
                                    >
                                        Login
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link disabled"
                                        href="#"
                                        tabindex="-1"
                                        aria-disabled="true"
                                    >
                                        Sign Up
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div
                style={{
                    height: "50vh",
                    background: "rgb(158,193,160)",
                    background:
                        "linear-gradient(270deg, rgba(158,193,160,1) 0%, rgba(232,233,201,1) 89%)",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <h1 className="landingPageTitle">
                    Accounting, Inventory, & Management Software
                </h1>
                <div
                    style={{
                        fontSize: "18px",
                        textAlign: "center",
                        fontFamily:
                            "sohne-var, Helvetica Neue, Arial, sans-serif",
                        whiteSpace: "pre-wrap",
                        fontWeight: "300",
                        width: "800px",
                    }}
                >
                    AIM Software provides a way for small businesses to manage
                    their business with a focus on throughput and profitability
                    as opposed to the traditional focus on cost.
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "rgb(233, 235, 238)",
                    height: "calc(50vh - 60px)",
                    minHeight: "300px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                }}
            >
                {" "}
                <div
                    style={{
                        position: "absolute",
                        left: "calc(50% + 50px)",
                        top: "calc(50% - 20px)",
                    }}
                    onClick={() => setBetaPopup({ show: true })}
                >
                    <TileButtonRaised
                        title="Sign up for Beta Testing"
                        image={
                            <div style={{ marginTop: "20px" }}>
                                <GrTest fontSize={"40px"} />
                            </div>
                        }
                    />
                </div>
                <div
                    style={{
                        position: "absolute",
                        left: "calc(50% - 192px - 50px)",
                        top: "calc(50% - 20px)",
                    }}
                    onClick={() => {
                        if (props.token) {
                            nav("/launchpad");
                        } else {
                            setTestPopup({ show: true });
                        }
                    }}
                >
                    <TileButtonRaised
                        title="Test Version 0.2.0"
                        image={
                            <div style={{ marginTop: "20px" }}>
                                <IoIosConstruct fontSize={"40px"} />
                            </div>
                        }
                    />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    © 2022 Advanced Scientific Products, Inc.
                </div>
            </div>
            <SignUp
                title="Sign up for Beta Testing"
                submit="Sign up"
                label="Email"
                popup={betaPopup}
                handleClose={handleClose}
                setUser={props.setUser}
            />
            <Login
                title="Beta Testing Login"
                submit="Log in"
                popup={testPopup}
                handleClose={handleClose}
                setUser={props.setUser}
            />
        </div>
    );
}

export default HomePage;
