import React from "react";
import { getDateLong, getTime } from "../../../util/dates";
import { getDate } from "../../../util/dates";
import { companyInfo } from "./Data";

function AgingSummaryHeader(props) {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div
            style={{ marginTop: "5px", fontSize: "14px", fontWeight: "bold" }}
          >
            {getTime()}
          </div>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {getDate()}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {companyInfo[0].name}
          </div>
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            {props.title}
          </div>
          <div style={{ fontSize: "14px", fontWeight: "bold" }}>
            As of {getDateLong()}
          </div>
        </div>

        <div style={{ opacity: "0" }}>
          <div>11:43 AM</div>
          <div>06/21/22</div>
        </div>
      </div>
      <hr
        style={{
          borderTop: "3px solid #000000",
          opacity: "1",
          margin: "4px 0px 20px 0px",
        }}
        width="100%"
      />
    </div>
  );
}

export default AgingSummaryHeader;
