import React from "react";

function Checkbox(props) {
  return (
    <>
      {props.label ? (
        <label htmlFor="Input" className="form-label">
          {props.label}
        </label>
      ) : null}
      <input
        style={{ marginLeft: "10px" }}
        type="checkbox"
        checked={props.value}
        onChange={(e) => {
          props.onChange(!props.value);
        }}
      />
    </>
  );
}

export default Checkbox;
