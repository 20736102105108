import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function DataImport() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Data Import" />
        <div>DataImport</div>
      </div>
    </div>
  );
}

export default DataImport;
