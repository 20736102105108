import { api_url } from "../../util/environment";
import { generate } from "../../util/schedules";
import { updateDocument } from "../Sales/api";

import { currentBusiness } from "../../features/authentication/util";

export async function getBills() {
    const response = await fetch(
        api_url + "/purchases/documents/bills/user/" + currentBusiness()
    );
    return response.json();
}
export async function getPurchaseOrders() {
    const response = await fetch(
        api_url + "/sales/documents/purchase-orders/user/" + currentBusiness()
    );
    return response.json();
}
export async function getPurchaseEstimates() {
    const response = await fetch(
        api_url +
            "/sales/documents/purchase-estimates/user/" +
            currentBusiness()
    );
    return response.json();
}
export async function getPurchaseBudgetaryEstimates() {
    const response = await fetch(
        api_url +
            "/sales/documents/purchase-budgetary-estimates/user/" +
            currentBusiness()
    );
    return response.json();
}
export async function getVendor(id) {
    const response = await fetch(api_url + "/purchases/vendors/" + id);
    return response.json();
}
export async function getVendors() {
    const response = await fetch(
        api_url + "/purchases/vendors/user/" + currentBusiness()
    );
    return response.json();
}

export async function getPurchasesProducts() {
    const response = await fetch(
        api_url +
            "/site/products-and-services/purchases/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function getRecurringBillsWithSchedules() {
    const response = await fetch(
        api_url +
            "/purchases/documents/schedules/recurring-bills/user/" +
            currentBusiness()
    );
    return response.json();
}
export async function getActiveRecurringBills() {
    const response = await fetch(
        api_url +
            "/purchases/documents/schedules/active/recurring-bills/user/" +
            currentBusiness()
    );
    return response.json();
}

export async function generateRecurring(id) {
    console.log("generate recurring");
    fetch(api_url + "/sales/documents/" + id + "/schedules").then((res) => {
        res.json().then((schedules) => {
            console.log(schedules);
            schedules[0].next_generation = new Date(
                schedules[0].next_generation + " UTC"
            );
            schedules[0].starting_date = new Date(
                schedules[0].starting_date + " UTC"
            );
            schedules[0].ending_date = new Date(
                schedules[0].ending_date + " UTC"
            );
            var generated = generate(schedules[0]);
            var schedule_id = schedules[0].id;
            if (generated) {
                console.log("TRUE");
                delete generated["id"];
                fetch(api_url + "/site/schedules/" + schedule_id, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(generated),
                });
                console.log(generated);
            } else {
                console.log("FALSE");
                delete schedules[0]["id"];
                schedules[0].next_generation = null;
                fetch(api_url + "/site/schedules/" + schedule_id, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(schedules[0]),
                });
                expireRecurringBill(schedules[0].document_id);
            }
        });
    });

    return;
}

export async function approveRecurringBill(id) {
    //get current invoice info
    const response = await fetch(api_url + "/sales/documents/" + id);
    const data = await response.json();
    delete data[0]["id"];
    data[0]["status"] = "active";
    data[0]["updated_at"] = Date.now();
    return updateDocument(data[0], id); //return promise so caller can wait for update to refresh page
}

export async function expireRecurringBill(id) {
    //get current invoice info
    const response = await fetch(api_url + "/sales/documents/" + id);
    const data = await response.json();
    delete data[0]["id"];
    data[0]["status"] = "expired";
    data[0]["updated_at"] = Date.now();
    return updateDocument(data[0], id); //return promise so caller can wait for update to refresh page
}

export async function deleteAllVendors() {
    const post = await fetch(
        api_url + "/purchases/vendors/user/" + currentBusiness(),
        {
            method: "DELETE",
        }
    );
    return await post.json();
}
