import { api_url } from "../../util/environment";

//TAG:AUTHENTICATION
export async function loginUser(credentials) {
    return fetch(api_url + "/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    }).then((data) => data.json());
}

export async function signupUser(credentials) {
    return fetch(api_url + "/signup", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    }).then((data) => data.json());
}

export async function inviteUser(credentials) {
    return fetch(api_url + "/invite", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    }).then((data) => data.json());
}

export async function forgotPassword(credentials) {
    let body = {
        from: "timjevans2000@gmail.com",
        to: credentials.email,
        subject: "ASP Accounting password reset request",

        email: credentials.email,
    };

    return fetch(api_url + "/forgot-password", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    }).then((data) => data.json());
}

export async function changePassword(credentials) {
    return fetch(api_url + "/change-password", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    }).then((data) => data.json());
}

export async function getAccountInfo(id) {
    return fetch(api_url + `/identities/accounts/user/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((data) => data.json());
}

export async function getUsersOfBusiness(id) {
    return fetch(api_url + `/identities/businesses/${id}/users`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((data) => data.json());
}

export async function getRolls(id) {
    return fetch(api_url + `/identities/rolls/business/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((data) => data.json());
}

export async function getUserBussinessRoll(business_id, user_id) {
    return fetch(
        api_url + `/identities/businesses/${business_id}/users/${user_id}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    ).then((data) => data.json());
}

export async function updateUserBussinessRoll(business_id, user_id, roll_id) {
    return fetch(
        api_url + `/identities/businesses/${business_id}/users/${user_id}`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ roll_id }),
        }
    ).then((data) => data.json());
}

export async function getRollsWithPermissions(business_id) {
    return fetch(
        api_url + `/identities/rolls/business/${business_id}/permissions`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    ).then((data) => data.json());
}
