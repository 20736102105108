import React, { useState, useRef, useEffect, createRef } from "react";
import Page from "./Page";
import AgingSummaryLineItem from "./AgingSummaryAR/AgingSummaryLineItem";
import AgingSummaryHeader from "./AgingSummaryAR/AgingSummaryHeader";
import AgingSummaryFooter from "./AgingSummaryAR/AgingSummaryFooter";
import AgingSummaryTotals from "./AgingSummaryAR/AgingSummaryTotals";
import ReportFooter from "./ReportFooter";

function getLineItemHeight(lineItem) {
  return 27;
}

function MultiPage(props) {
  const [pageMapper, setPageMapper] = useState([
    { startIndex: 0, endIndex: null },
  ]);
  const [pageDimensions, setPageDimensions] = useState({
    landscape: {
      height: "765px",
      width: "990px",
    },
    portrait: {
      height: "990px",
      width: "765px",
    },
  });

  const lineItemHeight = 24;

  const height = pageDimensions[props.orientation].height;
  const width = pageDimensions[props.orientation].width;

  const pageHeight = parseInt(
    pageDimensions[props.orientation].height.slice(0, -2)
  );
  const mainHeaderHeight = 101;
  const secondaryHeaderHeight = 101;
  const tableHeaderHeight = 27;
  const footerHeight = 30;

  const margin = 50;

  //figure out lineItem indexes and pages needed to display line items when there is a change to lineItems array
  useEffect(() => {
    var newPages = [{ startIndex: 0, endIndex: null }];

    const firstPageRoomAvailable =
      pageHeight -
      2 * margin -
      mainHeaderHeight -
      tableHeaderHeight -
      footerHeight;

    const nthPageRoomAvailable =
      pageHeight -
      2 * margin -
      secondaryHeaderHeight -
      tableHeaderHeight -
      footerHeight;

    var heightLeft = firstPageRoomAvailable;
    var itemHeight = "";
    var hasNextPage = false;
    for (var i in props.lineItems) {
      itemHeight = props.getLineItemHeight(props.lineItems[i]);
      heightLeft = heightLeft - itemHeight;

      // if total box ran out of room put it on the next page
      if (heightLeft < 0 && !hasNextPage) {
        newPages.push({ startIndex: null, endIndex: null });
        hasNextPage = true;
      } else if (heightLeft < 0) {
        newPages[newPages.length - 2].endIndex = i;
        newPages[newPages.length - 1].startIndex = i;

        heightLeft = nthPageRoomAvailable;
        hasNextPage = false;
      }
    }
    setPageMapper(newPages);
  }, [props.lineItems]);

  const Header = () => {
    const headerStyle = {
      height: "340px",
      backgroundColor: "lightGrey",
    };
    return <div style={headerStyle}>header</div>;
  };

  const Table = () => (
    <div
      style={{
        height: "115px",
        backgroundColor: "lightGreen",
      }}
    ></div>
  );
  const Thead = () => (
    <thead>
      <tr>
        <th width="28%"></th>
        <th
          width="12%"
          style={{
            textAlign: "center",
          }}
        >
          Current
          <Underline />
        </th>
        <th
          width="12%"
          style={{
            textAlign: "center",
          }}
        >
          1 - 30
          <Underline />
        </th>
        <th
          width="12%"
          style={{
            textAlign: "center",
          }}
        >
          31 - 60
          <Underline />
        </th>
        <th
          width="12%"
          style={{
            textAlign: "center",
          }}
        >
          61 - 90
          <Underline />
        </th>
        <th
          width="12%"
          style={{
            textAlign: "center",
          }}
        >
          {" "}
          &gt;90
          <Underline />
        </th>
        <th
          width="12%"
          style={{
            textAlign: "center",
          }}
        >
          {" "}
          TOTAL
          <Underline />
        </th>
      </tr>
    </thead>
  );

  const Underline = () => (
    <hr
      style={{
        borderTop: "1px solid #000000",
        opacity: "1",
        margin: "0px",
      }}
      width="100%"
    />
  );

  const Totals = () => {
    const totalsStyle = {
      minHeight: "140px",
      width: "300px",
      backgroundColor: "orange",
      alignSelf: "flex-end",
    };
    return <div style={totalsStyle}>totals</div>;
  };

  //ensure there is a ref for each page
  props.printRefs.current = pageMapper.map(
    (_, i) => props.printRefs.current[i] ?? createRef()
  );

  return (
    <div>
      {pageMapper.map((item, index) => {
        var slicedItems = [];
        if (item.startIndex != null && item.endIndex == null) {
          //ensure page displays correct line items
          slicedItems = props.lineItems.slice(item.startIndex);
        } else if (item.startIndex == null && item.endIndex == null) {
          slicedItems = [];
          console.log("NO ITEMS");
        } else {
          slicedItems = props.lineItems.slice(item.startIndex, item.endIndex);
        }
        console.log(pageMapper);
        return (
          <>
            {index == 0 ? (
              pageMapper.length - 1 == 0 ? (
                <Page //Page 1 of 1
                  printRef={props.printRefs[index]}
                  key={item}
                  header={props.header}
                  body={React.cloneElement(props.body, {
                    slicedItems: slicedItems,
                    date: props.date,
                  })}
                  footer={<ReportFooter pageNumber={index + 1} />}
                  margin={"0.5in"}
                  height={height}
                  width={width}
                />
              ) : (
                <Page //Page 1 of many
                  printRef={props.printRefs[index]}
                  key={item}
                  header={props.header}
                  body={React.cloneElement(props.body, {
                    slicedItems: slicedItems,
                    date: props.date,
                  })}
                  footer={<ReportFooter pageNumber={index + 1} />}
                  margin={"0.5in"}
                  height={height}
                  width={width}
                />
              )
            ) : index < pageMapper.length - 1 ? (
              <Page //page m of n
                printRef={props.printRefs[index]}
                key={item}
                header={props.header}
                body={React.cloneElement(props.body, {
                  slicedItems: slicedItems,
                  date: props.date,
                })}
                footer={<ReportFooter pageNumber={index + 1} />}
                margin={"0.5in"}
                height={height}
                width={width}
              />
            ) : (
              <Page //Page n of n
                printRef={props.printRefs[index]}
                key={item}
                header={props.header}
                body={React.cloneElement(props.body, {
                  slicedItems: slicedItems,
                  date: props.date,
                })}
                footer={<ReportFooter pageNumber={index + 1} />}
                margin={"0.5in"}
                height={height}
                width={width}
              />
            )}
          </>
        );
      })}
    </div>
  );
}

export default MultiPage;
