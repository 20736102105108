import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as IoIcons5 from "react-icons/io5";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as TbIcons from "react-icons/tb";
import { SiGooglesearchconsole, SiHackthebox } from "react-icons/si";
import { MdOutlineInventory } from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { ManagerIcon } from "../Forms/ManagerIcon";
export const DropdownSidebarData = [
    {
        title: "Launchpad",
        path: "/launchpad",
        icon: <IoIcons5.IoRocketOutline />,
        membership: ["lite", "pro", "premium"],
    },
    {
        title: "Dashboard",
        path: "/dashboard",
        icon: <AiIcons.AiOutlineDashboard />,
        membership: ["lite", "pro", "premium"],
    },

    {
        title: "Sales",
        icon: <BsIcons.BsCreditCard />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        membership: ["lite", "pro", "premium"],

        subNav: [
            {
                title: "Customers",
                path: "/sales/customers",
            },

            {
                title: "Budgetary Estimates",
                path: "/sales/budgetary-estimates",
                membership: ["pro", "premium"],
            },
            {
                title: "Estimates",
                path: "/sales/estimates",
                membership: ["pro", "premium"],
            },
            {
                title: "Sale Orders",
                path: "/sales/orders",
                membership: ["pro", "premium"],
            },

            {
                title: "Invoices",
                path: "/sales/invoices",
                membership: ["pro", "premium"],
            },
            {
                title: "Recurring Invoices",
                path: "/sales/recurring-invoices",
                membership: ["pro", "premium"],
            },
            {
                title: "Products & Services",
                path: "/sales/products-and-services",
            },
            {
                title: "Categories",
                path: "/sales/categories",
            },

            {
                title: "Customer Statements",
                path: "/sales/customer-statements",
                membership: ["pro", "premium"],
            },
            {
                title: "Checkouts",
                path: "/sales/checkouts",
                membership: ["pro", "premium"],
            },
            {
                title: "Payments",
                path: "/sales/payments",
                membership: ["pro", "premium"],
            },
        ],
    },
    {
        title: "Purchases",
        icon: <TbIcons.TbShoppingCart />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        membership: ["lite", "pro", "premium"],

        subNav: [
            {
                title: "Vendors",
                path: "/purchases/vendors",
                cName: "sub-nav",
            },

            {
                title: "Purchase Orders",
                path: "/purchases/orders",
                membership: ["pro", "premium"],
            },
            {
                title: "Bills",
                path: "/purchases/bills",
                membership: ["pro", "premium"],
            },
            {
                title: "Recurring Bills",
                path: "/purchases/recurring-bills",
                membership: ["pro", "premium"],
            },
            {
                title: "Products & Services",
                path: "/purchases/products-and-services",
            },
            {
                title: "Categories",
                path: "/purchases/categories",
            },
            {
                title: "Vendor Statements",
                path: "/purchases/vendor-statements",
                membership: ["pro", "premium"],
            },
        ],
    },
    {
        title: "Accounting",
        icon: <FaIcons.FaBalanceScale />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        membership: ["lite", "pro", "premium"],

        subNav: [
            {
                title: "Transactions",
                path: "/accounting/transactions",
                cName: "sub-nav",
            },
            {
                title: "Reconciliation",
                path: "/accounting/reconciliation",
                cName: "sub-nav",
            },
            {
                title: "Chart of Accounts",
                path: "/accounting/chart-of-accounts",
            },
        ],
    },
    {
        title: "Inventory",
        icon: <MdOutlineInventory />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        membership: ["premium"],

        subNav: [
            {
                title: "Stock",
                path: "/sales/products-and-services",
            },
            {
                title: "Work Orders",
                path: "/inventory/work-orders",
            },
            {
                title: "Parts List",
                path: "/inventory/parts",
            },
            {
                title: "Assemblies",
                path: "/inventory/assemblies",
            },
            {
                title: "Packing Lists",
                path: "/inventory/packing-lists",
            },
            {
                title: "Bill of Materials",
                path: "/inventory/bill-of-materials",
            },
        ],
    },
    {
        title: "Management",
        icon: <ManagerIcon color="white" />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        membership: ["lite", "pro", "premium"],

        subNav: [
            {
                title: "Throughput",
                path: "/management/throughput",
            },
            {
                title: "Constraints",
                path: "/management/constraints",
            },
            {
                title: "What-If Analysis",
                path: "/management/what-if-analysis",
            },
        ],
    },
    {
        title: "Banking",
        icon: <BsIcons.BsBank />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        membership: ["premium"],

        subNav: [
            {
                title: "ASP Money",
                path: "/banking/asp-money",
                icon: <IoIcons.IoIosPaper />,
                cName: "sub-nav",
            },
            {
                title: "Connected Accounts",
                path: "/banking/connected-accounts",
                icon: <IoIcons.IoIosPaper />,
                cName: "sub-nav",
            },
            {
                title: "Payouts",
                path: "/banking/payouts",
                icon: <IoIcons.IoIosPaper />,
            },
            {
                title: "Insurance",
                path: "/banking/insurance",
                icon: <IoIcons.IoIosPaper />,
            },
        ],
    },
    {
        title: "Payroll",
        icon: <BsIcons.BsCardChecklist />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        membership: ["premium"],

        subNav: [
            {
                title: "Run Payroll",
                path: "/payroll/run-payroll",
                cName: "sub-nav",
            },
            {
                title: "Employees",
                path: "/payroll/employees",
                cName: "sub-nav",
            },
            {
                title: "Timesheets",
                path: "/payroll/timesheets",
            },
            {
                title: "Payroll Transactions",
                path: "/payroll/payroll-transactions",
            },
            {
                title: "Taxes",
                path: "/payroll/taxes",
                cName: "sub-nav",
            },
            {
                title: "Tax Forms",
                path: "/payroll/tax-forms",
            },
            {
                title: "COVID-19",
                path: "/payroll/covid-19",
            },
        ],
    },
    {
        title: "Reports",
        path: "/reports",
        icon: <BsIcons.BsBarChart />,
        membership: ["lite", "pro", "premium"],
    },
    {
        title: "Advisors",
        path: "/advisors",
        icon: <BsIcons.BsPeople />,
        membership: ["premium"],
    },
    {
        title: "Tax Filing",
        path: "/tax-filing",
        icon: <TbIcons.TbReceiptTax />,
        membership: ["premium"],
    },
    {
        title: "Console",
        path: "/console",
        icon: <SiGooglesearchconsole />,
        membership: ["premium"],
    },
];
