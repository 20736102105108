import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MultiPage from "../../Documents/MultiPage";
import EstimateHeader from "../Estimates/EstimateHeader";
import EstimateBody from "../Estimates/EstimateBody";
// import EstimateTotals from "../Estimates/EstimateTotals";
import InvoiceTotals from "../Invoices/InvoiceTotals";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { companyInfo, userInvoiceSettings } from "../Estimates/Data";
import {
  getEstimateItems,
  getEstimate,
  newEstimate,
  updateEstimate,
  putLineItem,
  deleteLineItem,
  getCurrentDocumentId,
} from "../Estimates/util";
import { toFloatg } from "../../../util/formatters";

import {
  dateTodayUSAformat,
  dateNdaysFromDateFormatted,
  getDate,
  formatDate,
} from "../../../util/dates";

import { isEqual } from "../../../util/jsonUtil";
import {
  deleteLineItemTax,
  getCustomer,
  getCustomers,
  getItemTaxes,
  getSalesProducts,
  getSchedules,
  postLineItem,
  postLineItemTax,
  sendDocument,
} from "../api";
import MainFooter from "../../Documents/Footers/MainFooter";

import { IoSettingsSharp } from "react-icons/io5";

import SendInvoice from "../Invoices/Send/SendInvoice";
import ErrorPopup from "../../../components/Popups/ErrorPopup";

import ScheduleForm from "../../../components/Forms/ScheduleForm";
import { getProductPricing, postTax } from "../../Site/api";
import { generate } from "../../../util/schedules";
import { api_url } from "../../../util/environment";
import { getTaxes } from "../../Site/api";

import "./RecurringInvoicesForm.css";

async function handleDownloadPdf(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 2, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  pdf.save("print.pdf");
}

async function pdfToBase64(refs, orientation) {
  console.log(refs);
  var pdfOrientation = "";
  if (orientation == "landscape") {
    pdfOrientation = "l";
  } else {
    pdfOrientation = "r";
  }
  const pdf = new jsPDF({
    orientation: pdfOrientation,
    unit: "in",
    format: [8.5, 11],
  });
  for (var i = 0; i < refs.length; i++) {
    var element = refs[i].current;
    console.log(refs[i].current);
    var canvas = await html2canvas(element, { scale: 2, allowTaint: true });
    var data = canvas.toDataURL("image/png");

    var imgProperties = pdf.getImageProperties(data);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (i < refs.length - 1) {
      pdf.addPage();
    }
  }
  return btoa(pdf.output());
}

//handling form submission, page settings, db fetching
function RecurringInvoicesForm(props) {
  let navigate = useNavigate();
  let { id } = useParams();

  const [orientation, setOrientation] = useState("portrait");
  const [lineItemsOrig, setLineItemsOrig] = useState({});
  const [lineItemsForm, setLineItemsForm] = useState([
    {
      id: -1, //negative id to denote new item
      item_name: "LineItem",
      quantity: "1",
      price: "0.00",
      discount: null,
      tax: "0.0",
      line_total: "0.00",
      notes: "notes",
      page_order: "0",
      invoice_id: "",
      products_and_services_id: null,
    },
  ]);
  const [form, setForm] = useState({
    status: "draft",
    from_name: companyInfo[0].name,
    from_address: companyInfo[0].address,
    from_phone: companyInfo[0].number,
    from_website: companyInfo[0].website,
    from_email: companyInfo[0].email,

    issue_date: dateTodayUSAformat,
    net: userInvoiceSettings[0].default_net.toString(),
    due_date: dateNdaysFromDateFormatted(
      new Date(),
      parseInt(userInvoiceSettings[0].default_net)
    ),
    currency: "USD",
    po_number: "#",

    to_name: "Name",
    to_address: "Address",
    to_city: "City",
    to_zip: "Zip",
    to_state: "State",
    to_country: "Country",
    to_phone: "Phone",
    to_website: "Website",
    to_email: "Email",
    to_other: "Other",

    invoice_number: id,

    footer: "notes",
    tax_percentage: "0",
    discount_amount: "0",
    discount_percentage: "0",
    amount_paid: "0",
    amount_due: "0",

    type: "recurring-invoice",

    created_at: null,
    updated_at: null,
    archived_at: null,
    deleted_at: null,

    converted_from: null,
    converted_to: null,
    customer_id: null,
    vendor_id: null,
  });
  const [allTaxes, setAllTaxes] = useState([]);
  const [taxes, setTaxes] = useState([[]]);
  const [taxesOrig, setTaxesOrig] = useState([[]]);

  const [customers, setCustomers] = useState([]);

  const [sendPopup, setSendPopup] = useState({
    show: false,
    toEmail: null,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [period, setPeriod] = useState("1");

  const [allProducts, setAllProducts] = useState([]);
  const [quantityPricing, setQuantityPricing] = useState([[]]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState(1);
  const [periodType, setPeriodType] = useState(null);
  const [periodStart, setPeriodStart] = useState(null);
  const [type, setType] = useState("Daily");

  const [typeAlternative, setTypeAlternative] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("Monday");
  const [dayOfMonth, setDayOfMonth] = useState("1st");
  const [month, setMonth] = useState("January");
  const [endType, setEndType] = useState("On");
  const [after, setAfter] = useState(null);

  const printRefs = useRef([]);

  useEffect(() => {
    getCustomers().then((data) => setCustomers(data));

    if (id && props.idType == "document") {
      getEstimateItems(id).then((data) => {
        var taxList = [];
        for (let i in data) {
          getItemTaxes(data[i].id).then((taxData) => {
            console.log(taxData);
            taxList[i] = taxData;
            setTaxes(taxList);
            setTaxesOrig(JSON.parse(JSON.stringify(taxList)));
          });
          if (data[i].products_and_services_id) {
            getProductPricing(data[i].products_and_services_id).then(
              (pricingData) => {
                var pricingList = quantityPricing;
                pricingList[i] = pricingData;
                setQuantityPricing(pricingList);
              }
            );
          } else {
            var pricingList = quantityPricing;
            pricingList[i] = [];
            setQuantityPricing(pricingList);
          }
        }
        setTaxes(taxList);
        console.log(data);
        setLineItemsOrig(JSON.parse(JSON.stringify(data)));
        setLineItemsForm(data);

        console.log("edit" + id);
      });
      getEstimate(id).then((data) => {
        setForm(data[0]);
      });
      getSchedules(id).then((data) => {
        var schedule = data[0];
        console.log(schedule);
        setStartDate(schedule.starting_date);
        setEndDate(schedule.ending_date);
        setType(schedule.period_type.toString());
        setAfter(schedule.left_to_generate);
        setPeriod(schedule.period);
        console.log(schedule);
      });
    } else {
      console.log("add");
      getCurrentDocumentId().then((data) => {
        var currentForm = form;
        currentForm.invoice_number = parseInt(data[0].last_value) + 1;
        setForm(currentForm);
        console.log(JSON.parse(JSON.stringify(currentForm)));
      });
    }
    if (id && props.idType == "customer") {
      console.log("custo");
      getCustomer(id).then((data) => setCustomerInfo(data[0]));
    }

    if (id && props.idType == "convert") {
      getEstimateItems(id).then((data) => {
        var taxList = [];
        for (let i in data) {
          getItemTaxes(data[i].id).then((taxData) => {
            console.log(taxData);
            taxList[i] = taxData;
            setTaxes(taxList);
            setTaxesOrig([]);
          });
        }
        console.log(data);
        setLineItemsOrig([]);
        setLineItemsForm(data);

        console.log("edit" + id);
      });
      getEstimate(id).then((data) => {
        data[0].type = "invoice";

        getCurrentDocumentId().then((data2) => {
          data[0].invoice_number = parseInt(data2[0].last_value) + 1;
          data[0].created_at = null;
          data[0].updated_at = null;
          delete data[0].id;
          setForm(data[0]);
        });
      });
    }

    getTaxes().then((data) => {
      setAllTaxes(data);
    });

    getSalesProducts().then((data) => {
      console.log(data);
      setAllProducts(data);
    });
  }, []);

  const SecondaryHeader = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{props.upperLeft}</div>
        <div>{props.upperRight}</div>
      </div>
    );
  };

  const Footer = (props) => {
    return (
      <div
        style={{
          height: "30px",
          display: "flex",
          marginTop: "auto",
          justifyContent: "space-between",
        }}
      >
        <div>{props.lowerLeft}</div>
        <div>{props.lowerRight}</div>
      </div>
    );
  };

  const setCustomerInfo = (customer) => {
    var updatedForm = form;
    updatedForm.to_name = customer.primary_name;
    updatedForm.to_address = customer.billing_address;
    updatedForm.to_city = customer.billing_city;

    updatedForm.to_state = customer.billing_state;
    updatedForm.to_zip = customer.billing_zip_code;
    updatedForm.to_country = customer.billing_country;
    updatedForm.to_phone = customer.primary_phone;
    updatedForm.to_email = customer.primary_email;
    setForm(updatedForm);
  };

  const submitForm = () => {
    console.log(form);
    console.log(lineItemsForm);
    if (id && props.idType == "document") {
      editEstimate().then(() => {
        navigate("/sales/recurring-invoices");
      });
    } else {
      var scheduleBody = {
        period: period,
        period_type: periodType,
        period_start: periodStart,
        starting_date: startDate,
        ending_date: endDate,
        next_generation: null,
        left_to_generate: after,
        document_id: null,
      };
      console.log(scheduleBody);
      var firstGeneratedDocument = generate(scheduleBody);
      if (firstGeneratedDocument) {
        console.log(firstGeneratedDocument);
        newEstimate(form).then((data) => {
          const document_id = data.rows[0].id;
          console.log(document_id);
          // var body = {
          //   period: period,
          //   period_type: "day",
          //   starting_date: formatDate(startDate),
          //   ending_date: formatDate(endDate),
          //   document_id: id,
          // };
          firstGeneratedDocument["document_id"] = document_id;
          fetch(api_url + "/site/schedules", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(firstGeneratedDocument),
          });
          for (let i in lineItemsForm) {
            var lineItemsCopy = lineItemsForm[i];
            delete lineItemsCopy["id"];
            lineItemsCopy["invoice_id"] = document_id;

            postLineItem(lineItemsForm[i]).then((data) => {
              console.log(data);
              for (let j in taxes[i]) {
                postLineItemTax(data[0].id, taxes[i][j].id);
                console.log(data[0].id);
                console.log(taxes[i][j].id);
              }
            });
          }
          navigate("/sales/recurring-invoices");
        });
      } else {
        console.log("ERROR: YOUR SCHEDULE PRODUCES NO FUTURE DOCUMENTS");
      }
    }
  };

  const editEstimate = async () => {
    var estimateForm = form;
    delete estimateForm["id"];
    delete estimateForm["owner"];
    updateEstimate(estimateForm, id).then((data) => {
      console.log(data);
    });

    //Edit Taxes

    for (var i in taxesOrig) {
      for (var j in taxesOrig[i]) {
        var found_j = false;
        for (var k in taxes[i]) {
          if (taxesOrig[i][j].id == taxes[i][k].id) {
            found_j = true;
          }
        }
        if (!found_j) {
          deleteLineItemTax(lineItemsOrig[i].id, taxesOrig[i][j].id);
        }
      }
    }

    for (var i in taxes) {
      for (var j in taxes[i]) {
        var found_j = false;

        for (var k in taxesOrig[i]) {
          if (taxesOrig[i][k].id == taxes[i][j].id) {
            found_j = true;
          }
        }
        if (!found_j) {
          postLineItemTax(lineItemsForm[i].id, taxes[i][j].id);
        }
      }
    }

    //Edit Line Items

    // Loop through items and POST the ones with .new==true
    for (var i in lineItemsForm) {
      if (lineItemsForm[i].id < 0) {
        console.log("POST");
        var lineItemBody = lineItemsForm[i];
        delete lineItemBody["id"];
        lineItemBody["invoice_id"] = id;
        postLineItem(lineItemBody);
      }
    }
    var found_i = false;
    var found_index = null;
    console.log(lineItemsForm);
    console.log(lineItemsOrig);
    for (var i in lineItemsOrig) {
      for (var j in lineItemsForm) {
        if (lineItemsOrig[i].id == lineItemsForm[j].id) {
          found_i = true;
          found_index = j;
        }
      }
      if (found_i) {
        if (!isEqual(lineItemsOrig[i], lineItemsForm[found_index])) {
          var lineItemBody = lineItemsForm[found_index];
          const lineItemId = lineItemsForm[found_index].id;
          delete lineItemBody["id"];
          putLineItem(lineItemBody, lineItemId);
        }
      } else {
        console.log("DELETE" + lineItemsOrig[i].id);
        deleteLineItem(lineItemsOrig[i].id);
      }
      found_i = false; //reset boolean
      found_index = null;
    }
    // this.putInvoice(this.props.invoice_id);
  };

  const createTax = (form) => {
    console.log("creating tax");

    const body = {
      tax_number: form.tax_number,
      name: form.name,
      percentage: toFloatg(form.percentage / 100, "discount"),
      is_recoverable: form.is_recoverable,
      is_compound: form.is_compound,
      effective_date: null,
      obsolete_date: null,
      created_at: Date.now(),
      updated_at: Date.now(),
      archived_at: null,
      deleted_at: null,
    };

    postTax(body).then((json_res) => {
      console.log(json_res);
      getTaxes().then((data) => {
        setAllTaxes(data);
      });
    });
  };

  const addLineItem = () => {
    console.log("add line item");

    const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
    newLineItems.push({
      id: 0 - lineItemsForm.length - 1, //negative id to denote new item - starts counting from -1 if starting from empty
      item_name: "LineItem",
      quantity: "1",
      price: "0.00",
      discount: null,
      tax: "0.0",
      line_total: "0.00",
      notes: "notes",
      page_order: lineItemsForm.length,
      invoice_id: "",
      products_and_services_id: null,
    });
    setLineItemsForm(newLineItems);
    console.log(newLineItems);
    var newTaxes = JSON.parse(JSON.stringify(taxes));
    newTaxes.push([]);
    setTaxes(newTaxes);
    var newPricing = JSON.parse(JSON.stringify(quantityPricing));
    newPricing.push([]);
    setQuantityPricing(newPricing);
  };

  const removeLineItem = (index) => {
    var currentList = lineItemsForm;
    var currentTaxList = taxes;
    var currentQuantityPricingList = quantityPricing;
    currentList.splice(index, 1);
    currentTaxList.splice(index, 1);
    currentQuantityPricingList.splice(index, 1);
    setLineItemsForm(JSON.parse(JSON.stringify(currentList)));
    setTaxes(JSON.parse(JSON.stringify(currentTaxList)));
    setQuantityPricing(JSON.parse(JSON.stringify(currentQuantityPricingList)));
  };

  function getLineItemHeight(i) {
    var height = 50;
    if (lineItemsForm[i].discount != null) {
      height = height + 50;
    }
    if (taxes[i]) {
      height = height + taxes[i].length * 25;
    }
    var notesHeight = 50;
    if (lineItemsForm[i].notes != null) {
      //calculate the number of occuracnes of '</div>' in notes strings
      var count = (lineItemsForm[i].notes.match(/<\/div>/g) || []).length;

      notesHeight = notesHeight + 25 * count;
    }
    return Math.max(height, notesHeight);
  }
  function getTotalBoxHeight() {
    var height = 190; //starting height
    const numberOfUniqueTaxes = [
      ...new Set(taxes.flat().map((item) => item.name)),
    ].length;
    console.log(numberOfUniqueTaxes);
    height = height + numberOfUniqueTaxes * 25;
    //if there is a global discount
    if (
      parseFloat(form.discount_amount) > 0 ||
      parseFloat(form.discount_percentage) > 0
    ) {
      height = height + numberOfUniqueTaxes * 25; //shows unique taxes again with updated discount
      height = height + 80; //extra border separator and another subtotal line
    }

    return height;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        className="responsiveHeader"
        style={{
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
        }}
      >
        <div
          className="top-left"
          style={{ display: "flex", marginRight: "50px" }}
        >
          <h1 className="pageTitle">
            {id && props.idType == "document" ? "Edit Invoice" : "New Invoice"}{" "}
            <IoSettingsSharp
              className="settingsIcon"
              fontSize="16px"
              style={{ marginTop: "5px" }}
            />
          </h1>
        </div>

        <div className="invoiceButtons ">
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                if (orientation == "landscape") {
                  setOrientation("portrait");
                } else {
                  setOrientation("landscape");
                }
                const newLineItems = JSON.parse(JSON.stringify(lineItemsForm));
                setLineItemsForm(newLineItems);
              }}
            >
              Change Orientation
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => handleDownloadPdf(printRefs.current, orientation)}
            >
              Download as PDF
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                submitForm();
              }}
            >
              Save
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setSendPopup({ show: true, toEmail: form.to_email });
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>

      <div className="recurringForm">
        <MultiPage
          lineItems={lineItemsForm}
          orientation={orientation}
          printRefs={printRefs}
          header={
            <EstimateHeader
              setters={[setLineItemsForm, setForm]}
              getters={[lineItemsForm, form, customers]}
              title={"Invoice"}
            />
          }
          secondaryHeader={
            <SecondaryHeader
              upperLeft={"Invoice # " + (form.invoice_number || "")}
              upperRight={getDate(new Date())}
            />
          }
          body={<EstimateBody />}
          totals={
            <InvoiceTotals
              info={[...lineItemsForm.map((a) => [a.line_total])]}
              paid={form.amount_paid}
              taxes={allTaxes}
              createTax={(e) => createTax(e)}
              setters={[setLineItemsForm, setForm, setAllTaxes, setTaxes]}
              getters={[lineItemsForm, form, allTaxes, taxes]}
            />
          }
          mainFooter={
            <MainFooter lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />
          }
          footer={<Footer lowerLeft={"lowerLeft"} lowerRight={"lowerRight"} />}
          companyInfo={companyInfo}
          setters={[
            setLineItemsForm,
            setForm,
            setAllTaxes,
            setTaxes,
            setAllProducts,
            setQuantityPricing,
          ]}
          getters={[
            lineItemsForm,
            form,
            allTaxes,
            taxes,
            allProducts,
            quantityPricing,
          ]}
          addLineItem={addLineItem}
          deleteLineItem={removeLineItem}
          getLineItemHeight={getLineItemHeight}
          getTotalBoxHeight={getTotalBoxHeight}
          createTax={createTax}
        />
      </div>
      <SendInvoice
        sendPopup={sendPopup}
        closePopup={() => {
          setSendPopup({ show: false, id: null, toEmail: null });
        }}
        setError={(error) => setError(error)}
        setSuccess={() => setSuccess(true)}
        toSent={(id) => sendDocument(id).then(() => console.log("sent"))}
        attachment={() => pdfToBase64(printRefs.current, orientation)}
        subject={"Invoice #" + form.invoice_number || ""}
        message={"Here is your Invoice"}
      />
      {error ? (
        <ErrorPopup errorMessage={error} clearError={() => setError(null)} />
      ) : null}
      {id && props.idType == "document" ? (
        <>
          <div
            style={{
              position: "absolute",
              right: "50px",
              top: "25%",
              pointerEvents: "none",
              opacity: "0.5",
              filter: "blur(2px)",
            }}
          >
            <ScheduleForm
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              period={period}
              setPeriod={setPeriod}
              periodType={periodType}
              setPeriodType={setPeriodType}
              periodStart={periodStart}
              setPeriodStart={setPeriodStart}
              type={type}
              setType={setType}
              typeAlternative={typeAlternative}
              setTypeAlternative={setTypeAlternative}
              dayOfWeek={dayOfWeek}
              setDayOfWeek={setDayOfWeek}
              dayOfMonth={dayOfMonth}
              setDayOfMonth={setDayOfMonth}
              month={month}
              setMonth={setMonth}
              endType={endType}
              setEndType={setEndType}
              after={after}
              setAfter={setAfter}
            />
          </div>
          <div style={{ position: "absolute", right: "250px", top: "30%" }}>
            Schedule Editting: Under Construction
          </div>
        </>
      ) : (
        <div className="recurringSchedule">
          <ScheduleForm
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            period={period}
            setPeriod={setPeriod}
            periodType={periodType}
            setPeriodType={setPeriodType}
            periodStart={periodStart}
            setPeriodStart={setPeriodStart}
            type={type}
            setType={setType}
            typeAlternative={typeAlternative}
            setTypeAlternative={setTypeAlternative}
            dayOfWeek={dayOfWeek}
            setDayOfWeek={setDayOfWeek}
            dayOfMonth={dayOfMonth}
            setDayOfMonth={setDayOfMonth}
            month={month}
            setMonth={setMonth}
            endType={endType}
            setEndType={setEndType}
            after={after}
            setAfter={setAfter}
          />
        </div>
      )}
    </div>
  );
}

export default RecurringInvoicesForm;
