import React from "react";
import SettingsSidebar from "./SettingsSidebar";

function BusinessProfile() {
  return (
    <div className="page-container">
      <div style={{ display: "flex" }}>
        <SettingsSidebar active="Business Profile" />
        <div>BusinessProfile</div>
      </div>
    </div>
  );
}

export default BusinessProfile;
