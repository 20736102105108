import React, { useState } from "react";
import ImagePreview from "../Invoices/Form/ImagePreview";
import AlphanumericInput from "../../../components/Forms/documents/AlphanumericInput";
import PhoneInput from "../../../components/Forms/documents/PhoneInput";
import DateInput from "../../../components/Forms/documents/DateInput";
import IntInput from "../../../components/Forms/documents/IntInput";
import { formatDate } from "../../../util/dates";

function SaleOrderHeader(props) {
  const onChange = (key, value) => {
    var form = props.getters[1];
    form[key] = value;
    props.setters[1](JSON.parse(JSON.stringify(form)));
  };
  return (
    <div style={{ height: "360px" }}>
      <div
        style={{
          height: "calc(160px - 0.5in)",
          borderBottom: "solid",
          borderWidth: "1px 0",
          display: "flex",
        }}
      >
        <div style={{ minWidth: "35%", marginLeft: "20px" }}>
          <ImagePreview url={"/1_hCBlqxgSsc1acciJoWDwVA.jpeg"} />
        </div>

        <div>
          <AlphanumericInput
            width={"160px"}
            limit={30}
            changeText={(e) => onChange("from_name", e)}
            initialValue={props.getters[1].from_name}
          />
          <AlphanumericInput
            width={"160px"}
            limit={30}
            changeText={(e) => onChange("from_address", e)}
            initialValue={props.getters[1].from_address}
          />
          <div style={{ display: "flex" }}>
            <PhoneInput
              width={"160px"}
              changeText={(e) => onChange("from_phone", e)}
              id={"phone-entry-1"}
              initialValue={props.getters[1].from_phone}
            />
            <AlphanumericInput
              width={"160px"}
              limit={30}
              changeText={(e) => onChange("from_website", e)}
              initialValue={props.getters[1].from_website}
            />
          </div>

          <AlphanumericInput
            width={"160px"}
            limit={30}
            changeText={(e) => onChange("from_email", e)}
            initialValue={props.getters[1].from_email}
            extraAllowableChars={"@.-"}
          />
        </div>
      </div>
      <div style={{ height: "180px", marginTop: "10px", display: "flex" }}>
        <table height="50px" style={{ width: "50%" }}>
          <thead>
            <tr>
              <th width="120px"></th>
              <th width="120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label>Issue Date:</label>
              </td>
              <td>
                <DateInput
                  initialValue={props.getters[1].issue_date}
                  changeText={(e) => onChange("issue_date", formatDate(e))}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>P.O.#:</label>
              </td>
              <td>
                <AlphanumericInput
                  width={"120px"}
                  initialValue={props.getters[1].po_number}
                  limit={11}
                  changeText={(e) => onChange("po_number", e)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ width: "50%" }}>
          <div style={{ fontWeight: "bold" }}>Bill to:</div>
          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_name}
            limit={30}
            changeText={(e) => onChange("to_name", e)}
          />
          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_address}
            limit={30}
            changeText={(e) => onChange("to_address", e)}
          />
          <div style={{ display: "flex" }}>
            <AlphanumericInput
              width={"80px"}
              initialValue={props.getters[1].to_city}
              limit={30}
              changeText={(e) => onChange("to_city", e)}
            />
            <AlphanumericInput
              width={"80px"}
              initialValue={props.getters[1].to_state}
              limit={30}
              changeText={(e) => onChange("to_state", e)}
            />
            <AlphanumericInput
              width={"80px"}
              initialValue={props.getters[1].to_zip}
              limit={30}
              changeText={(e) => onChange("to_zip", e)}
            />
          </div>

          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_country}
            limit={30}
            changeText={(e) => onChange("to_country", e)}
          />
          <PhoneInput
            initialValue={props.getters[1].to_phone}
            id={"phone-entry-2"}
            changeText={(e) => onChange("to_phone", e)}
          />
          <AlphanumericInput
            width={"200px"}
            initialValue={props.getters[1].to_email}
            limit={30}
            changeText={(e) => onChange("to_email", e)}
            extraAllowableChars={"@.-"}
          />
          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_other}
            limit={30}
            changeText={(e) => onChange("to_other", e)}
          />
        </div>
        <div style={{ width: "50%" }}>
          <div style={{ fontWeight: "bold" }}>Send to:</div>
          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_name}
            limit={30}
            changeText={(e) => onChange("to_name", e)}
          />
          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_address}
            limit={30}
            changeText={(e) => onChange("to_address", e)}
          />
          <div style={{ display: "flex" }}>
            <AlphanumericInput
              width={"80px"}
              initialValue={props.getters[1].to_city}
              limit={30}
              changeText={(e) => onChange("to_city", e)}
            />
            <AlphanumericInput
              width={"80px"}
              initialValue={props.getters[1].to_state}
              limit={30}
              changeText={(e) => onChange("to_state", e)}
            />
            <AlphanumericInput
              width={"80px"}
              initialValue={props.getters[1].to_zip}
              limit={30}
              changeText={(e) => onChange("to_zip", e)}
            />
          </div>

          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_country}
            limit={30}
            changeText={(e) => onChange("to_country", e)}
          />
          <PhoneInput
            initialValue={props.getters[1].to_phone}
            id={"phone-entry-2"}
            changeText={(e) => onChange("to_phone", e)}
          />
          <AlphanumericInput
            width={"200px"}
            initialValue={props.getters[1].to_email}
            limit={30}
            changeText={(e) => onChange("to_email", e)}
            extraAllowableChars={"@.-"}
          />
          <AlphanumericInput
            width={"120px"}
            initialValue={props.getters[1].to_other}
            limit={30}
            changeText={(e) => onChange("to_other", e)}
          />
        </div>
      </div>
      <h3 style={{ textAlign: "center", margin: "20px" }}>
        {props.title} # {props.getters[1].invoice_number}
      </h3>
    </div>
  );
}

export default SaleOrderHeader;
