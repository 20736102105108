import { IoTrashOutline } from "react-icons/io5";

import React from "react";
import "./Icons.css";

function DeleteIcon(props) {
    return (
        <>
            {props.inactive ? (
                <div
                    style={{
                        color: "black",
                        cursor: "not-allowed",
                        opacity: "0.5",
                    }}
                >
                    <IoTrashOutline fontSize={props.fontSize} />
                </div>
            ) : (
                <div
                    class="clickable-icon trash-icon"
                    style={{ color: "#d11a2a" }}
                    onClick={(e) => props.onClick(e)}
                >
                    <IoTrashOutline fontSize={props.fontSize} />
                </div>
            )}
        </>
    );
}

export default DeleteIcon;
