import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const thumb = {
  display: "inline-flex",
  justifyContent: "center",
  marginBottom: 8,
  marginRight: 8,
  minWidth: 100,
  height: 100,
  padding: 4,
  borderWidth: 2,
  borderRadius: 2,
  boxSizing: "border-box",
  borderColor: "#D0D0D0",
  borderStyle: "dashed",
  transition: "border .24s ease-in-out",
};

const borderlessThumb = {
  display: "inline-flex",
  justifyContent: "center",
  marginBottom: 8,
  marginRight: 8,
  minWidth: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  transition: "border .24s ease-in-out",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function ImagePreview(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const style = {
    ...(props.url
      ? borderlessThumb
      : files.length == 0
      ? thumb
      : borderlessThumb),
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  };

  const thumbs = files.map((file) => (
    <div style={style} key={file.name}>
      <div>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      {files.length == 0 ? (
        props.url ? (
          <div className="invoiceLogo">
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{ width: "100%" }}
            >
              <input {...getInputProps()} />
              <div style={style}>
                <img src={props.url} style={img} />
              </div>
            </div>
          </div>
        ) : (
          <div className="invoiceLogo" data-html2canvas-ignore="true">
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{ width: "100%" }}
            >
              <input {...getInputProps()} />
              {files.length == 0 ? (
                <div style={style}>
                  <div style={{ marginTop: "30px", whiteSpace: "pre-wrap" }}>
                    Drop Image
                  </div>
                </div>
              ) : (
                thumbs
              )}
            </div>
          </div>
        )
      ) : (
        <div className="invoiceLogo">
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ width: "100%" }}
          >
            <input {...getInputProps()} />
            {files.length == 0 ? (
              <div style={style}>
                <div style={{ marginTop: "30px", whiteSpace: "pre-wrap" }}>
                  Drop Image
                </div>
              </div>
            ) : (
              thumbs
            )}
          </div>
        </div>
      )}
    </>
  );
}
