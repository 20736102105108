import React, { useState } from "react";
import { IoTrashOutline, IoArrowForwardCircleOutline } from "react-icons/io5";
import { toUSD } from "../../../util/formatters";
import PriceInput from "../../../components/Forms/documents/PriceInput";
import SearchableWithButton from "../../../components/Forms/SearchableWithButton";
import SearchableModal from "../../../components/Forms/SearchableModal";

function JournalItem(props) {
  const [description, setDescription] = useState("");
  const [account, setAccount] = useState("");
  const [debit, setDebit] = useState("");
  const [credit, setCredit] = useState("");
  return (
    <tr className={props.editable ? "journalRow" : null}>
      <td></td>
      <td>
        {/* <AlphanumericInput
          initialValue={props.info.account}
          changeText={(val) => props.setInfo("account", val)}
        /> */}
        <div style={{ position: "relative" }}>
          {console.log(props.info.credit)}
          {console.log(props.info.debit)}
          {props.info.debit == null ? (
            <div style={{ marginLeft: "100px", width: "80%" }}>
              {props.editable ? (
                // <SearchableWithButton
                //   appendID={"-searchable-" + props.index}
                //   list={props.accounts}
                //   value={props.info.account}
                //   setValue={(e) => props.setInfo("account", e)}
                //   defaultValue={"Select an Account"}
                // />
                <SearchableModal
                  initialValue={"Select an Account"}
                  list={props.accounts}
                  keys={{
                    name: (name) => name,
                  }}
                  value={props.info.account ? props.info.account.name : null}
                  onItemClick={(e) => {
                    props.setInfo("account", e);
                  }}
                />
              ) : (
                props.info.account.name
              )}
            </div>
          ) : (
            <div style={{ marginLeft: "0px", width: "80%" }}>
              {props.editable ? (
                // <SearchableWithButton
                //   appendID={"-searchable-" + props.index}
                //   list={props.accounts}
                //   value={props.info.account}
                //   setValue={(e) => props.setInfo("account", e)}
                //   defaultValue={"Select an Account"}
                // />
                <SearchableModal
                  initialValue={"Select an Account"}
                  list={props.accounts}
                  keys={{
                    name: (name) => name,
                  }}
                  value={props.info.account ? props.info.account.name : null}
                  onItemClick={(e) => {
                    props.setInfo("account", e);
                  }}
                />
              ) : (
                <div style={{ marginLeft: "50px" }}>
                  {props.info.account.name}
                </div>
              )}
            </div>
          )}
        </div>
      </td>
      <td style={{ textAlign: "right" }}>
        {props.info.debit ? (
          <PriceInput
            id={"editable-debit-" + props.index}
            initialValue={props.info.debit}
            decimalLimit={2}
            limit={15}
            changeText={(val) => props.setInfo("debit", val)}
            disabled={!props.editable}
          />
        ) : null}
      </td>
      <td>
        <div style={{ display: "flex" }}>
          {props.info.credit ? (
            <>
              <div style={{ width: "50%", opacity: 0 }}>blank</div>
              {props.editable ? (
                <IoArrowForwardCircleOutline
                  onClick={() => {
                    var swap = props.info.credit;
                    props.setInfo("credit", null);
                    props.setInfo("debit", swap);
                  }}
                  className="debitCreditSwapButton"
                  fontSize="25px"
                />
              ) : null}

              <div style={{ width: "50%", textAlign: "right" }}>
                <PriceInput
                  id={"editable-credit-" + props.index}
                  initialValue={props.info.credit}
                  decimalLimit={2}
                  limit={15}
                  changeText={(val) => props.setInfo("credit", val)}
                  disabled={!props.editable}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ width: "50%", opacity: 0 }}>blank</div>
              {props.editable ? (
                <IoArrowForwardCircleOutline
                  onClick={() => {
                    var swap = props.info.debit;
                    props.setInfo("debit", null);
                    props.setInfo("credit", swap);
                  }}
                  style={{ transform: "rotateZ(180deg)" }}
                  className="debitCreditSwapButton"
                  fontSize="25px"
                />
              ) : null}

              <div style={{ width: "50%", opacity: 0 }}>blank</div>
            </>
          )}

          <div style={{ opacity: 0 }}>.</div>
        </div>
      </td>

      {props.showDelete && props.editable ? (
        <td style={{ opacity: "1", fontSize: "20px" }}>
          <IoTrashOutline
            className="journalDelete"
            onClick={() => {
              props.onDelete();
            }}
          />
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
}

export default JournalItem;

function ensureDebitsEqualCredits(objList) {
  let SumOfDebits = 0;
  let SumOfCredits = 0;
  for (let i = 0; i < objList.length; i++) {
    SumOfDebits += objList[i].debit;
    SumOfCredits += objList[i].credit;
  }
  if (SumOfCredits == SumOfDebits) return true;
  else return false;
}
