import React from "react";
import * as IoIcons from "react-icons/io5";

function AddAdditionalIcon(props) {
  return (
    <>
      {props.active ? (
        <div className=" icon addContactIcon addContactLinkActive">
          {props.icon ? props.icon : <IoIcons.IoPersonAddOutline />}
          {props.title ? props.title : "Add Additional Contact"}
        </div>
      ) : (
        <div className=" icon addContactIcon">
          {props.icon ? props.icon : <IoIcons.IoPersonAddOutline />}
          {props.title ? props.title : "Add Additional Contact"}
        </div>
      )}
    </>
  );
}

export default AddAdditionalIcon;
