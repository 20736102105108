import { toUSD } from "../../../util/formatters";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: (props) => toUSD(props.value, "total"),
  },
  {
    Header: "Stock",
    accessor: "stock",
  },
];
