import React from "react";
import { useNavigate } from "react-router-dom";
import SettingsSidebar from "../../../pages/Settings/SettingsSidebar";

import Button from "../../../components/Buttons/Button";
import { BsShieldLockFill } from "react-icons/bs";

function SettingsPermissionGate({
    active,
    permissions,
    children,
    requiredPermissions,
    isAdmin,
}) {
    let nav = useNavigate();

    let userPermissions = permissions;
    let allowed = true;
    console.log(userPermissions);
    let missingPermissions = [];
    for (let i = 0; i < requiredPermissions.length; i++) {
        if (!userPermissions.includes(requiredPermissions[i])) {
            allowed = false;
            missingPermissions.push(requiredPermissions[i]);
        }
    }

    if (!allowed && !isAdmin) {
        return (
            <div className="page-container">
                <div style={{ display: "flex" }}>
                    <SettingsSidebar active={active} />
                    <div style={{ width: "calc(100% - var(--sidebar-width))" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "30px",
                            }}
                        >
                            <BsShieldLockFill style={{ marginRight: "5px" }} />{" "}
                            You are missing the following permission(s) to
                            access this page: {missingPermissions.join(", ")}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={() => nav(-1)}
                                title="Go Back"
                                type="light"
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return <>{children}</>;
}
export default SettingsPermissionGate;
